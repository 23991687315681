/**
 * Title: UploadPds.js
 * Description: This is a file that contains the components for the PDF Modal of the Personal Data Sheet that renders the PDF.
 * Authors:
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSuploadView.js
 **/

/*
 *Changes made:
 *2024.08.29  | Raymart Mojado | view for the uploaded pds Excel
 */
import React, { useState } from 'react'

import { Button, Modal, Upload, message } from 'antd'
import ExcelJS from 'exceljs'
import moment from 'moment'
import * as XLSX from 'xlsx'

import { UploadOutlined } from '@ant-design/icons'

import PDSuploadView from './PDSuploadView'

const countryData = [
  ' ',
  'Afghanistan',
  'Albania',
  ' ',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas, The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burma',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Esthiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia, The',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukrain',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

const UploadPds = ({ exportData, exportFrom }) => {
  const [data, setData] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [iDpicture, setIDpicture] = useState('')
  const [thumbImage, setThumbImage] = useState('')
  const [isDoneOk, setIsDoneOk] = useState(false)

  const civilServiceDropdownData = [
    'CAREER SERVICE - PROFESSIONAL',
    'CAREER SERVICE - SUBPROFESSIONAL',
    'CAREER SERVICE-SUB-PROFESSIONAL',
    'CAREER SERVICE - PROFESSIONAL',
    "PROFESSIONAL DRIVER'S LICENSE",
  ]
  const nameExtensionArray = [
    'N/A',
    'JR.',
    'SR.',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
  ]

  const formatNameExtension = (nameExtension) => {
    try {
      if (nameExtensionArray.includes(nameExtension.toUpperCase())) {
        return nameExtension.toUpperCase()
      }
      return ''
    } catch (error) {
      console.error('Error formatting name extension:', error)
      return ''
    }
  }

  const handleOk = () => {
    Modal.confirm({
      title: 'Confirmation',
      content:
        exportFrom === 'draft'
          ? 'This action will overwrite your current draft. Do you want to proceed?'
          : 'This action will overwrite your current data. Do you want to proceed?',
      width: 550,
      onOk() {
        const draftData = { ...data }
        delete draftData.cseData
        delete draftData.educationalBackground
        delete draftData.voluntaryWorkData
        delete draftData.workExperience
        exportData({
          data: draftData,
          idPicture: iDpicture,
          thumbImage: thumbImage,
        })
        setIsModalVisible(false)
        setIsDoneOk(false)
        setData(null) // Clear the data state
        setFileList([]) // Clear the file list
        message.success('PDS Imported Successfully')
      },
      onCancel() {
        console.log('Cancelled')
      },
    })
  }

  const excelDateToJSDate = (dateValue) => {
    const excelEpoch = new Date(1899, 11, 30) // Excel epoch starts at Dec 30, 1899

    if (
      typeof dateValue === 'number' ||
      (!isNaN(dateValue) && typeof dateValue === 'string')
    ) {
      // Handle Excel serial number (either number or numeric string)
      const serial = Number(dateValue) // Convert string to number if needed
      let jsDate = new Date(excelEpoch.getTime() + (serial - 1) * 86400000) // Convert to JS date
      if (serial > 60) {
        jsDate = new Date(jsDate.getTime() + 86400000) // Adjust for Excel's leap year bug
      }
      jsDate = new Date(jsDate.getTime() + 86400000) // Add one day to compensate for the discrepancy
      return !isNaN(jsDate) ? jsDate.toISOString().split('T')[0] : '' // Return in YYYY-MM-DD format if valid
    } else if (
      typeof dateValue === 'string' &&
      dateValue.toLowerCase() !== 'present'
    ) {
      // Handle string dates (e.g., "MM/DD/YYYY")
      const parsedDate = new Date(dateValue)
      if (!isNaN(parsedDate)) {
        const adjustedDate = new Date(parsedDate.getTime() + 86400000) // Add one day to compensate for the discrepancy
        return adjustedDate.toISOString().split('T')[0] // Convert to YYYY-MM-DD if valid
      }
    }
    return '' // Return empty string if date is invalid
  }

  const handleFileUpload = async (file) => {
    try {
      const isExcel =
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        'application/vnd.ms-excel'
      if (!isExcel) {
        message.error(
          'Invalid file type. Please upload only Excel files (e.g., .xls, .xlsx).'
        )
        return Upload.LIST_IGNORE
      }

      const reader = new FileReader()

      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const worksheetPage2 = workbook.Sheets[workbook.SheetNames[1]]
        const worksheetPage3 = workbook.Sheets[workbook.SheetNames[2]]
        const worksheetPage4 = workbook.Sheets[workbook.SheetNames[3]]

        if (worksheet && worksheetPage2 && worksheetPage3 && worksheetPage4) {
          const range = XLSX.utils.decode_range('D10:N10')
          // Extract data from the specified range
          const surNameData = []
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: col })
            const cell = worksheet[cellAddress]
            surNameData.push(cell ? cell.v : '')
          }

          // Filter out empty values and join non-empty values
          const surname = surNameData.filter((value) => value).join(', ')
          const firstNameCellReferences = [
            'D11',
            'E11',
            'F11',
            'G11',
            'H11',
            'I11',
            'J11',
            'K11',
          ]
          const firstName = firstNameCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const nameExtensionCells = ['L11', 'M11', 'N11']
          const nameExtension = nameExtensionCells
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .replace(/NAME EXTENSION \(JR\., SR\)\s*/i, '')
          const middleNameCellReferences = [
            'D12',
            'E12',
            'F12',
            'G12',
            'H12',
            'I12',
            'J12',
            'K12',
            'L12',
            'M12',
            'N12',
          ]
          const middleName = middleNameCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const dateOfBirthCellReferences = [
            'D13',
            'E13',
            'F13',
            'D14',
            'E14',
            'F14',
          ]
          const dateOfBirth = dateOfBirthCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const placeOfBirthCellReferences = ['D15', 'E15', 'F15']
          const placeOfBirth = placeOfBirthCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const sexCellReferences = {
            MALE: 'B63',
            FEMALE: 'C63',
          }
          const sex = Object.keys(sexCellReferences).filter((data) => {
            const cell = worksheet[sexCellReferences[data]]
            return cell && cell.v === true
          })
          const sexData = sex.length === 1 ? sex[0] : ''

          const civilStatusCellReferences = {
            SINGLE: 'B63',
            MARRIED: 'C63',
            WIDOWED: 'B64',
            SEPARATED: 'C64',
            OTHERS: 'B65',
          }

          const checkedStatuses = Object.keys(civilStatusCellReferences).filter(
            (status) => {
              const cell = worksheet[civilStatusCellReferences[status]]
              return cell && cell.v === true
            }
          )

          const civilStatus =
            checkedStatuses.length === 1 ? checkedStatuses[0] : ''

          const heightCellReferences = [
            'D22',
            'E22',
            'F22',
            'D23',
            'E23',
            'F23',
          ]
          const height = heightCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const weightCellReferences = ['D24', 'E24', 'F24']
          const weight = weightCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const bloodTypeCellReferences = [
            'D25',
            'E25',
            'F25',
            'D26',
            'E26',
            'F26',
          ]
          const bloodType = bloodTypeCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const gsisNoCellReferences = [
            'D27',
            'E27',
            'F27',
            'D28',
            'E28',
            'F28',
          ]
          const gsisNo = gsisNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const pagIbigIdNoCellReferences = [
            'D29',
            'E29',
            'F29',
            'D30',
            'E30',
            'F30',
          ]
          const pagIbigIdNo = pagIbigIdNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const philHealthNoCellReferences = ['D31', 'E31', 'F31']
          const philHealthNo = philHealthNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const sssNoCellReferences = ['D32', 'E32', 'F32']
          const sssNo = sssNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const tinNoCellReferences = ['D33', 'E33', 'F33']
          const tinNo = tinNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const agencyEmployeeNoCellReferences = ['D34', 'E34', 'F34']
          const agencyEmployeeNo = agencyEmployeeNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const citizenshipCellReferences = {
            FILIPINO: 'B66',
            DUALCITIZENSHIP: 'C66',
          }

          const checkedCitizenships = Object.keys(
            citizenshipCellReferences
          ).filter((status) => {
            const cell = worksheet[citizenshipCellReferences[status]]
            return cell && cell.v === true
          })
          const citizenship =
            checkedCitizenships.length === 1 ? checkedCitizenships[0] : ''

          const citizenshipTypeCellReferences = {
            BYBIRTH: 'B67',
            BYNATURALIZATION: 'C67',
          }

          const checkedCitizenshipTypes = Object.keys(
            citizenshipTypeCellReferences
          ).filter((type) => {
            const cell = worksheet[citizenshipTypeCellReferences[type]]
            return cell && cell.v === true
          })

          const citizenshipType =
            checkedCitizenshipTypes.length === 1
              ? checkedCitizenshipTypes[0]
              : ''

          const countryCellReferences = ['J16', 'K16', 'L16', 'M16', 'N16']
          const countryIndex = countryCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .replace('Pls. indicate country:', '')
            .trim()
          const country = countryData[countryIndex - 1]
          const residentialHouseNoCellReferences = ['I17', 'J17', 'K17']
          const residentialHouseNo = residentialHouseNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const residentialStreetCellReferences = ['L17', 'M17', 'N17']
          const residentialStreet = residentialStreetCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const residentialSubdivisionVillageCellReferences = [
            'I19',
            'J19',
            'K19',
            'I20',
            'J20',
            'K20',
          ]
          const residentialSubdivisionVillage =
            residentialSubdivisionVillageCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()
          const residentialBarangayCellReferences = [
            'L19',
            'M19',
            'N19',
            'L20',
            'M20',
            'N20',
          ]
          const residentialBarangay = residentialBarangayCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const residentialCityMunicipalityCellReferences = [
            'I22',
            'J22',
            'K22',
          ]
          const residentialCityMunicipality =
            residentialCityMunicipalityCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()
          const residentialProvinceCellReferences = ['L22', 'M22', 'N22']
          const residentialProvince = residentialProvinceCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const residentialZipCodeCellReferences = [
            'I24',
            'J24',
            'K24',
            'L24',
            'M24',
            'N24',
          ]
          const residentialZipCode = residentialZipCodeCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const permanentAddressHouseNoCellReferences = ['I25', 'J25', 'K25']
          const permanentAddressHouseNo = permanentAddressHouseNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const permanentSubdivisionVillageNoCellReferences = [
            'I27',
            'J27',
            'K27',
          ]
          const permanentSubdivisionVillage =
            permanentSubdivisionVillageNoCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

          const permanentStreetNoCellReferences = ['L25', 'M25', 'N25']
          const permanentStreet = permanentStreetNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const permanentBarangayCellReferences = ['L27', 'M27', 'N27']
          const permanentBarangay = permanentBarangayCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const permanentCityCellReferences = ['I29', 'J29', 'K29']
          const permanentCity = permanentCityCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const permanentProvinceCellReferences = ['L29', 'M29', 'N29']
          const permanentProvince = permanentProvinceCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const permanentZipcodeCellReferences = [
            'I31',
            'J31',
            'K31',
            'L31',
            'M31',
            'N31',
          ]
          const permanentZipcode = permanentZipcodeCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
          const telephoneNoCellReferences = [
            'I32',
            'J32',
            'K32',
            'L32',
            'M32',
            'N32',
          ]
          const telephoneNo = telephoneNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .toUpperCase()
          const mobileNoCellReferences = [
            'I33',
            'J33',
            'K33',
            'L33',
            'M33',
            'N33',
          ]
          const mobileNo = mobileNoCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const emailCellReferences = ['I34', 'J34', 'K34', 'L34', 'M34', 'N34']
          const emailAddress = emailCellReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const childrenData = []
          for (let row = 37; row <= 48; row++) {
            const nameCellReferences = [
              `I${row}`,
              `J${row}`,
              `K${row}`,
              `L${row}`,
            ]
            const dobCellReferences = [`M${row}`, `N${row}`]

            const name = nameCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const dob = dobCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            if (
              name &&
              dob &&
              name !== 'N/A' &&
              name !== 'n/a' &&
              dob !== 'N/A' &&
              dob !== 'n/a'
            ) {
              childrenData.push({ nameOfChildren: name, dateOfBirth: dob })
            }
          }

          const spouseSurnameReferences = ['D36', 'E36', 'F36', 'G36', 'H36']
          const spouseSurname = spouseSurnameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const spousefirstNameReferences = ['D37', 'E37', 'F37']
          const spouseFirstName = spousefirstNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const spousemiddleNameReferences = ['D38', 'E38', 'F38', 'G38', 'H38']
          const spouseMiddleName = spousemiddleNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const nameSpouseExtensionCells = ['G37', 'H37']
          const spouseNameExtension = nameSpouseExtensionCells
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .replace(/NAME EXTENSION \(JR\., SR\)\s*/i, '')

          const occupationReferences = ['D39', 'E39', 'F39', 'G39', 'H39']
          const occupation = occupationReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const bussinessAddressReferences = ['D41', 'E41', 'F41', 'G41', 'H41']
          const bussinessAddress = bussinessAddressReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const businessNameReferences = ['D40', 'E40', 'F40', 'G40', 'H40']
          const businessName = businessNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const businessTelephoneNoReferences = [
            'D42',
            'E42',
            'F42',
            'G42',
            'H42',
          ]
          const businessTelephoneNo = businessTelephoneNoReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .toUpperCase()
          const fathersSurnameReferences = ['D43', 'E43', 'F43', 'G43', 'H43']
          const fathersSurname = fathersSurnameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const fathersFirstNameReferences = ['D44', 'E44', 'F44']
          const fathersFirstName = fathersFirstNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const fathersMiddleNameReferences = [
            'D45',
            'E45',
            'F45',
            'G45',
            'H45',
          ]
          const fathersMiddleName = fathersMiddleNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const fatherNameExtensionCells = ['G44', 'H44']
          const fatherNameExtension = fatherNameExtensionCells
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()
            .replace(/NAME EXTENSION \(JR\., SR\)\s*/i, '')

          const motherSurnameReferences = ['D47', 'E47', 'F47', 'G47', 'H47']
          const motherSurname = motherSurnameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const motherFirstNameReferences = ['D48', 'E48', 'F48', 'G48', 'H48']
          const motherFirstName = motherFirstNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const motherMiddleNameReferences = ['D49', 'E49', 'F49', 'G49', 'H49']
          const motherMiddleName = motherMiddleNameReferences
            .map((ref) => {
              const cell = worksheet[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const educationalBackground = []
          for (let row = 54; row <= 58; row++) {
            const levelCellReferences = [`A${row}`, `B${row}`, `C${row}`]
            const nameOfSchoolCellReferences = [`D${row}`, `E${row}`, `F${row}`]
            const basicEducationCellReferences = [
              `G${row}`,
              `H${row}`,
              `I${row}`,
            ]
            const periodOfAttendanceFromCellReferences = [`J${row}`]
            const periodOfAttendanceToCellReferences = [`K${row}`]
            const unitsEarnedCellReferences = [`L${row}`]
            const yearGraduatedCellReferences = [`M${row}`]
            const scholarshipCellReferences = [`N${row}`]
            const level = levelCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const nameOfSchool = nameOfSchoolCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const basicEducation = basicEducationCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const periodOfAttendanceFrom = periodOfAttendanceFromCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()
              .toUpperCase()
            const periodOfAttendanceTo = periodOfAttendanceToCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()
              .toUpperCase()
            const unitsEarned = unitsEarnedCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const yearGraduated = yearGraduatedCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()
              .toUpperCase()
            const scholarship = scholarshipCellReferences
              .map((ref) => {
                const cell = worksheet[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            if (
              level ||
              nameOfSchool ||
              basicEducation ||
              periodOfAttendanceFrom ||
              periodOfAttendanceTo ||
              unitsEarned
            ) {
              educationalBackground.push({
                level: level,
                nameOfSchool: nameOfSchool,
                basicEducation: basicEducation,
                periodOfAttendanceFrom: periodOfAttendanceFrom,
                periodOfAttendanceTo: periodOfAttendanceTo,
                unitsEarned: unitsEarned,
                yearGraduated: yearGraduated,
                scholarship: scholarship,
              })
            }
          }
          // excel page2
          let continueSheetPositions = []
          let cseData = []
          let workExperienceData = []
          // Iterate through the references to find all occurrences of '(Continue on separate sheet if necessary)'
          for (let ref in worksheetPage2) {
            if (
              worksheetPage2[ref].v ===
              '(Continue on separate sheet if necessary)'
            ) {
              continueSheetPositions.push(ref)
            }
          }

          if (continueSheetPositions.length >= 1) {
            // Extract the row numbers for Civil Service Eligibility data
            const cseStartRow = 5
            const cseEndRow =
              parseInt(continueSheetPositions[0].match(/\d+/)[0], 10) - 1

            // Collect Civil Service Eligibility data
            const cseRowsData = []
            for (let row = cseStartRow; row <= cseEndRow; row++) {
              const rowData = {}
              const columns = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
              ] // Adjust columns as needed
              columns.forEach((col) => {
                const cellRef = `${col}${row}`
                if (worksheetPage2[cellRef]) {
                  rowData[col] = worksheetPage2[cellRef].v
                }
              })
              cseRowsData.push(rowData)
            }

            // Remove rows without data
            const filteredCseRowsData = cseRowsData.filter(
              (row) => Object.keys(row).length > 0
            )

            // Process the filtered rows data and add to cseData
            filteredCseRowsData.forEach((row) => {
              const careerService = ['A', 'B', 'C', 'D', 'E']
                .map((col) => row[col] || '')
                .join(' ')
                .trim()
              const cseRating = row['F'] || ''
              const cseDateExamination = ['G', 'H']
                .map((col) => row[col] || '')
                .join(' ')
                .trim()
              const csePlaceExamination = ['I', 'J', 'K']
                .map((col) => row[col] || '')
                .join(' ')
                .trim()
              const cseLicenseNumber = row['L'] || ''
              const cseLicenseDateofValidity = row['M'] || ''

              if (
                careerService ||
                cseRating ||
                cseDateExamination ||
                csePlaceExamination ||
                cseLicenseNumber ||
                cseLicenseDateofValidity
              ) {
                cseData.push({
                  careerService: careerService,
                  cseRating: cseRating,
                  cseDateExamination: cseDateExamination,
                  csePlaceExamination: csePlaceExamination,
                  cseLicenseNumber: cseLicenseNumber,
                  cseLicenseDateofValidity:
                    cseLicenseDateofValidity.length !== 0
                      ? cseLicenseDateofValidity == 'N/A'
                        ? 'N/A'
                        : excelDateToJSDate(cseLicenseDateofValidity)
                      : '',
                })
              }
            })

            // Extract the row numbers for work experience data
            const workStartRow =
              parseInt(continueSheetPositions[0].match(/\d+/)[0], 10) + 6
            const workEndRow =
              parseInt(continueSheetPositions[1].match(/\d+/)[0], 10) - 1

            // Collect work experience data from the target rows
            const targetRowsData = []
            for (let row = workStartRow; row <= workEndRow; row++) {
              const rowData = {}
              const columns = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
              ] // Adjust columns as needed
              columns.forEach((col) => {
                const cellRef = `${col}${row}`
                if (worksheetPage2[cellRef]) {
                  rowData[col] = worksheetPage2[cellRef].v
                }
              })
              targetRowsData.push(rowData)
            }

            // Remove rows without data
            const filteredRowsData = targetRowsData.filter(
              (row) => Object.keys(row).length > 0
            )

            // Process the filtered rows data and add to workExperienceData
            // workExperienceData = filteredRowsData.map(row => {
            //   const dateFrom = ['A', 'B'].map(col => row[col] || '').join(' ').trim();;
            //   const dateTo = row['C'] ? (row['C'] === 'PRESENT' || row['C'] === 'up to present' ? 'PRESENT' : row['C']) : '';
            //   const positionTitle = row['D'] || '';
            //   const departmentCompany = row['G'] || '';
            //   const monthlySalary = row['J'] ? parseFloat(row['J'].toString().replace(/,/g, '')).toLocaleString('en-US', {
            //     minimumFractionDigits: 2,
            //     maximumFractionDigits: 2,
            //   }) : '';
            //   const jobPayGrade = row['K'] || '';
            //   const statusAppointment = row['L'] || '';
            //   const govtService = row['M'] ? row['M'].replace(/'/g, '') : '';

            //   return {
            //     inclusiveDataFrom: dateFrom,
            //     inclusiveDataTo: dateTo,
            //     positionTitle: positionTitle,
            //     departmentCompany: departmentCompany,
            //     monthlySalary: monthlySalary,
            //     jobPayGrade: jobPayGrade,
            //     statusAppointment: statusAppointment,
            //     govtService: govtService === 'Y' ? 'YES' : (govtService === 'N' ? 'NO' : govtService),
            //   };
            // });
          }

          // work experience data dynamic
          if (continueSheetPositions.length >= 2) {
            // Extract the row numbers
            const startRow =
              parseInt(continueSheetPositions[0].match(/\d+/)[0], 10) + 6
            const endRow =
              parseInt(continueSheetPositions[1].match(/\d+/)[0], 10) - 1

            // Collect data from the target rows
            const targetRowsData = []
            for (let row = startRow; row <= endRow; row++) {
              const rowData = {}
              const columns = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
              ] // Adjust columns as needed
              columns.forEach((col) => {
                const cellRef = `${col}${row}`
                if (worksheetPage2[cellRef]) {
                  rowData[col] = worksheetPage2[cellRef].v
                }
              })
              targetRowsData.push(rowData)
            }

            const filteredRowsData = targetRowsData.filter(
              (row) => Object.keys(row).length > 0
            )
            workExperienceData = filteredRowsData.map((row) => {
              const dateFrom = ['A', 'B']
                .map((col) => row[col] || '')
                .join(' ')
                .trim()
              const dateTo = row['C']
              const positionTitle = row['D'] || ''
              const departmentCompany = row['G'] || ''
              const monthlySalary = row['J']
                ? parseFloat(
                    row['J'].toString().replace(/,/g, '')
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : ''
              const jobPayGrade = row['K'] || ''
              const statusAppointment = row['L'] || ''
              const govtService = row['M'] ? row['M'].replace(/'/g, '') : ''

              return {
                inclusiveDataFrom: dateFrom,
                inclusiveDataTo: dateTo,
                positionTitle: positionTitle,
                departmentCompany: departmentCompany,
                monthlySalary: monthlySalary,
                jobPayGrade: jobPayGrade,
                statusAppointment: statusAppointment,
                govtService:
                  govtService === 'Y'
                    ? 'YES'
                    : govtService === 'N'
                      ? 'NO'
                      : govtService,
              }
            })
          }

          // for (let row = 19; row <= 46; row++) {
          //   const dateFromReference = [`A${row}`, `B${row}`]
          //   const dateToReference = [`C${row}`]
          //   const positionTitleReference = [`D${row}`, `E${row}`, `F${row}`]
          //   const departmentCompanyReference = [`G${row}`, `H${row}`, `I${row}`]
          //   const monthlySalaryReference = [`J${row}`]
          //   const jobPayGradeReference = [`K${row}`]
          //   const statusAppointmentReference = [`L${row}`]
          //   const govtServiceReference = [`M${row}`]
          //   const dateFrom = dateFromReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()
          //   const dateTo = dateToReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const positionTitle = positionTitleReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const departmentCompany = departmentCompanyReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const monthlySalary = monthlySalaryReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const jobPayGrade = jobPayGradeReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const statusAppointment = statusAppointmentReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   const govtService = govtServiceReference
          //     .map((ref) => {
          //       const cell = worksheetPage2[ref]
          //       return cell ? cell.v : ''
          //     })
          //     .join(' ')
          //     .trim()

          //   if (dateFrom || dateTo || positionTitle) {

          //     workExperienceData.push({
          //       inclusiveDataFrom: dateFrom,
          //       inclusiveDataTo: dateTo,
          //       positionTitle: positionTitle,
          //       departmentCompany: departmentCompany,
          //       monthlySalary: monthlySalary,
          //       jobPayGrade: jobPayGrade,
          //       statusAppointment: statusAppointment,
          //       govtService: govtService,
          //     })
          //   }
          // }

          // excel page3

          // Iterate through the references to find all occurrences of '(Continue on separate sheet if necessary)' on page 3
          let continueSheetPositionsPage3 = []
          let voluntaryWorkData = []
          let learnAndDevelopmentData = []
          // Iterate through the references to find all occurrences of '(Continue on separate sheet if necessary)' on page 3
          for (let ref in worksheetPage3) {
            if (
              worksheetPage3[ref].v ===
              '(Continue on separate sheet if necessary)'
            ) {
              continueSheetPositionsPage3.push(ref)
            }
          }

          if (continueSheetPositionsPage3.length > 0) {
            // Extract the row number for the first occurrence
            const endRow =
              parseInt(continueSheetPositionsPage3[0].match(/\d+/)[0], 10) - 1

            // Collect data from row 6 to the row just above the first occurrence

            for (let row = 6; row <= endRow; row++) {
              const rowData = {}
              const columns = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
              ] // Adjust columns as needed
              columns.forEach((col) => {
                const cellRef = `${col}${row}`
                if (worksheetPage3[cellRef]) {
                  rowData[col] = worksheetPage3[cellRef].v
                }
              })
              voluntaryWorkData.push(rowData)
            }

            // Remove rows without data
            const filteredVoluntaryWorkData = voluntaryWorkData.filter(
              (row) => Object.keys(row).length > 0
            )

            const transformedVoluntaryWorkData = filteredVoluntaryWorkData.map(
              (row) => {
                const organizationName = ['A', 'B', 'C', 'D']
                  .map((col) => row[col] || '')
                  .join(' ')
                  .trim()
                const voluntaryWorkDateFrom = row['E'] || ''
                const voluntaryWorkDateTo = row['F'] || ''
                const voluntaryWorkHoursNo = row['G'] || ''
                const voluntaryWorkPosition = ['H', 'I', 'J', 'K']
                  .map((col) => row[col] || '')
                  .join(' ')
                  .trim()

                return {
                  voluntaryWorkOrg: organizationName,
                  voluntaryWorkDateFrom: voluntaryWorkDateFrom,
                  voluntaryWorkDateTo: voluntaryWorkDateTo,
                  voluntaryWorkHoursNo: voluntaryWorkHoursNo,
                  voluntaryWorkPosition: voluntaryWorkPosition,
                }
              }
            )

            voluntaryWorkData = transformedVoluntaryWorkData
          }
          if (continueSheetPositionsPage3.length >= 3) {
            // Extract the row numbers for the first and third occurrences
            const startRow =
              parseInt(continueSheetPositionsPage3[0].match(/\d+/)[0], 10) + 6
            const endRow =
              parseInt(continueSheetPositionsPage3[1].match(/\d+/)[0], 10) - 1

            // Collect data from startRow to endRow
            const learnAndDevelopData = []
            for (let row = startRow; row <= endRow; row++) {
              const rowData = {}
              const columns = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
              ] // Adjust columns as needed
              columns.forEach((col) => {
                const cellRef = `${col}${row}`
                if (worksheetPage3[cellRef]) {
                  rowData[col] = worksheetPage3[cellRef].v
                }
              })
              // Only push rowData if it contains significant values
              if (
                Object.values(rowData).some((value) => value && value !== 'n/a')
              ) {
                learnAndDevelopData.push(rowData)
              }
            }

            // Process the filtered rows data and transform it as needed
            const transformedLearnAndDevelopData = learnAndDevelopData.map(
              (row) => {
                const learnAndDevelopmentProgram = ['A', 'B', 'C', 'D']
                  .map((col) => row[col] || '')
                  .join(' ')
                  .trim()
                const learnAndDevelopmentDateFrom = row['E']
                  ? excelDateToJSDate(row['E'])
                  : ''
                const learnAndDevelopmentDateTo = row['F']
                  ? row['F'] === 'PRESENT'
                    ? 'PRESENT'
                    : excelDateToJSDate(row['F'])
                  : ''
                const learnAndDevelopmentHoursNo = row['G'] || ''
                const learnAndDevelopmentTypeLd = row['H'] || ''
                const learnAndDevelopmentSponsoredBy = ['I', 'J', 'K', 'L', 'M']
                  .map((col) => row[col] || '')
                  .join(' ')
                  .trim()

                return {
                  learnAndDevelopmentProgram: learnAndDevelopmentProgram,
                  learnAndDevelopmentDateFrom: learnAndDevelopmentDateFrom,
                  learnAndDevelopmentDateTo: learnAndDevelopmentDateTo,
                  learnAndDevelopmentHoursNo: learnAndDevelopmentHoursNo,
                  learnAndDevelopmentTypeLd: learnAndDevelopmentTypeLd,
                  learnAndDevelopmentSponsoredBy:
                    learnAndDevelopmentSponsoredBy,
                }
              }
            )

            // Push only the transformed data based on the specified conditions
            const learnAndDevelopmData = []
            transformedLearnAndDevelopData.forEach((item) => {
              if (
                item.learnAndDevelopmentProgram ||
                item.learnAndDevelopmentDateFrom ||
                item.learnAndDevelopmentDateTo ||
                item.learnAndDevelopmentHoursNo ||
                item.learnAndDevelopmentSponsoredBy ||
                item.learnAndDevelopmentTypeLd
              ) {
                learnAndDevelopmData.push({
                  learnAndDevelopmentProgram: item.learnAndDevelopmentProgram,
                  ldTrainingDateFrom: item.learnAndDevelopmentDateFrom,
                  ldTrainingDateTo: item.learnAndDevelopmentDateTo,
                  ldTrainingHoursNo: item.learnAndDevelopmentHoursNo,
                  ldTrainingSponsoredBy: item.learnAndDevelopmentSponsoredBy,
                  ldType: item.learnAndDevelopmentTypeLd,
                })
              }
            })
            learnAndDevelopmentData = learnAndDevelopmData
          }

          const startRow =
            parseInt(continueSheetPositionsPage3[1].match(/\d+/)[0], 10) + 3
          const endRow =
            parseInt(continueSheetPositionsPage3[2].match(/\d+/)[0], 10) - 1

          const skillAndHobbiesData = []
          for (let row = startRow; row <= endRow; row++) {
            const skillAndHobbiesReference = [`A${row}`, `B${row}`]

            const skillAndHobbies = skillAndHobbiesReference
              .map((ref) => {
                const cell = worksheetPage3[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            if (skillAndHobbies) {
              skillAndHobbiesData.push({ skillsAndHobbies: skillAndHobbies })
            }
          }
          const nonAcademicdistinctionsrecognitionGridData = []
          for (let row = startRow; row <= endRow; row++) {
            const nonAcademicdistinctionsrecognitionReference = [
              `C${row}`,
              `D${row}`,
              `E${row}`,
              `F${row}`,
              `G${row}`,
              `H${row}`,
            ]

            const nonAcademicdistinctionsrecognition =
              nonAcademicdistinctionsrecognitionReference
                .map((ref) => {
                  const cell = worksheetPage3[ref]
                  return cell ? cell.v : ''
                })
                .join(' ')
                .trim()

            if (nonAcademicdistinctionsrecognition) {
              nonAcademicdistinctionsrecognitionGridData.push({
                nonAcademicdistinctionsrecognition:
                  nonAcademicdistinctionsrecognition,
              })
            }
          }

          const orgMembershipGridData = []
          for (let row = startRow; row <= endRow; row++) {
            const orgMembershipGridDataReference = [
              `I${row}`,
              `J${row}`,
              `K${row}`,
            ]

            const orgMembershipGrid = orgMembershipGridDataReference
              .map((ref) => {
                const cell = worksheetPage3[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            if (orgMembershipGrid) {
              orgMembershipGridData.push({ orgMembership: orgMembershipGrid })
            }
          }

          // let skillAndHobbiesData = []
          // let nonAcademicdistinctionsrecognitionGridData = []
          // let orgMembershipGridData = []
          // if (continueSheetPositionsPage3.length >= 3) {
          //   console.log(`Found '(Continue on separate sheet if necessary)' on page 3 at positions: ${continueSheetPositionsPage3.join(', ')}`);

          //   // Extract the row numbers for the second and third occurrences
          //   const startRow = parseInt(continueSheetPositionsPage3[1].match(/\d+/)[0], 10) + 6;
          //   const endRow = parseInt(continueSheetPositionsPage3[2].match(/\d+/)[0], 10) - 1;

          //   // Collect data from startRow to endRow for skillAndHobbiesData
          //   const skillAndHobbies = [];
          //   for (let row = startRow; row <= endRow; row++) {
          //     const skillAndHobbiesReference = [`A${row}`, `B${row}`];
          //     const skillAndHobbie = skillAndHobbiesReference
          //       .map((ref) => {
          //         const cell = worksheetPage3[ref];
          //         return cell ? cell.v : '';
          //       })
          //       .join(' ')
          //       .trim();

          //     if (skillAndHobbie) {
          //       skillAndHobbies.push({ skillsAndHobbies: skillAndHobbies });
          //     }
          //   }
          //   skillAndHobbiesData = skillAndHobbies
          //   // Collect data from startRow to endRow for nonAcademicdistinctionsrecognitionGridData
          //   let nonAcademicdistinctionsrecognitionGrid = [];
          //   for (let row = startRow; row <= endRow; row++) {
          //     const nonAcademicdistinctionsrecognitionReference = [
          //       `C${row}`,
          //       `D${row}`,
          //       `E${row}`,
          //       `F${row}`,
          //       `G${row}`,
          //       `H${row}`,
          //     ];
          //     const nonAcademicdistinctionsrecognition = nonAcademicdistinctionsrecognitionReference
          //       .map((ref) => {
          //         const cell = worksheetPage3[ref];
          //         return cell ? cell.v : '';
          //       })
          //       .join(' ')
          //       .trim();

          //     if (nonAcademicdistinctionsrecognition) {
          //       nonAcademicdistinctionsrecognitionGrid.push({
          //         nonAcademicdistinctionsrecognition: nonAcademicdistinctionsrecognition,
          //       });
          //     }
          //   }
          //   nonAcademicdistinctionsrecognitionGridData = nonAcademicdistinctionsrecognitionGrid
          //   // Collect data from startRow to endRow for orgMembershipGridData
          //   let orgMembershipGridArray = [];
          //   for (let row = startRow; row <= endRow; row++) {
          //     const orgMembershipGridDataReference = [`I${row}`, `J${row}`, `K${row}`];
          //     const orgMembershipGrid = orgMembershipGridDataReference
          //       .map((ref) => {
          //         const cell = worksheetPage3[ref];
          //         return cell ? cell.v : '';
          //       })
          //       .join(' ')
          //       .trim();

          //     if (orgMembershipGrid) {
          //       orgMembershipGridArray.push({ orgMembership: orgMembershipGrid });
          //     }
          //   }
          //   orgMembershipGridData = orgMembershipGridArray
          //   console.log(`Skill and Hobbies data from rows ${startRow} to ${endRow}:`, skillAndHobbiesData);
          //   console.log(`Non-Academic Distinctions Recognition data from rows ${startRow} to ${endRow}:`, nonAcademicdistinctionsrecognitionGridData);
          //   console.log(`Org Membership data from rows ${startRow} to ${endRow}:`, orgMembershipGridData);
          // } else {
          //   console.log(`Less than three '(Continue on separate sheet if necessary)' found on page 3`);
          // }

          // PAGE 4
          const thirdDegreeReference = {
            yes: 'G2',
            no: 'H2',
          }
          const thirdDegree = Object.keys(thirdDegreeReference).filter(
            (data) => {
              const cell = worksheetPage4[thirdDegreeReference[data]]
              return cell && cell.v === true
            }
          )
          const thirdDegreeData = thirdDegree.length === 1 ? thirdDegree[0] : ''
          // isFourthDegreeRequired
          const fourthDegreeReference = {
            YES: 'G3',
            NO: 'H3',
          }
          const fourthDegree = Object.keys(fourthDegreeReference).filter(
            (data) => {
              const cell = worksheetPage4[fourthDegreeReference[data]]
              return cell && cell.v === true
            }
          )
          const fourthDegreeData =
            fourthDegree.length === 1 ? fourthDegree[0] : ''

          const relatedExplainReferences = [
            'G11',
            'H11',
            'I11',
            'J11',
            'K11',
            'L11',
            'M11',
          ]
          const relatedExplain = relatedExplainReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const offenseAReference = {
            YES: 'G13',
            NO: 'I13',
          }
          const offenseA = Object.keys(offenseAReference).filter((data) => {
            const cell = worksheetPage4[offenseAReference[data]]
            return cell && cell.v === true
          })

          const offenseAData = offenseA.length === 1 ? offenseA[0] : ''

          const offenseADetailsReferences = [
            'G15',
            'H15',
            'I15',
            'J15',
            'K15',
            'L15',
            'M15',
          ]
          const offenseADetails = offenseADetailsReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const offenseCReference = {
            YES: 'G17',
            NO: 'I17',
          }
          const offenseC = Object.keys(offenseCReference).filter((data) => {
            const cell = worksheetPage4[offenseCReference[data]]
            return cell && cell.v === true
          })

          const offenseCData = offenseC.length === 1 ? offenseC[0] : ''

          const offenseDateFiledReferences = ['K20', 'L20']
          const offenseDateFiled = offenseDateFiledReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const offenseCaseStatusReferences = ['K21', 'L21']
          const offenseCaseStatus = offenseCaseStatusReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const courtARequiredReference = {
            YES: 'G23',
            NO: 'H23',
          }
          const courtARequired = Object.keys(courtARequiredReference).filter(
            (data) => {
              const cell = worksheetPage4[courtARequiredReference[data]]
              return cell && cell.v === true
            }
          )

          const courtARequiredData =
            courtARequired.length === 1 ? courtARequired[0] : ''

          const courtADetailsReferences = [
            'G25',
            'H25',
            'I25',
            'J25',
            'K25',
            'L25',
            'M25',
          ]
          const courtADetails = courtADetailsReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const serviceSeparationARequiredReference = {
            YES: 'G27',
            NO: 'H27',
          }
          const serviceSeparationARequired = Object.keys(
            serviceSeparationARequiredReference
          ).filter((data) => {
            const cell = worksheetPage4[courtARequiredReference[data]]
            return cell && cell.v === true
          })

          const serviceSeparationARequiredData =
            serviceSeparationARequired.length === 1
              ? serviceSeparationARequired[0]
              : ''

          const serviceSeparationADetailsReferences = [
            'G29',
            'H29',
            'I29',
            'J29',
            'K29',
            'L29',
            'M29',
          ]
          const serviceSeparationADetails = serviceSeparationADetailsReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const candidateRequiredRequiredReference = {
            YES: 'G31',
            NO: 'H31',
          }
          const candidateRequired = Object.keys(
            candidateRequiredRequiredReference
          ).filter((data) => {
            const cell =
              worksheetPage4[candidateRequiredRequiredReference[data]]
            return cell && cell.v === true
          })

          const candidateRequiredData =
            candidateRequired.length === 1 ? candidateRequired[0] : ''

          const candidateDetailsReferences = ['K32', 'L32']
          const candidateDetails = candidateDetailsReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const resignedRequiredReference = {
            YES: 'G34',
            NO: 'H34',
          }
          const resignedRequired = Object.keys(
            resignedRequiredReference
          ).filter((data) => {
            const cell = worksheetPage4[resignedRequiredReference[data]]
            return cell && cell.v === true
          })

          const resignedRequiredData =
            resignedRequired.length === 1 ? resignedRequired[0] : ''

          const resignedDetailsReference = ['K35', 'L35']
          const resignedDetails = resignedDetailsReference
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const immigrantRequiredReference = {
            YES: 'G37',
            NO: 'H37',
          }
          const immigrantRequired = Object.keys(
            immigrantRequiredReference
          ).filter((data) => {
            const cell = worksheetPage4[immigrantRequiredReference[data]]
            return cell && cell.v === true
          })

          const immigrantRequiredData =
            immigrantRequired.length === 1 ? immigrantRequired[0] : ''

          const immigrantDetailsReference = [
            'H39',
            'I39',
            'J39',
            'K39',
            'L39',
            'M39',
          ]
          const immigrantDetails = immigrantDetailsReference
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const isIndigenousRequiredReference = {
            YES: 'G43',
            NO: 'H43',
          }
          const isIndigenousRequired = Object.keys(
            isIndigenousRequiredReference
          ).filter((data) => {
            const cell = worksheetPage4[isIndigenousRequiredReference[data]]
            return cell && cell.v === true
          })

          const isIndigenousRequiredData =
            isIndigenousRequired.length === 1 ? isIndigenousRequired[0] : ''

          const isIndigenousDetailsReference = ['K44', 'L44']
          const isIndigenousDetails = isIndigenousDetailsReference
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const isPersonWDisabilityRequiredReference = {
            YES: 'G45',
            NO: 'H45',
          }
          const isPersonWDisabilityRequired = Object.keys(
            isPersonWDisabilityRequiredReference
          ).filter((data) => {
            const cell =
              worksheetPage4[isPersonWDisabilityRequiredReference[data]]
            return cell && cell.v === true
          })

          const isPersonWDisabilityRequiredData =
            isPersonWDisabilityRequired.length === 1
              ? isPersonWDisabilityRequired[0]
              : ''

          const isPersonWDisabilityDetailsReference = ['K46', 'L46']
          const isPersonWDisabilityDetails = isPersonWDisabilityDetailsReference
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const isSoloParentRequiredReference = {
            YES: 'G47',
            NO: 'H47',
          }
          const isSoloParentRequired = Object.keys(
            isSoloParentRequiredReference
          ).filter((data) => {
            const cell = worksheetPage4[isSoloParentRequiredReference[data]]
            return cell && cell.v === true
          })

          const isSoloParentRequiredData =
            isSoloParentRequired.length === 1 ? isSoloParentRequired[0] : ''

          const isSoloParentDetailsReference = ['K48', 'L48']
          const isSoloParentDetails = isSoloParentDetailsReference
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const referencesRequiredData = []
          for (let row = 52; row <= 54; row++) {
            const referenceNameCellReferences = [
              `A${row}`,
              `B${row}`,
              `C${row}`,
              `D${row}`,
              `E${row}`,
            ]
            const referenceAddressCellReferences = [`F${row}`]
            const referenceTelCellReferences = [`G${row}`, `H${row}`, `I${row}`]
            const name = referenceNameCellReferences
              .map((ref) => {
                const cell = worksheetPage4[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const address = referenceAddressCellReferences
              .map((ref) => {
                const cell = worksheetPage4[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            const tellNo = referenceTelCellReferences
              .map((ref) => {
                const cell = worksheetPage4[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

            if (name || address || tellNo) {
              referencesRequiredData.push({
                referenceNameRequired: name,
                referenceAddressRequired: address,
                referenceTel: tellNo,
              })
            }
          }

          const govtIssuedIDRequiredCellReferences = ['D61']
          const govtIssuedIDRequired = govtIssuedIDRequiredCellReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const govtIDNoRequiredCellReferences = ['D62', 'D63']
          const govtIDNoRequired = govtIDNoRequiredCellReferences
            .map((ref) => {
              const cell = worksheetPage4[ref]
              return cell ? cell.v : ''
            })
            .join(' ')
            .trim()

          const govtIDIssuancePlaceOrDateRequiredCellReferences = ['D64', 'D65']
          const govtIDIssuancePlaceOrDateRequired =
            govtIDIssuancePlaceOrDateRequiredCellReferences
              .map((ref) => {
                const cell = worksheetPage4[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

          const dateAccomplishedRequiredCellReferences = [
            'F64',
            'G64',
            'H64',
            'I64',
          ]
          const dateAccomplishedRequired =
            dateAccomplishedRequiredCellReferences
              .map((ref) => {
                const cell = worksheetPage4[ref]
                return cell ? cell.v : ''
              })
              .join(' ')
              .trim()

          // const workbookImage = new ExcelJS.Workbook();
          // await workbookImage.xlsx.load(file);
          // extractThumbmarkImage(workbookImage, 3);
          // extractImageByRange(workbookImage, 3);

          function bloodTypeFormat(bloodType) {
            try {
              switch (bloodType) {
                case 'O-':
                  return 'oMinus'
                case 'O+':
                  return 'oPlus'
                case 'A+':
                  return 'aPlus'
                case 'A-':
                  return 'aMinus'
                case 'B+':
                  return 'bPlus'
                case 'B-':
                  return 'bMinus'
                case 'AB+':
                  return 'abPlus'
                case 'AB-':
                  return 'abMinus'
                case 'TBD':
                  return 'TBD'
                default:
                  return ''
              }
            } catch (error) {
              // Log the error or handle it as needed
              console.error('Error in bloodTypeFormat:', error)
              return 'Error' // Return a default or error-specific value
            }
          }
          function formatDateToISOString(date) {
            try {
              return new Date(date).toISOString()
            } catch (error) {
              return ''
            }
          }

          // Create JSON object
          let jsonData = {
            surnameRequired: surname ? surname.toUpperCase() : '',
            firstnameRequired: firstName,
            middlenameRequired: middleName,
            suffixNaAllowed: formatNameExtension(nameExtension),
            dateofbirthRequired: excelDateToJSDate(dateOfBirth),
            placeofbirthRequired: placeOfBirth
              ? placeOfBirth.toUpperCase()
              : '',
            sexRequired: sexData,
            civilStatusRequired: civilStatus,
            heightFormat: 'meters',
            heightMeters: height,
            weightFormat: 'kilograms',
            weightKg: weight,
            bloodtype: bloodTypeFormat(bloodType),
            gsisidnoRequired: gsisNo,
            pagibigIdNoRequired: pagIbigIdNo,
            philhealthNoRequired: philHealthNo,
            sssNumberRequired: sssNo,
            tinNumberRequired: tinNo,
            agencyemployeenoNaAllowed: agencyEmployeeNo,
            citizenshipQuestionRequired:
              citizenship && citizenship === 'DUALCITIZENSHIP'
                ? 'DUAL CITIZENSHIP'
                : citizenship,
            residentialHouseBlockLotNoRequired: residentialHouseNo
              ? residentialHouseNo.toUpperCase()
              : '',
            residentialStreetRequired: residentialStreet
              ? residentialStreet.toUpperCase()
              : '',
            residentialSubdivisionVillageRequired: residentialSubdivisionVillage
              ? residentialSubdivisionVillage.toUpperCase()
              : '',
            residentialBarangayRequired: residentialBarangay
              ? residentialBarangay.toUpperCase()
              : '',
            residentialCityMunicipalityRequired: residentialCityMunicipality
              ? residentialCityMunicipality.toUpperCase()
              : '',
            residentialProvinceRequired: residentialProvince
              ? residentialProvince.toUpperCase()
              : '',
            residentialZipcodeNaAllowed: residentialZipCode,
            permanentHouseBlockLotNoRequired: permanentAddressHouseNo
              ? permanentAddressHouseNo.toUpperCase()
              : '',
            permanentSubdivisionVillageRequired: permanentSubdivisionVillage
              ? permanentSubdivisionVillage.toUpperCase()
              : '',
            permanentStreetRequired: permanentStreet
              ? permanentStreet.toUpperCase()
              : '',
            permanentBarangayRequired: permanentBarangay
              ? permanentBarangay.toUpperCase()
              : '',
            permanentCityMunicipalityRequired: permanentCity
              ? permanentCity.toUpperCase()
              : '',
            permanentProvinceRequired: permanentProvince
              ? permanentProvince.toUpperCase()
              : '',
            permanentZipcodeNaAllowed: permanentZipcode,
            telephonenoNaAllowed: telephoneNo,
            mobilenoNaAllowed: mobileNo,
            emailAddressNaAllowed: emailAddress,
            spouseSurnameNaAllowed: spouseSurname
              ? spouseSurname.toUpperCase()
              : '',
            spouseFirstnameNaAllowed: spouseFirstName
              ? spouseFirstName.toUpperCase()
              : '',
            spouseMiddlenameNaAllowed: spouseMiddleName
              ? spouseMiddleName.toUpperCase()
              : '',
            spouseSuffixNaAllowed: formatNameExtension(spouseNameExtension),
            spouseOccupationNaAllowed: occupation
              ? occupation.toUpperCase()
              : '',
            spouseEmployerAddressNaAllowed: bussinessAddress
              ? bussinessAddress.toUpperCase()
              : '',
            spouseEmployerNameNaAllowed: businessName
              ? businessName.toUpperCase()
              : '',
            spouseEmployerTelephonenoNaAllowed: businessTelephoneNo,
            fatherSurnameNaAllowed: fathersSurname
              ? fathersSurname.toUpperCase()
              : '',
            fatherFirstnameNaAllowed: fathersFirstName
              ? fathersFirstName.toUpperCase()
              : '',
            fatherMiddlenameNaAllowed: fathersMiddleName
              ? fathersMiddleName.toUpperCase()
              : '',
            fatherSuffixNaAllowed: formatNameExtension(fatherNameExtension),
            motherSurnameNaAllowed: motherSurname
              ? motherSurname.toUpperCase()
              : '',
            motherFirstnameNaAllowed: motherFirstName
              ? motherFirstName.toUpperCase()
              : '',
            motherMiddlenameNaAllowed: motherMiddleName
              ? motherMiddleName.toUpperCase()
              : '',
            educationalBackground: educationalBackground,
            cseData: cseData,
            sameAsResidentialAddressBox: false,
            workExperience: workExperienceData,
            voluntaryWorkData: voluntaryWorkData,
            skillsAndHobbiesGrid: skillAndHobbiesData,
            nonAcademicdistinctionsrecognitionGrid:
              nonAcademicdistinctionsrecognitionGridData,
            orgMembershipGrid: orgMembershipGridData,
            isThirdDegreeRequired: thirdDegreeData,
            isFourthDegreeRequired: fourthDegreeData,
            offenseARequired: offenseAData,
            offenceCRequired: offenseCData,
            courtARequired: courtARequiredData,
            serviceSeparationARequired: serviceSeparationARequiredData,
            candidateRequired: candidateRequiredData,
            resignedRequired: resignedRequiredData,
            immigrantRequired: immigrantRequiredData,
            isIndigenousRequired: isIndigenousRequiredData,
            isPersonWDisabilityRequired: isPersonWDisabilityRequiredData,
            isSoloParentRequired: isSoloParentRequiredData,
            referencesRequired: referencesRequiredData,
            govtIssuedIDRequired: govtIssuedIDRequired,
            govtIDNoRequired: govtIDNoRequired,
            govtIDIssuancePlaceOrDateRequired:
              govtIDIssuancePlaceOrDateRequired,
            dateAccomplishedRequired: dateAccomplishedRequired
              ? formatDateToISOString(dateAccomplishedRequired)
              : '',
            // relatedExplain
          }
          if (citizenship === 'DUALCITIZENSHIP') {
            if (citizenshipType === 'BYBIRTH') {
              jsonData.birthOrNaturaliztion = 'BY BIRTH'
            }
            if (citizenshipType === 'BYNATURALIZATION') {
              jsonData.birthOrNaturaliztion = 'BY NATURALIZATION'
            }
            jsonData.indicateCountry = country
          }

          if (childrenData && childrenData.length === 0) {
            jsonData.ifChildren = 'NO'
          } else {
            const firstChild = childrenData[0]
            if (
              firstChild.nameOfChildren === 'N/A' ||
              firstChild.dateOfBirth === 'N/A'
            ) {
              jsonData.ifChildren = 'NO'
              jsonData.well3Well2DataGrid = childrenData.map((child) => ({
                childrenDOBNaAllowed: excelDateToJSDate(child.dateOfBirth),
                childrenFullnameNaAllowed: child.nameOfChildren,
              }))
            } else {
              jsonData.ifChildren = 'YES'

              jsonData.well3Well2DataGrid = childrenData.map((child) => ({
                childrenDOBNaAllowed: excelDateToJSDate(child.dateOfBirth),
                childrenFullnameNaAllowed: child.nameOfChildren,
              }))
            }
          }

          const elementaryEducation = educationalBackground.filter(
            (edu) => edu.level === 'ELEMENTARY'
          )
          if (elementaryEducation.length > 0) {
            jsonData.well4DataGrid2 = elementaryEducation.map((edu) => ({
              elementaryBasicEducationNaAllowed: edu.basicEducation,
              elementaryDateFromNaAllowed: edu.periodOfAttendanceFrom,
              elementaryDateToNaAllowed: edu.periodOfAttendanceTo,
              elementaryNameOfSchoolNaAllowed: edu.nameOfSchool,
              elementaryScholarshipNaAllowed: edu.scholarship,
              elementaryUnitsEarnedNaAllowed: edu.unitsEarned,
              elementaryYearGraduatedNaAllowed: edu.yearGraduated,
            }))
          }
          const secondaryEducation = educationalBackground.filter(
            (edu) => edu.level === 'SECONDARY'
          )
          if (secondaryEducation.length > 0) {
            jsonData.well4DataGrid = secondaryEducation.map((edu) => ({
              secondaryBasicEducation: edu.basicEducation,
              secondaryDateFromNaAllowed: edu.periodOfAttendanceFrom,
              secondaryDateToNaAllowed: edu.periodOfAttendanceTo,
              secondaryNameOfSchoolNaAllowed: edu.nameOfSchool,
              secondaryScholarshipNaAllowed: edu.scholarship,
              secondaryUnitsEarnedNaAllowed: edu.unitsEarned,
              secondaryYearGraduatedNaAllowed: edu.yearGraduated,
            }))
          }

          const vocationalEducation = educationalBackground.filter((edu) =>
            edu.level.trim().includes('VOCATIONAL')
          )
          if (vocationalEducation.length > 0) {
            jsonData.well4Levelvocationaltradecourse = vocationalEducation.map(
              (edu) => ({
                vocationalBasicEducationNaAllowed: edu.basicEducation,
                vocationalDateFromNaAllowed: edu.periodOfAttendanceFrom,
                vocationalDateToNaAllowed: edu.periodOfAttendanceTo,
                vocationalNameOfSchoolNaAllowed: edu.nameOfSchool,
                vocationalScholarshipNaAllowed: edu.scholarship,
                vocationalUnitsEarnedNaAllowed: edu.unitsEarned,
                vocationalYeargraduatedNaAllowed: edu.yearGraduated,
              })
            )
          }
          const collegeEducation = educationalBackground.filter(
            (edu) => edu.level === 'COLLEGE'
          )
          if (collegeEducation.length > 0) {
            jsonData.well4DataGrid3 = collegeEducation.map((edu) => ({
              collegeCourseNaAllowed: edu.basicEducation,
              collegeDateFromNaAllowed: edu.periodOfAttendanceFrom,
              collegeDateToNaAllowed: edu.periodOfAttendanceTo,
              collegeNameOfSchoolNaAllowed: edu.nameOfSchool,
              collegeScholarshipNaAllowed: edu.scholarship,
              collegeUnitsEarnedNaAllowed: edu.unitsEarned,
              collegeYearGraduatedNaAllowed: edu.yearGraduated,
            }))
          }

          const graduateEducation = educationalBackground.filter(
            (edu) => edu.level === 'GRADUATE STUDIES'
          )
          if (graduateEducation.length > 0) {
            jsonData.well4Well5Levelgraduatestudies = graduateEducation.map(
              (edu) => ({
                graduateCourseNaAllowed: edu.basicEducation,
                graduateDateFromNaAllowed: edu.periodOfAttendanceFrom,
                graduateDateToNaAllowed: edu.periodOfAttendanceTo,
                graduateNameOfSchoolNaAllowed: edu.nameOfSchool,
                graduateScholarshipNaAllowed: edu.scholarship,
                graduateUnitsEarnedNaAllowed: edu.unitsEarned,
                graduateYearGraduatedNaAllowed: edu.yearGraduated,
              })
            )
          }

          if (cseData.length > 0) {
            const transformedCseData = cseData.map((data) => {
              const transformedItem = {
                civilServiceAndOthersLicense: data.careerService
                  ? data.careerService.toUpperCase()
                  : '',
                civilServiceExamDate: '',
                civilServiceExamPlace: data.csePlaceExamination,
                civilServiceLicenseNo: data.cseLicenseNumber,
                civilServiceRating: data.cseRating.toLocaleString(),
                haveAnyEligibility: 'YES',
                civilServiceExamDateIsNA: '',
              }
              if (
                !civilServiceDropdownData.includes(
                  transformedItem.civilServiceAndOthersLicense
                )
              ) {
                transformedItem.civilServiceAndOthersLicenseOthersEligibility =
                  transformedItem.civilServiceAndOthersLicense
                transformedItem.civilServiceAndOthersLicense = 'OTHERS'
              }

              if (data.cseDateExamination == 'N/A') {
                transformedItem.civilServiceExamDateIsNA = 'N/A'
              } else {
                transformedItem.civilServiceExamDateIsNA = 'enterDate'
                transformedItem.civilServiceExamDate = excelDateToJSDate(
                  data.cseDateExamination
                )
              }

              if (
                !data.cseLicenseDateofValidity ||
                data.cseLicenseDateofValidity === 'N/A' ||
                data.cseLicenseDateofValidity === 'n/a'
              ) {
                transformedItem.civilServiceLicenseValidityDateIsNA = 'N/A'
              } else {
                transformedItem.civilServiceLicenseValidityDateIsNA =
                  'enterDate'
                transformedItem.civilServiceLicenseValidityDate =
                  data.cseLicenseDateofValidity
              }

              return transformedItem
            })

            jsonData.well4Well7DataGrid = transformedCseData
          }
          // Transform work experience data
          if (workExperienceData.length > 0) {
            const firstWorkExperience = workExperienceData[0]
            if (
              firstWorkExperience.workExperienceAppointmentStatus === 'N/A' &&
              firstWorkExperience.workExperienceCompany === 'N/A' &&
              firstWorkExperience.workExperienceDateFrom === 'N/A' &&
              firstWorkExperience.workExperienceIsGovtService === 'N/A' &&
              firstWorkExperience.workExperiencePositionTitle === 'N/A' &&
              firstWorkExperience.workExperienceSalary === 'N/A' &&
              firstWorkExperience.workInclusiveDateToFormat === 'N/A'
            ) {
              jsonData.isWorkExperience = 'NO'
            } else {
              jsonData.isWorkExperience = 'YES'
              const transformedWorkExperienceData = workExperienceData
                .sort(
                  (a, b) =>
                    new Date(b.inclusiveDataFrom) -
                    new Date(a.inclusiveDataFrom)
                )
                .map((data) => {
                  const transformedItem = {
                    workExperienceAppointmentStatus: data.statusAppointment
                      ? data.statusAppointment.toUpperCase()
                      : '',
                    workExperienceCompany: data.departmentCompany,
                    workExperienceIsGovtService:
                      data.govtService === 'Y'
                        ? 'YES'
                        : data.govtService === 'N'
                          ? 'NO'
                          : data.govtService,
                    workExperiencePositionTitle: data.positionTitle,
                    workExperienceDateFrom: excelDateToJSDate(
                      data.inclusiveDataFrom
                    ),
                    workExperienceSalary: parseFloat(
                      data.monthlySalary.replace(/,/g, '')
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                    workInclusiveDateToFormat: data.inclusiveDataTo,
                  }

                  if (data.jobPayGrade.includes('-')) {
                    transformedItem.workExperiencePayGrade = parseInt(
                      data.jobPayGrade.split('-')[0],
                      10
                    )
                    transformedItem.workExperienceStepIncrement = parseInt(
                      data.jobPayGrade.split('-')[1],
                      10
                    )
                    transformedItem.workPayGradeIsNA =
                      'selectSalaryGradeStepIncrement'
                  } else {
                    transformedItem.workPayGradeIsNA = 'N/A'
                  }

                  if (
                    data.inclusiveDataTo === 'PRESENT' ||
                    data.inclusiveDataTo === 'up to present'
                  ) {
                    transformedItem.workInclusiveDateToFormat = 'PRESENT'
                  } else {
                    transformedItem.workInclusiveDateToFormat = 'selectDate'
                    transformedItem.workExperienceDateTo = excelDateToJSDate(
                      data.inclusiveDataTo
                    )
                  }

                  return transformedItem
                })
              jsonData.well4Well8DataGrid = transformedWorkExperienceData
            }
          } else {
            jsonData.isWorkExperience = 'NO'
          }
          if (voluntaryWorkData.length > 0) {
            const firstVoluntaryWork = voluntaryWorkData[0]
            if (
              (firstVoluntaryWork.voluntaryWorkOrg === 'N/A' &&
                firstVoluntaryWork.voluntaryWorkDateFrom === 'N/A' &&
                firstVoluntaryWork.voluntaryWorkDateTo === 'N/A' &&
                firstVoluntaryWork.voluntaryWorkHoursNo === 'N/A' &&
                firstVoluntaryWork.voluntaryWorkPosition === 'N/A') ||
              (firstVoluntaryWork.voluntaryWorkOrg === 'n/a' &&
                firstVoluntaryWork.voluntaryWorkDateFrom === 'n/a' &&
                firstVoluntaryWork.voluntaryWorkDateTo === 'n/a' &&
                firstVoluntaryWork.voluntaryWorkHoursNo === 'n/a' &&
                firstVoluntaryWork.voluntaryWorkPosition === 'n/a')
            ) {
              jsonData.isVoluntaryWork = 'NO'
            } else {
              jsonData.isVoluntaryWork = 'YES'

              const transformVoluntaryWorkData = voluntaryWorkData.map(
                (data) => {
                  const transformedItem = {
                    voluntaryWorkOrg: data.voluntaryWorkOrg,
                    voluntaryWorkHoursNo: data.voluntaryWorkHoursNo,
                    voluntaryWorkPosition: data.voluntaryWorkPosition,
                  }

                  if (
                    data.voluntaryWorkDateFrom ||
                    data.voluntaryWorkDateFrom !== 'N/A' ||
                    data.voluntaryWorkDateTo !== 'n/a'
                  ) {
                    transformedItem.voluntaryWorkDateIsNA = 'selectDates'
                    transformedItem.voluntaryWorkDateFrom =
                      data.voluntaryWorkDateFrom
                        ? excelDateToJSDate(data.voluntaryWorkDateFrom)
                        : 'N/A'
                    transformedItem.voluntaryWorkDateTo =
                      data.voluntaryWorkDateTo
                        ? excelDateToJSDate(data.voluntaryWorkDateTo)
                        : 'N/A'
                  } else {
                    transformedItem.voluntaryWorkDateIsNA = 'N/A'
                  }
                  return transformedItem
                }
              )
              jsonData.well4Well10DataGrid = transformVoluntaryWorkData
            }
          } else {
            jsonData.isVoluntaryWork = 'NO'
          }

          if (learnAndDevelopmentData.length > 0) {
            const firstlearnAndDevelopment = learnAndDevelopmentData[0]
            if (
              firstlearnAndDevelopment.ldTrainingDateFrom === 'N/A' &&
              firstlearnAndDevelopment.ldTrainingDateTo === 'N/A' &&
              firstlearnAndDevelopment.ldTrainingHoursNo === 'N/A' &&
              firstlearnAndDevelopment.ldTrainingSponsoredBy === 'N/A' &&
              firstlearnAndDevelopment.ldType === 'N/A' &&
              firstlearnAndDevelopment.learnAndDevelopmentProgram === 'N/A'
            ) {
              jsonData.isLDAttended = 'NO'
            } else {
              jsonData.isLDAttended = 'YES'

              const transformLearnAndDevelopmentData =
                learnAndDevelopmentData.map((data) => {
                  const transformedItem = {
                    ldTrainingTitle: data.learnAndDevelopmentProgram
                      ? data.learnAndDevelopmentProgram.toUpperCase()
                      : '',
                    ldTrainingHoursNo: data.ldTrainingHoursNo,
                    ldSponsoredBy: data.ldTrainingSponsoredBy
                      ? data.ldTrainingSponsoredBy.toUpperCase()
                      : '',
                    ldType: data.ldType ? data.ldType.toUpperCase() : '',
                  }

                  if (
                    (data.ldTrainingDateFrom &&
                      data.ldTrainingDateFrom !== 'N/A') ||
                    (data.ldTrainingDateTo && data.ldTrainingDateTo !== 'N/A')
                  ) {
                    transformedItem.ldTrainingDateIsNA = 'selectDates'

                    if (
                      data.ldTrainingDateFrom &&
                      data.ldTrainingDateFrom !== 'N/A'
                    ) {
                      transformedItem.ldTrainingDateFrom =
                        data.ldTrainingDateFrom
                    } else {
                      transformedItem.ldTrainingDateIsNA = 'selectDates'
                    }

                    if (
                      data.ldTrainingDateTo &&
                      data.ldTrainingDateTo !== 'N/A'
                    ) {
                      transformedItem.ldTrainingDateTo = data.ldTrainingDateTo
                    } else {
                      transformedItem.ldTrainingDateIsNA = 'selectDates'
                    }
                  } else {
                    transformedItem.ldTrainingDateIsNA = 'N/A'
                  }

                  // if ((data.ldTrainingDateFrom && data.ldTrainingDateFrom !== 'N/A') && (data.ldTrainingDateTo && data.ldTrainingDateTo !== 'N/A')) {
                  //   transformedItem.ldTrainingDateIsNA = "selectDates"
                  //   transformedItem.ldTrainingDateFrom = moment(data.ldTrainingDateFrom, ['YYYY-MM-DD', 'MM/DD/YYYY'], true).toISOString();
                  //   transformedItem.ldTrainingDateTo = moment(data.ldTrainingDateTo, ['YYYY-MM-DD', 'MM/DD/YYYY'], true).toISOString();
                  // } else {

                  // }
                  return transformedItem
                })
              jsonData.well4Well11DataGrid = transformLearnAndDevelopmentData
            }
          } else {
            jsonData.isLDAttended = 'NO'
          }

          if (fourthDegreeData === 'YES') {
            jsonData.relatedExplain = relatedExplain
          }

          if (offenseAData === 'YES') {
            jsonData.offenseADetails = offenseADetails
          }

          if (offenseCData === 'YES') {
            const date = offenseDateFiled.trim()
            jsonData.offenseDateFiled = date.isValid() ? date : 'wrong date'
            jsonData.offenseCaseStatus = offenseCaseStatus
          }

          if (courtARequiredData === 'YES') {
            jsonData.courtADetails = courtADetails
          }

          if (serviceSeparationARequiredData === 'YES') {
            jsonData.serviceSeparationADetails = serviceSeparationADetails
          }

          if (candidateRequiredData === 'YES') {
            jsonData.candidateDetails = candidateDetails
          }

          if (resignedRequiredData === 'YES') {
            jsonData.resignedDetails = resignedDetails
          }

          if (immigrantRequiredData === 'YES') {
            jsonData.immigrantDetails = immigrantDetails
          }

          if (isIndigenousRequiredData === 'YES') {
            jsonData.isIndigenousDetails = isIndigenousDetails
          }

          if (isPersonWDisabilityRequiredData === 'YES') {
            jsonData.isPersonWDisabilityDetails = isPersonWDisabilityDetails
          }

          if (isSoloParentRequiredData === 'YES') {
            jsonData.isSoloParentDetails = isSoloParentDetails
          }

          function toUpperCaseIfExists(value) {
            return value ? value.toUpperCase() : value
          }

          setData(jsonData)
        } else {
          message.error(
            'Invalid file type. Please upload only Excel files (e.g., .xls, .xlsx).'
          )
          setFileList([])
          setData(null)
        }
      }

      reader.readAsArrayBuffer(file)
      setFileList([file]) // Set the file list to contain only the current file
      return false // Prevent automatic upload
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setData(null) // Clear the data state
    setFileList([]) // Clear the file list
  }

  // const extractImageByRange = async (workbook, sheetIndex) => {
  //   try {
  //     const sheet = workbook.model.sheets[sheetIndex] // Access the specific sheet
  //     const media = sheet.media // Access the media array from the sheet

  //     if (!media || !media.length) {
  //       console.log('No media found on the specified sheet.')
  //       return
  //     }

  //     // Define the exact range for the thumbmark image
  //     const idRange = {
  //       tl: { nativeCol: 10, nativeRow: 50 },
  //       br: { nativeCol: 11, nativeRow: 54 },
  //     }

  //     const mediaItem = media.find((item) => {
  //       if (item.type === 'image') {
  //         const { tl, br } = item.range

  //         // Match the exact location
  //         return (
  //           tl.nativeCol === idRange.tl.nativeCol &&
  //           tl.nativeRow === idRange.tl.nativeRow &&
  //           br.nativeCol === idRange.br.nativeCol &&
  //           br.nativeRow === idRange.br.nativeRow
  //         )
  //       }
  //       return false
  //     })

  //     if (mediaItem) {
  //       const image = workbook.model.media.find(
  //         (img) => img.index === mediaItem.imageId
  //       ) // Find the image data using imageId

  //       if (image) {
  //         const imageData = new Uint8Array(image.buffer) // Convert the buffer to a Uint8Array

  //         // Convert Buffer to Blob
  //         const blob = new Blob([imageData], {
  //           type: `image/${image.extension || 'png'}`,
  //         }) // Set the MIME type based on the extension

  //         // Create a FileReader to read the Blob as a data URL (Base64 string)
  //         const reader = new FileReader()
  //         reader.onloadend = () => {
  //           const base64String = reader.result // This is the Base64 string

  //           // Create an Image object
  //           const img = new Image()
  //           img.onload = () => {
  //             // Create a canvas element
  //             const canvas = document.createElement('canvas')
  //             const ctx = canvas.getContext('2d')

  //             // Set the canvas dimensions to the desired size
  //             const desiredWidth = 100 // Set your desired width here
  //             const desiredHeight = 100 // Set your desired height here
  //             canvas.width = desiredWidth
  //             canvas.height = desiredHeight

  //             // Draw the image onto the canvas at the desired size
  //             ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight)

  //             // Convert the canvas content to a Base64 string
  //             const resizedBase64String = canvas.toDataURL(
  //               `image/${image.extension || 'png'}`
  //             )

  //             // Set the resized Base64 string to ID picture
  //             setIDpicture(resizedBase64String)
  //           }

  //           // Set the src of the Image object to the Base64 string
  //           img.src = base64String
  //         }

  //         // Read the Blob as a data URL
  //         reader.readAsDataURL(blob)
  //       } else {
  //         console.log(
  //           `id picture image with imageId ${mediaItem.imageId} not found in the workbook.`
  //         )
  //       }
  //     } else {
  //       console.log('No id picture image found at the specified location.')
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while extracting the image:', error)
  //   }
  // }

  // const extractThumbmarkImage = async (workbook, sheetIndex) => {
  //   try {
  //     const sheet = workbook.model.sheets[sheetIndex] // Access the specific sheet
  //     const media = sheet.media // Access the media array from the sheet

  //     if (!media || !media.length) {
  //       console.log('No media found on the specified sheet.')
  //       return
  //     }

  //     // Define the exact range for the thumbmark image
  //     const thumbmarkRange = {
  //       tl: { nativeCol: 10, nativeRow: 57 },
  //       br: { nativeCol: 11, nativeRow: 63 },
  //     }

  //     const mediaItem = media.find((item) => {
  //       if (item.type === 'image') {
  //         const { tl, br } = item.range

  //         // Match the exact location
  //         return (
  //           tl.nativeCol === thumbmarkRange.tl.nativeCol &&
  //           tl.nativeRow === thumbmarkRange.tl.nativeRow &&
  //           br.nativeCol === thumbmarkRange.br.nativeCol &&
  //           br.nativeRow === thumbmarkRange.br.nativeRow
  //         )
  //       }
  //       return false
  //     })

  //     if (mediaItem) {
  //       const image = workbook.model.media.find(
  //         (img) => img.index === mediaItem.imageId
  //       ) // Find the image data using imageId

  //       if (image) {
  //         const imageData = new Uint8Array(image.buffer) // Convert the buffer to a Uint8Array

  //         // Convert Buffer to Blob
  //         const blob = new Blob([imageData], {
  //           type: `image/${image.extension || 'png'}`,
  //         }) // Set the MIME type based on the extension

  //         // Create a FileReader to read the Blob as a data URL (Base64 string)
  //         const reader = new FileReader()
  //         reader.onloadend = () => {
  //           const base64String = reader.result // This is the Base64 string

  //           // Create an Image object
  //           const img = new Image()
  //           img.onload = () => {
  //             // Create a canvas element
  //             const canvas = document.createElement('canvas')
  //             const ctx = canvas.getContext('2d')

  //             // Set the canvas dimensions to the desired size
  //             const desiredWidth = 100 // Set your desired width here
  //             const desiredHeight = 100 // Set your desired height here
  //             canvas.width = desiredWidth
  //             canvas.height = desiredHeight

  //             // Draw the image onto the canvas at the desired size
  //             ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight)

  //             // Convert the canvas content to a Base64 string
  //             const resizedBase64String = canvas.toDataURL(
  //               `image/${image.extension || 'png'}`
  //             )

  //             // Set the resized Base64 string to thumbImage
  //             setThumbImage(resizedBase64String)
  //           }

  //           // Set the src of the Image object to the Base64 string
  //           img.src = base64String
  //         }

  //         // Read the Blob as a data URL
  //         reader.readAsDataURL(blob)
  //       } else {
  //         console.log(
  //           `Thumbmark image with imageId ${mediaItem.imageId} not found in the workbook.`
  //         )
  //       }
  //     } else {
  //       console.log('No thumbmark image found at the specified location.')
  //     }
  //   } catch (error) {
  //     console.error(
  //       'An error occurred while extracting the thumbmark image:',
  //       error
  //     )
  //   }
  // }

  return (
    <div className='mb-3'>
      <Button type='primary' onClick={() => setIsModalVisible(true)}>
        IMPORT PDS FROM EXCEL
      </Button>
      <Modal
        destroyOnClose={true}
        title='IMPORT PDS FROM EXCEL'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1400}
        okButtonProps={{
          disabled: !isDoneOk, // Disables the 'OK' button
        }}
      >
        <Upload
          name='file'
          multiple={false}
          beforeUpload={handleFileUpload}
          onRemove={() => {
            setData(null)
            setFileList([])
          }}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Click to Import</Button>
        </Upload>

        {data && (
          <div>
            <PDSuploadView data={data} isDoneButton={setIsDoneOk} />
          </div>
        )}
      </Modal>
    </div>
  )
}

export default UploadPds
