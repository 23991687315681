/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      mname
      lname
      suffix
      contact
      role
      position
      department
      assignedDept
      division
      sectionUnit
      dataPrivacy
      form
      form2
      isTakeSurvey
      date
      gender
      status
      createdAt
      username
      profilePicture
      ipcrRole
      dpcrRole
      opcrRole
      employeeIDNumber
      pdsRole
      psbRole
      appointmentStatus
      updatedAt
      owner
      __typename
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      mname
      lname
      suffix
      contact
      role
      position
      department
      assignedDept
      division
      sectionUnit
      dataPrivacy
      form
      form2
      isTakeSurvey
      date
      gender
      status
      createdAt
      username
      profilePicture
      ipcrRole
      dpcrRole
      opcrRole
      employeeIDNumber
      pdsRole
      psbRole
      appointmentStatus
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      mname
      lname
      suffix
      contact
      role
      position
      department
      assignedDept
      division
      sectionUnit
      dataPrivacy
      form
      form2
      isTakeSurvey
      date
      gender
      status
      createdAt
      username
      profilePicture
      ipcrRole
      dpcrRole
      opcrRole
      employeeIDNumber
      pdsRole
      psbRole
      appointmentStatus
      updatedAt
      owner
      __typename
    }
  }
`
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      isLanguage
      userID
      isFinal
      finalSubmitDate
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      isLanguage
      userID
      isFinal
      finalSubmitDate
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      isLanguage
      userID
      isFinal
      finalSubmitDate
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createForm2 = /* GraphQL */ `
  mutation CreateForm2(
    $input: CreateForm2Input!
    $condition: ModelForm2ConditionInput
  ) {
    createForm2(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      userID
      etnaId
      filipino
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateForm2 = /* GraphQL */ `
  mutation UpdateForm2(
    $input: UpdateForm2Input!
    $condition: ModelForm2ConditionInput
  ) {
    updateForm2(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      userID
      etnaId
      filipino
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteForm2 = /* GraphQL */ `
  mutation DeleteForm2(
    $input: DeleteForm2Input!
    $condition: ModelForm2ConditionInput
  ) {
    deleteForm2(input: $input, condition: $condition) {
      id
      form
      dept
      assignedDept
      division
      position
      userID
      etnaId
      filipino
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createIDLPUpload = /* GraphQL */ `
  mutation CreateIDLPUpload(
    $input: CreateIDLPUploadInput!
    $condition: ModelIDLPUploadConditionInput
  ) {
    createIDLPUpload(input: $input, condition: $condition) {
      id
      file
      date
      userID
      etnaId
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateIDLPUpload = /* GraphQL */ `
  mutation UpdateIDLPUpload(
    $input: UpdateIDLPUploadInput!
    $condition: ModelIDLPUploadConditionInput
  ) {
    updateIDLPUpload(input: $input, condition: $condition) {
      id
      file
      date
      userID
      etnaId
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteIDLPUpload = /* GraphQL */ `
  mutation DeleteIDLPUpload(
    $input: DeleteIDLPUploadInput!
    $condition: ModelIDLPUploadConditionInput
  ) {
    deleteIDLPUpload(input: $input, condition: $condition) {
      id
      file
      date
      userID
      etnaId
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteHandbookAcknowledgement = /* GraphQL */ `
  mutation DeleteHandbookAcknowledgement(
    $input: DeleteHandbookAcknowledgementInput!
    $condition: ModelhandbookAcknowledgementConditionInput
  ) {
    deleteHandbookAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteLdRefresher = /* GraphQL */ `
  mutation DeleteLdRefresher(
    $input: DeleteLdRefresherInput!
    $condition: ModelldRefresherConditionInput
  ) {
    deleteLdRefresher(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      mess
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteLdRefresherVideos = /* GraphQL */ `
  mutation DeleteLdRefresherVideos(
    $input: DeleteLdRefresherVideosInput!
    $condition: ModelldRefresherVideosConditionInput
  ) {
    deleteLdRefresherVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createCscVideos = /* GraphQL */ `
  mutation CreateCscVideos(
    $input: CreateCscVideosInput!
    $condition: ModelcscVideosConditionInput
  ) {
    createCscVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateCscVideos = /* GraphQL */ `
  mutation UpdateCscVideos(
    $input: UpdateCscVideosInput!
    $condition: ModelcscVideosConditionInput
  ) {
    updateCscVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteCscVideos = /* GraphQL */ `
  mutation DeleteCscVideos(
    $input: DeleteCscVideosInput!
    $condition: ModelcscVideosConditionInput
  ) {
    deleteCscVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createCscMaterials = /* GraphQL */ `
  mutation CreateCscMaterials(
    $input: CreateCscMaterialsInput!
    $condition: ModelcscMaterialsConditionInput
  ) {
    createCscMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materials
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateCscMaterials = /* GraphQL */ `
  mutation UpdateCscMaterials(
    $input: UpdateCscMaterialsInput!
    $condition: ModelcscMaterialsConditionInput
  ) {
    updateCscMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materials
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteCscMaterials = /* GraphQL */ `
  mutation DeleteCscMaterials(
    $input: DeleteCscMaterialsInput!
    $condition: ModelcscMaterialsConditionInput
  ) {
    deleteCscMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materials
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createVariousMaterials = /* GraphQL */ `
  mutation CreateVariousMaterials(
    $input: CreateVariousMaterialsInput!
    $condition: ModelvariousMaterialsConditionInput
  ) {
    createVariousMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materialName
      additionalDetails
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateVariousMaterials = /* GraphQL */ `
  mutation UpdateVariousMaterials(
    $input: UpdateVariousMaterialsInput!
    $condition: ModelvariousMaterialsConditionInput
  ) {
    updateVariousMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materialName
      additionalDetails
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteVariousMaterials = /* GraphQL */ `
  mutation DeleteVariousMaterials(
    $input: DeleteVariousMaterialsInput!
    $condition: ModelvariousMaterialsConditionInput
  ) {
    deleteVariousMaterials(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      materialName
      additionalDetails
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createOtherUpload = /* GraphQL */ `
  mutation CreateOtherUpload(
    $input: CreateOtherUploadInput!
    $condition: ModelotherUploadConditionInput
  ) {
    createOtherUpload(input: $input, condition: $condition) {
      id
      filename
      location
      description
      date
      userID
      createdAt
      updatedAt
      uploadedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`
export const updateOtherUpload = /* GraphQL */ `
  mutation UpdateOtherUpload(
    $input: UpdateOtherUploadInput!
    $condition: ModelotherUploadConditionInput
  ) {
    updateOtherUpload(input: $input, condition: $condition) {
      id
      filename
      location
      description
      date
      userID
      createdAt
      updatedAt
      uploadedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`
export const deleteOtherUpload = /* GraphQL */ `
  mutation DeleteOtherUpload(
    $input: DeleteOtherUploadInput!
    $condition: ModelotherUploadConditionInput
  ) {
    deleteOtherUpload(input: $input, condition: $condition) {
      id
      filename
      location
      description
      date
      userID
      createdAt
      updatedAt
      uploadedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      userID
      surveyScore
      surveyRecommendation
      dept
      assignedDept
      division
      position
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      userID
      surveyScore
      surveyRecommendation
      dept
      assignedDept
      division
      position
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      userID
      surveyScore
      surveyRecommendation
      dept
      assignedDept
      division
      position
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createTrainingAnnouncement = /* GraphQL */ `
  mutation CreateTrainingAnnouncement(
    $input: CreateTrainingAnnouncementInput!
    $condition: ModeltrainingAnnouncementConditionInput
  ) {
    createTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      additionalTexts
      isSeminar
      isPassPreAssessmentRequired
      preAssessmentScore
      createdAt
      updatedAt
      owner
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const updateTrainingAnnouncement = /* GraphQL */ `
  mutation UpdateTrainingAnnouncement(
    $input: UpdateTrainingAnnouncementInput!
    $condition: ModeltrainingAnnouncementConditionInput
  ) {
    updateTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      additionalTexts
      isSeminar
      isPassPreAssessmentRequired
      preAssessmentScore
      createdAt
      updatedAt
      owner
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const deleteTrainingAnnouncement = /* GraphQL */ `
  mutation DeleteTrainingAnnouncement(
    $input: DeleteTrainingAnnouncementInput!
    $condition: ModeltrainingAnnouncementConditionInput
  ) {
    deleteTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      additionalTexts
      isSeminar
      isPassPreAssessmentRequired
      preAssessmentScore
      createdAt
      updatedAt
      owner
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const createTrainingRegistration = /* GraphQL */ `
  mutation CreateTrainingRegistration(
    $input: CreateTrainingRegistrationInput!
    $condition: ModeltrainingRegistrationConditionInput
  ) {
    createTrainingRegistration(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      isAgreeDataPrivacyConsent
      fullName
      first_name
      last_name
      middle_initial
      emailAddress
      gender
      contactNumber
      dept_office_unit_taskForce
      division_section
      designation_position
      endorsementLetter
      nickname
      employmentType
      questionnaire
      expectedOutcomes
      createdAt
      isRegistered
      isSubmitEndorsementLetter
      isAttended
      isPostEvalDone
      isCourseOutputDone
      additionalRemarks
      cscProof
      isAdminRegistration
      isPreAssessmentPassed
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      onlineTraining {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        sectionTitle
        additionalFiles
        sectionVideos
        sectionQuestions
        additionalTexts
        videoTranscripts
        isSeminar
        resourceSpeakerDetails
        announcementAndReminders
        questionAndAnswerObject
        isPassPreAssessmentRequired
        preAssessmentScore
        isPassPostAssessmentRequired
        postAssessmentScore
        createdAt
        updatedAt
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const updateTrainingRegistration = /* GraphQL */ `
  mutation UpdateTrainingRegistration(
    $input: UpdateTrainingRegistrationInput!
    $condition: ModeltrainingRegistrationConditionInput
  ) {
    updateTrainingRegistration(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      isAgreeDataPrivacyConsent
      fullName
      first_name
      last_name
      middle_initial
      emailAddress
      gender
      contactNumber
      dept_office_unit_taskForce
      division_section
      designation_position
      endorsementLetter
      nickname
      employmentType
      questionnaire
      expectedOutcomes
      createdAt
      isRegistered
      isSubmitEndorsementLetter
      isAttended
      isPostEvalDone
      isCourseOutputDone
      additionalRemarks
      cscProof
      isAdminRegistration
      isPreAssessmentPassed
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      onlineTraining {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        sectionTitle
        additionalFiles
        sectionVideos
        sectionQuestions
        additionalTexts
        videoTranscripts
        isSeminar
        resourceSpeakerDetails
        announcementAndReminders
        questionAndAnswerObject
        isPassPreAssessmentRequired
        preAssessmentScore
        isPassPostAssessmentRequired
        postAssessmentScore
        createdAt
        updatedAt
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const deleteTrainingRegistration = /* GraphQL */ `
  mutation DeleteTrainingRegistration(
    $input: DeleteTrainingRegistrationInput!
    $condition: ModeltrainingRegistrationConditionInput
  ) {
    deleteTrainingRegistration(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      isAgreeDataPrivacyConsent
      fullName
      first_name
      last_name
      middle_initial
      emailAddress
      gender
      contactNumber
      dept_office_unit_taskForce
      division_section
      designation_position
      endorsementLetter
      nickname
      employmentType
      questionnaire
      expectedOutcomes
      createdAt
      isRegistered
      isSubmitEndorsementLetter
      isAttended
      isPostEvalDone
      isCourseOutputDone
      additionalRemarks
      cscProof
      isAdminRegistration
      isPreAssessmentPassed
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      onlineTraining {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        sectionTitle
        additionalFiles
        sectionVideos
        sectionQuestions
        additionalTexts
        videoTranscripts
        isSeminar
        resourceSpeakerDetails
        announcementAndReminders
        questionAndAnswerObject
        isPassPreAssessmentRequired
        preAssessmentScore
        isPassPostAssessmentRequired
        postAssessmentScore
        createdAt
        updatedAt
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const createHomeCardAnnouncement = /* GraphQL */ `
  mutation CreateHomeCardAnnouncement(
    $input: CreateHomeCardAnnouncementInput!
    $condition: ModelhomeCardAnnouncementConditionInput
  ) {
    createHomeCardAnnouncement(input: $input, condition: $condition) {
      id
      upper
      lower
      lowerLocation
      hideLower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateHomeCardAnnouncement = /* GraphQL */ `
  mutation UpdateHomeCardAnnouncement(
    $input: UpdateHomeCardAnnouncementInput!
    $condition: ModelhomeCardAnnouncementConditionInput
  ) {
    updateHomeCardAnnouncement(input: $input, condition: $condition) {
      id
      upper
      lower
      lowerLocation
      hideLower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteHomeCardAnnouncement = /* GraphQL */ `
  mutation DeleteHomeCardAnnouncement(
    $input: DeleteHomeCardAnnouncementInput!
    $condition: ModelhomeCardAnnouncementConditionInput
  ) {
    deleteHomeCardAnnouncement(input: $input, condition: $condition) {
      id
      upper
      lower
      lowerLocation
      hideLower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createHomeCardRegistration = /* GraphQL */ `
  mutation CreateHomeCardRegistration(
    $input: CreateHomeCardRegistrationInput!
    $condition: ModelhomeCardRegistrationConditionInput
  ) {
    createHomeCardRegistration(input: $input, condition: $condition) {
      id
      upper
      lower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateHomeCardRegistration = /* GraphQL */ `
  mutation UpdateHomeCardRegistration(
    $input: UpdateHomeCardRegistrationInput!
    $condition: ModelhomeCardRegistrationConditionInput
  ) {
    updateHomeCardRegistration(input: $input, condition: $condition) {
      id
      upper
      lower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteHomeCardRegistration = /* GraphQL */ `
  mutation DeleteHomeCardRegistration(
    $input: DeleteHomeCardRegistrationInput!
    $condition: ModelhomeCardRegistrationConditionInput
  ) {
    deleteHomeCardRegistration(input: $input, condition: $condition) {
      id
      upper
      lower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createHomeCardUpcomingTrainingCard = /* GraphQL */ `
  mutation CreateHomeCardUpcomingTrainingCard(
    $input: CreateHomeCardUpcomingTrainingCardInput!
    $condition: ModelhomeCardUpcomingTrainingCardConditionInput
  ) {
    createHomeCardUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateHomeCardUpcomingTrainingCard = /* GraphQL */ `
  mutation UpdateHomeCardUpcomingTrainingCard(
    $input: UpdateHomeCardUpcomingTrainingCardInput!
    $condition: ModelhomeCardUpcomingTrainingCardConditionInput
  ) {
    updateHomeCardUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteHomeCardUpcomingTrainingCard = /* GraphQL */ `
  mutation DeleteHomeCardUpcomingTrainingCard(
    $input: DeleteHomeCardUpcomingTrainingCardInput!
    $condition: ModelhomeCardUpcomingTrainingCardConditionInput
  ) {
    deleteHomeCardUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createHomeUpcomingTrainingCard = /* GraphQL */ `
  mutation CreateHomeUpcomingTrainingCard(
    $input: CreateHomeUpcomingTrainingCardInput!
    $condition: ModelhomeUpcomingTrainingCardConditionInput
  ) {
    createHomeUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateHomeUpcomingTrainingCard = /* GraphQL */ `
  mutation UpdateHomeUpcomingTrainingCard(
    $input: UpdateHomeUpcomingTrainingCardInput!
    $condition: ModelhomeUpcomingTrainingCardConditionInput
  ) {
    updateHomeUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteHomeUpcomingTrainingCard = /* GraphQL */ `
  mutation DeleteHomeUpcomingTrainingCard(
    $input: DeleteHomeUpcomingTrainingCardInput!
    $condition: ModelhomeUpcomingTrainingCardConditionInput
  ) {
    deleteHomeUpcomingTrainingCard(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createTrainingPostEvaluation = /* GraphQL */ `
  mutation CreateTrainingPostEvaluation(
    $input: CreateTrainingPostEvaluationInput!
    $condition: ModeltrainingPostEvaluationConditionInput
  ) {
    createTrainingPostEvaluation(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      clarityOfObjectives
      relevanceOfContent
      sequenceOfContent
      facilitationMOTS
      facilitationTimeMgt
      facilitationProfConduct
      adminMOTS
      adminTimeMgt
      adminProfConduct
      postEvalQuestions
      questionnaire
      additionalComments
      cscComments
      createdAt
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
export const updateTrainingPostEvaluation = /* GraphQL */ `
  mutation UpdateTrainingPostEvaluation(
    $input: UpdateTrainingPostEvaluationInput!
    $condition: ModeltrainingPostEvaluationConditionInput
  ) {
    updateTrainingPostEvaluation(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      clarityOfObjectives
      relevanceOfContent
      sequenceOfContent
      facilitationMOTS
      facilitationTimeMgt
      facilitationProfConduct
      adminMOTS
      adminTimeMgt
      adminProfConduct
      postEvalQuestions
      questionnaire
      additionalComments
      cscComments
      createdAt
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
export const deleteTrainingPostEvaluation = /* GraphQL */ `
  mutation DeleteTrainingPostEvaluation(
    $input: DeleteTrainingPostEvaluationInput!
    $condition: ModeltrainingPostEvaluationConditionInput
  ) {
    deleteTrainingPostEvaluation(input: $input, condition: $condition) {
      id
      userID
      trainingAnnouncementID
      clarityOfObjectives
      relevanceOfContent
      sequenceOfContent
      facilitationMOTS
      facilitationTimeMgt
      facilitationProfConduct
      adminMOTS
      adminTimeMgt
      adminProfConduct
      postEvalQuestions
      questionnaire
      additionalComments
      cscComments
      createdAt
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
export const createEvaluationQuestions = /* GraphQL */ `
  mutation CreateEvaluationQuestions(
    $input: CreateEvaluationQuestionsInput!
    $condition: ModelevaluationQuestionsConditionInput
  ) {
    createEvaluationQuestions(input: $input, condition: $condition) {
      id
      eval
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateEvaluationQuestions = /* GraphQL */ `
  mutation UpdateEvaluationQuestions(
    $input: UpdateEvaluationQuestionsInput!
    $condition: ModelevaluationQuestionsConditionInput
  ) {
    updateEvaluationQuestions(input: $input, condition: $condition) {
      id
      eval
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteEvaluationQuestions = /* GraphQL */ `
  mutation DeleteEvaluationQuestions(
    $input: DeleteEvaluationQuestionsInput!
    $condition: ModelevaluationQuestionsConditionInput
  ) {
    deleteEvaluationQuestions(input: $input, condition: $condition) {
      id
      eval
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createOnlineActivityQuestions = /* GraphQL */ `
  mutation CreateOnlineActivityQuestions(
    $input: CreateOnlineActivityQuestionsInput!
    $condition: ModelonlineActivityQuestionsConditionInput
  ) {
    createOnlineActivityQuestions(input: $input, condition: $condition) {
      id
      questions
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateOnlineActivityQuestions = /* GraphQL */ `
  mutation UpdateOnlineActivityQuestions(
    $input: UpdateOnlineActivityQuestionsInput!
    $condition: ModelonlineActivityQuestionsConditionInput
  ) {
    updateOnlineActivityQuestions(input: $input, condition: $condition) {
      id
      questions
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteOnlineActivityQuestions = /* GraphQL */ `
  mutation DeleteOnlineActivityQuestions(
    $input: DeleteOnlineActivityQuestionsInput!
    $condition: ModelonlineActivityQuestionsConditionInput
  ) {
    deleteOnlineActivityQuestions(input: $input, condition: $condition) {
      id
      questions
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createOnlineActivityAnswers = /* GraphQL */ `
  mutation CreateOnlineActivityAnswers(
    $input: CreateOnlineActivityAnswersInput!
    $condition: ModelonlineActivityAnswersConditionInput
  ) {
    createOnlineActivityAnswers(input: $input, condition: $condition) {
      id
      answers
      userID
      onlineTrainingAnnouncementID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateOnlineActivityAnswers = /* GraphQL */ `
  mutation UpdateOnlineActivityAnswers(
    $input: UpdateOnlineActivityAnswersInput!
    $condition: ModelonlineActivityAnswersConditionInput
  ) {
    updateOnlineActivityAnswers(input: $input, condition: $condition) {
      id
      answers
      userID
      onlineTrainingAnnouncementID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteOnlineActivityAnswers = /* GraphQL */ `
  mutation DeleteOnlineActivityAnswers(
    $input: DeleteOnlineActivityAnswersInput!
    $condition: ModelonlineActivityAnswersConditionInput
  ) {
    deleteOnlineActivityAnswers(input: $input, condition: $condition) {
      id
      answers
      userID
      onlineTrainingAnnouncementID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createAssessmentQuestions = /* GraphQL */ `
  mutation CreateAssessmentQuestions(
    $input: CreateAssessmentQuestionsInput!
    $condition: ModelassessmentQuestionsConditionInput
  ) {
    createAssessmentQuestions(input: $input, condition: $condition) {
      id
      assessmentQuestionnaire
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateAssessmentQuestions = /* GraphQL */ `
  mutation UpdateAssessmentQuestions(
    $input: UpdateAssessmentQuestionsInput!
    $condition: ModelassessmentQuestionsConditionInput
  ) {
    updateAssessmentQuestions(input: $input, condition: $condition) {
      id
      assessmentQuestionnaire
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteAssessmentQuestions = /* GraphQL */ `
  mutation DeleteAssessmentQuestions(
    $input: DeleteAssessmentQuestionsInput!
    $condition: ModelassessmentQuestionsConditionInput
  ) {
    deleteAssessmentQuestions(input: $input, condition: $condition) {
      id
      assessmentQuestionnaire
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createSpmsOPCRPartOne = /* GraphQL */ `
  mutation CreateSpmsOPCRPartOne(
    $input: CreateSpmsOPCRPartOneInput!
    $condition: ModelspmsOPCRPartOneConditionInput
  ) {
    createSpmsOPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      isApprovedByApprover
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateSpmsOPCRPartOne = /* GraphQL */ `
  mutation UpdateSpmsOPCRPartOne(
    $input: UpdateSpmsOPCRPartOneInput!
    $condition: ModelspmsOPCRPartOneConditionInput
  ) {
    updateSpmsOPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      isApprovedByApprover
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteSpmsOPCRPartOne = /* GraphQL */ `
  mutation DeleteSpmsOPCRPartOne(
    $input: DeleteSpmsOPCRPartOneInput!
    $condition: ModelspmsOPCRPartOneConditionInput
  ) {
    deleteSpmsOPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      isApprovedByApprover
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createSpmsOPCREvaluation = /* GraphQL */ `
  mutation CreateSpmsOPCREvaluation(
    $input: CreateSpmsOPCREvaluationInput!
    $condition: ModelspmsOPCREvaluationConditionInput
  ) {
    createSpmsOPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      approverID
      formOPCRID
      isForApproverReview
      isFinal
      formulationOPCR {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateSpmsOPCREvaluation = /* GraphQL */ `
  mutation UpdateSpmsOPCREvaluation(
    $input: UpdateSpmsOPCREvaluationInput!
    $condition: ModelspmsOPCREvaluationConditionInput
  ) {
    updateSpmsOPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      approverID
      formOPCRID
      isForApproverReview
      isFinal
      formulationOPCR {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteSpmsOPCREvaluation = /* GraphQL */ `
  mutation DeleteSpmsOPCREvaluation(
    $input: DeleteSpmsOPCREvaluationInput!
    $condition: ModelspmsOPCREvaluationConditionInput
  ) {
    deleteSpmsOPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      approverID
      formOPCRID
      isForApproverReview
      isFinal
      formulationOPCR {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createSpmsDPCREvaluation = /* GraphQL */ `
  mutation CreateSpmsDPCREvaluation(
    $input: CreateSpmsDPCREvaluationInput!
    $condition: ModelspmsDPCREvaluationConditionInput
  ) {
    createSpmsDPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      division
      approverID
      formDPCRID
      isForApproverReview
      isFinal
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationDPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const updateSpmsDPCREvaluation = /* GraphQL */ `
  mutation UpdateSpmsDPCREvaluation(
    $input: UpdateSpmsDPCREvaluationInput!
    $condition: ModelspmsDPCREvaluationConditionInput
  ) {
    updateSpmsDPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      division
      approverID
      formDPCRID
      isForApproverReview
      isFinal
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationDPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const deleteSpmsDPCREvaluation = /* GraphQL */ `
  mutation DeleteSpmsDPCREvaluation(
    $input: DeleteSpmsDPCREvaluationInput!
    $condition: ModelspmsDPCREvaluationConditionInput
  ) {
    deleteSpmsDPCREvaluation(input: $input, condition: $condition) {
      id
      data
      employeeID
      department
      division
      approverID
      formDPCRID
      isForApproverReview
      isFinal
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationDPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const createSpmsIPCREvaluation = /* GraphQL */ `
  mutation CreateSpmsIPCREvaluation(
    $input: CreateSpmsIPCREvaluationInput!
    $condition: ModelspmsIPCREvaluationConditionInput
  ) {
    createSpmsIPCREvaluation(input: $input, condition: $condition) {
      id
      data
      isForSupervisorReview
      isFinal
      employeeID
      department
      division
      supervisorID
      formIPCRID
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationIPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const updateSpmsIPCREvaluation = /* GraphQL */ `
  mutation UpdateSpmsIPCREvaluation(
    $input: UpdateSpmsIPCREvaluationInput!
    $condition: ModelspmsIPCREvaluationConditionInput
  ) {
    updateSpmsIPCREvaluation(input: $input, condition: $condition) {
      id
      data
      isForSupervisorReview
      isFinal
      employeeID
      department
      division
      supervisorID
      formIPCRID
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationIPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const deleteSpmsIPCREvaluation = /* GraphQL */ `
  mutation DeleteSpmsIPCREvaluation(
    $input: DeleteSpmsIPCREvaluationInput!
    $condition: ModelspmsIPCREvaluationConditionInput
  ) {
    deleteSpmsIPCREvaluation(input: $input, condition: $condition) {
      id
      data
      isForSupervisorReview
      isFinal
      employeeID
      department
      division
      supervisorID
      formIPCRID
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationIPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const createSpmsIPCRPartOne = /* GraphQL */ `
  mutation CreateSpmsIPCRPartOne(
    $input: CreateSpmsIPCRPartOneInput!
    $condition: ModelspmsIPCRPartOneConditionInput
  ) {
    createSpmsIPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      supervisorID
      commentsOfSupervisor
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateSpmsIPCRPartOne = /* GraphQL */ `
  mutation UpdateSpmsIPCRPartOne(
    $input: UpdateSpmsIPCRPartOneInput!
    $condition: ModelspmsIPCRPartOneConditionInput
  ) {
    updateSpmsIPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      supervisorID
      commentsOfSupervisor
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteSpmsIPCRPartOne = /* GraphQL */ `
  mutation DeleteSpmsIPCRPartOne(
    $input: DeleteSpmsIPCRPartOneInput!
    $condition: ModelspmsIPCRPartOneConditionInput
  ) {
    deleteSpmsIPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      supervisorID
      commentsOfSupervisor
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createSpmsDPCRPartOne = /* GraphQL */ `
  mutation CreateSpmsDPCRPartOne(
    $input: CreateSpmsDPCRPartOneInput!
    $condition: ModelspmsDPCRPartOneConditionInput
  ) {
    createSpmsDPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateSpmsDPCRPartOne = /* GraphQL */ `
  mutation UpdateSpmsDPCRPartOne(
    $input: UpdateSpmsDPCRPartOneInput!
    $condition: ModelspmsDPCRPartOneConditionInput
  ) {
    updateSpmsDPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteSpmsDPCRPartOne = /* GraphQL */ `
  mutation DeleteSpmsDPCRPartOne(
    $input: DeleteSpmsDPCRPartOneInput!
    $condition: ModelspmsDPCRPartOneConditionInput
  ) {
    deleteSpmsDPCRPartOne(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createTrainingVideo = /* GraphQL */ `
  mutation CreateTrainingVideo(
    $input: CreateTrainingVideoInput!
    $condition: ModeltrainingVideoConditionInput
  ) {
    createTrainingVideo(input: $input, condition: $condition) {
      id
      key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateTrainingVideo = /* GraphQL */ `
  mutation UpdateTrainingVideo(
    $input: UpdateTrainingVideoInput!
    $condition: ModeltrainingVideoConditionInput
  ) {
    updateTrainingVideo(input: $input, condition: $condition) {
      id
      key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteTrainingVideo = /* GraphQL */ `
  mutation DeleteTrainingVideo(
    $input: DeleteTrainingVideoInput!
    $condition: ModeltrainingVideoConditionInput
  ) {
    deleteTrainingVideo(input: $input, condition: $condition) {
      id
      key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createShowVideoByTraining = /* GraphQL */ `
  mutation CreateShowVideoByTraining(
    $input: CreateShowVideoByTrainingInput!
    $condition: ModelshowVideoByTrainingConditionInput
  ) {
    createShowVideoByTraining(input: $input, condition: $condition) {
      id
      trainingVideoID
      trainingAnnouncementID
      createdAt
      updatedAt
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateShowVideoByTraining = /* GraphQL */ `
  mutation UpdateShowVideoByTraining(
    $input: UpdateShowVideoByTrainingInput!
    $condition: ModelshowVideoByTrainingConditionInput
  ) {
    updateShowVideoByTraining(input: $input, condition: $condition) {
      id
      trainingVideoID
      trainingAnnouncementID
      createdAt
      updatedAt
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteShowVideoByTraining = /* GraphQL */ `
  mutation DeleteShowVideoByTraining(
    $input: DeleteShowVideoByTrainingInput!
    $condition: ModelshowVideoByTrainingConditionInput
  ) {
    deleteShowVideoByTraining(input: $input, condition: $condition) {
      id
      trainingVideoID
      trainingAnnouncementID
      createdAt
      updatedAt
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createStepThreeRegistration = /* GraphQL */ `
  mutation CreateStepThreeRegistration(
    $input: CreateStepThreeRegistrationInput!
    $condition: ModelstepThreeRegistrationConditionInput
  ) {
    createStepThreeRegistration(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateStepThreeRegistration = /* GraphQL */ `
  mutation UpdateStepThreeRegistration(
    $input: UpdateStepThreeRegistrationInput!
    $condition: ModelstepThreeRegistrationConditionInput
  ) {
    updateStepThreeRegistration(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteStepThreeRegistration = /* GraphQL */ `
  mutation DeleteStepThreeRegistration(
    $input: DeleteStepThreeRegistrationInput!
    $condition: ModelstepThreeRegistrationConditionInput
  ) {
    deleteStepThreeRegistration(input: $input, condition: $condition) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createFaqTable = /* GraphQL */ `
  mutation CreateFaqTable(
    $input: CreateFaqTableInput!
    $condition: ModelfaqTableConditionInput
  ) {
    createFaqTable(input: $input, condition: $condition) {
      id
      category
      questionText
      answerText
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateFaqTable = /* GraphQL */ `
  mutation UpdateFaqTable(
    $input: UpdateFaqTableInput!
    $condition: ModelfaqTableConditionInput
  ) {
    updateFaqTable(input: $input, condition: $condition) {
      id
      category
      questionText
      answerText
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteFaqTable = /* GraphQL */ `
  mutation DeleteFaqTable(
    $input: DeleteFaqTableInput!
    $condition: ModelfaqTableConditionInput
  ) {
    deleteFaqTable(input: $input, condition: $condition) {
      id
      category
      questionText
      answerText
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteSpmsVideoAcknowledgement = /* GraphQL */ `
  mutation DeleteSpmsVideoAcknowledgement(
    $input: DeleteSpmsVideoAcknowledgementInput!
    $condition: ModelspmsVideoAcknowledgementConditionInput
  ) {
    deleteSpmsVideoAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createIPCRUpload = /* GraphQL */ `
  mutation CreateIPCRUpload(
    $input: CreateIPCRUploadInput!
    $condition: ModelIPCRUploadConditionInput
  ) {
    createIPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      division
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateIPCRUpload = /* GraphQL */ `
  mutation UpdateIPCRUpload(
    $input: UpdateIPCRUploadInput!
    $condition: ModelIPCRUploadConditionInput
  ) {
    updateIPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      division
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteIPCRUpload = /* GraphQL */ `
  mutation DeleteIPCRUpload(
    $input: DeleteIPCRUploadInput!
    $condition: ModelIPCRUploadConditionInput
  ) {
    deleteIPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      division
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createDPCRUpload = /* GraphQL */ `
  mutation CreateDPCRUpload(
    $input: CreateDPCRUploadInput!
    $condition: ModelDPCRUploadConditionInput
  ) {
    createDPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateDPCRUpload = /* GraphQL */ `
  mutation UpdateDPCRUpload(
    $input: UpdateDPCRUploadInput!
    $condition: ModelDPCRUploadConditionInput
  ) {
    updateDPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteDPCRUpload = /* GraphQL */ `
  mutation DeleteDPCRUpload(
    $input: DeleteDPCRUploadInput!
    $condition: ModelDPCRUploadConditionInput
  ) {
    deleteDPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createSecretKeys = /* GraphQL */ `
  mutation CreateSecretKeys(
    $input: CreateSecretKeysInput!
    $condition: ModelsecretKeysConditionInput
  ) {
    createSecretKeys(input: $input, condition: $condition) {
      id
      secret
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateSecretKeys = /* GraphQL */ `
  mutation UpdateSecretKeys(
    $input: UpdateSecretKeysInput!
    $condition: ModelsecretKeysConditionInput
  ) {
    updateSecretKeys(input: $input, condition: $condition) {
      id
      secret
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteSecretKeys = /* GraphQL */ `
  mutation DeleteSecretKeys(
    $input: DeleteSecretKeysInput!
    $condition: ModelsecretKeysConditionInput
  ) {
    deleteSecretKeys(input: $input, condition: $condition) {
      id
      secret
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createOnlineTrainingAnnouncement = /* GraphQL */ `
  mutation CreateOnlineTrainingAnnouncement(
    $input: CreateOnlineTrainingAnnouncementInput!
    $condition: ModelonlineTrainingAnnouncementConditionInput
  ) {
    createOnlineTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      sectionTitle
      additionalFiles
      sectionVideos
      sectionQuestions
      additionalTexts
      videoTranscripts
      isSeminar
      resourceSpeakerDetails
      announcementAndReminders
      questionAndAnswerObject
      isPassPreAssessmentRequired
      preAssessmentScore
      isPassPostAssessmentRequired
      postAssessmentScore
      createdAt
      updatedAt
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateOnlineTrainingAnnouncement = /* GraphQL */ `
  mutation UpdateOnlineTrainingAnnouncement(
    $input: UpdateOnlineTrainingAnnouncementInput!
    $condition: ModelonlineTrainingAnnouncementConditionInput
  ) {
    updateOnlineTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      sectionTitle
      additionalFiles
      sectionVideos
      sectionQuestions
      additionalTexts
      videoTranscripts
      isSeminar
      resourceSpeakerDetails
      announcementAndReminders
      questionAndAnswerObject
      isPassPreAssessmentRequired
      preAssessmentScore
      isPassPostAssessmentRequired
      postAssessmentScore
      createdAt
      updatedAt
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteOnlineTrainingAnnouncement = /* GraphQL */ `
  mutation DeleteOnlineTrainingAnnouncement(
    $input: DeleteOnlineTrainingAnnouncementInput!
    $condition: ModelonlineTrainingAnnouncementConditionInput
  ) {
    deleteOnlineTrainingAnnouncement(input: $input, condition: $condition) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      sectionTitle
      additionalFiles
      sectionVideos
      sectionQuestions
      additionalTexts
      videoTranscripts
      isSeminar
      resourceSpeakerDetails
      announcementAndReminders
      questionAndAnswerObject
      isPassPreAssessmentRequired
      preAssessmentScore
      isPassPostAssessmentRequired
      postAssessmentScore
      createdAt
      updatedAt
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createOPCRUpload = /* GraphQL */ `
  mutation CreateOPCRUpload(
    $input: CreateOPCRUploadInput!
    $condition: ModelOPCRUploadConditionInput
  ) {
    createOPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateOPCRUpload = /* GraphQL */ `
  mutation UpdateOPCRUpload(
    $input: UpdateOPCRUploadInput!
    $condition: ModelOPCRUploadConditionInput
  ) {
    updateOPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteOPCRUpload = /* GraphQL */ `
  mutation DeleteOPCRUpload(
    $input: DeleteOPCRUploadInput!
    $condition: ModelOPCRUploadConditionInput
  ) {
    deleteOPCRUpload(input: $input, condition: $condition) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteOrientationVideoAcknowledgement = /* GraphQL */ `
  mutation DeleteOrientationVideoAcknowledgement(
    $input: DeleteOrientationVideoAcknowledgementInput!
    $condition: ModelorientationVideoAcknowledgementConditionInput
  ) {
    deleteOrientationVideoAcknowledgement(
      input: $input
      condition: $condition
    ) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createHomeSlidesUploaded = /* GraphQL */ `
  mutation CreateHomeSlidesUploaded(
    $input: CreateHomeSlidesUploadedInput!
    $condition: ModelhomeSlidesUploadedConditionInput
  ) {
    createHomeSlidesUploaded(input: $input, condition: $condition) {
      id
      slideImageFileName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateHomeSlidesUploaded = /* GraphQL */ `
  mutation UpdateHomeSlidesUploaded(
    $input: UpdateHomeSlidesUploadedInput!
    $condition: ModelhomeSlidesUploadedConditionInput
  ) {
    updateHomeSlidesUploaded(input: $input, condition: $condition) {
      id
      slideImageFileName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteHomeSlidesUploaded = /* GraphQL */ `
  mutation DeleteHomeSlidesUploaded(
    $input: DeleteHomeSlidesUploadedInput!
    $condition: ModelhomeSlidesUploadedConditionInput
  ) {
    deleteHomeSlidesUploaded(input: $input, condition: $condition) {
      id
      slideImageFileName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createPublicKeys = /* GraphQL */ `
  mutation CreatePublicKeys(
    $input: CreatePublicKeysInput!
    $condition: ModelpublicKeysConditionInput
  ) {
    createPublicKeys(input: $input, condition: $condition) {
      id
      publicKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePublicKeys = /* GraphQL */ `
  mutation UpdatePublicKeys(
    $input: UpdatePublicKeysInput!
    $condition: ModelpublicKeysConditionInput
  ) {
    updatePublicKeys(input: $input, condition: $condition) {
      id
      publicKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePublicKeys = /* GraphQL */ `
  mutation DeletePublicKeys(
    $input: DeletePublicKeysInput!
    $condition: ModelpublicKeysConditionInput
  ) {
    deletePublicKeys(input: $input, condition: $condition) {
      id
      publicKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createActivityOneAnswer = /* GraphQL */ `
  mutation CreateActivityOneAnswer(
    $input: CreateActivityOneAnswerInput!
    $condition: ModelactivityOneAnswerConditionInput
  ) {
    createActivityOneAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateActivityOneAnswer = /* GraphQL */ `
  mutation UpdateActivityOneAnswer(
    $input: UpdateActivityOneAnswerInput!
    $condition: ModelactivityOneAnswerConditionInput
  ) {
    updateActivityOneAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteActivityOneAnswer = /* GraphQL */ `
  mutation DeleteActivityOneAnswer(
    $input: DeleteActivityOneAnswerInput!
    $condition: ModelactivityOneAnswerConditionInput
  ) {
    deleteActivityOneAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createActivityTwoAnswer = /* GraphQL */ `
  mutation CreateActivityTwoAnswer(
    $input: CreateActivityTwoAnswerInput!
    $condition: ModelactivityTwoAnswerConditionInput
  ) {
    createActivityTwoAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateActivityTwoAnswer = /* GraphQL */ `
  mutation UpdateActivityTwoAnswer(
    $input: UpdateActivityTwoAnswerInput!
    $condition: ModelactivityTwoAnswerConditionInput
  ) {
    updateActivityTwoAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteActivityTwoAnswer = /* GraphQL */ `
  mutation DeleteActivityTwoAnswer(
    $input: DeleteActivityTwoAnswerInput!
    $condition: ModelactivityTwoAnswerConditionInput
  ) {
    deleteActivityTwoAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createActivityThreeAnswer = /* GraphQL */ `
  mutation CreateActivityThreeAnswer(
    $input: CreateActivityThreeAnswerInput!
    $condition: ModelactivityThreeAnswerConditionInput
  ) {
    createActivityThreeAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateActivityThreeAnswer = /* GraphQL */ `
  mutation UpdateActivityThreeAnswer(
    $input: UpdateActivityThreeAnswerInput!
    $condition: ModelactivityThreeAnswerConditionInput
  ) {
    updateActivityThreeAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteActivityThreeAnswer = /* GraphQL */ `
  mutation DeleteActivityThreeAnswer(
    $input: DeleteActivityThreeAnswerInput!
    $condition: ModelactivityThreeAnswerConditionInput
  ) {
    deleteActivityThreeAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createActivityFourAnswer = /* GraphQL */ `
  mutation CreateActivityFourAnswer(
    $input: CreateActivityFourAnswerInput!
    $condition: ModelactivityFourAnswerConditionInput
  ) {
    createActivityFourAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateActivityFourAnswer = /* GraphQL */ `
  mutation UpdateActivityFourAnswer(
    $input: UpdateActivityFourAnswerInput!
    $condition: ModelactivityFourAnswerConditionInput
  ) {
    updateActivityFourAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteActivityFourAnswer = /* GraphQL */ `
  mutation DeleteActivityFourAnswer(
    $input: DeleteActivityFourAnswerInput!
    $condition: ModelactivityFourAnswerConditionInput
  ) {
    deleteActivityFourAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createActivityFiveAnswer = /* GraphQL */ `
  mutation CreateActivityFiveAnswer(
    $input: CreateActivityFiveAnswerInput!
    $condition: ModelactivityFiveAnswerConditionInput
  ) {
    createActivityFiveAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateActivityFiveAnswer = /* GraphQL */ `
  mutation UpdateActivityFiveAnswer(
    $input: UpdateActivityFiveAnswerInput!
    $condition: ModelactivityFiveAnswerConditionInput
  ) {
    updateActivityFiveAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteActivityFiveAnswer = /* GraphQL */ `
  mutation DeleteActivityFiveAnswer(
    $input: DeleteActivityFiveAnswerInput!
    $condition: ModelactivityFiveAnswerConditionInput
  ) {
    deleteActivityFiveAnswer(input: $input, condition: $condition) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPersonalDataSheet = /* GraphQL */ `
  mutation CreatePersonalDataSheet(
    $input: CreatePersonalDataSheetInput!
    $condition: ModelpersonalDataSheetConditionInput
  ) {
    createPersonalDataSheet(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApproved
      approverID
      commentsOfApprover
      isHRApproved
      hrApproverID
      commentsOfHRApprover
      createdAt
      isAgreeDataPrivacyPDS
      eSignatureLoc
      thumbmarkSignLoc
      passportPhotoLoc
      encodedBy
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      encoder {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePersonalDataSheet = /* GraphQL */ `
  mutation UpdatePersonalDataSheet(
    $input: UpdatePersonalDataSheetInput!
    $condition: ModelpersonalDataSheetConditionInput
  ) {
    updatePersonalDataSheet(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApproved
      approverID
      commentsOfApprover
      isHRApproved
      hrApproverID
      commentsOfHRApprover
      createdAt
      isAgreeDataPrivacyPDS
      eSignatureLoc
      thumbmarkSignLoc
      passportPhotoLoc
      encodedBy
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      encoder {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePersonalDataSheet = /* GraphQL */ `
  mutation DeletePersonalDataSheet(
    $input: DeletePersonalDataSheetInput!
    $condition: ModelpersonalDataSheetConditionInput
  ) {
    deletePersonalDataSheet(input: $input, condition: $condition) {
      id
      data
      userID
      isFinal
      department
      division
      isApproved
      approverID
      commentsOfApprover
      isHRApproved
      hrApproverID
      commentsOfHRApprover
      createdAt
      isAgreeDataPrivacyPDS
      eSignatureLoc
      thumbmarkSignLoc
      passportPhotoLoc
      encodedBy
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      encoder {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateErrorLogs = /* GraphQL */ `
  mutation UpdateErrorLogs(
    $input: UpdateErrorLogsInput!
    $condition: ModelerrorLogsConditionInput
  ) {
    updateErrorLogs(input: $input, condition: $condition) {
      id
      errorLog
      date
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteErrorLogs = /* GraphQL */ `
  mutation DeleteErrorLogs(
    $input: DeleteErrorLogsInput!
    $condition: ModelerrorLogsConditionInput
  ) {
    deleteErrorLogs(input: $input, condition: $condition) {
      id
      errorLog
      date
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createPSBSubmission = /* GraphQL */ `
  mutation CreatePSBSubmission(
    $input: CreatePSBSubmissionInput!
    $condition: ModelPSBSubmissionConditionInput
  ) {
    createPSBSubmission(input: $input, condition: $condition) {
      id
      userID
      department
      assignedDept
      division
      sequenceNumber
      sequenceDate
      isPlantilla
      psbDeliberationID
      status
      screeningRemarks
      expectedDeliberationDate
      actualDeliberationDate
      dateDeptApproved
      dateHRApproved
      positionAppliedFor
      psbWorkExperienceID
      eligibility
      performance
      education
      training
      experience
      hrRemarks
      psbTrainingExperienceID
      psbTranscriptOrDiplomaID
      psbCertificationID
      psbIndividualPerformanceID
      psbDrugTestID
      psbClearanceID
      psbAffidavitID
      psbJustificationLetterID
      psbLoneApplicantID
      psbAppraisalReportID
      psbMedicalCertificateID
      psbMarriageCertificateID
      psbBirthCertificateID
      psbEvaluationReportID
      psbAdditionalDocumentID
      isForSubmission
      assignedItemNumber
      createdAt
      updatedAt
      owner
      psbAdditionalDocument {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbWorkExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTrainingExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        status
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTranscriptOrDiploma {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbCertification {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbIndividualPerformance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbDrugTest {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbClearance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAffidavit {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbJustificationLetter {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbLoneApplicant {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAppraisalReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMedicalCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMarriageCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbBirthCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbEvaluationReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const updatePSBSubmission = /* GraphQL */ `
  mutation UpdatePSBSubmission(
    $input: UpdatePSBSubmissionInput!
    $condition: ModelPSBSubmissionConditionInput
  ) {
    updatePSBSubmission(input: $input, condition: $condition) {
      id
      userID
      department
      assignedDept
      division
      sequenceNumber
      sequenceDate
      isPlantilla
      psbDeliberationID
      status
      screeningRemarks
      expectedDeliberationDate
      actualDeliberationDate
      dateDeptApproved
      dateHRApproved
      positionAppliedFor
      psbWorkExperienceID
      eligibility
      performance
      education
      training
      experience
      hrRemarks
      psbTrainingExperienceID
      psbTranscriptOrDiplomaID
      psbCertificationID
      psbIndividualPerformanceID
      psbDrugTestID
      psbClearanceID
      psbAffidavitID
      psbJustificationLetterID
      psbLoneApplicantID
      psbAppraisalReportID
      psbMedicalCertificateID
      psbMarriageCertificateID
      psbBirthCertificateID
      psbEvaluationReportID
      psbAdditionalDocumentID
      isForSubmission
      assignedItemNumber
      createdAt
      updatedAt
      owner
      psbAdditionalDocument {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbWorkExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTrainingExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        status
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTranscriptOrDiploma {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbCertification {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbIndividualPerformance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbDrugTest {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbClearance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAffidavit {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbJustificationLetter {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbLoneApplicant {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAppraisalReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMedicalCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMarriageCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbBirthCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbEvaluationReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const deletePSBSubmission = /* GraphQL */ `
  mutation DeletePSBSubmission(
    $input: DeletePSBSubmissionInput!
    $condition: ModelPSBSubmissionConditionInput
  ) {
    deletePSBSubmission(input: $input, condition: $condition) {
      id
      userID
      department
      assignedDept
      division
      sequenceNumber
      sequenceDate
      isPlantilla
      psbDeliberationID
      status
      screeningRemarks
      expectedDeliberationDate
      actualDeliberationDate
      dateDeptApproved
      dateHRApproved
      positionAppliedFor
      psbWorkExperienceID
      eligibility
      performance
      education
      training
      experience
      hrRemarks
      psbTrainingExperienceID
      psbTranscriptOrDiplomaID
      psbCertificationID
      psbIndividualPerformanceID
      psbDrugTestID
      psbClearanceID
      psbAffidavitID
      psbJustificationLetterID
      psbLoneApplicantID
      psbAppraisalReportID
      psbMedicalCertificateID
      psbMarriageCertificateID
      psbBirthCertificateID
      psbEvaluationReportID
      psbAdditionalDocumentID
      isForSubmission
      assignedItemNumber
      createdAt
      updatedAt
      owner
      psbAdditionalDocument {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbWorkExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTrainingExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        status
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTranscriptOrDiploma {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbCertification {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbIndividualPerformance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbDrugTest {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbClearance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAffidavit {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbJustificationLetter {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbLoneApplicant {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAppraisalReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMedicalCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMarriageCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbBirthCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbEvaluationReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        isRequired
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const createPSBAdditionalDocument = /* GraphQL */ `
  mutation CreatePSBAdditionalDocument(
    $input: CreatePSBAdditionalDocumentInput!
    $condition: ModelPSBAdditionalDocumentConditionInput
  ) {
    createPSBAdditionalDocument(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBAdditionalDocument = /* GraphQL */ `
  mutation UpdatePSBAdditionalDocument(
    $input: UpdatePSBAdditionalDocumentInput!
    $condition: ModelPSBAdditionalDocumentConditionInput
  ) {
    updatePSBAdditionalDocument(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBAdditionalDocument = /* GraphQL */ `
  mutation DeletePSBAdditionalDocument(
    $input: DeletePSBAdditionalDocumentInput!
    $condition: ModelPSBAdditionalDocumentConditionInput
  ) {
    deletePSBAdditionalDocument(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBDeliberationActivity = /* GraphQL */ `
  mutation CreatePSBDeliberationActivity(
    $input: CreatePSBDeliberationActivityInput!
    $condition: ModelPSBDeliberationActivityConditionInput
  ) {
    createPSBDeliberationActivity(input: $input, condition: $condition) {
      id
      userID
      sequenceNumber
      date
      startDate
      cutoffDate
      isCompleted
      createdAt
      notes
      updatedAt
      user {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBDeliberationActivity = /* GraphQL */ `
  mutation UpdatePSBDeliberationActivity(
    $input: UpdatePSBDeliberationActivityInput!
    $condition: ModelPSBDeliberationActivityConditionInput
  ) {
    updatePSBDeliberationActivity(input: $input, condition: $condition) {
      id
      userID
      sequenceNumber
      date
      startDate
      cutoffDate
      isCompleted
      createdAt
      notes
      updatedAt
      user {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBDeliberationActivity = /* GraphQL */ `
  mutation DeletePSBDeliberationActivity(
    $input: DeletePSBDeliberationActivityInput!
    $condition: ModelPSBDeliberationActivityConditionInput
  ) {
    deletePSBDeliberationActivity(input: $input, condition: $condition) {
      id
      userID
      sequenceNumber
      date
      startDate
      cutoffDate
      isCompleted
      createdAt
      notes
      updatedAt
      user {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBUpload = /* GraphQL */ `
  mutation CreatePSBUpload(
    $input: CreatePSBUploadInput!
    $condition: ModelPSBUploadConditionInput
  ) {
    createPSBUpload(input: $input, condition: $condition) {
      id
      data
      userID
      department
      division
      isApproved
      dateApproved
      approverID
      commentsOfApprover
      isHRApproved
      dateHRApproved
      hrApproverID
      commentsOfHRApprover
      sequenceNumber
      sequenceDate
      isPlantilla
      isHardcopyReceived
      isHRHardcopyReceived
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBUpload = /* GraphQL */ `
  mutation UpdatePSBUpload(
    $input: UpdatePSBUploadInput!
    $condition: ModelPSBUploadConditionInput
  ) {
    updatePSBUpload(input: $input, condition: $condition) {
      id
      data
      userID
      department
      division
      isApproved
      dateApproved
      approverID
      commentsOfApprover
      isHRApproved
      dateHRApproved
      hrApproverID
      commentsOfHRApprover
      sequenceNumber
      sequenceDate
      isPlantilla
      isHardcopyReceived
      isHRHardcopyReceived
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBUpload = /* GraphQL */ `
  mutation DeletePSBUpload(
    $input: DeletePSBUploadInput!
    $condition: ModelPSBUploadConditionInput
  ) {
    deletePSBUpload(input: $input, condition: $condition) {
      id
      data
      userID
      department
      division
      isApproved
      dateApproved
      approverID
      commentsOfApprover
      isHRApproved
      dateHRApproved
      hrApproverID
      commentsOfHRApprover
      sequenceNumber
      sequenceDate
      isPlantilla
      isHardcopyReceived
      isHRHardcopyReceived
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBWorkExperience = /* GraphQL */ `
  mutation CreatePSBWorkExperience(
    $input: CreatePSBWorkExperienceInput!
    $condition: ModelPSBWorkExperienceConditionInput
  ) {
    createPSBWorkExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBWorkExperience = /* GraphQL */ `
  mutation UpdatePSBWorkExperience(
    $input: UpdatePSBWorkExperienceInput!
    $condition: ModelPSBWorkExperienceConditionInput
  ) {
    updatePSBWorkExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBWorkExperience = /* GraphQL */ `
  mutation DeletePSBWorkExperience(
    $input: DeletePSBWorkExperienceInput!
    $condition: ModelPSBWorkExperienceConditionInput
  ) {
    deletePSBWorkExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBTrainingExperience = /* GraphQL */ `
  mutation CreatePSBTrainingExperience(
    $input: CreatePSBTrainingExperienceInput!
    $condition: ModelPSBTrainingExperienceConditionInput
  ) {
    createPSBTrainingExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      status
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBTrainingExperience = /* GraphQL */ `
  mutation UpdatePSBTrainingExperience(
    $input: UpdatePSBTrainingExperienceInput!
    $condition: ModelPSBTrainingExperienceConditionInput
  ) {
    updatePSBTrainingExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      status
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBTrainingExperience = /* GraphQL */ `
  mutation DeletePSBTrainingExperience(
    $input: DeletePSBTrainingExperienceInput!
    $condition: ModelPSBTrainingExperienceConditionInput
  ) {
    deletePSBTrainingExperience(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      status
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBTranscriptOrDiploma = /* GraphQL */ `
  mutation CreatePSBTranscriptOrDiploma(
    $input: CreatePSBTranscriptOrDiplomaInput!
    $condition: ModelPSBTranscriptOrDiplomaConditionInput
  ) {
    createPSBTranscriptOrDiploma(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBTranscriptOrDiploma = /* GraphQL */ `
  mutation UpdatePSBTranscriptOrDiploma(
    $input: UpdatePSBTranscriptOrDiplomaInput!
    $condition: ModelPSBTranscriptOrDiplomaConditionInput
  ) {
    updatePSBTranscriptOrDiploma(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBTranscriptOrDiploma = /* GraphQL */ `
  mutation DeletePSBTranscriptOrDiploma(
    $input: DeletePSBTranscriptOrDiplomaInput!
    $condition: ModelPSBTranscriptOrDiplomaConditionInput
  ) {
    deletePSBTranscriptOrDiploma(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBCertification = /* GraphQL */ `
  mutation CreatePSBCertification(
    $input: CreatePSBCertificationInput!
    $condition: ModelPSBCertificationConditionInput
  ) {
    createPSBCertification(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBCertification = /* GraphQL */ `
  mutation UpdatePSBCertification(
    $input: UpdatePSBCertificationInput!
    $condition: ModelPSBCertificationConditionInput
  ) {
    updatePSBCertification(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBCertification = /* GraphQL */ `
  mutation DeletePSBCertification(
    $input: DeletePSBCertificationInput!
    $condition: ModelPSBCertificationConditionInput
  ) {
    deletePSBCertification(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBIndividualPerformance = /* GraphQL */ `
  mutation CreatePSBIndividualPerformance(
    $input: CreatePSBIndividualPerformanceInput!
    $condition: ModelPSBIndividualPerformanceConditionInput
  ) {
    createPSBIndividualPerformance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBIndividualPerformance = /* GraphQL */ `
  mutation UpdatePSBIndividualPerformance(
    $input: UpdatePSBIndividualPerformanceInput!
    $condition: ModelPSBIndividualPerformanceConditionInput
  ) {
    updatePSBIndividualPerformance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBIndividualPerformance = /* GraphQL */ `
  mutation DeletePSBIndividualPerformance(
    $input: DeletePSBIndividualPerformanceInput!
    $condition: ModelPSBIndividualPerformanceConditionInput
  ) {
    deletePSBIndividualPerformance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBDrugTest = /* GraphQL */ `
  mutation CreatePSBDrugTest(
    $input: CreatePSBDrugTestInput!
    $condition: ModelPSBDrugTestConditionInput
  ) {
    createPSBDrugTest(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBDrugTest = /* GraphQL */ `
  mutation UpdatePSBDrugTest(
    $input: UpdatePSBDrugTestInput!
    $condition: ModelPSBDrugTestConditionInput
  ) {
    updatePSBDrugTest(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBDrugTest = /* GraphQL */ `
  mutation DeletePSBDrugTest(
    $input: DeletePSBDrugTestInput!
    $condition: ModelPSBDrugTestConditionInput
  ) {
    deletePSBDrugTest(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBClearance = /* GraphQL */ `
  mutation CreatePSBClearance(
    $input: CreatePSBClearanceInput!
    $condition: ModelPSBClearanceConditionInput
  ) {
    createPSBClearance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBClearance = /* GraphQL */ `
  mutation UpdatePSBClearance(
    $input: UpdatePSBClearanceInput!
    $condition: ModelPSBClearanceConditionInput
  ) {
    updatePSBClearance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBClearance = /* GraphQL */ `
  mutation DeletePSBClearance(
    $input: DeletePSBClearanceInput!
    $condition: ModelPSBClearanceConditionInput
  ) {
    deletePSBClearance(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBAffidavit = /* GraphQL */ `
  mutation CreatePSBAffidavit(
    $input: CreatePSBAffidavitInput!
    $condition: ModelPSBAffidavitConditionInput
  ) {
    createPSBAffidavit(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBAffidavit = /* GraphQL */ `
  mutation UpdatePSBAffidavit(
    $input: UpdatePSBAffidavitInput!
    $condition: ModelPSBAffidavitConditionInput
  ) {
    updatePSBAffidavit(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBAffidavit = /* GraphQL */ `
  mutation DeletePSBAffidavit(
    $input: DeletePSBAffidavitInput!
    $condition: ModelPSBAffidavitConditionInput
  ) {
    deletePSBAffidavit(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBJustificationLetter = /* GraphQL */ `
  mutation CreatePSBJustificationLetter(
    $input: CreatePSBJustificationLetterInput!
    $condition: ModelPSBJustificationLetterConditionInput
  ) {
    createPSBJustificationLetter(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBJustificationLetter = /* GraphQL */ `
  mutation UpdatePSBJustificationLetter(
    $input: UpdatePSBJustificationLetterInput!
    $condition: ModelPSBJustificationLetterConditionInput
  ) {
    updatePSBJustificationLetter(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBJustificationLetter = /* GraphQL */ `
  mutation DeletePSBJustificationLetter(
    $input: DeletePSBJustificationLetterInput!
    $condition: ModelPSBJustificationLetterConditionInput
  ) {
    deletePSBJustificationLetter(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBLoneApplicant = /* GraphQL */ `
  mutation CreatePSBLoneApplicant(
    $input: CreatePSBLoneApplicantInput!
    $condition: ModelPSBLoneApplicantConditionInput
  ) {
    createPSBLoneApplicant(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBLoneApplicant = /* GraphQL */ `
  mutation UpdatePSBLoneApplicant(
    $input: UpdatePSBLoneApplicantInput!
    $condition: ModelPSBLoneApplicantConditionInput
  ) {
    updatePSBLoneApplicant(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBLoneApplicant = /* GraphQL */ `
  mutation DeletePSBLoneApplicant(
    $input: DeletePSBLoneApplicantInput!
    $condition: ModelPSBLoneApplicantConditionInput
  ) {
    deletePSBLoneApplicant(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBAppraisalReport = /* GraphQL */ `
  mutation CreatePSBAppraisalReport(
    $input: CreatePSBAppraisalReportInput!
    $condition: ModelPSBAppraisalReportConditionInput
  ) {
    createPSBAppraisalReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBAppraisalReport = /* GraphQL */ `
  mutation UpdatePSBAppraisalReport(
    $input: UpdatePSBAppraisalReportInput!
    $condition: ModelPSBAppraisalReportConditionInput
  ) {
    updatePSBAppraisalReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBAppraisalReport = /* GraphQL */ `
  mutation DeletePSBAppraisalReport(
    $input: DeletePSBAppraisalReportInput!
    $condition: ModelPSBAppraisalReportConditionInput
  ) {
    deletePSBAppraisalReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBMedicalCertificate = /* GraphQL */ `
  mutation CreatePSBMedicalCertificate(
    $input: CreatePSBMedicalCertificateInput!
    $condition: ModelPSBMedicalCertificateConditionInput
  ) {
    createPSBMedicalCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBMedicalCertificate = /* GraphQL */ `
  mutation UpdatePSBMedicalCertificate(
    $input: UpdatePSBMedicalCertificateInput!
    $condition: ModelPSBMedicalCertificateConditionInput
  ) {
    updatePSBMedicalCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBMedicalCertificate = /* GraphQL */ `
  mutation DeletePSBMedicalCertificate(
    $input: DeletePSBMedicalCertificateInput!
    $condition: ModelPSBMedicalCertificateConditionInput
  ) {
    deletePSBMedicalCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBMarriageCertificate = /* GraphQL */ `
  mutation CreatePSBMarriageCertificate(
    $input: CreatePSBMarriageCertificateInput!
    $condition: ModelPSBMarriageCertificateConditionInput
  ) {
    createPSBMarriageCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBMarriageCertificate = /* GraphQL */ `
  mutation UpdatePSBMarriageCertificate(
    $input: UpdatePSBMarriageCertificateInput!
    $condition: ModelPSBMarriageCertificateConditionInput
  ) {
    updatePSBMarriageCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBMarriageCertificate = /* GraphQL */ `
  mutation DeletePSBMarriageCertificate(
    $input: DeletePSBMarriageCertificateInput!
    $condition: ModelPSBMarriageCertificateConditionInput
  ) {
    deletePSBMarriageCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBBirthCertificate = /* GraphQL */ `
  mutation CreatePSBBirthCertificate(
    $input: CreatePSBBirthCertificateInput!
    $condition: ModelPSBBirthCertificateConditionInput
  ) {
    createPSBBirthCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBBirthCertificate = /* GraphQL */ `
  mutation UpdatePSBBirthCertificate(
    $input: UpdatePSBBirthCertificateInput!
    $condition: ModelPSBBirthCertificateConditionInput
  ) {
    updatePSBBirthCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBBirthCertificate = /* GraphQL */ `
  mutation DeletePSBBirthCertificate(
    $input: DeletePSBBirthCertificateInput!
    $condition: ModelPSBBirthCertificateConditionInput
  ) {
    deletePSBBirthCertificate(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPSBEvaluationReport = /* GraphQL */ `
  mutation CreatePSBEvaluationReport(
    $input: CreatePSBEvaluationReportInput!
    $condition: ModelPSBEvaluationReportConditionInput
  ) {
    createPSBEvaluationReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updatePSBEvaluationReport = /* GraphQL */ `
  mutation UpdatePSBEvaluationReport(
    $input: UpdatePSBEvaluationReportInput!
    $condition: ModelPSBEvaluationReportConditionInput
  ) {
    updatePSBEvaluationReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deletePSBEvaluationReport = /* GraphQL */ `
  mutation DeletePSBEvaluationReport(
    $input: DeletePSBEvaluationReportInput!
    $condition: ModelPSBEvaluationReportConditionInput
  ) {
    deletePSBEvaluationReport(input: $input, condition: $condition) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      isRequired
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createAttendanceRecord = /* GraphQL */ `
  mutation CreateAttendanceRecord(
    $input: CreateAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    createAttendanceRecord(input: $input, condition: $condition) {
      id
      dateTimeEntry
      tags
      username
      employeeUserID
      firstName
      middleName
      lastName
      createdAt
      isApproved
      isAccepted
      isOTApproved
      location
      idOfEditedRecord
      editedRecord {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      updatedAt
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateAttendanceRecord = /* GraphQL */ `
  mutation UpdateAttendanceRecord(
    $input: UpdateAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    updateAttendanceRecord(input: $input, condition: $condition) {
      id
      dateTimeEntry
      tags
      username
      employeeUserID
      firstName
      middleName
      lastName
      createdAt
      isApproved
      isAccepted
      isOTApproved
      location
      idOfEditedRecord
      editedRecord {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      updatedAt
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteAttendanceRecord = /* GraphQL */ `
  mutation DeleteAttendanceRecord(
    $input: DeleteAttendanceRecordInput!
    $condition: ModelAttendanceRecordConditionInput
  ) {
    deleteAttendanceRecord(input: $input, condition: $condition) {
      id
      dateTimeEntry
      tags
      username
      employeeUserID
      firstName
      middleName
      lastName
      createdAt
      isApproved
      isAccepted
      isOTApproved
      location
      idOfEditedRecord
      editedRecord {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      updatedAt
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createAttendanceRecordAuditTrail = /* GraphQL */ `
  mutation CreateAttendanceRecordAuditTrail(
    $input: CreateAttendanceRecordAuditTrailInput!
    $condition: ModelAttendanceRecordAuditTrailConditionInput
  ) {
    createAttendanceRecordAuditTrail(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      attendanceFrom
      editedBy
      editedByID
      createdBy
      createdByID
      dateTimeEdite
      createdAt
      editDetails
      updatedAt
      editedByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      createdByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const updateAttendanceRecordAuditTrail = /* GraphQL */ `
  mutation UpdateAttendanceRecordAuditTrail(
    $input: UpdateAttendanceRecordAuditTrailInput!
    $condition: ModelAttendanceRecordAuditTrailConditionInput
  ) {
    updateAttendanceRecordAuditTrail(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      attendanceFrom
      editedBy
      editedByID
      createdBy
      createdByID
      dateTimeEdite
      createdAt
      editDetails
      updatedAt
      editedByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      createdByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const deleteAttendanceRecordAuditTrail = /* GraphQL */ `
  mutation DeleteAttendanceRecordAuditTrail(
    $input: DeleteAttendanceRecordAuditTrailInput!
    $condition: ModelAttendanceRecordAuditTrailConditionInput
  ) {
    deleteAttendanceRecordAuditTrail(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        idOfEditedRecord
        editedRecord {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          idOfEditedRecord
          editedRecord {
            id
            dateTimeEntry
            tags
            username
            employeeUserID
            firstName
            middleName
            lastName
            createdAt
            isApproved
            isAccepted
            isOTApproved
            location
            idOfEditedRecord
            editedRecord {
              id
              dateTimeEntry
              tags
              username
              employeeUserID
              firstName
              middleName
              lastName
              createdAt
              isApproved
              isAccepted
              isOTApproved
              location
              idOfEditedRecord
              editedRecord {
                id
                dateTimeEntry
                tags
                username
                employeeUserID
                firstName
                middleName
                lastName
                createdAt
                isApproved
                isAccepted
                isOTApproved
                location
                idOfEditedRecord
                editedRecord {
                  id
                  dateTimeEntry
                  tags
                  username
                  employeeUserID
                  firstName
                  middleName
                  lastName
                  createdAt
                  isApproved
                  isAccepted
                  isOTApproved
                  location
                  idOfEditedRecord
                  editedRecord {
                    id
                    dateTimeEntry
                    tags
                    username
                    employeeUserID
                    firstName
                    middleName
                    lastName
                    createdAt
                    isApproved
                    isAccepted
                    isOTApproved
                    location
                    idOfEditedRecord
                    editedRecord {
                      id
                      dateTimeEntry
                      tags
                      username
                      employeeUserID
                      firstName
                      middleName
                      lastName
                      createdAt
                      isApproved
                      isAccepted
                      isOTApproved
                      location
                      idOfEditedRecord
                      editedRecord {
                        id
                        dateTimeEntry
                        tags
                        username
                        employeeUserID
                        firstName
                        middleName
                        lastName
                        createdAt
                        isApproved
                        isAccepted
                        isOTApproved
                        location
                        idOfEditedRecord
                        updatedAt
                        owner
                        __typename
                      }
                      updatedAt
                      employee {
                        id
                        name
                        mname
                        lname
                        suffix
                        contact
                        role
                        position
                        department
                        assignedDept
                        division
                        sectionUnit
                        dataPrivacy
                        form
                        form2
                        isTakeSurvey
                        date
                        gender
                        status
                        createdAt
                        username
                        profilePicture
                        ipcrRole
                        dpcrRole
                        opcrRole
                        employeeIDNumber
                        pdsRole
                        psbRole
                        appointmentStatus
                        updatedAt
                        owner
                        __typename
                      }
                      owner
                      __typename
                    }
                    updatedAt
                    employee {
                      id
                      name
                      mname
                      lname
                      suffix
                      contact
                      role
                      position
                      department
                      assignedDept
                      division
                      sectionUnit
                      dataPrivacy
                      form
                      form2
                      isTakeSurvey
                      date
                      gender
                      status
                      createdAt
                      username
                      profilePicture
                      ipcrRole
                      dpcrRole
                      opcrRole
                      employeeIDNumber
                      pdsRole
                      psbRole
                      appointmentStatus
                      updatedAt
                      owner
                      __typename
                    }
                    owner
                    __typename
                  }
                  updatedAt
                  employee {
                    id
                    name
                    mname
                    lname
                    suffix
                    contact
                    role
                    position
                    department
                    assignedDept
                    division
                    sectionUnit
                    dataPrivacy
                    form
                    form2
                    isTakeSurvey
                    date
                    gender
                    status
                    createdAt
                    username
                    profilePicture
                    ipcrRole
                    dpcrRole
                    opcrRole
                    employeeIDNumber
                    pdsRole
                    psbRole
                    appointmentStatus
                    updatedAt
                    owner
                    __typename
                  }
                  owner
                  __typename
                }
                updatedAt
                employee {
                  id
                  name
                  mname
                  lname
                  suffix
                  contact
                  role
                  position
                  department
                  assignedDept
                  division
                  sectionUnit
                  dataPrivacy
                  form
                  form2
                  isTakeSurvey
                  date
                  gender
                  status
                  createdAt
                  username
                  profilePicture
                  ipcrRole
                  dpcrRole
                  opcrRole
                  employeeIDNumber
                  pdsRole
                  psbRole
                  appointmentStatus
                  updatedAt
                  owner
                  __typename
                }
                owner
                __typename
              }
              updatedAt
              employee {
                id
                name
                mname
                lname
                suffix
                contact
                role
                position
                department
                assignedDept
                division
                sectionUnit
                dataPrivacy
                form
                form2
                isTakeSurvey
                date
                gender
                status
                createdAt
                username
                profilePicture
                ipcrRole
                dpcrRole
                opcrRole
                employeeIDNumber
                pdsRole
                psbRole
                appointmentStatus
                updatedAt
                owner
                __typename
              }
              owner
              __typename
            }
            updatedAt
            employee {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      attendanceFrom
      editedBy
      editedByID
      createdBy
      createdByID
      dateTimeEdite
      createdAt
      editDetails
      updatedAt
      editedByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      createdByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const createPMISEmployeeOld = /* GraphQL */ `
  mutation CreatePMISEmployeeOld(
    $input: CreatePMISEmployeeOldInput!
    $condition: ModelPMISEmployeeOldConditionInput
  ) {
    createPMISEmployeeOld(input: $input, condition: $condition) {
      id
      employeeNo
      surname
      givenName
      middleName
      firstDayInAgency
      firstDayInGov
      workDate
      employmentStatus
      statusParticular
      effectiveDateEmployeeStatus
      lastDayInAgency
      lastUpdateByCOEmploymentStatus
      nickname
      sex
      civilStatus
      effectiveDatePersonnelInfo
      maidenName
      spouseName
      spouseOccupation
      dateOfBirth
      birthplace
      bloodType
      nationality
      religion
      height
      weight
      fatherName
      fatherBirthplace
      motherName
      motherBirthplace
      employeeHomeAddress
      employeeDependent
      employeeEducationalAttainment
      employeeEligibility
      gsisPolicyNo
      gsisBranch
      pagibigNo
      pagibigBranch
      tinNo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePMISEmployeeOld = /* GraphQL */ `
  mutation UpdatePMISEmployeeOld(
    $input: UpdatePMISEmployeeOldInput!
    $condition: ModelPMISEmployeeOldConditionInput
  ) {
    updatePMISEmployeeOld(input: $input, condition: $condition) {
      id
      employeeNo
      surname
      givenName
      middleName
      firstDayInAgency
      firstDayInGov
      workDate
      employmentStatus
      statusParticular
      effectiveDateEmployeeStatus
      lastDayInAgency
      lastUpdateByCOEmploymentStatus
      nickname
      sex
      civilStatus
      effectiveDatePersonnelInfo
      maidenName
      spouseName
      spouseOccupation
      dateOfBirth
      birthplace
      bloodType
      nationality
      religion
      height
      weight
      fatherName
      fatherBirthplace
      motherName
      motherBirthplace
      employeeHomeAddress
      employeeDependent
      employeeEducationalAttainment
      employeeEligibility
      gsisPolicyNo
      gsisBranch
      pagibigNo
      pagibigBranch
      tinNo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePMISEmployeeOld = /* GraphQL */ `
  mutation DeletePMISEmployeeOld(
    $input: DeletePMISEmployeeOldInput!
    $condition: ModelPMISEmployeeOldConditionInput
  ) {
    deletePMISEmployeeOld(input: $input, condition: $condition) {
      id
      employeeNo
      surname
      givenName
      middleName
      firstDayInAgency
      firstDayInGov
      workDate
      employmentStatus
      statusParticular
      effectiveDateEmployeeStatus
      lastDayInAgency
      lastUpdateByCOEmploymentStatus
      nickname
      sex
      civilStatus
      effectiveDatePersonnelInfo
      maidenName
      spouseName
      spouseOccupation
      dateOfBirth
      birthplace
      bloodType
      nationality
      religion
      height
      weight
      fatherName
      fatherBirthplace
      motherName
      motherBirthplace
      employeeHomeAddress
      employeeDependent
      employeeEducationalAttainment
      employeeEligibility
      gsisPolicyNo
      gsisBranch
      pagibigNo
      pagibigBranch
      tinNo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createPMISEmployeeOldAddress = /* GraphQL */ `
  mutation CreatePMISEmployeeOldAddress(
    $input: CreatePMISEmployeeOldAddressInput!
    $condition: ModelPMISEmployeeOldAddressConditionInput
  ) {
    createPMISEmployeeOldAddress(input: $input, condition: $condition) {
      id
      createdAt
      homeAddressNo
      homeAddressStreet
      homeAddressBarangay
      homeAddressMunicipalCity
      homeAddressProvince
      telephoneNumber
      status
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePMISEmployeeOldAddress = /* GraphQL */ `
  mutation UpdatePMISEmployeeOldAddress(
    $input: UpdatePMISEmployeeOldAddressInput!
    $condition: ModelPMISEmployeeOldAddressConditionInput
  ) {
    updatePMISEmployeeOldAddress(input: $input, condition: $condition) {
      id
      createdAt
      homeAddressNo
      homeAddressStreet
      homeAddressBarangay
      homeAddressMunicipalCity
      homeAddressProvince
      telephoneNumber
      status
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePMISEmployeeOldAddress = /* GraphQL */ `
  mutation DeletePMISEmployeeOldAddress(
    $input: DeletePMISEmployeeOldAddressInput!
    $condition: ModelPMISEmployeeOldAddressConditionInput
  ) {
    deletePMISEmployeeOldAddress(input: $input, condition: $condition) {
      id
      createdAt
      homeAddressNo
      homeAddressStreet
      homeAddressBarangay
      homeAddressMunicipalCity
      homeAddressProvince
      telephoneNumber
      status
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const createPMISEmployeeOldDependent = /* GraphQL */ `
  mutation CreatePMISEmployeeOldDependent(
    $input: CreatePMISEmployeeOldDependentInput!
    $condition: ModelPMISEmployeeOldDependentConditionInput
  ) {
    createPMISEmployeeOldDependent(input: $input, condition: $condition) {
      id
      createdAt
      firstname
      middlename
      lastname
      dateOfBirth
      relation
      isIncapacitated
      isQualifiedForTaxExemption
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePMISEmployeeOldDependent = /* GraphQL */ `
  mutation UpdatePMISEmployeeOldDependent(
    $input: UpdatePMISEmployeeOldDependentInput!
    $condition: ModelPMISEmployeeOldDependentConditionInput
  ) {
    updatePMISEmployeeOldDependent(input: $input, condition: $condition) {
      id
      createdAt
      firstname
      middlename
      lastname
      dateOfBirth
      relation
      isIncapacitated
      isQualifiedForTaxExemption
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePMISEmployeeOldDependent = /* GraphQL */ `
  mutation DeletePMISEmployeeOldDependent(
    $input: DeletePMISEmployeeOldDependentInput!
    $condition: ModelPMISEmployeeOldDependentConditionInput
  ) {
    deletePMISEmployeeOldDependent(input: $input, condition: $condition) {
      id
      createdAt
      firstname
      middlename
      lastname
      dateOfBirth
      relation
      isIncapacitated
      isQualifiedForTaxExemption
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const createPMISEmployeeOldEducationalAttainment = /* GraphQL */ `
  mutation CreatePMISEmployeeOldEducationalAttainment(
    $input: CreatePMISEmployeeOldEducationalAttainmentInput!
    $condition: ModelPMISEmployeeOldEducationalAttainmentConditionInput
  ) {
    createPMISEmployeeOldEducationalAttainment(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      educationLevel
      degreeCourse
      isGraduate
      lastAttendedYear
      unitsEarned
      gradeOrYearLevel
      honor
      nameOfSchool
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePMISEmployeeOldEducationalAttainment = /* GraphQL */ `
  mutation UpdatePMISEmployeeOldEducationalAttainment(
    $input: UpdatePMISEmployeeOldEducationalAttainmentInput!
    $condition: ModelPMISEmployeeOldEducationalAttainmentConditionInput
  ) {
    updatePMISEmployeeOldEducationalAttainment(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      educationLevel
      degreeCourse
      isGraduate
      lastAttendedYear
      unitsEarned
      gradeOrYearLevel
      honor
      nameOfSchool
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePMISEmployeeOldEducationalAttainment = /* GraphQL */ `
  mutation DeletePMISEmployeeOldEducationalAttainment(
    $input: DeletePMISEmployeeOldEducationalAttainmentInput!
    $condition: ModelPMISEmployeeOldEducationalAttainmentConditionInput
  ) {
    deletePMISEmployeeOldEducationalAttainment(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      educationLevel
      degreeCourse
      isGraduate
      lastAttendedYear
      unitsEarned
      gradeOrYearLevel
      honor
      nameOfSchool
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const createPMISEmployeeOldEligibility = /* GraphQL */ `
  mutation CreatePMISEmployeeOldEligibility(
    $input: CreatePMISEmployeeOldEligibilityInput!
    $condition: ModelPMISEmployeeOldEligibilityConditionInput
  ) {
    createPMISEmployeeOldEligibility(input: $input, condition: $condition) {
      id
      createdAt
      barBoardGovtExam
      dateOfExam
      rating
      rank
      placeOfExam
      licenseNumber
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePMISEmployeeOldEligibility = /* GraphQL */ `
  mutation UpdatePMISEmployeeOldEligibility(
    $input: UpdatePMISEmployeeOldEligibilityInput!
    $condition: ModelPMISEmployeeOldEligibilityConditionInput
  ) {
    updatePMISEmployeeOldEligibility(input: $input, condition: $condition) {
      id
      createdAt
      barBoardGovtExam
      dateOfExam
      rating
      rank
      placeOfExam
      licenseNumber
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePMISEmployeeOldEligibility = /* GraphQL */ `
  mutation DeletePMISEmployeeOldEligibility(
    $input: DeletePMISEmployeeOldEligibilityInput!
    $condition: ModelPMISEmployeeOldEligibilityConditionInput
  ) {
    deletePMISEmployeeOldEligibility(input: $input, condition: $condition) {
      id
      createdAt
      barBoardGovtExam
      dateOfExam
      rating
      rank
      placeOfExam
      licenseNumber
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createDivision = /* GraphQL */ `
  mutation CreateDivision(
    $input: CreateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    createDivision(input: $input, condition: $condition) {
      id
      name
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision(
    $input: UpdateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    updateDivision(input: $input, condition: $condition) {
      id
      name
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision(
    $input: DeleteDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    deleteDivision(input: $input, condition: $condition) {
      id
      name
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createSectionUnit = /* GraphQL */ `
  mutation CreateSectionUnit(
    $input: CreateSectionUnitInput!
    $condition: ModelSectionUnitConditionInput
  ) {
    createSectionUnit(input: $input, condition: $condition) {
      id
      name
      divisionID
      division {
        id
        name
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateSectionUnit = /* GraphQL */ `
  mutation UpdateSectionUnit(
    $input: UpdateSectionUnitInput!
    $condition: ModelSectionUnitConditionInput
  ) {
    updateSectionUnit(input: $input, condition: $condition) {
      id
      name
      divisionID
      division {
        id
        name
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteSectionUnit = /* GraphQL */ `
  mutation DeleteSectionUnit(
    $input: DeleteSectionUnitInput!
    $condition: ModelSectionUnitConditionInput
  ) {
    deleteSectionUnit(input: $input, condition: $condition) {
      id
      name
      divisionID
      division {
        id
        name
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createPositionList = /* GraphQL */ `
  mutation CreatePositionList(
    $input: CreatePositionListInput!
    $condition: ModelpositionListConditionInput
  ) {
    createPositionList(input: $input, condition: $condition) {
      id
      poscode
      positionTitle
      posacro
      posgrade
      itemCode
      educationReqs
      trainingReqs
      experienceReqs
      eligibilityReqs
      salaryGrade
      monthlySalary
      createdAt
      additionalDetails
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePositionList = /* GraphQL */ `
  mutation UpdatePositionList(
    $input: UpdatePositionListInput!
    $condition: ModelpositionListConditionInput
  ) {
    updatePositionList(input: $input, condition: $condition) {
      id
      poscode
      positionTitle
      posacro
      posgrade
      itemCode
      educationReqs
      trainingReqs
      experienceReqs
      eligibilityReqs
      salaryGrade
      monthlySalary
      createdAt
      additionalDetails
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePositionList = /* GraphQL */ `
  mutation DeletePositionList(
    $input: DeletePositionListInput!
    $condition: ModelpositionListConditionInput
  ) {
    deletePositionList(input: $input, condition: $condition) {
      id
      poscode
      positionTitle
      posacro
      posgrade
      itemCode
      educationReqs
      trainingReqs
      experienceReqs
      eligibilityReqs
      salaryGrade
      monthlySalary
      createdAt
      additionalDetails
      updatedAt
      owner
      __typename
    }
  }
`
export const createGradeStep = /* GraphQL */ `
  mutation CreateGradeStep(
    $input: CreateGradeStepInput!
    $condition: ModelGradeStepConditionInput
  ) {
    createGradeStep(input: $input, condition: $condition) {
      id
      grade
      step
      salary
      yearVersion
      documentUpload
      isEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateGradeStep = /* GraphQL */ `
  mutation UpdateGradeStep(
    $input: UpdateGradeStepInput!
    $condition: ModelGradeStepConditionInput
  ) {
    updateGradeStep(input: $input, condition: $condition) {
      id
      grade
      step
      salary
      yearVersion
      documentUpload
      isEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteGradeStep = /* GraphQL */ `
  mutation DeleteGradeStep(
    $input: DeleteGradeStepInput!
    $condition: ModelGradeStepConditionInput
  ) {
    deleteGradeStep(input: $input, condition: $condition) {
      id
      grade
      step
      salary
      yearVersion
      documentUpload
      isEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createPositionDepartment = /* GraphQL */ `
  mutation CreatePositionDepartment(
    $input: CreatePositionDepartmentInput!
    $condition: ModelPositionDepartmentConditionInput
  ) {
    createPositionDepartment(input: $input, condition: $condition) {
      id
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      positions
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updatePositionDepartment = /* GraphQL */ `
  mutation UpdatePositionDepartment(
    $input: UpdatePositionDepartmentInput!
    $condition: ModelPositionDepartmentConditionInput
  ) {
    updatePositionDepartment(input: $input, condition: $condition) {
      id
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      positions
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deletePositionDepartment = /* GraphQL */ `
  mutation DeletePositionDepartment(
    $input: DeletePositionDepartmentInput!
    $condition: ModelPositionDepartmentConditionInput
  ) {
    deletePositionDepartment(input: $input, condition: $condition) {
      id
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      positions
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      id
      shiftType
      startTime
      endTime
      breakDuration
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      id
      shiftType
      startTime
      endTime
      breakDuration
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      id
      shiftType
      startTime
      endTime
      breakDuration
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      employeeID
      shiftID
      scheduleDates
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      employeeID
      shiftID
      scheduleDates
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      employeeID
      shiftID
      scheduleDates
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createHoliday = /* GraphQL */ `
  mutation CreateHoliday(
    $input: CreateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    createHoliday(input: $input, condition: $condition) {
      id
      label
      date
      category
      isWorkDay
      hasWork
      type
      subType
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateHoliday = /* GraphQL */ `
  mutation UpdateHoliday(
    $input: UpdateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    updateHoliday(input: $input, condition: $condition) {
      id
      label
      date
      category
      isWorkDay
      hasWork
      type
      subType
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const deleteHoliday = /* GraphQL */ `
  mutation DeleteHoliday(
    $input: DeleteHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    deleteHoliday(input: $input, condition: $condition) {
      id
      label
      date
      category
      isWorkDay
      hasWork
      type
      subType
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createHandbookAcknowledgement = /* GraphQL */ `
  mutation CreateHandbookAcknowledgement(
    $input: CreateHandbookAcknowledgementInput!
    $condition: ModelhandbookAcknowledgementConditionInput
  ) {
    createHandbookAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateHandbookAcknowledgement = /* GraphQL */ `
  mutation UpdateHandbookAcknowledgement(
    $input: UpdateHandbookAcknowledgementInput!
    $condition: ModelhandbookAcknowledgementConditionInput
  ) {
    updateHandbookAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createLdRefresher = /* GraphQL */ `
  mutation CreateLdRefresher(
    $input: CreateLdRefresherInput!
    $condition: ModelldRefresherConditionInput
  ) {
    createLdRefresher(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      mess
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateLdRefresher = /* GraphQL */ `
  mutation UpdateLdRefresher(
    $input: UpdateLdRefresherInput!
    $condition: ModelldRefresherConditionInput
  ) {
    updateLdRefresher(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      mess
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createLdRefresherVideos = /* GraphQL */ `
  mutation CreateLdRefresherVideos(
    $input: CreateLdRefresherVideosInput!
    $condition: ModelldRefresherVideosConditionInput
  ) {
    createLdRefresherVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateLdRefresherVideos = /* GraphQL */ `
  mutation UpdateLdRefresherVideos(
    $input: UpdateLdRefresherVideosInput!
    $condition: ModelldRefresherVideosConditionInput
  ) {
    updateLdRefresherVideos(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createSpmsVideoAcknowledgement = /* GraphQL */ `
  mutation CreateSpmsVideoAcknowledgement(
    $input: CreateSpmsVideoAcknowledgementInput!
    $condition: ModelspmsVideoAcknowledgementConditionInput
  ) {
    createSpmsVideoAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateSpmsVideoAcknowledgement = /* GraphQL */ `
  mutation UpdateSpmsVideoAcknowledgement(
    $input: UpdateSpmsVideoAcknowledgementInput!
    $condition: ModelspmsVideoAcknowledgementConditionInput
  ) {
    updateSpmsVideoAcknowledgement(input: $input, condition: $condition) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createOrientationVideoAcknowledgement = /* GraphQL */ `
  mutation CreateOrientationVideoAcknowledgement(
    $input: CreateOrientationVideoAcknowledgementInput!
    $condition: ModelorientationVideoAcknowledgementConditionInput
  ) {
    createOrientationVideoAcknowledgement(
      input: $input
      condition: $condition
    ) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const updateOrientationVideoAcknowledgement = /* GraphQL */ `
  mutation UpdateOrientationVideoAcknowledgement(
    $input: UpdateOrientationVideoAcknowledgementInput!
    $condition: ModelorientationVideoAcknowledgementConditionInput
  ) {
    updateOrientationVideoAcknowledgement(
      input: $input
      condition: $condition
    ) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const createErrorLogs = /* GraphQL */ `
  mutation CreateErrorLogs(
    $input: CreateErrorLogsInput!
    $condition: ModelerrorLogsConditionInput
  ) {
    createErrorLogs(input: $input, condition: $condition) {
      id
      errorLog
      date
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
