import { Button, Modal } from 'antd'

const UserExistModal = ({ openModal, closeModal }) => {
  return (
    <Modal
      title='Username Already Registered'
      open={openModal}
      footer={null}
      closable={true}
      onCancel={closeModal}
      zIndex={99999}
      width={600}
    >
      <p style={{ textAlign: 'center' }}>
        This username has previously been used on the platform. Click the button
        to log in or verify your account.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Button onClick={() => (window.location.href = '/login')}>
          Redirect to Login
        </Button>
        <Button onClick={() => (window.location.href = '/verify')}>
          Redirect to Verify
        </Button>
      </div>
    </Modal>
  )
}

export default UserExistModal
