/**
 * Title: SubmittedEncodedPDSForms.js
 * Description: This is a file that contains the components for the Submitted Personal Data Sheet Forms page of the employee.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/SubmittedEncodedPDSForms.js
 **/
import { useEffect, useRef, useState } from 'react'

import {
  BackTop,
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import DOMPurify from 'dompurify'
import moment from 'moment'

import {
  CommentOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FundViewOutlined,
} from '@ant-design/icons'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { Form } from '@formio/react'

import {
  deletePersonalDataSheet,
  updatePersonalDataSheet,
} from '../../api/mutations'
import {
  UserbyDepartment,
  UserbyUsername,
  personalDataSheetByEncodedByID,
} from '../../api/queries'
import PDFModal from './PDFModal'
import { getEmailBodySubmittedByEmployeeNotifDeptApprover } from './PDSEmailTemplates'
import PDSGuide from './PDSGuide'
import RenderRemarksContent from './PDSRemarksContentComponent'
import PDSVideo from './PDSVideo'
import UploadPds from './UploadPds'
import './pds.css'
import placeDataArray from './zipcodePH'

const { Option } = Select

export default function SubmittedEncodedPDSForms() {
  const [userDataSheets, setUserDataSheets] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [holdData, setHoldData] = useState({})
  const [formIsFinal, setFormIsFinal] = useState(false)
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false)
  const [foundRecordID, setFoundRecordID] = useState(null)
  const [foundRecord, setFoundRecord] = useState(null)
  const [dataReview, setDataReview] = useState({})

  const [isModalOpenEditApprover, setIsModalOpenEditApprover] = useState(false)
  const [approverRemarks, setApproverRemarks] = useState([])
  const [isModalOpenEditHRApprover, setIsModalOpenEditHRApprover] =
    useState(false)
  const [hrApproverRemarks, setHRApproverRemarks] = useState([])

  const [uploadedSignature, setUploadedSignature] = useState(null)
  const [uploadedThumbmark, setUploadedThumbmark] = useState(null)
  const [isModalOpenThumbmark, setIsModalOpenThumbmark] = useState(false)
  const [isModalOpenESign, setIsModalOpenESign] = useState(false)
  const [uploadedSignatureLocInit, setUploadedSignatureLocInit] = useState('')
  const [uploadedThumbmarkLocInit, setUploadedThumbmarkLocInit] = useState('')
  const [isModalOpenPassportPhoto, setIsModalOpenPassportPhoto] =
    useState(false)
  const [uploadedPassportPhoto, setUploadedPassportPhoto] = useState(null)
  const [uploadedPassportLocInit, setUploadedPassportLocInit] = useState('')

  const [removeAsterisk, setRemoveAsterisk] = useState(null)

  const [isOpenPDF, setIsOpenPDF] = useState(false)
  const [recordData, setRecordData] = useState({})

  const [isModalOpenProvince, setIsModalOpenProvince] = useState(false)
  const [regionGroupList, setRegionGroupList] = useState([])
  const [municipalityList, setMunicipalityList] = useState([])
  const [provinceData, setProvinceData] = useState({})
  const [isModalOpenCity, setIsModalOpenCity] = useState(false)
  const [allAreasData, setAllAreasData] = useState({})
  const [selectedCityData, setSelectedCityData] = useState({})
  const [isModalOpenBrgy, setIsModalOpenBrgy] = useState(false)
  const [barangayList, setBarangayList] = useState([])

  const [isProvincePerm, setIsProvincePerm] = useState(false)
  const [isBarangayPerm, setIsBarangayPerm] = useState(false)
  const [isCityPerm, setIsCityPerm] = useState(false)
  const [municipalityListPerm, setMunicipalityListPerm] = useState([])
  const [selectedCityDataPerm, setSelectedCityDataPerm] = useState({})
  const [allAreasDataPerm, setAllAreasDataPerm] = useState({})
  const [barangayListPerm, setBarangayListPerm] = useState([])
  const [provinceDataPerm, setProvinceDataPerm] = useState({})

  const [isModalOpenZipcode, setIsModalOpenZipcode] = useState(false)
  const [isZipcodePerm, setIsZipcodePerm] = useState(false)

  const [isDisabledAfterSave, setIsDisabledAfterSave] = useState(false)

  const [foundOwner, setFoundOwner] = useState(null)

  const [toBeDeletedDraft, setToBeDeletedDraft] = useState(null)
  const [isOpenDeleteDraftModal, setIsOpenDeleteDraftModal] = useState(false)

  const fileInputRefThumb = useRef(null)
  const fileInputRefESign = useRef(null)
  const fileInputRefPassportPhoto = useRef(null)

  const placeData = placeDataArray()

  const fieldCheckerFunction = (obj, key) => {
    try {
      let value = obj[key]
      if (
        typeof value === 'undefined' ||
        value === null ||
        value === '' ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        return {
          allRequiredValuesPresent: false,
          failedKey: key,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleExportData = async (data) => {
    try {
      await API.graphql(
        graphqlOperation(updatePersonalDataSheet, {
          input: {
            id: foundRecordID,
            data: JSON.stringify({ data: data.data }),
            userID: foundOwner.id,
            isFinal: false,
            department: foundOwner.assignedDept,
            division: foundOwner.division,
            isApproved: 'Pending Approval',
            isHRApproved: 'Pending Approval',
            isAgreeDataPrivacyPDS: foundRecord.isAgreeDataPrivacyPDS,
            eSignatureLoc: '',
            thumbmarkSignLoc: '',
            passportPhotoLoc: '',
          },
        })
      )
      setIsModalOpenEdit(false)
      setIsModalOpen(false)
      fetchUserDataSheets()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function uuidv4
   *@description Generates a unique identifier used for mapping keys.
   *@param {Object} none no parameter needed.
   *@returns {Object} return a unique identifier.
   */
  function uuidv4() {
    try {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)

      return (
        array[0].toString(16).padStart(8, '0') +
        '-' +
        array[1].toString(16).padStart(4, '0') +
        '-4' +
        array[2].toString(12).padStart(3, '0') +
        '-y' +
        array[3].toString(12).padStart(3, '0') +
        '-' +
        array[4].toString(16).padStart(12, '0')
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *@function fetchUserDataSheets
   *@description Fetches the user's data sheets and filters the data sheets that are final and approved.
   *@param {Object} None no parameters needed.
   *@returns {Object} the data sheets that are final and approved.
   */
  /**
   * @param {Object} None no parameters needed.
   * @returns {Object} the data sheets that are final and approved.
   */
  const fetchUserDataSheets = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { username } = user
      const userDetails = await API.graphql(
        graphqlOperation(UserbyUsername, { username })
      )
      const foundDetails = userDetails.data.UserbyUsername.items[0]
      const userDataSheetsData = await API.graphql(
        graphqlOperation(personalDataSheetByEncodedByID, {
          encodedBy: foundDetails.id,
        })
      )

      const updatedUserDataSheets =
        userDataSheetsData.data.personalDataSheetByEncodedByID.items.map(
          (item, index) => {
            try {
              const parsedData = JSON.parse(item.data)

              if (parsedData?.data?.skillsAndHobies) {
                parsedData.data.skillsAndHobbiesGrid =
                  parsedData.data.skillsAndHobies.map((hobby) => ({
                    skillsAndHobbies: hobby,
                  }))
                delete parsedData.data.skillsAndHobies
              }

              if (parsedData?.data?.orgMembership) {
                parsedData.data.orgMembershipGrid =
                  parsedData.data.orgMembership.map((org) => ({
                    orgMembership: org,
                  }))
                delete parsedData.data.orgMembership
              }

              if (parsedData?.data?.nonAcademicdistinctionsrecognition) {
                parsedData.data.nonAcademicdistinctionsrecognitionGrid =
                  parsedData.data.nonAcademicdistinctionsrecognition.map(
                    (nonAcad) => ({
                      nonAcademicdistinctionsrecognition: nonAcad,
                    })
                  )
                delete parsedData.data.nonAcademicdistinctionsrecognition
              }

              return {
                ...item,
                data: JSON.stringify(parsedData),
              }
            } catch (error) {
              console.error(
                `Error parsing data for user data sheet ${index + 1}:`,
                error
              )
              return item // Return unchanged item in case of error
            }
          }
        )

      setUserDataSheets(updatedUserDataSheets)
    } catch (err) {
      console.log(err)
    }
  }

  function transformGeoRegionData(geoRegionData) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return []
    }

    const dataArray = Object.values(geoRegionData)
    const regions = {}
    const allAreas = {} // This will store both provinces and regions without provinces

    dataArray.forEach((item) => {
      if (!item || typeof item !== 'object') return

      if (item.geographic_level === 'Reg') {
        regions[item.code] = {
          label: item.name,
          value: item.code,
          options: [],
        }
        // Add region to allAreas
        allAreas[item.code] = {
          name: item.name,
          code: item.code,
          geographic_level: 'Reg',
        }
      } else if (item.geographic_level === 'Prov') {
        const province = {
          ...item,
          label: item.name,
          value: item.code,
          regionCode: item.code.substring(0, 3) + '0000000',
        }
        allAreas[item.code] = province

        const regionEntry = regions[province.regionCode]
        if (regionEntry) {
          regionEntry.options.push(province)
        }
      }
    })

    const transformedRegions = Object.values(regions).map((region) => {
      if (region.options.length === 0) {
        // If a region has no provinces, return it as its own option
        return {
          label: region.label,
          value: region.value,
        }
      }
      return region
    })

    return {
      regionGroupList: transformedRegions,
      allAreas: allAreas,
    }
  }

  const loadAndTransformData = async () => {
    let combinedRegionGroupList = []
    let allAreasData = {}
    for (let i = 1; i <= 17; i++) {
      try {
        const geoRegionData = await import(`./../../pgsc/geo-reg-${i}.json`)
        const { regionGroupList, allAreas } = transformGeoRegionData(
          geoRegionData.default
        )
        combinedRegionGroupList =
          combinedRegionGroupList.concat(regionGroupList)
        Object.assign(allAreasData, allAreas)
      } catch (error) {
        console.error(`Error loading data for region ${i}:`, error)
      }
    }
    combinedRegionGroupList.forEach((region) => {
      if (region.label === 'Metro Manila') {
        region.label = 'National Capital Region (NCR)'
      }
    })
    setRegionGroupList(combinedRegionGroupList)
    setProvinceData(allAreasData) // This now includes both provinces and regions
    setProvinceDataPerm(allAreasData) // This now includes both provinces and regions
  }

  function transformMunicipalityData(geoRegionData, selectedProvinceCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { municipalityGroupList: [], allAreas: {} }
    }

    const municipalityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'Mun' || item.geographic_level === 'City') &&
        item.code.startsWith(selectedProvinceCode.substring(0, 5))
      ) {
        const municipalityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        municipalityList.push(municipalityItem)
        allAreas[item.code] = municipalityItem
      }
    })

    return {
      municipalityGroupList: municipalityList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformMunicipalityData = async (provinceCode) => {
    let municipalityList = []
    let allAreasData = {}

    try {
      const regionNumber = provinceCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { municipalityGroupList, allAreas } = transformMunicipalityData(
        geoRegionData.default,
        provinceCode
      )

      municipalityList = municipalityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for province ${provinceCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(municipalityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(municipalityList)
      setSelectedCityData(allAreasData)
    }
  }

  const loadAndTransformCityData = async (regionCode) => {
    let cityList = []
    let allAreasData = {}

    try {
      const regionNumber = regionCode.substring(0, 2)

      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { cityGroupList, allAreas } = transformCityData(
        geoRegionData.default,
        regionCode
      )

      cityList = cityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for region ${regionCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(cityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(cityList)
      setSelectedCityData(allAreasData)
    }
  }

  function transformCityData(geoRegionData, selectedRegionCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { cityGroupList: [], allAreas: {} }
    }

    const cityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'City' || item.geographic_level === 'Mun') &&
        item.code.startsWith(selectedRegionCode.substring(0, 2))
      ) {
        const cityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        cityList.push(cityItem)
        allAreas[item.code] = cityItem
      }
    })

    return {
      cityGroupList: cityList,
      allAreas: allAreas,
    }
  }

  function transformBarangayData(geoRegionData, selectedMunicipalityCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { barangayGroupList: [], allAreas: {} }
    }
    const barangayList = []
    const allAreas = {}
    if (
      selectedMunicipalityCode === '1380600000' ||
      selectedMunicipalityCode === 1380600000
    ) {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          (item.code.startsWith(13806) || item.code.startsWith('13806'))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    } else {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          item.code.startsWith(selectedMunicipalityCode.substring(0, 7))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    }

    return {
      barangayGroupList: barangayList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformBarangayData = async (municipalityCode) => {
    let brgyList = []
    let brgyAreasData = {}
    try {
      const regionNumber = municipalityCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )
      const { barangayGroupList, allAreas } = transformBarangayData(
        geoRegionData.default,
        municipalityCode
      )
      brgyList = barangayGroupList
      brgyAreasData = allAreas
    } catch (error) {
      console.error(
        `Error loading data for municipality ${municipalityCode}:`,
        error
      )
    }

    const sortedArray = brgyList.sort((a, b) => a.label.localeCompare(b.label))

    if (isCityPerm) {
      setBarangayListPerm(sortedArray)
      setAllAreasDataPerm(brgyAreasData)
    } else {
      setBarangayList(sortedArray)
      setAllAreasData(brgyAreasData)
    }
  }

  useEffect(() => {
    loadAndTransformData()
    fetchUserDataSheets()
  }, [])

  const handlePDFSave = (record) => {
    try {
      setIsOpenPDF(true)
      setRecordData(record)
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '5%',
    },
    {
      title: 'Employee Name',
      dataIndex: 'fullName',
      key: 'locale',
      render: (text, record) => (
        <span>
          {DOMPurify.sanitize(
            `${record.ownedBy.name}${record.ownedBy.mname ? ' ' + record.ownedBy.mname : ''} ${record.ownedBy.lname}`
          )}
        </span>
      ),
      width: '12%',
    },
    {
      title: 'Date PDS Form Encoded',
      dataIndex: 'date',
      key: 'locale',
      render: (text, record) => (
        <Tag color='geekblue'>
          {moment(record.updatedAt).format('MMMM D, YYYY h:mm A')}
        </Tag>
      ),
      width: '12%',
    },
    {
      title: 'Saved as Draft or Final',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div key={record.id}>
          {record.isFinal === true ? (
            <Tag color='green'>Submitted as Final</Tag>
          ) : (
            <Tag color='yellow'>Saved as Draft</Tag>
          )}
        </div>
      ),
      width: '12%',
    },

    {
      title: 'Approval Status (by Department)',
      dataIndex: 'isApproved',
      key: 'isApproved',
      render: (text, record) => (
        <div key={record.id}>
          <Row justify='center'>
            {record.isApproved === 'Returned' ? (
              <Tag className='pe-3 ps-3' color='red'>
                Returned
              </Tag>
            ) : null}

            {record.isApproved === 'Pending Approval' ? (
              <Tag className='pe-3 ps-3' color='yellow'>
                Pending Approval
              </Tag>
            ) : null}

            {record.isApproved === 'Approved' ? (
              <Tag className='pe-3 ps-3' color='green'>
                Approved
              </Tag>
            ) : null}
          </Row>
          <Row justify='center'>
            <Button
              onClick={() => handleViewRemarks(record)}
              type='text'
              icon={<CommentOutlined />}
            >
              Remarks
            </Button>
          </Row>
        </div>
      ),
      width: '12%',
    },

    {
      title: 'Approval Status (by HR)',
      dataIndex: 'isHRApproved',
      key: 'isHRApproved',
      render: (text, record) => (
        <div key={record.id}>
          <Row justify='center'>
            {record.isHRApproved === 'Returned' ? (
              <Tag className='pe-3 ps-3' color='red'>
                Returned
              </Tag>
            ) : null}

            {record.isHRApproved === 'Pending Approval' ? (
              <Tag className='pe-3 ps-3' color='yellow'>
                Pending Approval
              </Tag>
            ) : null}

            {record.isHRApproved === 'Approved' ? (
              <Tag className='pe-3 ps-3' color='green'>
                Approved
              </Tag>
            ) : null}
          </Row>
          <Row justify='center'>
            <Button
              onClick={() => handleViewHRRemarks(record)}
              type='text'
              icon={<CommentOutlined />}
            >
              Remarks
            </Button>
          </Row>
        </div>
      ),
      width: '12%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Row justify='space-around'>
          <Col>
            <Button
              danger
              size='large'
              onClick={() => handleViewForm(record, record.isFinal)}
              icon={<FundViewOutlined />}
            >
              <b>{record.isFinal ? 'View' : 'View / Edit'}</b>
            </Button>
          </Col>
          {record.isFinal ? (
            <Col>
              <Button
                className='pdf-preview-button'
                onClick={() => handlePDFSave(record)}
                icon={<FilePdfOutlined />}
                size='large'
              >
                <b>PDF Preview</b>
              </Button>
            </Col>
          ) : null}
          {record.isFinal ? null : (
            <Col>
              <Button
                onClick={() => {
                  setToBeDeletedDraft(record.id)
                  setIsOpenDeleteDraftModal(true)
                }}
                type='text'
                icon={<DeleteOutlined />}
                danger
              />
            </Col>
          )}
        </Row>
      ),
      width: '12%',
    },
  ]

  /**
   *@function handleSignType
   *@description Handles the event type of the button from Formio
   *@param {Object} type - The type of the event.
   *@returns {Object} none No return value.
   */
  const handleSignType = (type) => {
    try {
      if (type === 'customEventThumbSign') {
        setIsModalOpenThumbmark(true)
      }

      if (type === 'customEventESign') {
        setIsModalOpenESign(true)
      }

      if (type === 'customEventPassportPhoto') {
        setIsModalOpenPassportPhoto(true)
      }

      if (type === 'chooseProvince') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(false)
      }

      if (type === 'chooseBarangay') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(false)
      }

      if (type === 'chooseCity') {
        setIsModalOpenCity(true)
        setIsCityPerm(false)
      }

      if (type === 'chooseProvincePerm') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(true)
      }

      if (type === 'chooseBarangayPerm') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(true)
      }

      if (type === 'chooseCityPerm') {
        setIsModalOpenCity(true)
        setIsCityPerm(true)
      }

      if (type === 'choosezipcode') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(false)
      }

      if (type === 'choosezipcodePerm') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleViewRemarks
   *@description Opens a modal that displays the remarks of the department admin.
   *@param {Object} e the data of the data sheet.
   *@returns {Object} the remarks of the department admin.
   */
  const handleViewRemarks = (e) => {
    try {
      setIsModalOpenEditApprover(true)

      if (!e?.commentsOfApprover) {
        setApproverRemarks([])
        return
      }

      let parsedComments
      try {
        parsedComments = JSON.parse(e.commentsOfApprover)
      } catch (parseError) {
        console.log('Failed to parse department comments JSON:', parseError)
        parsedComments = []
      }

      setApproverRemarks(parsedComments)
    } catch (err) {
      console.log('Error handling HR remarks:', err)
      notification.error({
        message: 'Error',
        description:
          'Failed to load HR remarks, please contact System Admin for help.',
      })
    }
  }

  /**
   *@function handleViewHRRemarks
   *@description Opens a modal that displays the remarks of the HR admin.
   *@param {Object} e the data of the data sheet.
   *@returns {Object} the remarks of the HR admin.
   */
  const handleViewHRRemarks = (e) => {
    try {
      setIsModalOpenEditHRApprover(true)

      if (!e?.commentsOfHRApprover) {
        setHRApproverRemarks([])
        return
      }

      let parsedComments
      try {
        parsedComments = JSON.parse(e.commentsOfHRApprover)
      } catch (parseError) {
        console.log('Failed to parse HR comments JSON:', parseError)
        parsedComments = []
      }

      setHRApproverRemarks(parsedComments)
    } catch (err) {
      console.log('Error handling HR remarks:', err)
      notification.error({
        message: 'Error',
        description:
          'Failed to load HR remarks, please contact System Admin for help.',
      })
    }
  }

  /**
   * Handles the action of viewing a form.
   *
   * @param {Object} e - The event object containing form data.
   * @param {boolean} isFinal - Indicates if the form is in its final state.
   */
  const handleViewForm = (e, isFinal) => {
    try {
      setFoundOwner(e.ownedBy)
      setIsModalOpen(true)
      setFormIsFinal(isFinal)
      setFoundRecordID(e.id)
      setFoundRecord(e)
      setHoldData(JSON.parse(e.data).data)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Creates an input element with specified attributes and inserts it into the DOM.
   *
   * @param {string} selectedValue - The value to be set for the input element.
   * @param {HTMLElement} i - The HTML element after which the new input element will be inserted.
   */
  const createInputElementFunction = (selectedValue, i) => {
    try {
      let inputElement = document.createElement('input')
      inputElement.value = selectedValue.toUpperCase()
      inputElement.setAttribute('aria-required', 'false')
      inputElement.setAttribute('spellcheck', 'true')
      inputElement.setAttribute('lang', 'en')
      inputElement.setAttribute('class', 'form-control')
      inputElement.setAttribute('type', 'text')
      inputElement.setAttribute('ref', 'input')
      inputElement.setAttribute('aria-invalid', 'false')
      inputElement.setAttribute('disabled', '')
      i.parentNode.insertAdjacentElement('afterend', inputElement)
      i.parentNode.remove()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleSubmitButton
   *@description Handles the showing of the Submit button and the disabling of the fields of the PDS Form if its already submitted as Final.
   *@param {Object} None no parameters needed.
   */
  const handleSubmitButton = () => {
    try {
      handleImages()
      if (formIsFinal) {
        const submitDiv = document.getElementsByClassName(
          'formio-component-submit'
        )

        submitDiv[0].style.display = 'none'
        setTimeout(() => {
          document
            .querySelectorAll('.submitted-pds-page-modal button')
            .forEach((i) => {
              i.parentNode.remove()
            })
          document
            .querySelectorAll(
              '.submitted-pds-page-modal input, .submitted-pds-page-modal textarea'
            )
            .forEach((i) => {
              i.disabled = true
            })
        }, 1000)
        setTimeout(() => {
          document
            .querySelectorAll('.submitted-pds-page-modal select')
            .forEach((i) => {
              if (i && i.getAttribute('name') === 'data[bloodtype]') {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (i && i.getAttribute('name') === 'data[suffixNaAllowed]') {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (i && i.getAttribute('name') === 'data[indicateCountry]') {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i.getAttribute('name') === 'data[spouseSuffixNaAllowed]'
              ) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i.getAttribute('name') === 'data[fatherSuffixNaAllowed]'
              ) {
                let selectedValue = i.options[0]?.value
                createInputElementFunction(selectedValue, i)
              }
              if (
                i &&
                i.getAttribute('name') === 'data[serviceSeparationADetails]'
              ) {
                let selectedValue = i.options[0]?.value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i
                  .getAttribute('name')
                  .includes('elementaryBasicEducationNaAllowed')
              ) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i.getAttribute('name').includes('secondaryBasicEducation')
              ) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i.getAttribute('name').includes('civilServiceAndOthersLicense')
              ) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (
                i &&
                i
                  .getAttribute('name')
                  .includes('workExperienceAppointmentStatus')
              ) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }

              if (i && i.getAttribute('name').includes('ldType')) {
                let selectedValue = i.options[0].value
                createInputElementFunction(selectedValue, i)
              }
            })
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function showModal
   * @description Opens modal to display form data and handles form submission review
   * @param {Object} e - Event object containing form data
   * @param {Object} e.data - The form data to be reviewed
   * @param {Object} e.form - The form configuration
   * @returns {void}
   */
  const showModal = (e) => {
    try {
      setDataReview({
        data: e.data,
        form: e.form,
      })
      setIsModalOpenEdit(true)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function forUpload
   * @description Handles file uploads to S3 storage for signature, thumbmark and passport photo
   * @returns {Promise<Object>} Object containing uploaded file URLs
   * @throws {Error} If upload fails
   */
  const forUpload = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      let url, urlThumb, urlPassport

      if (uploadedSignature) {
        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `${foundOwner.id}/${moment().valueOf()}-${
            uploadedSignature.name
          }`,
          Body: uploadedSignature,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        await s3.send(command)
        url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
      }

      if (uploadedThumbmark) {
        const paramsThumb = {
          Bucket: 's3etnahris133956-dev',
          Key: `${foundOwner.id}/${moment().valueOf()}-${
            uploadedThumbmark.name
          }`,
          Body: uploadedThumbmark,
        }

        const commandThumb = new PutObjectCommand(paramsThumb)
        await s3.send(commandThumb)
        urlThumb = `https://${paramsThumb.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsThumb.Key}`
      }

      if (uploadedPassportPhoto) {
        const paramsPassport = {
          Bucket: 's3etnahris133956-dev',
          Key: `${foundOwner.id}/${moment().valueOf()}-${
            uploadedPassportPhoto.name
          }`,
          Body: uploadedPassportPhoto,
        }

        const commandPassport = new PutObjectCommand(paramsPassport)
        await s3.send(commandPassport)
        urlPassport = `https://${paramsPassport.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsPassport.Key}`
      }

      return { url, urlThumb, urlPassport }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function triggerSave
   * @description Saves PDS form data with uploaded file URLs
   * @param {string} url - E-signature file URL
   * @param {string} urlThumb - Thumbmark file URL
   * @param {string} urlPassport - Passport photo URL
   * @param {boolean} isFinal - Whether this is final submission
   * @returns {Promise<Object>} Updated PDS record
   * @throws {Error} If save fails
   */
  const triggerSave = async (url, urlThumb, urlPassport, isFinal) => {
    try {
      return await API.graphql(
        graphqlOperation(updatePersonalDataSheet, {
          input: {
            id: foundRecordID,
            data: JSON.stringify(dataReview),
            userID: foundOwner.id,
            isFinal: isFinal,
            department: foundOwner.assignedDept,
            division: foundOwner.division,
            isApproved: 'Pending Approval',
            isHRApproved: 'Pending Approval',
            isAgreeDataPrivacyPDS: foundRecord.isAgreeDataPrivacyPDS,
            eSignatureLoc: url || uploadedSignatureLocInit,
            thumbmarkSignLoc: urlThumb || uploadedThumbmarkLocInit,
            passportPhotoLoc: urlPassport || uploadedPassportLocInit,
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function getSesClientToken
   * @description Gets the SES client token.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Object>} Returns an SES client object.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
      throw err
    }
  }

  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  /**
   * @function paramsGenerator
   * @description Generates email parameters for SES
   * @param {string} username - Recipient email
   * @param {string} emailBody - Email content
   * @param {string} emailTitle - Email subject
   * @returns {Object} SES email parameters
   */
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [username],
        },
        Message: {
          Body: {
            Text: {
              Data: emailBody,
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: emailTitle, // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: sourceEmail,
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function sendEmail
   * @async
   * @description Sends email notification via AWS SES
   * @param {string} usernameFound - Recipient username/email
   * @param {SESClient} sesClient - AWS SES client instance
   * @returns {Promise<void>}
   * @throws {Error} If email sending fails
   */
  async function sendEmail(usernameFound, sesClient, sourceEmail) {
    try {
      const emailBody =
        getEmailBodySubmittedByEmployeeNotifDeptApprover(foundOwner)

      const params = paramsGenerator(
        usernameFound,
        emailBody,
        'Submission of Personal Data Sheet',
        sourceEmail
      )

      const sesClient = await getSesClientToken()
      await sesClient.send(new SendEmailCommand(params))
    } catch (err) {
      console.log(err)
      throw new Error(
        'There was an error sending the email to the Department Approver.',
        err
      )
    }
  }

  /**
   *@function handleSaveAsDraft
   *@description Saves the data of the Personal Data Sheet as a draft.
   *@param {Object} isFinal the status of the Personal Data Sheet.
   *@returns {Object} the updated data of the Personal Data Sheet.
   */
  const handleSaveAsDraft = async (isFinal) => {
    try {
      setIsDisabledAfterSave(true)
      setHoldData({ ...dataReview.data })
      const { url, urlThumb, urlPassport } = await forUpload()
      await triggerSave(url, urlThumb, urlPassport, isFinal)
      setIsModalOpenEdit(false)
      setIsModalOpen(false)
      setIsDisabledAfterSave(false)
      fetchUserDataSheets()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function getDeptApproverDetailsArrayFunction
   * @description Fetches department approver details from database
   * @returns {Promise<Array>} Array of department approver records
   * @throws {Error} If query fails
   */
  const getDeptApproverDetailsArrayFunction = async () => {
    try {
      let getDeptApproverDetailsArray = []
      let token = null

      do {
        const getDeptApproverDetails = await API.graphql(
          graphqlOperation(UserbyDepartment, {
            assignedDept: foundOwner.assignedDept,
            filter: {
              or: [
                { pdsRole: { eq: 'deptApprover' } },
                { pdsRole: { eq: 'deptApproverAndEncoder' } },
              ],
            },
            nextToken: token,
          })
        )

        getDeptApproverDetailsArray.push(
          ...getDeptApproverDetails.data.UserbyDepartment.items
        )

        token = getDeptApproverDetails.data.UserbyDepartment.nextToken
      } while (token)

      return getDeptApproverDetailsArray
    } catch (err) {
      console.log(err)
      return []
    }
  }

  /**
   *@function handleSaveAsFinal
   *@description Saves the data of the Personal Data Sheet as a Final.
   *@param {Object} isFinal the status of the Personal Data Sheet.
   *@returns {Object} the updated data of the Personal Data Sheet.
   */
  const handleSaveAsFinal = async (isFinal) => {
    try {
      setIsDisabledAfterSave(true)
      const { url, urlThumb, urlPassport } = await forUpload()

      if (removeAsterisk) {
        removeAsterisk.remove()
      }

      if (isFinal) {
        let errorSpans = document.getElementsByClassName('errorSpan')
        let errorSpansArray = Array.from(errorSpans)

        errorSpansArray.forEach(function (span) {
          span.parentNode.removeChild(span)
        })

        function checkRequiredValues(obj) {
          const childrenFormatInputs = document.querySelectorAll(
            'input[name*="ifChildren"]'
          )

          const workExperienceFormatinputs = document.querySelectorAll(
            'input[name*="isWorkExperience"]'
          )

          const voluntaryWorkFormInputs = document.querySelectorAll(
            'input[name*="data[isVoluntaryWork]"]'
          )

          const lAndDFormInputs = document.querySelectorAll(
            'input[name*="isLDAttended"]'
          )

          for (let key in obj) {
            if (
              key.includes('Required') ||
              key.includes('NaAllowed') ||
              key === 'heightFormat' ||
              key === 'weightFormat' ||
              key === 'birthOrNaturaliztion' ||
              key === 'indicateCountry' ||
              key === 'bloodtype' ||
              key === 'ifChildren' ||
              key === 'heightFt' ||
              key === 'heightIn' ||
              key === 'heightMeters' ||
              key === 'weightPounds' ||
              key === 'relatedExplain' ||
              key === 'offenseADetails' ||
              key === 'serviceSeparationADetails' ||
              key === 'candidateDetails' ||
              key === 'resignedDetails' ||
              key === 'offenseCDetails' ||
              key === 'offenseDateFiled' ||
              key === 'courtADetails' ||
              key === 'offenseCaseStatus' ||
              key === 'immigrantDetails' ||
              key === 'isIndigenousDetails' ||
              key === 'isPersonWDisabilityDetails' ||
              key === 'isSoloParentDetails'
            ) {
              if (key === 'referencesRequired') {
                let value = obj[key]

                if (!Array.isArray(value) || value.length === 0) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                    isGridData: true,
                  }
                }

                for (let i = 0; i < value.length; i++) {
                  let element = value[i]
                  if (typeof element !== 'object' || element === null) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}]`,
                      isGridData: true,
                    }
                  }

                  for (let elementKey in element) {
                    if (
                      element[elementKey] === undefined ||
                      element[elementKey] === null ||
                      (typeof element[elementKey] === 'string' &&
                        element[elementKey].trim() === '')
                    ) {
                      return {
                        allRequiredValuesPresent: false,
                        failedKey: `[${key}][${i}][${elementKey}]`,
                        dataKey: `${key}[${i}].[${elementKey}]`,
                        isGridData: true,
                      }
                    }
                  }
                }
              } else {
                let value = obj[key]
                if (
                  typeof value === 'undefined' ||
                  value === null ||
                  (typeof value === 'string' && value.trim() === '')
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                  }
                }
              }
            } else if (
              childrenFormatInputs[0].value === 'YES' &&
              key === 'well3Well2DataGrid'
            ) {
              let value = obj[key]
              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataChildren: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataChildren: true,
                  }
                }
                for (let elementKey in element) {
                  if (
                    element[elementKey] === undefined ||
                    element[elementKey] === null ||
                    element[elementKey] === '' ||
                    (typeof element[elementKey] === 'string' &&
                      element[elementKey].trim() === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${elementKey}]`,
                      dataKey: `${key}[${i}].[${elementKey}]`,
                      isGridDataChildren: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid2') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isElementaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isElementaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'elementaryNameOfSchoolNaAllowed',
                  'elementaryBasicEducationNaAllowed',
                  'elementaryDateFromNaAllowed',
                  'elementaryDateToNaAllowed',
                  'elementaryUnitsEarnedNaAllowed',
                  'elementaryYearGraduatedNaAllowed',
                  'elementaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isElementaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isSecondaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isSecondaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'secondaryNameOfSchoolNaAllowed',
                  'secondaryBasicEducation',
                  'secondaryDateFromNaAllowed',
                  'secondaryDateToNaAllowed',
                  'secondaryUnitsEarnedNaAllowed',
                  'secondaryYearGraduatedNaAllowed',
                  'secondaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isSecondaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Levelvocationaltradecourse') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isVocationalGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isVocationalGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'vocationalNameOfSchoolNaAllowed',
                  'vocationalBasicEducationNaAllowed',
                  'vocationalDateFromNaAllowed',
                  'vocationalDateToNaAllowed',
                  'vocationalUnitsEarnedNaAllowed',
                  'vocationalYeargraduatedNaAllowed',
                  'vocationalScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isVocationalGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid3') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCollegeGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCollegeGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'collegeNameOfSchoolNaAllowed',
                  'collegeCourseNaAllowed',
                  'collegeDateFromNaAllowed',
                  'collegeDateToNaAllowed',
                  'collegeUnitsEarnedNaAllowed',
                  'collegeYearGraduatedNaAllowed',
                  'collegeScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCollegeGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well7DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCivilServiceEligibility: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCivilServiceEligibility: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'haveAnyEligibility',
                  'civilServiceAndOthersLicense',
                  'civilServiceRating',
                  'civilServiceExamDateIsNA',
                  'civilServiceExamPlace',
                  'civilServiceLicenseNo',
                  'civilServiceLicenseValidityDateIsNA',
                ]
                const civilServiceExamDateIsNAFormat =
                  element['civilServiceExamDateIsNA']
                if (civilServiceExamDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(4, 0, 'civilServiceExamDate')
                }
                const civilServiceLicenseValidityDateIsNAFormat =
                  element['civilServiceLicenseValidityDateIsNA']
                if (civilServiceLicenseValidityDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(9, 0, 'civilServiceLicenseValidityDate')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCivilServiceEligibility: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well5Levelgraduatestudies') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGraduateStudies: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGraduateStudies: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'graduateNameOfSchoolNaAllowed',
                  'graduateCourseNaAllowed',
                  'graduateDateFromNaAllowed',
                  'graduateDateToNaAllowed',
                  'graduateUnitsEarnedNaAllowed',
                  'graduateYearGraduatedNaAllowed',
                  'graduateScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGraduateStudies: true,
                    }
                  }
                }
              }
            } else if (key === 'isWorkExperience') {
              fieldCheckerFunction(obj, key)
            } else if (
              workExperienceFormatinputs[0].value === 'YES' &&
              key === 'well4Well8DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataWorkExperience: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataWorkExperience: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'workExperienceDateFrom',
                  'workExperiencePositionTitle',
                  'workExperienceCompany',
                  'workExperienceSalary',
                  'workPayGradeIsNA',
                  'workExperienceAppointmentStatus',
                  'workExperienceIsGovtService',
                  'workInclusiveDateToFormat',
                ]
                if (elementKeys.includes('workExperienceDateTo')) {
                  const workInclusiveDateToFormat =
                    element['workInclusiveDateToFormat']
                  if (workInclusiveDateToFormat !== 'PRESENT') {
                    fieldsToCheck.splice(1, 0, 'workExperienceDateTo')
                  }
                }
                if (elementKeys.includes('workExperiencePayGrade')) {
                  fieldsToCheck.splice(5, 0, 'workExperiencePayGrade')
                }
                if (elementKeys.includes('workExperienceStepIncrement')) {
                  fieldsToCheck.splice(6, 0, 'workExperienceStepIncrement')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (
              voluntaryWorkFormInputs[0].value === 'YES' &&
              key === 'well4Well10DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridVoluntaryWork: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridVoluntaryWork: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'voluntaryWorkOrg',
                  'voluntaryWorkDateIsNA',
                  'voluntaryWorkHoursNo',
                  'voluntaryWorkPosition',
                ]
                if (elementKeys.includes('voluntaryWorkDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'voluntaryWorkDateFrom')
                }
                if (elementKeys.includes('voluntaryWorkDateTo')) {
                  fieldsToCheck.splice(2, 0, 'voluntaryWorkDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (key === 'isVoluntaryWork') {
              fieldCheckerFunction(obj, key)
            } else if (key === 'isLDAttended') {
              fieldCheckerFunction(obj, key)
            } else if (
              lAndDFormInputs[0].value === 'YES' &&
              key === 'well4Well11DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridlNd: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridlNd: true,
                  }
                }

                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'ldTrainingTitle',
                  'ldTrainingDateIsNA',
                  'ldTrainingHoursNo',
                  'ldType',
                  'ldSponsoredBy',
                ]
                if (elementKeys.includes('ldTrainingDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'ldTrainingDateFrom')
                }
                if (elementKeys.includes('ldTrainingDateTo')) {
                  fieldsToCheck.splice(2, 0, 'ldTrainingDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridlNd: true,
                    }
                  }
                }
              }
            } else if (key === 'skillsAndHobbiesGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.skillsAndHobbies.length === 0 ||
                  (typeof element.skillsAndHobbies === 'string' &&
                    element.skillsAndHobbies.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataHobbies: true,
                  }
                }
              }
            } else if (key === 'nonAcademicdistinctionsrecognitionGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if the specific property is an empty string
                if (
                  element.nonAcademicdistinctionsrecognition.length === 0 ||
                  (typeof element.nonAcademicdistinctionsrecognition ===
                    'string' &&
                    element.nonAcademicdistinctionsrecognition.trim().length ===
                      0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDatanonAcademicdistinctionsrecognition: true,
                  }
                }
              }
            } else if (key === 'orgMembershipGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.orgMembership.length === 0 ||
                  (typeof element.orgMembership === 'string' &&
                    element.orgMembership.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataorgMembership: true,
                  }
                }
              }
            }
          }
          return {
            allRequiredValuesPresent: true,
            failedKey: null,
          }
        }

        let result = checkRequiredValues(dataReview.data)
        const isGridData = result.isGridData
        const allRequiredValuesPresent = result.allRequiredValuesPresent
        const isGridDataChildren = result.isGridDataChildren
        const isGridDataElementary = result.isElementaryGrid
        const isGridDataSecondary = result.isSecondaryGrid
        const isGridDataVocational = result.isVocationalGrid
        const isGridDataCollege = result.isCollegeGrid
        const isGridDataGraduateStudies = result.isGraduateStudies
        const isGridDataWorkExperience = result.isGridDataWorkExperience
        const isGridDataVoluntaryWork = result.isGridVoluntaryWork
        const isGridDataLNDAtendee = result.isGridlNd
        const isGridDataHobbies = result.isGridDataHobbies
        const isGridDatanonAcademicdistinctionsrecognition =
          result.isGridDatanonAcademicdistinctionsrecognition
        const isGridDataorgMembership = result.isGridDataorgMembership
        const isCivilServiceEligibility = result.isCivilServiceEligibility
        function errorNonTextType(element, picture) {
          if (picture) {
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '* upload here *'
            errorSpan.classList.add('errorSpan')

            element.insertAdjacentElement('afterend', errorSpan)

            notification.error({
              message: 'Error on a required field.',
              description: `Please upload your ${picture}.`,
            })

            setRemoveAsterisk(errorSpan)
          } else {
            const labelElement = element.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
          }

          element.scrollIntoView({ behavior: 'smooth' })

          const elementRect = element.getBoundingClientRect()
          const offset = (window.innerHeight - elementRect.height) / 2
          const scrollOffset = elementRect.top - offset

          element.scrollIntoView({ behavior: 'smooth' })

          const modalParent = element.closest('.ant-modal-root')
          if (modalParent) {
            const modalBody = modalParent.querySelector('.ant-modal-body')
            modalBody.scrollTop += scrollOffset
          } else {
            window.scrollBy(0, scrollOffset)
          }

          const modalContainer = document.querySelector('.ant-modal-wrap') // Select the modal's container element
          const modalContent =
            modalContainer.querySelector('.ant-modal-content') // Select the scrollable container within the modal

          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          modalContent.scrollTop += modalContent.getBoundingClientRect().top
        }

        if (allRequiredValuesPresent === false) {
          const editData = { ...dataReview.data }
          if (isGridData) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            const labelElement =
              element.parentNode.parentNode.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
            element.classList.add('is-invalid')
            element.scrollIntoView({ behavior: 'smooth' })

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            isGridDataElementary ||
            isGridDataSecondary ||
            isGridDataVocational ||
            isGridDataCollege ||
            isGridDataGraduateStudies ||
            isGridDataLNDAtendee
          ) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              const labelElement =
                element.parentNode.parentNode.querySelector('label')
              if (labelElement) {
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              } else {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              isGridDataLNDAtendee &&
              result.failedKey.includes('[ldTrainingDateIsNA]')
            ) {
              const elementSelect = document.querySelectorAll(
                '.formio-component-wellContent3'
              )
              const parts = result.failedKey.split(']') // Split by ']'
              const indexPart = parts[1] // Get the part with the index, which will be "[1"
              const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
              const index = parseInt(indexStr, 10) // Convert to integer

              let elementSelectedWithIndex = elementSelect[index]

              if (elementSelectedWithIndex) {
                const labelElement =
                  elementSelectedWithIndex.parentNode.parentNode.parentNode.querySelector(
                    'span'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelectedWithIndex.classList.add('is-invalid')
                elementSelectedWithIndex.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )

              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
          } else if (isGridDataWorkExperience || isGridDataVoluntaryWork) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              if (
                result.failedKey.includes('[workExperienceDateFrom]') ||
                result.failedKey.includes('[workExperienceSalary]') ||
                result.failedKey.includes('[voluntaryWorkOrg]') ||
                result.failedKey.includes('[voluntaryWorkDateFrom]') ||
                result.failedKey.includes('[voluntaryWorkDateTo]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else if (
                result.failedKey.includes('[workExperiencePositionTitle]') ||
                result.failedKey.includes('[voluntaryWorkHoursNo]') ||
                result.failedKey.includes('[voluntaryWorkPosition]') ||
                result.failedKey.includes('[workExperienceCompany]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelectorAll(
                  '.formio-component-voluntaryWorkDateIsNA'
                )
                const parts = result.failedKey.split(']')
                const indexPart = parts[1]
                const indexStr = indexPart.substring(1)
                const index = parseInt(indexStr, 10)

                let elementSelectedWithIndex = elementSelect[index]
                if (elementSelectedWithIndex) {
                  const labelElement =
                    elementSelectedWithIndex.parentNode.querySelector('span')
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelectedWithIndex.classList.add('is-invalid')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataHobbies) {
            const element = document.querySelectorAll(
              '.formio-component-skillsAndHobbiesGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDatanonAcademicdistinctionsrecognition) {
            const element = document.querySelectorAll(
              '.formio-component-nonAcademicdistinctionsrecognitionGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDataorgMembership) {
            const element = document.querySelectorAll(
              '.formio-component-orgMembershipGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isCivilServiceEligibility) {
            if (result.failedKey.includes('[haveAnyEligibility]')) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-haveAnyEligibility'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes('[civilServiceExamDateIsNA]') ||
              result.failedKey.includes('[civilServiceExamDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceExamDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes(
                '[civilServiceLicenseValidityDateIsNA]'
              ) ||
              result.failedKey.includes('[civilServiceLicenseValidityDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (elementSelect) {
                  const labelElement =
                    elementSelect.parentNode.parentNode.parentNode.querySelector(
                      'label'
                    )
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelect.classList.add('is-invalid')
                  elementSelect.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataChildren) {
            if (result.failedKey.includes('[childrenFullnameNaAllowed]')) {
              const element = document.querySelector(
                `input[name="data${result.failedKey}"]`
              )

              if (element) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')

                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')

                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')

                element.scrollIntoView({ behavior: 'smooth' })

                const elementRect = element.getBoundingClientRect()
                const offset = (window.innerHeight - elementRect.height) / 2
                const scrollOffset = elementRect.top - offset

                element.scrollIntoView({ behavior: 'smooth', block: 'center' })

                window.scrollBy(0, scrollOffset)
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
              // else {
              //   const allElements = document.querySelectorAll(
              //     `input[name^="data[well3Well2DataGrid]["][name$="][childrenFullnameNaAllowed]"]`
              //   );
              //   allElements.forEach(element => {
              //     const dataGridElements = document.querySelectorAll(`input[name^="data[well3Well2DataGrid]"]`);
              //     element.parentNode.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
              //     for (let element of dataGridElements) {
              //       if (!element.value) {
              //         element.style.border = '1px solid red';
              //         element.addEventListener('input', function () {
              //           this.style.border = '';
              //         });
              //         let label = element.parentNode.parentNode;
              //         console.log(label, 'labvel')
              //         console.log(label.children[0], 'children label')
              //         let labelName = label.children[0]
              //         if (!labelName.querySelector('.errorSpan')) {
              //           const errorSpan = document.createElement('span');
              //           errorSpan.textContent = '*';
              //           errorSpan.classList.add('errorSpan');
              //           labelName.appendChild(errorSpan);
              //           setRemoveAsterisk(errorSpan);
              //         }
              //         break;
              //       }
              //     }
              //   });
              //   notification.error({
              //     message: 'Error on a required field.',
              //     description: 'This is a required field. Please fill it out before proceeding.',
              //   });
              // }
            } else if (result.failedKey.includes('[childrenDOBNaAllowed]')) {
              if (result.failedKey) {
                const element = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (element) {
                  // Use closest() to find the nearest ancestor which matches the selector
                  const labelElement = element
                    .closest('.form-group')
                    .querySelector('label')
                  if (labelElement) {
                    const errorSpan = document.createElement('span')
                    errorSpan.textContent = '*'
                    errorSpan.classList.add('errorSpan')
                    labelElement.appendChild(errorSpan)
                    setRemoveAsterisk(errorSpan)
                  }
                  element.classList.add('is-invalid')
                  const errorElement = document.querySelector('.errorSpan')
                  if (errorElement) {
                    errorElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
                // else {
                //   const allElements = document.querySelectorAll(
                //     `input[name^="data[well3Well2DataGrid]["][name$="][childrenDOBNaAllowed]"]`
                //   );
                //   allElements.forEach(element => {
                //     const dataGridElements = document.querySelectorAll(`input[name^="data[well3Well2DataGrid]"]`);
                //     element.parentNode.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
                //     for (let element of dataGridElements) {
                //       if (!element.value) {
                //         element.style.border = '1px solid red';
                //         element.addEventListener('input', function () {
                //           this.style.border = '';
                //         });
                //         let label = element.parentNode.parentNode.parentNode;
                //         let labelName = label.children[0]
                //         if (!labelName.querySelector('.errorSpan')) {
                //           const errorSpan = document.createElement('span');
                //           errorSpan.textContent = '*';
                //           errorSpan.classList.add('errorSpan');
                //           labelName.appendChild(errorSpan);
                //           setRemoveAsterisk(errorSpan);
                //         }
                //         break;
                //       }
                //     }
                //   });
                //   notification.error({
                //     message: 'Error on a required field.',
                //     description: 'This is a required field. Please fill it out before proceeding.',
                //   });
                // }
              }
            }
          } else if (
            result.failedKey === 'suffixNaAllowed' ||
            result.failedKey === 'spouseSuffixNaAllowed' ||
            result.failedKey === 'fatherSuffixNaAllowed'
          ) {
            const dataSuffix = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            errorNonTextType(dataSuffix)
            dataSuffix.parentNode.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'serviceSeparationADetails') {
            const elementSelect = document.querySelector(
              '.formio-component-serviceSeparationADetails'
            )

            if (elementSelect) {
              const labelElement =
                elementSelect.parentNode.parentNode.parentNode.querySelector(
                  'label'
                )
              const errorSpan = document.createElement('span')
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (result.failedKey === 'offenseDateFiled') {
            const elementSelect = document.querySelector(
              '.formio-component-offenseDateFiled'
            )

            if (elementSelect) {
              const labelElement = elementSelect.querySelector('label')
              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              window.scrollBy({
                top: 100,
                behavior: 'smooth',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (
            result.failedKey !== 'sexRequired' &&
            result.failedKey !== 'heightFormat' &&
            result.failedKey !== 'weightFormat' &&
            result.failedKey !== 'dateofbirthRequired' &&
            result.failedKey !== 'civilStatusRequired' &&
            result.failedKey !== 'citizenshipQuestionRequired' &&
            result.failedKey !== 'birthOrNaturaliztion' &&
            result.failedKey !== 'indicateCountry' &&
            result.failedKey !== 'isThirdDegreeRequired' &&
            result.failedKey !== 'isFourthDegreeRequired' &&
            result.failedKey !== 'offenseARequired' &&
            result.failedKey !== 'offenceCRequired' &&
            result.failedKey !== 'courtARequired' &&
            result.failedKey !== 'serviceSeparationARequired' &&
            result.failedKey !== 'candidateRequired' &&
            result.failedKey !== 'resignedRequired' &&
            result.failedKey !== 'immigrantRequired' &&
            result.failedKey !== 'isIndigenousRequired' &&
            result.failedKey !== 'isPersonWDisabilityRequired' &&
            result.failedKey !== 'isSoloParentRequired' &&
            result.failedKey !== 'bloodtype' &&
            result.failedKey !== 'ifChildren' &&
            result.failedKey !== 'well3Well2DataGrid' &&
            result.failedKey !== 'isWorkExperience' &&
            result.failedKey !== 'isVoluntaryWork' &&
            result.failedKey !== 'isLDAttended' &&
            result.failedKey !== 'offenseDateFiled' &&
            !result.failedKey.includes('[workExperienceIsGovtService]') &&
            !result.failedKey.includes('[workPayGradeIsNA]') &&
            !result.failedKey.includes('[workInclusiveDateToFormat]') &&
            !result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const element = document.querySelector(
              `input[name="data[${result.failedKey}]"]`
            )
            const labelElement =
              element.parentElement?.parentNode?.querySelector('label')
            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            repeatingNotif()
          }

          setHoldData({ ...editData })

          const element = document.querySelector(
            `input[name="data[${result.failedKey}]"]`
          )

          function repeatingNotif() {
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          }

          if (element) {
            if (result.failedKey === 'dateofbirthRequired') {
              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.parentNode.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              const modalBody = document.querySelector('.ant-modal-body') // Select the modal body element using a class

              if (modalBody) {
                modalBody.scrollTo({
                  top: scrollOffset,
                  behavior: 'smooth',
                })
              }

              notification.error({
                message: 'Error',
                description: 'Please select your valid date of birth.',
              })
            } else {
              element.classList.add('is-invalid')
              element.scrollIntoView({ behavior: 'smooth', block: 'center' })

              const modalContainer = document.querySelector('.ant-modal-wrap') // Select the modal's container element
              const modalContent =
                modalContainer.querySelector('.ant-modal-content') // Select the scrollable container within the modal

              element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              modalContent.scrollTop += modalContent.getBoundingClientRect().top
            }
          } else if (result.failedKey === 'bloodtype') {
            const element = document.querySelector(
              '.formio-component-bloodtype'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your blood type.',
            })
          } else if (
            result.failedKey.includes('[workExperienceIsGovtService]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workExperienceIsGovtService'
            )

            errorNonTextType(element[index])
          } else if (result.failedKey.includes('[workPayGradeIsNA]')) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workPayGradeIsNA'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            result.failedKey.includes('[workInclusiveDateToFormat]') ||
            result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workInclusiveDateToFormat'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'civilStatusRequired') {
            const element = document.querySelector(
              '.formio-component-civilStatusRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your civil status.',
            })
          } else if (result.failedKey === 'citizenshipQuestionRequired') {
            const element = document.querySelector(
              '.formio-component-citizenshipQuestionRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your citizenship.',
            })
          } else if (result.failedKey === 'birthOrNaturaliztion') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-birthOrNaturaliztion'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description: `Please select between "by birth" or "by naturalization".`,
              })
            }
          } else if (result.failedKey === 'indicateCountry') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-indicateCountry'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description:
                  'Please select if country if you have dual citizenship.',
              })
            }
          } else if (result.failedKey === 'isLDAttended') {
            const element = document.querySelector(
              '.formio-component-isLDAttended'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isThirdDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isThirdDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isFourthDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isFourthDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenseARequired') {
            const element = document.querySelector(
              '.formio-component-offenseARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenceCRequired') {
            const element = document.querySelector(
              '.formio-component-offenceCRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'courtARequired') {
            const element = document.querySelector(
              '.formio-component-courtARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'serviceSeparationARequired') {
            const element = document.querySelector(
              '.formio-component-serviceSeparationARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'candidateRequired') {
            const element = document.querySelector(
              '.formio-component-candidateRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'resignedRequired') {
            const element = document.querySelector(
              '.formio-component-resignedRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'immigrantRequired') {
            const element = document.querySelector(
              '.formio-component-immigrantRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isIndigenousRequired') {
            const element = document.querySelector(
              '.formio-component-isIndigenousRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isPersonWDisabilityRequired') {
            const element = document.querySelector(
              '.formio-component-isPersonWDisabilityRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isSoloParentRequired') {
            const element = document.querySelector(
              '.formio-component-isSoloParentRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'sexRequired') {
            const element = document.querySelector(
              '.formio-component-sexRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'heightFormat') {
            const element = document.querySelector(
              '.formio-component-heightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'weightFormat') {
            const element = document.querySelector(
              '.formio-component-weightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'ifChildren') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'well3Well2DataGrid') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )
            errorNonTextType(element)
            repeatingNotif()
          }
        } else if (
          (uploadedSignatureLocInit.length || uploadedSignature) &&
          (uploadedThumbmarkLocInit.length || uploadedThumbmark) &&
          (uploadedPassportLocInit.length || uploadedPassportPhoto)
        ) {
          await triggerSave(url, urlThumb, urlPassport, isFinal)

          const getDeptApproverDetailsArray =
            await getDeptApproverDetailsArrayFunction()

          setHoldData({ ...dataReview.data })

          const credentials = await Auth.currentCredentials()
          const accessKeyId = credentials.accessKeyId
          const secretAccessKey = credentials.secretAccessKey
          const sessionToken = credentials.sessionToken

          const sesClient = new SESClient({
            region: 'ap-southeast-1',
            credentials: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              sessionToken: sessionToken,
            },
          })

          const sourceEmail = (await getSourceEmail()) || ''

          if (
            getDeptApproverDetailsArray.length >= 1 &&
            !window.location.href.includes('localhost')
          ) {
            await sendEmail(
              getDeptApproverDetailsArray[0].username,
              sesClient,
              sourceEmail
            )

            notification.success({
              message: 'Success',
              description:
                'You have submitted for review your Personal Data Sheet successfully.',
            })
          } else if (!getDeptApproverDetailsArray?.length >= 1) {
            notification.info({
              message: 'NOTICE',
              duration: 120000,
              description: `We didn't see any Department Approver for your PDS. Please reach out to HRM Department to inform them of your submitted PDS Form`,
            })
          }

          setIsModalOpen(false)
        } else if (!uploadedSignatureLocInit.length && !uploadedSignature) {
          let divElement = document.querySelector(
            '.formio-component-well4Well6ColumnsSignature'
          )

          let childDiv = divElement.querySelector('div')
          let spanElement = childDiv.querySelector('span')
          errorNonTextType(spanElement, 'e-Signature / thumbmark photo')
        } else if (!uploadedThumbmarkLocInit.length && !uploadedThumbmark) {
          let divElement = document.querySelector(
            '.formio-component-well4Well6Content'
          )

          let childDiv = divElement.querySelector('div')
          let spanElement = childDiv.querySelector('span')
          errorNonTextType(spanElement, 'thumbmark photo / e-Signature')
        } else if (!uploadedPassportLocInit.length && !uploadedPassportPhoto) {
          let divElement = document.querySelector(
            '.formio-component-well4Well22ColumnsContent'
          )

          let spanElement = divElement.querySelector('span')
          errorNonTextType(spanElement, 'passport photo')
        }
      }

      setIsModalOpenEdit(false)
      setIsDisabledAfterSave(false)
      fetchUserDataSheets()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleDeletePDS
   *@description Deletes the data of the Personal Data Sheet.
   *@param {Object} e the ID of the data sheet.
   *@returns {Object} the updated data of the Personal Data Sheet (response)
   */
  const handleDeletePDS = async (e) => {
    try {
      await API.graphql(
        graphqlOperation(deletePersonalDataSheet, {
          input: {
            id: e,
          },
        })
      )

      setToBeDeletedDraft(null)
      setIsOpenDeleteDraftModal(false)
      fetchUserDataSheets()
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Failed to delete your draft PDS. Please ask System Admin for help.',
      })
    }
  }

  /**
   *@function handleImages
   *@description Displays the images of the Personal Data Sheet.
   *@param {Object} None no parameters needed.
   *@returns {Object} the images of the Personal Data Sheet.
   */
  const handleImages = () => {
    try {
      if (JSON.parse(foundRecord.data).data.sameAsResidentialAddressBox) {
        handleSameAsResidentialAddressBoxFunc()
      }

      if (JSON.parse(foundRecord.data).data.markSpouseAsNA) {
        handleMarkSpouseAsNAFunc()
      }

      if (JSON.parse(foundRecord.data).data.markFatherAsNA) {
        handleMarkFatherAsNAFunc()
      }

      if (JSON.parse(foundRecord.data).data.markMotherAsNA) {
        handleMarkMotherAsNAFunc()
      }

      if (foundRecord?.eSignatureLoc) {
        handleLoadSignature()
      }

      if (foundRecord?.thumbmarkSignLoc) {
        handleLoadThumbmarkSign()
      }

      if (foundRecord?.passportPhotoLoc) {
        handleLoadPassportPicture()
      }

      setTimeout(() => {
        elementaryAsNALoadFunc(JSON.parse(foundRecord.data).data)
        secondaryAsNALoadFunc(JSON.parse(foundRecord.data).data)
        vocationalAsNALoadFunc(JSON.parse(foundRecord.data).data)
        collegeAsNALoadFunc(JSON.parse(foundRecord.data).data)
        gradStudiesAsNALoadFunc(JSON.parse(foundRecord.data).data)
        civilStatusRequiredLoadFunc(JSON.parse(foundRecord.data).data)
        civilServiceLoadFunc(JSON.parse(foundRecord.data).data)
        handleIfChildrenLoadFunc(JSON.parse(foundRecord.data).data)
        handleWorkExperienceLoadFunc(JSON.parse(foundRecord.data).data)
        handleVoluntaryWorkLoadFunc(JSON.parse(foundRecord.data).data)
        handleLDTrainingsLoadFunc(JSON.parse(foundRecord.data).data)
        handleNonAcademicdistinctionsrecognitionLoadFunc(
          JSON.parse(foundRecord.data).data
        )
        handleOrgMembershipLoadFunc(JSON.parse(foundRecord.data).data)
        handleSkillsAndHobbiesLoadFunc(JSON.parse(foundRecord.data).data)

        handleReferencesRemoveRowButtons()
      }, 1000)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangePassportPhoto
   *@description Handles the uploading of the passport photo of the Personal Data Sheet.
   *@param {Object} e the file of the passport photo.
   *@returns {Object} the uploaded passport photo.
   */
  const handleFileChangePassportPhoto = (e) => {
    try {
      const removeNote = document.getElementsByClassName(
        'formio-component-well4Well22ColumnsContent'
      )[0]
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      const passportPhoto = document.getElementsByClassName('passport-photo')[0]

      const imgElementDels = passportPhoto.querySelectorAll('img')

      if (imgElementDels.length) {
        imgElementDels.forEach((imgElement) => {
          passportPhoto.removeChild(imgElement)
        })
      }

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          passportPhoto.appendChild(imgElement)

          removeNote.style.display = 'none'

          setUploadedPassportLocInit('')
        }

        reader.readAsDataURL(file)
        setUploadedPassportPhoto(file)
      } else {
        removeNote.style.display = 'block'

        setIsModalOpenPassportPhoto(false)
        setUploadedPassportPhoto(null)
        setUploadedPassportLocInit('')

        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }

      setIsModalOpenESign(false)
      setIsModalOpenThumbmark(false)
      setIsModalOpenPassportPhoto(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeESign
   *@description Handles the uploading of the e-Signature of the Personal Data Sheet.
   *@param {Object} e the file of the e-Signature.
   *@returns {Object} the uploaded e-Signature.
   */
  const handleFileChangeESign = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eSignDiv = document.getElementsByClassName(
          'electronic-signature'
        )[0]

        const imgElementDels = eSignDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eSignDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eSignDiv.appendChild(imgElement)
          setIsModalOpenESign(false)
          setIsModalOpenThumbmark(false)
          setIsModalOpenPassportPhoto(false)

          setUploadedSignatureLocInit('')
        }

        reader.readAsDataURL(file)
        setUploadedSignature(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeThumb
   *@description Handles the uploading of the thumbmark photo of the Personal Data Sheet.
   *@param {Object} e the file of the thumbmark photo.
   *@returns {Object} the uploaded thumbmark photo.
   */
  const handleFileChangeThumb = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eThumbDiv = document.getElementsByClassName(
          'electronic-thumbmark'
        )[0]

        const imgElementDels = eThumbDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eThumbDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eThumbDiv.appendChild(imgElement)
          setIsModalOpenThumbmark(false)
          setIsModalOpenESign(false)
          setIsModalOpenPassportPhoto(false)

          setUploadedThumbmarkLocInit('')
        }

        reader.readAsDataURL(file)
        setUploadedThumbmark(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function inputChangeHandler
   * @description Handles changes to input elements, particularly radio buttons.
   * Programmatically updates input values and triggers change events.
   *
   * @param {HTMLInputElement} inputElement - The input DOM element to modify
   * @param {string|boolean} val - The new value to set for the input
   * @throws {Error} If input element manipulation fails
   * @returns {void}
   *
   * @example
   * // For a radio button
   * inputChangeHandler(radioElement, "option1")
   *
   * @example
   * // For a checkbox
   * inputChangeHandler(checkboxElement, true)
   */
  const inputChangeHandler = (inputElement, val) => {
    try {
      if (inputElement) {
        if (inputElement.type === 'radio') {
          // For radio inputs, find the corresponding radio option and set its checked property
          const radioOption = document.querySelector(
            `input[name="${inputElement.name}"][value="${val}"]`
          )
          if (radioOption) {
            radioOption.checked = true

            const event = new Event('change', { bubbles: true })
            radioOption.dispatchEvent(event)
          }
        } else {
          // For other input types, set the value property
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
          ).set
          nativeInputValueSetter.call(inputElement, val)

          const event = new Event('input', { bubbles: true })
          inputElement.dispatchEvent(event)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  function handleClick(event) {
    event.preventDefault()
  }

  const handleCivilStatusRequiredToSingleFunc = (e) => {
    const spouseFields = callSpouseFieldsFunc()
    const {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    } = spouseFields

    markSpouseAsNA.checked = true
    markSpouseAsNA.disabled = true

    spouseSurname.disabled = true
    spouseFirstName.disabled = true
    spouseMiddleName.disabled = true
    spouseOccupation.disabled = true
    spouseEmployerName.disabled = true
    spouseEmployerAddress.disabled = true
    spouseEmployerTelephoneno.disabled = true

    spouseSurname.value = 'N/A'
    spouseFirstName.value = 'N/A'
    spouseMiddleName.value = 'N/A'
    spouseOccupation.value = 'N/A'
    spouseEmployerName.value = 'N/A'
    spouseEmployerAddress.value = 'N/A'
    spouseEmployerTelephoneno.value = 'N/A'

    createSpouseSuffixSpanTemp(spouseSuffixparentElement)

    spouseSuffixparentElement.style.display = 'none'
    spouseSuffixparentElement.style.visibility = 'hidden'

    e.data.markSpouseAsNA = true
    e.data.spouseSurnameNaAllowed = 'N/A'
    e.data.spouseFirstnameNaAllowed = 'N/A'
    e.data.spouseMiddlenameNaAllowed = 'N/A'
    e.data.spouseOccupationNaAllowed = 'N/A'
    e.data.spouseEmployerNameNaAllowed = 'N/A'
    e.data.spouseEmployerAddressNaAllowed = 'N/A'
    e.data.spouseEmployerTelephonenoNaAllowed = 'N/A'
    e.data.spouseSuffixNaAllowed = 'N/A'

    setHoldData(e.data)
  }

  const handleCivilStatusRequiredToNOTSingleFunc = (e) => {
    const spouseFields = callSpouseFieldsFunc()
    const {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    } = spouseFields

    e.data.markSpouseAsNA = false
    e.data.spouseSurname = ''
    e.data.spouseFirstName = ''
    e.data.spouseMiddleName = ''
    e.data.spouseOccupation = ''
    e.data.spouseEmployerName = ''
    e.data.spouseEmployerAddress = ''
    e.data.spouseEmployerTelephoneno = ''
    e.data.spouseSuffixNaAllowed = ''

    markSpouseAsNA.checked = false
    markSpouseAsNA.disabled = false

    spouseSurname.disabled = false
    spouseFirstName.disabled = false
    spouseMiddleName.disabled = false
    spouseOccupation.disabled = false
    spouseEmployerName.disabled = false
    spouseEmployerAddress.disabled = false
    spouseMiddleName.disabled = false
    spouseEmployerTelephoneno.disabled = false

    spouseSurname.value = ''
    spouseFirstName.value = ''
    spouseMiddleName.value = ''
    spouseOccupation.value = ''
    spouseEmployerName.value = ''
    spouseEmployerAddress.value = ''
    spouseMiddleName.value = ''
    spouseEmployerTelephoneno.value = ''

    spouseSuffixparentElement.style = undefined

    removeSpouseSuffixSpanTemp()

    setHoldData(e.data)
  }

  const createSpouseSuffixSpanTemp = (spouseSuffixparentElement) => {
    // Create a new input element
    const spouseSuffixInput = document.createElement('input')
    const spouseSuffixInputLabel = document.createElement('label')

    // Set attributes for the input
    spouseSuffixInput.type = 'text'
    spouseSuffixInput.value = 'N/A'
    spouseSuffixInput.disabled = true // Disables the input field

    spouseSuffixInputLabel.classList.add('col-form-label')
    spouseSuffixInputLabel.classList.add('spouse-suffix-temp-label')
    spouseSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

    // Optional: Add some styling or classes if desired
    spouseSuffixInput.classList.add('form-control')
    spouseSuffixInput.classList.add('spouse-suffix-temp')

    // Insert the input next to where the select was
    spouseSuffixparentElement.parentNode.insertBefore(
      spouseSuffixInput,
      spouseSuffixparentElement.nextSibling
    )
    spouseSuffixparentElement.parentNode.insertBefore(
      spouseSuffixInputLabel,
      spouseSuffixparentElement.nextSibling
    )
  }

  const removeSpouseSuffixSpanTemp = () => {
    const spouseSuffixInput = document.querySelectorAll('.spouse-suffix-temp')
    const spouseSuffixInputLabel = document.querySelectorAll(
      '.spouse-suffix-temp-label'
    )

    if (spouseSuffixInput) {
      spouseSuffixInput.forEach((i) => {
        i.remove()
      })
    }

    if (spouseSuffixInputLabel) {
      spouseSuffixInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const createElemSpanTemp = (elemFound, text) => {
    // Create a new input element
    const elemValueInput = document.createElement('input')
    const elemValueInputLabel = document.createElement('label')

    // Set attributes for the input
    elemValueInput.type = 'text'
    elemValueInput.value = 'N/A'
    elemValueInput.disabled = true // Disables the input field

    elemValueInputLabel.classList.add('col-form-label')
    elemValueInputLabel.classList.add('elem-val-temp-label')
    elemValueInputLabel.innerHTML = text

    // Optional: Add some styling or classes if desired
    elemValueInput.classList.add('form-control')
    elemValueInput.classList.add('elem-val-temp')

    // Insert the input next to where the select was
    elemFound.parentNode.insertBefore(elemValueInput, elemFound.nextSibling)
    elemFound.parentNode.insertBefore(
      elemValueInputLabel,
      elemFound.nextSibling
    )
  }

  const removeElemSpanTemp = () => {
    const elemValueInput = document.querySelectorAll('.elem-val-temp')
    const elemValueInputLabel = document.querySelectorAll(
      '.elem-val-temp-label'
    )

    if (elemValueInput) {
      elemValueInput.forEach((i) => {
        i.remove()
      })
    }

    if (elemValueInputLabel) {
      elemValueInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const createFatherSuffixSpanTemp = (fatherSuffixparentElement) => {
    // Create a new input element
    const fatherSuffixInput = document.createElement('input')
    const fatherSuffixInputLabel = document.createElement('label')

    // Set attributes for the input
    fatherSuffixInput.type = 'text'
    fatherSuffixInput.value = 'N/A'
    fatherSuffixInput.disabled = true // Disables the input field

    fatherSuffixInputLabel.classList.add('col-form-label')
    fatherSuffixInputLabel.classList.add('father-suffix-temp-label')
    fatherSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

    // Optional: Add some styling or classes if desired
    fatherSuffixInput.classList.add('form-control')
    fatherSuffixInput.classList.add('father-suffix-temp')

    // Insert the input next to where the select was
    fatherSuffixparentElement.parentNode.insertBefore(
      fatherSuffixInput,
      fatherSuffixparentElement.nextSibling
    )
    fatherSuffixparentElement.parentNode.insertBefore(
      fatherSuffixInputLabel,
      fatherSuffixparentElement.nextSibling
    )
  }

  const removeFatherSuffixSpanTemp = () => {
    const fatherSuffixInput = document.querySelectorAll('.father-suffix-temp')
    const fatherSuffixInputLabel = document.querySelectorAll(
      '.father-suffix-temp-label'
    )

    if (fatherSuffixInput) {
      fatherSuffixInput.forEach((i) => {
        i.remove()
      })
    }

    if (fatherSuffixInputLabel) {
      fatherSuffixInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const callSpouseFieldsFunc = () => {
    const spouseSurname = document.querySelector(
      'input[name="data[spouseSurnameNaAllowed]"]'
    )
    const spouseFirstName = document.querySelector(
      'input[name="data[spouseFirstnameNaAllowed]"]'
    )
    const spouseMiddleName = document.querySelector(
      'input[name="data[spouseMiddlenameNaAllowed]"]'
    )
    const spouseSuffixparentElement = document.querySelector(
      '.formio-component-spouseSuffixNaAllowed'
    )
    const dropdownList = spouseSuffixparentElement.querySelectorAll(
      '.choices__list.choices__list--dropdown'
    )
    const suffixDropDown = dropdownList[0]
    const allChoices = spouseSuffixparentElement.querySelectorAll(
      '.choices__item.choices__placeholder.choices__item--selectable'
    )
    const spouseSuffixSelect = allChoices[0]
    const spouseOccupation = document.querySelector(
      'input[name="data[spouseOccupationNaAllowed]"]'
    )
    const spouseEmployerName = document.querySelector(
      'input[name="data[spouseEmployerNameNaAllowed]"]'
    )
    const spouseEmployerAddress = document.querySelector(
      'input[name="data[spouseEmployerAddressNaAllowed]"]'
    )
    const spouseEmployerTelephoneno = document.querySelector(
      'input[name="data[spouseEmployerTelephonenoNaAllowed]"]'
    )
    const markSpouseAsNA = document.querySelector(
      'input[name="data[markSpouseAsNA]"]'
    )

    return {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      dropdownList,
      suffixDropDown,
      allChoices,
      spouseSuffixSelect,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    }
  }

  const callFatherFieldsFunc = () => {
    const fatherSurnameNaAllowed = document.querySelector(
      'input[name="data[fatherSurnameNaAllowed]"]'
    )
    const fatherFirstnameNaAllowed = document.querySelector(
      'input[name="data[fatherFirstnameNaAllowed]"]'
    )
    const fatherMiddlenameNaAllowed = document.querySelector(
      'input[name="data[fatherMiddlenameNaAllowed]"]'
    )
    const fatherSuffixparentElement = document.querySelector(
      '.formio-component-fatherSuffixNaAllowed'
    )
    const dropdownListFather = fatherSuffixparentElement.querySelectorAll(
      '.choices__list.choices__list--dropdown'
    )
    const suffixDropDownFather = dropdownListFather[0]
    const allChoicesFather = fatherSuffixparentElement.querySelectorAll(
      '.choices__item.choices__placeholder.choices__item--selectable'
    )
    const fatherSuffixSelect = allChoicesFather[0]

    return {
      fatherSurnameNaAllowed,
      fatherFirstnameNaAllowed,
      fatherMiddlenameNaAllowed,
      fatherSuffixparentElement,
      dropdownListFather,
      suffixDropDownFather,
      allChoicesFather,
      fatherSuffixSelect,
    }
  }

  const callMotherFieldsFunc = () => {
    const motherSurnameNaAllowed = document.querySelector(
      'input[name="data[motherSurnameNaAllowed]"]'
    )
    const motherFirstnameNaAllowed = document.querySelector(
      'input[name="data[motherFirstnameNaAllowed]"]'
    )
    const motherMiddlenameNaAllowed = document.querySelector(
      'input[name="data[motherMiddlenameNaAllowed]"]'
    )

    return {
      motherSurnameNaAllowed,
      motherFirstnameNaAllowed,
      motherMiddlenameNaAllowed,
    }
  }

  const handleSameAsResidentialAddressBoxFunc = () => {
    const chooseProvinceButtonPermElem = document.querySelector(
      'button[name="data[chooseProvinceButtonPerm]"]'
    )
    const chooseCityButtonPermElem = document.querySelector(
      'button[name="data[chooseCityButtonPerm]"]'
    )
    const chooseBarangayButtonPermElem = document.querySelector(
      'button[name="data[chooseBarangayPerm]"]'
    )
    const chooseZipCodeButtonPermElem = document.querySelector(
      'button[name="data[choosezipcodePerm]"]'
    )
    const permanentHouseBlockLotNoRequiredElem = document.querySelector(
      'input[name="data[permanentHouseBlockLotNoRequired]"]'
    )
    const permanentStreetRequiredElem = document.querySelector(
      'input[name="data[permanentStreetRequired]"]'
    )
    const permanentSubdivisionVillageElem = document.querySelector(
      'input[name="data[permanentSubdivisionVillageRequired]"'
    )
    const permanentBarangayRequiredElem = document.querySelector(
      'input[name="data[permanentBarangayRequired]"]'
    )
    const permanentCityMunicipalityRequiredElem = document.querySelector(
      'input[name="data[permanentCityMunicipalityRequired]"]'
    )
    const permanentProvinceRequiredElem = document.querySelector(
      'input[name="data[permanentProvinceRequired]"]'
    )
    const permanentZipcodeElem = document.querySelector(
      'input[name="data[permanentZipcodeNaAllowed]"]'
    )
    setTimeout(() => {
      chooseProvinceButtonPermElem.disabled = true
      chooseCityButtonPermElem.disabled = true
      chooseBarangayButtonPermElem.disabled = true
      chooseZipCodeButtonPermElem.disabled = true
    }, 500)
    permanentHouseBlockLotNoRequiredElem.disabled = true
    permanentStreetRequiredElem.disabled = true
    permanentSubdivisionVillageElem.disabled = true
    permanentBarangayRequiredElem.disabled = true
    permanentCityMunicipalityRequiredElem.disabled = true
    permanentProvinceRequiredElem.disabled = true
    permanentZipcodeElem.disabled = true
  }

  const handleMarkSpouseAsNAFunc = () => {
    const spouseFields = callSpouseFieldsFunc()
    const {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    } = spouseFields

    spouseSurname.disabled = true
    spouseFirstName.disabled = true
    spouseMiddleName.disabled = true
    spouseOccupation.disabled = true
    spouseEmployerName.disabled = true
    spouseEmployerAddress.disabled = true
    spouseEmployerTelephoneno.disabled = true

    spouseSurname.value = 'N/A'
    spouseFirstName.value = 'N/A'
    spouseMiddleName.value = 'N/A'
    spouseOccupation.value = 'N/A'
    spouseEmployerName.value = 'N/A'
    spouseEmployerAddress.value = 'N/A'
    spouseEmployerTelephoneno.value = 'N/A'

    spouseSuffixparentElement.style.display = 'none'
    spouseSuffixparentElement.style.visibility = 'hidden'

    createSpouseSuffixSpanTemp(spouseSuffixparentElement)

    if (JSON.parse(foundRecord.data).data.civilStatusRequired === 'SINGLE') {
      markSpouseAsNA.disabled = true
    }
  }

  const handleMarkFatherAsNAFunc = () => {
    const fatherFields = callFatherFieldsFunc()
    const {
      fatherSurnameNaAllowed,
      fatherFirstnameNaAllowed,
      fatherMiddlenameNaAllowed,
      fatherSuffixparentElement,
    } = fatherFields

    fatherSuffixparentElement.style.display = 'none'
    fatherSuffixparentElement.style.visibility = 'hidden'

    createFatherSuffixSpanTemp(fatherSuffixparentElement)

    fatherSurnameNaAllowed.disabled = true
    fatherFirstnameNaAllowed.disabled = true
    fatherMiddlenameNaAllowed.disabled = true
  }

  const handleLoadSignature = () => {
    setUploadedSignatureLocInit(foundRecord.eSignatureLoc)
    const eSignDiv = document.getElementsByClassName('electronic-signature')[0]

    const imgElementESign = document.createElement('img')
    imgElementESign.src = foundRecord.eSignatureLoc

    eSignDiv.appendChild(imgElementESign)
  }

  const handleLoadThumbmarkSign = () => {
    setUploadedThumbmarkLocInit(foundRecord.thumbmarkSignLoc)
    const eThumbDiv = document.getElementsByClassName('electronic-thumbmark')[0]

    const imgElementThumb = document.createElement('img')
    imgElementThumb.src = foundRecord.thumbmarkSignLoc

    eThumbDiv.appendChild(imgElementThumb)
  }

  const handleLoadPassportPicture = () => {
    setUploadedPassportLocInit(foundRecord.passportPhotoLoc)
    const passportDiv = document.getElementsByClassName('passport-photo')[0]

    const imgElementPassport = document.createElement('img')
    imgElementPassport.src = foundRecord.passportPhotoLoc

    passportDiv.appendChild(imgElementPassport)
  }

  const handleMarkMotherAsNAFunc = () => {
    const motherFields = callMotherFieldsFunc()
    const {
      motherSurnameNaAllowed,
      motherFirstnameNaAllowed,
      motherMiddlenameNaAllowed,
    } = motherFields

    motherSurnameNaAllowed.disabled = true
    motherFirstnameNaAllowed.disabled = true
    motherMiddlenameNaAllowed.disabled = true
  }

  const elementaryAsNALoadFunc = (holdData) => {
    const well4DataGrid2 = holdData.well4DataGrid2
    for (const [index, item] of well4DataGrid2.entries()) {
      const elementaryDateFromNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryDateFromNaAllowed]"]`
      )
      const elementaryDateToNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryDateToNaAllowed]"]`
      )
      const elementaryNameOfSchoolNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryNameOfSchoolNaAllowed]"]`
      )
      const elementaryScholarshipNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryScholarshipNaAllowed]"]`
      )
      const elementaryUnitsEarnedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryUnitsEarnedNaAllowed]"]`
      )
      const elementaryYearGraduatedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid2][${index}][elementaryYearGraduatedNaAllowed]"]`
      )

      const parent =
        elementaryDateFromNaAllowed.parentNode.parentNode.parentNode.parentNode
          .parentNode
      const hideElem = parent.querySelector(
        '.formio-component-elementaryBasicEducationNaAllowed'
      )

      if (index < well4DataGrid2.length - 1) {
        const disableAddRowButton = elementaryDateFromNaAllowed
          .closest('tr')
          .querySelector('.formio-button-remove-row')

        disableAddRowButton.disabled = true
      }

      if (item.elementaryAsNA === true) {
        elementaryDateFromNaAllowed.disabled = true
        elementaryDateToNaAllowed.disabled = true
        elementaryNameOfSchoolNaAllowed.disabled = true
        elementaryScholarshipNaAllowed.disabled = true
        elementaryUnitsEarnedNaAllowed.disabled = true
        elementaryYearGraduatedNaAllowed.disabled = true

        hideElem.style.display = 'none'

        createElemSpanTemp(hideElem, 'BASIC EDUCATION/DEGREE/COURSE')
      }
    }

    const foundTR = document.querySelectorAll(
      '.formio-component-well4DataGrid2 tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4DataGrid2 table'
    )
    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4DataGrid2.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        if (key === 'elementaryAsNA') {
          return (
            value !== true &&
            value !== null &&
            value !== undefined &&
            value !== ''
          )
        }
        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const secondaryAsNALoadFunc = (holdData) => {
    const well4DataGrid = holdData.well4DataGrid
    for (const [index, item] of well4DataGrid.entries()) {
      const secondaryDateFromNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryDateFromNaAllowed]"]`
      )
      const secondaryDateToNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryDateToNaAllowed]"]`
      )
      const secondaryNameOfSchoolNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryNameOfSchoolNaAllowed]"]`
      )
      const secondaryScholarshipNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryScholarshipNaAllowed]"]`
      )
      const secondaryUnitsEarnedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryUnitsEarnedNaAllowed]"]`
      )
      const secondaryYearGraduatedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid][${index}][secondaryYearGraduatedNaAllowed]"]`
      )

      const parent =
        secondaryDateFromNaAllowed.parentNode.parentNode.parentNode.parentNode
          .parentNode
      const hideElem = parent.querySelector(
        '.formio-component-secondaryBasicEducation'
      )

      if (index < well4DataGrid.length - 1) {
        const disableAddRowButton = secondaryDateFromNaAllowed
          .closest('tr')
          .querySelector('.formio-button-remove-row')

        disableAddRowButton.disabled = true
      }

      if (item.secondaryAsNA === true) {
        secondaryDateFromNaAllowed.disabled = true
        secondaryDateToNaAllowed.disabled = true
        secondaryNameOfSchoolNaAllowed.disabled = true
        secondaryScholarshipNaAllowed.disabled = true
        secondaryUnitsEarnedNaAllowed.disabled = true
        secondaryYearGraduatedNaAllowed.disabled = true

        hideElem.style.display = 'none'

        createElemSpanTemp(hideElem, 'BASIC EDUCATION/DEGREE/COURSE')
      }
    }

    const foundTR = document.querySelectorAll(
      '.formio-component-well4DataGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4DataGrid table'
    )
    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4DataGrid.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        if (key === 'secondaryAsNA') {
          return (
            value !== true &&
            value !== null &&
            value !== undefined &&
            value !== ''
          )
        }
        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const vocationalAsNALoadFunc = (holdData) => {
    const well4Levelvocationaltradecourse =
      holdData.well4Levelvocationaltradecourse
    for (const [index, item] of well4Levelvocationaltradecourse.entries()) {
      const vocationalBasicEducationNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalBasicEducationNaAllowed]"]`
      )
      const vocationalDateFromNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalDateFromNaAllowed]"]`
      )
      const vocationalDateToNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalDateToNaAllowed]"]`
      )
      const vocationalNameOfSchoolNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalNameOfSchoolNaAllowed]"]`
      )
      const vocationalScholarshipNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalScholarshipNaAllowed]"]`
      )
      const vocationalUnitsEarnedNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalUnitsEarnedNaAllowed]"]`
      )
      const vocationalYeargraduatedNaAllowed = document.querySelector(
        `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalYeargraduatedNaAllowed]"]`
      )

      if (index < well4Levelvocationaltradecourse.length - 1) {
        const disableAddRowButton = vocationalBasicEducationNaAllowed
          .closest('tr')
          .querySelector('.formio-button-remove-row')

        disableAddRowButton.disabled = true
      }

      if (item.vocationalAsNA === true) {
        vocationalBasicEducationNaAllowed.disabled = true
        vocationalDateFromNaAllowed.disabled = true
        vocationalDateToNaAllowed.disabled = true
        vocationalNameOfSchoolNaAllowed.disabled = true
        vocationalScholarshipNaAllowed.disabled = true
        vocationalUnitsEarnedNaAllowed.disabled = true
        vocationalYeargraduatedNaAllowed.disabled = true
      }
    }

    const foundTR = document.querySelectorAll(
      '.formio-component-well4Levelvocationaltradecourse tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Levelvocationaltradecourse table'
    )
    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4Levelvocationaltradecourse.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        if (key === 'vocationalAsNA') {
          return (
            value !== true &&
            value !== null &&
            value !== undefined &&
            value !== ''
          )
        }
        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const collegeAsNALoadFunc = (holdData) => {
    const well4DataGrid3 = holdData.well4DataGrid3
    for (const [index, item] of well4DataGrid3.entries()) {
      const collegeCourseNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeCourseNaAllowed]"]`
      )
      const collegeDateFromNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeDateFromNaAllowed]"]`
      )
      const collegeDateToNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeDateToNaAllowed]"]`
      )
      const collegeNameOfSchoolNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeNameOfSchoolNaAllowed]"]`
      )
      const collegeScholarshipNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeScholarshipNaAllowed]"]`
      )
      const collegeUnitsEarnedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeUnitsEarnedNaAllowed]"]`
      )
      const collegeYearGraduatedNaAllowed = document.querySelector(
        `input[name="data[well4DataGrid3][${index}][collegeYearGraduatedNaAllowed]"]`
      )

      if (index < well4DataGrid3.length - 1) {
        const disableAddRowButton = collegeCourseNaAllowed
          .closest('tr')
          .querySelector('.formio-button-remove-row')

        disableAddRowButton.disabled = true
      }

      if (item.collegeAsNA === true) {
        collegeCourseNaAllowed.disabled = true
        collegeDateFromNaAllowed.disabled = true
        collegeDateToNaAllowed.disabled = true
        collegeNameOfSchoolNaAllowed.disabled = true
        collegeScholarshipNaAllowed.disabled = true
        collegeUnitsEarnedNaAllowed.disabled = true
        collegeYearGraduatedNaAllowed.disabled = true
      }
    }

    const foundTR = document.querySelectorAll(
      '.formio-component-well4DataGrid3 tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4DataGrid3 table'
    )
    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4DataGrid3.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        if (key === 'collegeAsNA') {
          return (
            value !== true &&
            value !== null &&
            value !== undefined &&
            value !== ''
          )
        }
        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const gradStudiesAsNALoadFunc = (holdData) => {
    const well4Well5Levelgraduatestudies =
      holdData.well4Well5Levelgraduatestudies
    for (const [index, item] of well4Well5Levelgraduatestudies.entries()) {
      const graduateCourseNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateCourseNaAllowed]"]`
      )
      const graduateDateFromNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateDateFromNaAllowed]"]`
      )
      const graduateDateToNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateDateToNaAllowed]"]`
      )
      const graduateNameOfSchoolNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateNameOfSchoolNaAllowed]"]`
      )
      const graduateUnitsEarnedNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateUnitsEarnedNaAllowed]"]`
      )
      const graduateYearGraduatedNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateYearGraduatedNaAllowed]"]`
      )
      const graduateScholarshipNaAllowed = document.querySelector(
        `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateScholarshipNaAllowed]"]`
      )

      if (index < well4Well5Levelgraduatestudies.length - 1) {
        const disableAddRowButton = graduateCourseNaAllowed
          .closest('tr')
          .querySelector('.formio-button-remove-row')

        disableAddRowButton.disabled = true
      }

      if (item.gradStudiesAsNA === true) {
        graduateCourseNaAllowed.disabled = true
        graduateDateFromNaAllowed.disabled = true
        graduateDateToNaAllowed.disabled = true
        graduateNameOfSchoolNaAllowed.disabled = true
        graduateUnitsEarnedNaAllowed.disabled = true
        graduateYearGraduatedNaAllowed.disabled = true
        graduateScholarshipNaAllowed.disabled = true
      }
    }

    const foundTR = document.querySelectorAll(
      '.formio-component-well4Well5Levelgraduatestudies tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Well5Levelgraduatestudies table'
    )
    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4Well5Levelgraduatestudies.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        if (key === 'gradStudiesAsNA') {
          return (
            value !== true &&
            value !== null &&
            value !== undefined &&
            value !== ''
          )
        }
        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const civilStatusRequiredLoadFunc = (holdData) => {
    if (holdData.civilStatusRequired === 'SINGLE') {
      const spouseSuffixInput = document.querySelectorAll('.spouse-suffix-temp')
      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
        markSpouseAsNA,
      } = spouseFields

      spouseSurname.disabled = true
      spouseFirstName.disabled = true
      spouseMiddleName.disabled = true
      spouseOccupation.disabled = true
      spouseEmployerName.disabled = true
      spouseEmployerAddress.disabled = true
      spouseEmployerTelephoneno.disabled = true

      if (spouseSuffixInput.length === 0) {
        createSpouseSuffixSpanTemp(spouseSuffixparentElement)
      }

      spouseSuffixparentElement.style.display = 'none'
      spouseSuffixparentElement.style.visibility = 'hidden'

      markSpouseAsNA.checked = true
      markSpouseAsNA.disabled = true
    }
  }

  const civilServiceLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-well4Well7DataGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Well7DataGrid table'
    )
    const well4Well7DataGrid = holdData.well4Well7DataGrid
    well4Well7DataGrid.forEach((dataPath, index) => {
      if (index < well4Well7DataGrid.length - 1) {
        const disableRemoveRowButton = foundTR[index].querySelector(
          '.formio-button-remove-row'
        )
        disableRemoveRowButton.disabled = true
      }

      if (dataPath.haveAnyEligibility === 'NO') {
        const parentElem = foundTR[index]
        const radioToDisable = parentElem.querySelectorAll(
          'input[type="radio"][value="NO"]'
        )
        const radioToEnable = parentElem.querySelectorAll(
          'input[type="radio"][value="YES"]'
        )
        radioToDisable.forEach((elem) => {
          elem.disabled = true
        })
        radioToEnable.forEach((elem) => {
          elem.disabled = false
        })

        const searchCheckboxParent = foundTR[index]

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
        )
        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-civilServiceAndOthersLicense'
        )
        const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
          '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
        )
        const radioDateValidityDisable = searchCheckboxParent.querySelectorAll(
          '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
        )
        radioDateExamDisable.forEach((elem) => {
          elem.disabled = true
          if (elem.value === 'N/A') {
            elem.checked = true
          }
        })
        radioDateValidityDisable.forEach((elem) => {
          elem.disabled = true
          if (elem.value === 'N/A') {
            elem.checked = true
          }
        })

        createElemSpanTemp(
          nonTextInputs,
          `CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE`
        )
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      }
    })

    const disableAddRowButton = foundTable.querySelector(
      '.formio-button-add-row'
    )

    const isValidValue = (value) =>
      value !== '' && value !== null && value !== undefined

    const allValuesPresent = well4Well7DataGrid.every((obj) =>
      Object.entries(obj).every(([key, value]) => {
        // Skip validation for empty dates when NA is set
        if (key === 'civilServiceLicenseValidityDate') {
          return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
            ? true
            : isValidValue(value)
        }

        if (key === 'civilServiceExamDate') {
          return obj.civilServiceExamDateIsNA === 'N/A'
            ? true
            : isValidValue(value)
        }

        if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
          return obj.civilServiceAndOthersLicense === 'OTHERS'
            ? isValidValue(value)
            : true
        }

        if (key === 'civilServiceAndOthersLicense') {
          if (value === 'OTHERS') {
            return isValidValue(
              obj.civilServiceAndOthersLicenseOthersEligibility
            )
          }
          return isValidValue(value)
        }

        if (key.includes('IsNA')) {
          return value === 'N/A' || value === 'enterDate'
        }

        return isValidValue(value)
      })
    )

    if (!allValuesPresent) {
      disableAddRowButton.disabled = true
    }
  }

  const handleIfChildrenLoadFunc = (holdData) => {
    const ifChildren = holdData.ifChildren

    const mainElement = document.querySelector('.formio-component-ifChildren')
    const yesRadio = mainElement.querySelectorAll(
      'input[type="radio"][value="YES"]'
    )
    const noRadio = mainElement.querySelectorAll(
      'input[type="radio"][value="NO"]'
    )

    if (ifChildren === 'NO') {
      yesRadio.forEach((elem) => {
        elem.disabled = false
      })
      noRadio.forEach((elem) => {
        elem.disabled = true
      })
    }

    if (ifChildren === 'YES') {
      yesRadio.forEach((elem) => {
        elem.disabled = true
      })
      noRadio.forEach((elem) => {
        elem.disabled = false
      })

      setTimeout(() => {
        const foundGrid = document.querySelector(
          '.formio-component-well3Well2DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = holdData.well3Well2DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      }, 500)
    }
  }

  const handleWorkExperienceLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-well4Well8DataGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Well8DataGrid table'
    )

    if (holdData?.well4Well8DataGrid?.length > 0) {
      const well4Well8DataGrid = holdData.well4Well8DataGrid
      well4Well8DataGrid.forEach((dataPath, index) => {
        if (index < well4Well8DataGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = well4Well8DataGrid.every((obj) =>
        Object.entries(obj).every(([key, value]) => {
          if (
            key === 'workPayGradeIsNA' &&
            value === 'selectSalaryGradeStepIncrement'
          ) {
            return (
              isValidValue(obj.workExperienceStepIncrement) &&
              isValidValue(obj.workExperiencePayGrade)
            )
          }

          if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
            return isValidValue(obj.workExperienceDateTo)
          }

          return isValidValue(value)
        })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      }
    }
  }

  const handleVoluntaryWorkLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-well4Well10DataGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Well10DataGrid table'
    )

    if (holdData?.well4Well10DataGrid?.length > 0) {
      const well4Well10DataGrid = holdData.well4Well10DataGrid

      well4Well10DataGrid.forEach((dataPath, index) => {
        if (index < well4Well10DataGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = well4Well10DataGrid.every((obj) =>
        Object.entries(obj).every(([key, value]) => {
          if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
            return (
              isValidValue(obj.voluntaryWorkDateFrom) &&
              isValidValue(obj.voluntaryWorkDateTo)
            )
          }

          return isValidValue(value)
        })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      }
    }
  }

  const handleLDTrainingsLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-well4Well11DataGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-well4Well11DataGrid table'
    )
    const encryptedData = localStorage.getItem('encryptedFormData')

    if (holdData?.well4Well11DataGrid?.length > 0 && encryptedData) {
      const well4Well11DataGrid = holdData.well4Well11DataGrid
      well4Well11DataGrid.forEach((dataPath, index) => {
        if (index < well4Well11DataGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = well4Well11DataGrid.every((obj) =>
        Object.entries(obj).every(([key, value]) => {
          if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
            return (
              isValidValue(obj.ldTrainingDateFrom) &&
              isValidValue(obj.ldTrainingDateTo)
            )
          }

          return isValidValue(value)
        })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      }
    }
  }

  const handleNonAcademicdistinctionsrecognitionLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-nonAcademicdistinctionsrecognitionGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-nonAcademicdistinctionsrecognitionGrid table'
    )

    if (holdData?.nonAcademicdistinctionsrecognitionGrid?.length > 0) {
      const nonAcademicdistinctionsrecognitionGrid =
        holdData.nonAcademicdistinctionsrecognitionGrid

      nonAcademicdistinctionsrecognitionGrid.forEach((dataPath, index) => {
        if (index < nonAcademicdistinctionsrecognitionGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = nonAcademicdistinctionsrecognitionGrid.every(
        (obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      } else {
        disableAddRowButton.disabled = false
      }
    }
  }

  const handleOrgMembershipLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-orgMembershipGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-orgMembershipGrid table'
    )

    if (holdData?.orgMembershipGrid?.length > 0) {
      const orgMembershipGrid = holdData.orgMembershipGrid

      orgMembershipGrid.forEach((dataPath, index) => {
        if (index < orgMembershipGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = orgMembershipGrid.every((obj) =>
        Object.entries(obj).every(([key, value]) => {
          return isValidValue(value)
        })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      } else {
        disableAddRowButton.disabled = false
      }
    }
  }

  const handleSkillsAndHobbiesLoadFunc = (holdData) => {
    const foundTR = document.querySelectorAll(
      '.formio-component-skillsAndHobbiesGrid tr'
    )
    const foundTable = document.querySelector(
      '.formio-component-skillsAndHobbiesGrid table'
    )

    if (holdData?.skillsAndHobbiesGrid?.length > 0) {
      const skillsAndHobbiesGrid = holdData.skillsAndHobbiesGrid

      skillsAndHobbiesGrid.forEach((dataPath, index) => {
        if (index < skillsAndHobbiesGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }
      })

      const disableAddRowButton = foundTable.querySelector(
        '.formio-button-add-row'
      )

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      const allValuesPresent = skillsAndHobbiesGrid.every((obj) =>
        Object.entries(obj).every(([key, value]) => {
          return isValidValue(value)
        })
      )

      if (!allValuesPresent) {
        disableAddRowButton.disabled = true
      } else {
        disableAddRowButton.disabled = false
      }
    }
  }

  const handleReferencesRemoveRowButtons = () => {
    try {
      const referencesGrid = document.querySelector(
        '.formio-component-referencesRequired'
      )
      const removeButtons = referencesGrid.querySelectorAll(
        '.formio-button-remove-row'
      )
      const addButton = referencesGrid.querySelector('.formio-button-add-row')

      if (addButton) {
        addButton.closest('td').remove()
      }

      if (removeButtons) {
        removeButtons.forEach((elem) => {
          const delTD = elem.closest('td')
          delTD.remove()
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Handles form field changes and updates related fields
   * @param {Object} e - Form change event
   * @param {string} e.changed.component.key - Changed field key
   * @param {any} e.changed.value - New field value
   * @returns {void}
   * @throws {Error} If field update fails
   */
  const handleChangeForm = (e) => {
    try {
      console.log(e)

      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )
      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )
      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )

      const firstCSERatingElem = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceRating]"]'
      )
      const firstCSEPOE = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceExamDateIsNA]"]'
      )

      const firstCSEPlace = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceExamPlace]"]'
      )

      const firstOtherEligibility = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceAndOthersLicenseOthersEligibility]"]'
      )

      const firstCSELicense = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceLicenseNo]"]'
      )

      const firstCSEDOV = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceLicenseValidityDateIsNA]"]'
      )

      const firstCSEExamDateInput = document.getElementsByClassName(
        'formio-component-civilServiceExamDate'
      )
      const firstCSELVD = document.getElementsByClassName(
        'formio-component-civilServiceLicenseValidityDate'
      )

      const firstCSEAddRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-addRow"]'
      )

      const firstCSERemoveRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-removeRow"]'
      )

      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
      } = spouseFields

      const fatherFields = callFatherFieldsFunc()
      const {
        fatherSurnameNaAllowed,
        fatherFirstnameNaAllowed,
        fatherMiddlenameNaAllowed,
        fatherSuffixparentElement,
      } = fatherFields

      const motherFields = callMotherFieldsFunc()
      const {
        motherSurnameNaAllowed,
        motherFirstnameNaAllowed,
        motherMiddlenameNaAllowed,
      } = motherFields

      if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === true
      ) {
        setHoldData(e.data)

        inputChangeHandler(
          permanentHouseBlockLotNoRequiredElem,
          e.data.residentialHouseBlockLotNoRequired
        )

        inputChangeHandler(
          permanentStreetRequiredElem,
          e.data.residentialStreetRequired
        )

        inputChangeHandler(
          permanentSubdivisionVillageElem,
          e.data.residentialSubdivisionVillageRequired
        )

        inputChangeHandler(
          permanentBarangayRequiredElem,
          e.data.residentialBarangayRequired
        )

        inputChangeHandler(
          permanentCityMunicipalityRequiredElem,
          e.data.residentialCityMunicipalityRequired
        )

        inputChangeHandler(
          permanentProvinceRequiredElem,
          e.data.residentialProvinceRequired
        )

        inputChangeHandler(
          permanentZipcodeElem,
          e.data.residentialZipcodeNaAllowed
        )

        setTimeout(() => {
          chooseProvinceButtonPermElem.disabled = true
          chooseCityButtonPermElem.disabled = true
          chooseBarangayButtonPermElem.disabled = true
          chooseZipCodeButtonPermElem.disabled = true
        }, 500)

        permanentHouseBlockLotNoRequiredElem.disabled = true
        permanentStreetRequiredElem.disabled = true
        permanentSubdivisionVillageElem.disabled = true
        permanentBarangayRequiredElem.disabled = true
        permanentCityMunicipalityRequiredElem.disabled = true
        permanentProvinceRequiredElem.disabled = true
        permanentZipcodeElem.disabled = true
      } else if (
        e?.changed?.component?.key === 'workExperienceAppointmentStatus'
      ) {
        for (const [index, element] of e.data.well4Well8DataGrid.entries()) {
          if (
            element.workExperienceAppointmentStatus === 'CONTRACT OF SERVICE' ||
            element.workExperienceAppointmentStatus === 'JOB ORDER'
          ) {
            element.workExperienceIsGovtService = 'NO'
            const workExperienceIsGovtService = document.querySelector(
              `input[name*="data[well4Well8DataGrid][${index}][workExperienceIsGovtService]"][value="NO"]`
            )

            if (workExperienceIsGovtService) {
              workExperienceIsGovtService.checked = true

              const event = new Event('change', { bubbles: true })
              workExperienceIsGovtService.dispatchEvent(event)
            }
          } else {
            element.workExperienceIsGovtService = 'YES'
            const workExperienceIsGovtService = document.querySelector(
              `input[name*="data[well4Well8DataGrid][${index}][workExperienceIsGovtService]"][value="YES"]`
            )

            if (workExperienceIsGovtService) {
              workExperienceIsGovtService.checked = true

              const event = new Event('change', { bubbles: true })
              workExperienceIsGovtService.dispatchEvent(event)
            }
          }
        }

        setHoldData(e.data)
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value === 'SINGLE'
      ) {
        handleCivilStatusRequiredToSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value !== 'SINGLE'
      ) {
        handleCivilStatusRequiredToNOTSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(spouseSurname, 'N/A')
        inputChangeHandler(spouseFirstName, 'N/A')
        inputChangeHandler(spouseMiddleName, 'N/A')
        inputChangeHandler(spouseOccupation, 'N/A')
        inputChangeHandler(spouseEmployerName, 'N/A')
        inputChangeHandler(spouseEmployerAddress, 'N/A')
        inputChangeHandler(spouseEmployerTelephoneno, 'N/A')

        spouseSuffixparentElement.style.display = 'none'

        e.data.spouseSuffixNaAllowed = 'N/A'

        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseMiddleName.disabled = true
        spouseEmployerTelephoneno.disabled = true

        createSpouseSuffixSpanTemp(spouseSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === false
      ) {
        removeSpouseSuffixSpanTemp()

        inputChangeHandler(spouseSurname, '')
        inputChangeHandler(spouseFirstName, '')
        inputChangeHandler(spouseMiddleName, '')
        inputChangeHandler(spouseOccupation, '')
        inputChangeHandler(spouseEmployerName, '')
        inputChangeHandler(spouseEmployerAddress, '')
        inputChangeHandler(spouseEmployerTelephoneno, '')

        spouseSuffixparentElement.style = undefined

        e.data.spouseSuffixNaAllowed = ''

        spouseSurname.disabled = false
        spouseFirstName.disabled = false
        spouseMiddleName.disabled = false
        spouseOccupation.disabled = false
        spouseEmployerName.disabled = false
        spouseEmployerAddress.disabled = false
        spouseMiddleName.disabled = false
        spouseEmployerTelephoneno.disabled = false
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(fatherSurnameNaAllowed, 'N/A')
        inputChangeHandler(fatherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(fatherMiddlenameNaAllowed, 'N/A')

        e.data.fatherSuffixNaAllowed = 'N/A'

        fatherSurnameNaAllowed.disabled = true
        fatherFirstnameNaAllowed.disabled = true
        fatherMiddlenameNaAllowed.disabled = true

        fatherSuffixparentElement.style.display = 'none'
        fatherSuffixparentElement.style.visibility = 'hidden'

        createFatherSuffixSpanTemp(fatherSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === false
      ) {
        removeFatherSuffixSpanTemp()

        inputChangeHandler(fatherSurnameNaAllowed, '')
        inputChangeHandler(fatherFirstnameNaAllowed, '')
        inputChangeHandler(fatherMiddlenameNaAllowed, '')

        fatherSuffixparentElement.style = undefined

        e.data.fatherSuffixNaAllowed = null

        fatherSurnameNaAllowed.disabled = false
        fatherFirstnameNaAllowed.disabled = false
        fatherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(motherSurnameNaAllowed, 'N/A')
        inputChangeHandler(motherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(motherMiddlenameNaAllowed, 'N/A')

        motherSurnameNaAllowed.disabled = true
        motherFirstnameNaAllowed.disabled = true
        motherMiddlenameNaAllowed.disabled = true
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === false
      ) {
        inputChangeHandler(motherSurnameNaAllowed, '')
        inputChangeHandler(motherFirstnameNaAllowed, '')
        inputChangeHandler(motherMiddlenameNaAllowed, '')

        motherSurnameNaAllowed.disabled = false
        motherFirstnameNaAllowed.disabled = false
        motherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp()
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp()
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-vocationalBasicEducationNaAllowed'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp()
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-vocationalBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        createElemSpanTemp(
          nonTextInputs,
          'BASIC EDUCATION/DEGREE/COURSE (Write in full)'
        )
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp()
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed'
        )
        createElemSpanTemp(
          nonTextInputs,
          'BASIC EDUCATION/DEGREE/COURSE (Write in full)'
        )
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp()
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed '
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })
      } else if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === false
      ) {
        setHoldData(e.data)

        chooseProvinceButtonPermElem.disabled = false
        chooseCityButtonPermElem.disabled = false
        chooseBarangayButtonPermElem.disabled = false
        chooseZipCodeButtonPermElem.disabled = false
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false
      } else if (
        (e?.changed?.component?.key === 'residentialHouseBlockLotNoRequired' ||
          e?.changed?.component?.key === 'residentialStreetRequired' ||
          e?.changed?.component?.key ===
            'residentialSubdivisionVillageRequired' ||
          e?.changed?.component?.key === 'residentialBarangayRequired' ||
          e?.changed?.component?.key ===
            'residentialCityMunicipalityRequired' ||
          e?.changed?.component?.key === 'residentialProvinceRequired' ||
          e?.changed?.component?.key === 'residentialZipcodeNaAllowed') &&
        e.data.sameAsResidentialAddressBox === true
      ) {
        const sameAsResidentialAddressBox = document.querySelector(
          'input[name="data[sameAsResidentialAddressBox]"]'
        )
        sameAsResidentialAddressBox.checked = false

        const event = new Event('change', { bubbles: true })
        sameAsResidentialAddressBox.dispatchEvent(event)
        chooseProvinceButtonPermElem.disabled = false
        chooseCityButtonPermElem.disabled = false
        chooseBarangayButtonPermElem.disabled = false
        chooseZipCodeButtonPermElem.disabled = false
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false

        inputChangeHandler(permanentHouseBlockLotNoRequiredElem, '')

        inputChangeHandler(permanentStreetRequiredElem, '')

        inputChangeHandler(permanentSubdivisionVillageElem, '')

        inputChangeHandler(permanentBarangayRequiredElem, '')

        inputChangeHandler(permanentCityMunicipalityRequiredElem, '')

        inputChangeHandler(permanentProvinceRequiredElem, '')

        inputChangeHandler(permanentZipcodeElem, '')

        setHoldData({
          ...e.data,
          sameAsResidentialAddressBox: false,
          [`${e?.changed?.component?.key}`]: e?.changed?.value,
          permanentHouseBlockLotNoRequired: '',
          permanentStreetRequired: '',
          permanentSubdivisionVillageRequired: '',
          permanentBarangayRequired: '',
          permanentCityMunicipalityRequired: '',
          permanentProvinceRequired: '',
          permanentZipcodeNaAllowed: '',
        })
      } else if (e?.changed?.component?.key === 'haveAnyEligibility') {
        if (e?.changed?.value === 'NO') {
          inputChangeHandler(firstCSERatingElem, 'N/A')
          inputChangeHandler(firstCSEPOE, 'N/A')
          inputChangeHandler(firstCSEPlace, 'N/A')
          inputChangeHandler(firstCSELicense, 'N/A')
          inputChangeHandler(firstCSEDOV, 'N/A')

          firstCSEExamDateInput[0].classList.add('formio-hidden')
          firstCSEExamDateInput[0].innerHTML = ''

          if (firstOtherEligibility?.parentNode?.parentNode) {
            firstOtherEligibility.parentNode.parentNode.remove()
          }

          firstCSELVD[0].classList.add('formio-hidden')
          firstCSELVD[0].innerHTML = ''

          firstCSEAddRowButton.disabled = true
          firstCSERemoveRowButton.disabled = true

          const updatedData = {
            ...e.data,
            well4Well7DataGrid: [
              {
                ...e.data.well4Well7DataGrid[0],
                civilServiceAndOthersLicense: 'N/A',
                civilServiceExamDateIsNA: 'N/A',
                civilServiceLicenseValidityDateIsNA: 'N/A',
                civilServiceLicenseValidityDate: '',
                civilServiceExamDate: '',
                civilServiceRating: 'N/A',
                civilServiceExamPlace: 'N/A',
                civilServiceLicenseNo: 'N/A',
                haveAnyEligibility: 'NO',
              },
            ],
          }

          setHoldData({
            ...updatedData,
            dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          })

          document.querySelectorAll('.pds-answer-form select').forEach((i) => {
            if (
              i &&
              i.getAttribute('name') ===
                'data[well4Well7DataGrid][0][civilServiceAndOthersLicense]'
            ) {
              const labelElement =
                i.parentNode.parentNode.parentNode.querySelector('label')
              labelElement.addEventListener('click', handleClick)

              let inputElement = document.createElement('input')
              inputElement.value = 'N/A'
              inputElement.setAttribute('aria-required', 'false')
              inputElement.setAttribute('spellcheck', 'N/A')
              inputElement.setAttribute('lang', 'en')
              inputElement.setAttribute('class', 'form-control')
              inputElement.setAttribute(
                'id',
                'civilServiceAndOthersLicenseTempInput'
              )
              inputElement.setAttribute('type', 'text')
              inputElement.setAttribute('ref', 'input')
              inputElement.setAttribute('aria-invalid', 'false')
              inputElement.setAttribute('disabled', '')
              i.parentNode.insertAdjacentElement('afterend', inputElement)
              i.parentNode.style.display = 'none'
            }
          })
        }

        if (e?.changed?.value === 'YES') {
          inputChangeHandler(firstCSERatingElem, '')
          inputChangeHandler(firstCSEPlace, '')
          inputChangeHandler(firstCSELicense, '')
          inputChangeHandler(firstCSEPOE, 'enterDate')
          inputChangeHandler(firstCSEDOV, 'enterDate')

          firstCSEAddRowButton.disabled = false
          firstCSERemoveRowButton.disabled = false

          firstCSEExamDateInput[0].classList.remove('formio-hidden')
          firstCSELVD[0].classList.remove('formio-hidden')

          const firstCSEDOV2 = document.querySelector(
            'input[name*="data[well4Well7DataGrid][0][civilServiceLicenseValidityDateIsNA]"][value="enterDate"]'
          )
          const firstCSEPOE2 = document.querySelector(
            'input[name*="data[well4Well7DataGrid][0][civilServiceExamDateIsNA]"][value="enterDate"]'
          )
          const firstCSEDOV3 = document.querySelector(
            'input[name*="data[well4Well7DataGrid][0][civilServiceLicenseValidityDateIsNA]"][value="N/A"]'
          )
          const firstCSEPOE3 = document.querySelector(
            'input[name*="data[well4Well7DataGrid][0][civilServiceExamDateIsNA]"][value="N/A"]'
          )

          if (firstCSEDOV2) {
            firstCSEDOV3.checked = false
            const event = new Event('change', { bubbles: false })
            firstCSEDOV3.dispatchEvent(event)

            firstCSEDOV2.checked = true
            const event2 = new Event('change', { bubbles: true })
            firstCSEDOV2.dispatchEvent(event2)
          }

          if (firstCSEPOE2) {
            firstCSEPOE3.checked = true
            const event = new Event('change', { bubbles: false })
            firstCSEPOE3.dispatchEvent(event)

            firstCSEPOE2.checked = true
            const event2 = new Event('change', { bubbles: true })
            firstCSEPOE2.dispatchEvent(event2)
          }

          const updatedData = {
            ...e.data,
            well4Well7DataGrid: [
              {
                ...e.data.well4Well7DataGrid[0],
                civilServiceAndOthersLicense: '',
                civilServiceRating: '',
                civilServiceExamPlace: '',
                civilServiceLicenseNo: '',
                civilServiceExamDateIsNA: 'enterDate',
                civilServiceLicenseValidityDateIsNA: 'enterDate',
              },
              ...e.data.well4Well7DataGrid.slice(1),
            ],
          }

          setHoldData({
            ...updatedData,
            dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          })

          document.querySelectorAll('.pds-answer-form select').forEach((i) => {
            if (
              i &&
              i.getAttribute('name') ===
                'data[well4Well7DataGrid][0][civilServiceAndOthersLicense]'
            ) {
              const labelElement =
                i.parentNode.parentNode.parentNode.querySelector('label')

              labelElement.removeEventListener('click', handleClick)

              let inputElement = document.getElementById(
                'civilServiceAndOthersLicenseTempInput'
              )

              if (inputElement) {
                inputElement.remove()
              }

              i.parentNode.style.display = 'block'
            }
          })
        }
      } else if (e?.isValid) {
        setHoldData(e.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleChangeProvinces
   * @param {string} value - The selected province value
   * @description Handles province selection changes in the address form.
   * Updates related city/municipality and barangay fields based on selection.
   * @returns {void}
   */
  const handleChangeProvinces = (value) => {
    const selectedArea = isProvincePerm
      ? provinceDataPerm[value]
      : provinceData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isProvincePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentProvinceRequired: selectedArea.name.toUpperCase(),
        permanentCityMunicipalityRequired: '',
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialProvinceRequired: selectedArea.name.toUpperCase(),
        residentialCityMunicipalityRequired: '',
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenProvince(false)

    // Check if the selected area is a region or a province
    if (selectedArea.geographic_level === 'Reg') {
      // If it's a region (like NCR), load cities directly
      loadAndTransformCityData(value)
    } else {
      // If it's a province, load municipalities and cities
      loadAndTransformMunicipalityData(value)
    }
  }

  /**
   * @function handleChangeCities
   * @param {string} value - The selected city value
   * @description Handles city/municipality selection changes.
   * Updates related barangay fields and triggers barangay data loading.
   * @returns {void}
   */
  const handleChangeCities = (value) => {
    const selectedArea = isCityPerm
      ? selectedCityDataPerm[value]
      : selectedCityData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isCityPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenCity(false)

    loadAndTransformBarangayData(value)
  }

  /**
   * @function handleChangeBarangay
   * @param {string} value - The selected barangay value
   * @description Handles barangay selection changes in the address form.
   * Updates form fields with selected barangay data.
   * @returns {void}
   */
  const handleChangeBarangay = (value) => {
    const selectedArea = isBarangayPerm
      ? allAreasDataPerm[value]
      : allAreasData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isBarangayPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    }

    setIsModalOpenBrgy(false)
  }

  /**
   * @function handleChangeZipcode
   * @param {Object} selectedOption - The selected zip code option object
   * @param {string} selectedOption.value - The zip code value
   * @param {string} selectedOption.label - The zip code label
   * @description Handles zip code selection changes and updates related address fields.
   * @returns {void}
   */
  const handleChangeZipcode = (selectedOption) => {
    const { value } = JSON.parse(selectedOption.value)
    if (isZipcodePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentZipcodeNaAllowed: value,
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialZipcodeNaAllowed: value,
      }))
    }
  }

  const zipcodeList = placeData.map((place, index) => ({
    value: place.post_code,
    label: `${place.municipality}, ${place.location} (${place.post_code})`,
    key: `${place.post_code}-${place.municipality}-${index}`,
    uniqueId: `${place.post_code}-${index}`,
  }))

  return (
    <div className='site-layout-background submitted-pds-page'>
      <Card>
        <Breadcrumb className={'font-Mont pds-breadcrumb'}>
          <Breadcrumb.Item>
            <b>Home</b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>View Submitted PDS Forms</b>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className={'mb-4'}>
          <Col span={24}>
            <div className='compTable' style={{ marginTop: '30px' }}>
              <Table
                className={'font-Mont'}
                rowKey='id'
                bordered
                dataSource={userDataSheets}
                columns={columns}
              />
            </div>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          width={'75vw'}
          bodyStyle={{
            height: '78vh', // This limits the body height to 70% of viewport height
            overflow: 'auto', // Makes the body scrollable
            position: 'relative', // Required for BackTop positioning
          }}
          title='Personal Data Sheet'
          open={isModalOpen}
          onCancel={() => {
            setMunicipalityList([])
            setBarangayList([])
            setIsModalOpen(false)
          }}
          footer={[]}
          destroyOnClose={true}
        >
          <>{!formIsFinal ? <PDSGuide /> : null}</>
          <>{!formIsFinal ? <PDSVideo /> : null}</>
          <div>
            {!formIsFinal ? (
              <UploadPds exportData={handleExportData} exportFrom={'draft'} />
            ) : null}
          </div>
          <div className='pds-answer-form'>
            {formIsFinal ? (
              <div className='submitted-pds-page-modal'>
                <Form
                  onRender={handleSubmitButton}
                  submission={{ data: holdData }}
                  src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
                  onCustomEvent={(e) => handleSignType(e.type)}
                />
              </div>
            ) : (
              <div>
                <Form
                  onRender={handleImages}
                  onChange={(e) => handleChangeForm(e)}
                  onSubmit={(e) => showModal(e)}
                  submission={{ data: holdData }}
                  src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
                  onCustomEvent={(e) => handleSignType(e.type)}
                />
                <BackTop
                  target={() => document.querySelector('.ant-modal-body')}
                  visibilityHeight={100}
                  style={{
                    right: '25%',
                    bottom: 35,
                    position: 'fixed', // Changed to fixed
                  }}
                >
                  <div className='pds-backtop-div'>BACK TO TOP</div>
                </BackTop>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          maskClosable={false}
          title='Personal Data Sheet'
          open={isModalOpenEdit}
          onCancel={() => setIsModalOpenEdit(false)}
          footer={[]}
          destroyOnClose={true}
        >
          <div style={{ textAlign: 'center' }}>
            <Button
              className={'me-4'}
              size='large'
              style={{ backgroundColor: '#635380' }}
              key='draft'
              ghost
              onClick={() => handleSaveAsDraft(false)}
              disabled={isDisabledAfterSave}
            >
              Save as draft
            </Button>
            <Button
              size='large'
              style={{ backgroundColor: '#87C38F' }}
              key='final'
              ghost
              onClick={() => handleSaveAsFinal(true)}
              disabled={isDisabledAfterSave}
            >
              Save as final
            </Button>
          </div>
        </Modal>

        <Modal
          maskClosable={false}
          title='Remarks of Department Admin'
          open={isModalOpenEditApprover}
          onCancel={() => setIsModalOpenEditApprover(false)}
          footer={[]}
          destroyOnClose={true}
          width={'50vw'}
        >
          <Card>
            {approverRemarks?.length ? (
              approverRemarks.map((i) => (
                <div key={uuidv4()} className='mb-4'>
                  <p>
                    <b>Date: </b>
                    {i.dateOfRemarks}
                  </p>
                  <div>
                    <b>Remarks: </b>
                    <RenderRemarksContent remarks={i.commentsOfApprover} />
                  </div>
                </div>
              ))
            ) : (
              <span>no remarks</span>
            )}
          </Card>
        </Modal>

        <Modal
          maskClosable={false}
          title='Remarks of HR Admin'
          open={isModalOpenEditHRApprover}
          onCancel={() => setIsModalOpenEditHRApprover(false)}
          footer={[]}
          width={'50vw'}
        >
          <Card>
            {hrApproverRemarks.length ? (
              hrApproverRemarks.map((i) => {
                return (
                  <div key={uuidv4()} className='mb-4'>
                    <p>
                      <b>Date: </b>
                      {`${i.dateOfRemarks}`}
                    </p>
                    <p>
                      <b>Remarks: </b>
                      <RenderRemarksContent remarks={i.commentsOfHRApprover} />
                    </p>
                  </div>
                )
              })
            ) : (
              <span>no remarks</span>
            )}
          </Card>
        </Modal>
      </Card>

      <Modal
        maskClosable={false}
        title='Personal Data Sheet (Right Thumbmark) Agreement'
        open={isModalOpenThumbmark}
        onCancel={() => setIsModalOpenThumbmark(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <div style={{ textAlign: 'center' }}>
          <input
            type='file'
            ref={fileInputRefThumb}
            style={{ display: 'none' }}
            onChange={handleFileChangeThumb}
            accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
          />
          <Button
            onClick={() => fileInputRefThumb.current.click()}
            type='primary'
            size='large'
            key='final'
            ghost
          >
            Agree and Upload
          </Button>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Personal Data Sheet (e-Signature) Agreement'
        open={isModalOpenESign}
        onCancel={() => setIsModalOpenESign(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <div style={{ textAlign: 'center' }}>
          <input
            type='file'
            ref={fileInputRefESign}
            style={{ display: 'none' }}
            onChange={handleFileChangeESign}
            accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
          />
          <Button
            onClick={() => fileInputRefESign.current.click()}
            type='primary'
            size='large'
            key='final'
            ghost
          >
            Agree and Upload
          </Button>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Personal Data Sheet (Passport Photo) Agreement'
        open={isModalOpenPassportPhoto}
        onCancel={() => setIsModalOpenPassportPhoto(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <div style={{ textAlign: 'center' }}>
          <input
            type='file'
            ref={fileInputRefPassportPhoto}
            style={{ display: 'none' }}
            onChange={handleFileChangePassportPhoto}
            accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
          />
          <Button
            onClick={() => fileInputRefPassportPhoto.current.click()}
            type='primary'
            size='large'
            key='final'
            ghost
          >
            Agree and Upload
          </Button>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Choose Province'
        open={isModalOpenProvince}
        onCancel={() => setIsModalOpenProvince(false)}
        footer={[]}
        destroyOnClose
        width={900}
      >
        <div style={{ textAlign: 'center' }}>
          <Select
            style={{
              width: 800,
            }}
            size='large'
            showSearch
            placeholder='Select a province'
            optionFilterProp='children'
            onChange={handleChangeProvinces}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={regionGroupList}
          />
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Choose City or Municipality'
        open={isModalOpenCity}
        onCancel={() => setIsModalOpenCity(false)}
        footer={[]}
        destroyOnClose
        width={900}
      >
        <div style={{ textAlign: 'center' }}>
          <Select
            style={{
              width: 800,
            }}
            size='large'
            showSearch
            placeholder='Select a city or municipality'
            optionFilterProp='children'
            onChange={handleChangeCities}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={isCityPerm ? municipalityListPerm : municipalityList}
          />
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Choose Barangay'
        open={isModalOpenBrgy}
        onCancel={() => setIsModalOpenBrgy(false)}
        footer={[]}
        destroyOnClose
        width={900}
      >
        <div style={{ textAlign: 'center' }}>
          <Select
            style={{
              width: 800,
            }}
            size='large'
            showSearch
            placeholder='Select a barangay'
            optionFilterProp='children'
            onChange={handleChangeBarangay}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={isBarangayPerm ? barangayListPerm : barangayList}
          />
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title='Choose Zip Code'
        open={isModalOpenZipcode}
        onCancel={() => setIsModalOpenZipcode(false)}
        footer={[]}
        destroyOnClose
        width={900}
      >
        <div style={{ textAlign: 'center' }}>
          <Select
            style={{
              width: 800,
            }}
            size='large'
            showSearch
            placeholder='Select a place'
            optionFilterProp='children'
            onChange={handleChangeZipcode}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
          >
            {zipcodeList.map((item) => (
              <Option
                key={item.key}
                value={JSON.stringify({
                  value: item.value,
                  label: item.label,
                })}
                className='option-item'
              >
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        title={
          <div className='pds-remarks-delete-row-div'>
            <ExclamationCircleOutlined style={{ color: '#faad14' }} />
            {'Delete draft item?'}
          </div>
        }
        open={isOpenDeleteDraftModal}
        onCancel={() => {
          setIsOpenDeleteDraftModal(false)
          setToBeDeletedDraft(null)
        }}
        onOk={() => handleDeletePDS(toBeDeletedDraft)}
        okText='Delete draft'
        cancelText='Cancel'
        destroyOnClose
        okButtonProps={{
          danger: true,
          style: {
            backgroundColor: '#ff4d4f',
            borderColor: '#ff4d4f',
          },
        }}
      >
        <p style={{ fontSize: '16px', marginBottom: '16px' }}>
          Are you sure you want to delete this draft? This action cannot be
          undone.
        </p>
      </Modal>

      <PDFModal
        record={recordData}
        isOpenPDF={isOpenPDF}
        setIsOpenPDF={(e) => setIsOpenPDF(e)}
        user={'employee'}
      />
    </div>
  )
}
