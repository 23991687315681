import React, { useState } from 'react'

import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Modal,
  Row,
  Select,
  notification,
} from 'antd'
import PropTypes from 'prop-types'

// Add at the top with other imports
const MIN_AGE = 18
const MAX_AGE = 100

// Add after imports
const VALID_CIVIL_STATUSES = {
  SINGLE: 'SINGLE',
  MARRIED: 'MARRIED',
  WIDOWED: 'WIDOWED',
  SEPARATED: 'SEPARATED',
  OTHERS: 'OTHERS',
}

const MindscapesModal = ({ open, onClose, userData }) => {
  const [birthday, setBirthday] = useState(null)
  const [birthdayString, setBirthdayString] = useState(null)
  const [civilStatus, setCivilStatus] = useState(null)
  const [validationError, setValidationError] = useState('')
  const [capturedData, setCapturedData] = useState({})

  const [openProceedModal, setOpenProceedModal] = useState(false)
  const [displayData, setDisplayData] = useState([])

  /**
   * Validates if a person's age (calculated from birth date) falls within the allowed range.
   * The age is calculated by comparing the birth date with the current date, taking into
   * account months and days for accurate age calculation.
   *
   * @param {string} birthDate - Birth date string in 'YYYY-MM-DD' format (e.g., '1990-01-31')
   * @returns {boolean} True if the calculated age is between MIN_AGE and MAX_AGE (inclusive),
   *                   false otherwise
   * @throws {Error} If birthDate is invalid or not in correct format
   *
   * @example
   * // Returns true if age is within range
   * validateAge('1990-01-01')
   *
   * @see MIN_AGE - Minimum allowed age (defined as constant)
   * @see MAX_AGE - Maximum allowed age (defined as constant)
   */
  const validateAge = (birthDate) => {
    const today = new Date()
    const birth = new Date(birthDate)
    let age = today.getFullYear() - birth.getFullYear()
    const monthDiff = today.getMonth() - birth.getMonth()

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--
    }

    return age >= MIN_AGE && age <= MAX_AGE
  }

  /**
   * Validates form inputs for birthday and civil status.
   * Performs validation checks and sets validation error messages.
   *
   * @returns {boolean} True if all inputs are valid, false otherwise
   * @see validateAge - Used for age validation
   * @see VALID_CIVIL_STATUSES - Object containing valid civil status values
   * @see MIN_AGE - Minimum allowed age
   * @see MAX_AGE - Maximum allowed age
   *
   * @example
   * if (validateInputs()) {
   *   // proceed with form submission
   * }
   *
   * @sideEffect Sets validation error message via setValidationError
   * @dependencies birthdayString, civilStatus - Form input state values
   */
  const validateInputs = () => {
    let errors = []

    if (!birthdayString) {
      errors.push('Birthday is required.')
    } else if (!validateAge(birthdayString)) {
      errors.push(`Age must be between ${MIN_AGE} and ${MAX_AGE} years.`)
    }

    if (!civilStatus) {
      errors.push('Civil status is required.')
    } else if (!VALID_CIVIL_STATUSES[civilStatus]) {
      errors.push('Invalid civil status selected.')
    }

    if (errors.length > 0) {
      setValidationError(errors.join(' '))
      return false
    }

    setValidationError('')
    return true
  }

  const handleCloseMainModal = () => {
    onClose(false)
    setBirthday(null)
    setBirthdayString(null)
    setCivilStatus(null)
    setValidationError('')
    setCapturedData({})
    setDisplayData([])
  }

  const handleCloseSecondModal = () => {
    setOpenProceedModal(false)
    setBirthday(null)
    setBirthdayString(null)
    setCivilStatus(null)
    setValidationError('')
    setCapturedData({})
    setDisplayData([])
  }

  /**
   * Handles form submission and data preparation for the next step.
   * Validates inputs, prepares display data, and updates application state.
   *
   * @returns {void}
   * @throws {never} Does not throw errors, handles validation failures via UI notification
   *
   * @dependencies {object} userData - User profile data (name, lname, username, contact)
   * @dependencies {string} birthdayString - Selected birthday
   * @dependencies {string} civilStatus - Selected civil status
   *
   * @sideEffect Sets displayData with formatted user information array
   * @sideEffect Sets capturedData with combined form and user data
   * @sideEffect Opens proceed modal on successful validation
   * @sideEffect Shows error notification on validation failure
   *
   * @example
   * // Called from form submit or button click
   * handleProceed()
   */
  const handleProceed = () => {
    if (validateInputs()) {
      const formData = {
        birthday: birthdayString,
        civilStatus,
      }

      const displayDataArray = [
        {
          key: '1',
          label: 'First name',
          children: userData.name,
        },
        {
          key: '2',
          label: 'Last name',
          children: userData.lname,
        },
        {
          key: '3',
          label: 'Email',
          children: userData.username,
        },
        {
          key: '4',
          label: 'Mobile Number',
          children: userData.contact,
        },
        {
          key: '5',
          label: 'Birthday',
          children: birthdayString,
        },
        {
          key: '6',
          label: 'Civil Status',
          children: civilStatus,
        },
      ]

      setDisplayData(displayDataArray)
      setCapturedData({
        ...formData,
        email: userData.username,
        name: userData.name,
        lastName: userData.lname,
        mobileNumber: userData.contact,
      })

      setOpenProceedModal(true)
    } else {
      notification['error']({
        message: 'Validation Error',
        description: 'Please fill in all the fields.',
      })
    }
  }

  /**
   * Handles user agreement submission and final form processing.
   * Validates inputs, submits data, and performs cleanup actions.
   *
   * @returns {void}
   * @throws {never} Does not throw errors, handles validation failures via UI notification
   *
   * @dependencies {object} capturedData - Collected form data
   * @dependencies {function} validateInputs - Input validation function
   *
   * @sideEffect Clears form state (birthday, civilStatus, validation)
   * @sideEffect Closes modal windows
   * @sideEffect Opens Mindscapes external URL
   * @sideEffect Shows error notification on validation failure
   *
   * @example
   * // Called when user clicks agree button
   * handleAgree()
   */
  const handleAgree = () => {
    if (validateInputs()) {
      // Send capturedData to the Mindscapes server
      console.log('Sending data to Mindscapes server:', capturedData)
      onClose(false)
      setOpenProceedModal(false)
      window.open(
        'https://dev.mindscapes.com.ph/member/?SSOTokenID=60812A08-066E-452A-9E57-00EC1AC45875',
        '_blank'
      )
      setBirthday(null)
      setBirthdayString(null)
      setCivilStatus(null)
      setValidationError('')
      setCapturedData({})
      setDisplayData([])
    } else {
      notification['error']({
        message: 'Validation Error',
        description: 'Please fill in all the fields.',
      })
    }
  }

  /**
   * Handles birthday input changes and validates age requirements.
   *
   * @param {Date|null} date - Date object from date picker
   * @param {string} dateString - Date string in YYYY-MM-DD format
   * @returns {void}
   * @throws {never} Catches and handles all errors via UI
   *
   * @dependencies {function} validateAge - Age validation function
   * @dependencies {number} MIN_AGE - Minimum allowed age
   * @dependencies {number} MAX_AGE - Maximum allowed age
   *
   * @sideEffect Updates birthday state
   * @sideEffect Updates birthdayString state
   * @sideEffect Sets validation error messages
   *
   * @example
   * // Called from date picker onChange
   * handleBirthday(new Date(), '1990-01-01')
   */
  const handleBirthday = (date, dateString) => {
    try {
      if (date) {
        const isValidAge = validateAge(dateString)
        if (!isValidAge) {
          setValidationError(
            `Age must be between ${MIN_AGE} and ${MAX_AGE} years.`
          )
          setBirthday(null)
          setBirthdayString(null)
          return
        }
      }
      setBirthday(date)
      setBirthdayString(dateString)
      setValidationError('')
    } catch (err) {
      console.error('Error processing date:', err)
      setValidationError('Invalid date format')
    }
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        title='Mindscapes Modal'
        open={open}
        onCancel={handleCloseMainModal}
        footer={[
          <Button key='cancel' onClick={() => onClose(false)}>
            Cancel
          </Button>,
          <Button key='proceed' type='primary' onClick={handleProceed}>
            Proceed
          </Button>,
        ]}
      >
        <Row className='mb-3'>
          <Col span={24}>
            <Row>
              <label htmlFor='birthday'>Birthday:</label>
            </Row>
            <Row>
              <DatePicker
                style={{
                  width: '100%',
                }}
                id='birthday'
                value={birthday}
                onChange={handleBirthday}
                disabledDate={(current) => {
                  // Convert the current moment to JS Date for comparison
                  return current && current.valueOf() > Date.now()
                }}
                placeholder='Select birthday'
                format='YYYY-MM-DD'
              />
            </Row>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col span={24}>
            <Row>
              <label htmlFor='civilStatus'>Civil Status:</label>
            </Row>
            <Row>
              <Select
                id='civilStatus'
                value={civilStatus}
                onChange={(value) => {
                  if (VALID_CIVIL_STATUSES[value]) {
                    setCivilStatus(value)
                    setValidationError('')
                  } else {
                    setValidationError('Invalid civil status selected')
                  }
                }}
                style={{ width: '100%' }}
              >
                {Object.values(VALID_CIVIL_STATUSES).map((status) => (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        {validationError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {validationError}
          </div>
        )}
      </Modal>
      <Modal
        destroyOnClose={true}
        width={800}
        title='User Details'
        open={openProceedModal}
        onCancel={() => setOpenProceedModal(false)}
        footer={[
          <Button key='cancel' onClick={handleCloseSecondModal}>
            Cancel
          </Button>,
          <Button key='proceed' type='primary' onClick={handleAgree}>
            Agree
          </Button>,
        ]}
      >
        <Card style={{ width: '100%', margin: '0 auto' }}>
          <Descriptions bordered column={1}>
            {displayData.map((item) => (
              <Descriptions.Item key={item.key} label={<b>{item.label}</b>}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Card>
        <Row className='mt-3'>
          <b>Disclaimer</b>
        </Row>
        <Row className='mt-3'>
          <span>
            <span className='ms-3'>By</span> clicking the <b>"Agree"</b> button,
            you confirm that you understand and accept that your details will be
            shared with a third-party website. We are not responsible for the
            privacy practices, security, or content of third-party websites.
            Once you leave our platform, their terms and policies will apply.
            Please review their privacy policy and terms of service before
            providing any personal information. If you do not agree, please do
            not proceed.
          </span>
        </Row>
        <Row className='mt-3'>
          <span>
            If you <b>do not agree</b>, please do not proceed.
          </span>
        </Row>
      </Modal>
    </>
  )
}
MindscapesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    name: PropTypes.string,
    lname: PropTypes.string,
    username: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
}

export default MindscapesModal
