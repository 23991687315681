/**
 * Title: PDSuploadView.js
 * Description: This is a file that contains the components for the PDF Modal of the Personal Data Sheet that renders the PDF.
 * Authors:
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSuploadView.js
 **/

/*
 *Changes made:
 *2024.08.29  | Raymart Mojado | view for the uploaded pds Excel
 */
import { useEffect, useState } from 'react'

import { notification } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Document,
  Font,
  PDFViewer,
  Page,
  Path,
  Rect,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer'

import OpenSansBoldItalic from '../../images/static/OpenSans-BoldItalic.ttf'
import OpenSansExtraBold from '../../images/static/OpenSans-ExtraBold.ttf'
import PTSansItalic from '../../images/static/OpenSans-Italic.ttf'
import PTSansNarrowBold from '../../images/static/PTSansNarrow-Bold.ttf'
import PTSansNarrow from '../../images/static/PTSansNarrow-Regular.ttf'
import RobotoBoldItalic from '../../images/static/Roboto-BoldItalic.ttf'
import { styles } from '../PDS/PDFStylesheet'

// Register font
Font.register({
  family: 'OpenSans-ExtraBold',
  src: OpenSansExtraBold,
})

Font.register({
  family: 'Roboto',
  src: RobotoBoldItalic,
  fontWeight: 'italic-bold',
})

Font.register({
  family: 'OpenSansBoldItalic',
  fonts: [
    {
      src: OpenSansBoldItalic,
    },
  ],
})

Font.register({
  family: 'PTSansNarrow',
  fonts: [
    {
      src: PTSansNarrow, // Regular
    },
    {
      src: PTSansItalic, // Italic
      fontStyle: 'italic',
    },
    {
      src: OpenSansBoldItalic, // Bold Italic
      fontStyle: 'italic-bold',
    },
    {
      src: PTSansNarrowBold, // Bold
      fontWeight: 'bold',
    },
  ],
})

// PDSuploadView.propTypes = {
//   data: PropTypes.shape({
//     skillsAndHobies: PropTypes.array,
//     skillsAndHobbiesGrid: PropTypes.array,
//     nonAcademicdistinctionsrecognition: PropTypes.array,
//     nonAcademicdistinctionsrecognitionGrid: PropTypes.array,
//     orgMembership: PropTypes.array,
//     orgMembershipGrid: PropTypes.array,
//     well4Well8DataGrid: PropTypes.array,
//     well4Well7DataGrid: PropTypes.array,
//     well4DataGrid2: PropTypes.array,
//     well4DataGrid: PropTypes.array,
//     well4Levelvocationaltradecourse: PropTypes.array,
//     well4DataGrid3: PropTypes.array,
//     well4Well5Levelgraduatestudies: PropTypes.array,
//     well3Well2DataGrid: PropTypes.array,
//     count: PropTypes.number,
//     workExperienceCompany: PropTypes.string,
//   }).isRequired,
// };

export default function PDSuploadView({ data, isDoneButton }) {
  const [pdfData, setPdfData] = useState(null)
  const [isDone, setIsDone] = useState(true)

  const [otherInformationData, setOtherInformationData] = useState([])
  const [otherInforCount, setOtherInforCount] = useState(0)
  const [otherInforCountNextPage, setOtherInforCountNextPage] = useState(0)
  const [workExperienceData, setWorkExperienceData] = useState([])
  const [workExperienceCount, setWorkExperienceCount] = useState(0)
  const [workExperienceNextPage, setWorkExperienceNextPage] = useState([])
  const [civilServiceData, setCivilServiceData] = useState([])
  const [civilServiceDataCount, setCivilServiceDataCount] = useState(0)
  const [civilServiceDataNextPage, setCivilServiceDataNextPage] = useState([])

  /**
   *@function uuidv4
   *@description Generates a random id.
   *@param {Object} none No parameter needed.
   *@returns {Object} Returns a random id.
   */
  function uuidv4() {
    try {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)

      return (
        array[0].toString(16).padStart(8, '0') +
        '-' +
        array[1].toString(16).padStart(4, '0') +
        '-4' +
        array[2].toString(12).padStart(3, '0') +
        '-y' +
        array[3].toString(12).padStart(3, '0') +
        '-' +
        array[4].toString(16).padStart(12, '0')
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getHeight = (count) => {
    try {
      switch (count) {
        case 7:
          return 84
        case 6:
          return 72
        case 5:
          return 60
        case 4:
          return 48
        case 3:
          return 36
        case 2:
          return 24
        default:
          return 12
      }
    } catch (error) {
      return 12 // Default height in case of error
    }
  }

  const processRecord = async () => {
    if (data) {
      try {
        const parsedData = data
        setPdfData(parsedData)
        await getAllCombinedData(parsedData)
        await generateWorkExperienceData(parsedData)
        await generateCivilServiceData(parsedData)

        setTimeout(() => {
          setIsDone(true)
          isDoneButton(true)
        }, 2000)
      } catch (error) {
        console.error('Error processing record:', error)
        setIsDone(false)
        notification.error({
          message: 'Error',
          description:
            'An error occurred while processing the record. Contact the System Admin for help.',
        })
      }
    }
  }

  useEffect(() => {
    processRecord()
  }, [data])

  const getAllCombinedData = async (pdfData) => {
    try {
      const maxLength = Math.max(
        pdfData.skillsAndHobies?.length || 0,
        pdfData.skillsAndHobbiesGrid?.length || 0,
        pdfData.nonAcademicdistinctionsrecognition?.length || 0,
        pdfData.nonAcademicdistinctionsrecognitionGrid?.length || 0,
        pdfData.orgMembership?.length || 0,
        pdfData.orgMembershipGrid?.length || 0
      )

      const combinedData = []
      let currentChunk = []
      let currentCount = 0
      let isFirstChunk = true

      for (let i = 0; i < maxLength; i++) {
        let skillsAndHobbies =
          pdfData.skillsAndHobies && pdfData.skillsAndHobies.length > i
            ? pdfData.skillsAndHobies[i]
            : ''
        if (
          !skillsAndHobbies &&
          pdfData.skillsAndHobbiesGrid &&
          pdfData.skillsAndHobbiesGrid.length > i
        ) {
          skillsAndHobbies = pdfData.skillsAndHobbiesGrid[i].skillsAndHobbies
        }

        let nonAcademicDistinctions = ' '

        if (
          pdfData.nonAcademicdistinctionsrecognitionGrid &&
          pdfData.nonAcademicdistinctionsrecognitionGrid.length > i
        ) {
          nonAcademicDistinctions =
            pdfData.nonAcademicdistinctionsrecognitionGrid[i]
              .nonAcademicdistinctionsrecognition
        }

        let orgMembership = ' '

        if (pdfData.orgMembership && pdfData.orgMembership.length > i) {
          orgMembership = pdfData.orgMembership[i]
        } else if (
          pdfData.orgMembershipGrid &&
          pdfData.orgMembershipGrid.length > i
        ) {
          orgMembership = pdfData.orgMembershipGrid[i].orgMembership
        }

        let count

        if (
          skillsAndHobbies.length >= 233 ||
          nonAcademicDistinctions.length >= 370 ||
          orgMembership.length >= 240
        ) {
          count = 4
        } else if (
          skillsAndHobbies.length >= 57 ||
          nonAcademicDistinctions.length >= 232 ||
          orgMembership.length >= 61
        ) {
          count = 3
        } else if (
          skillsAndHobbies.length >= 30 ||
          nonAcademicDistinctions.length >= 62 ||
          orgMembership.length >= 33
        ) {
          count = 2
        } else {
          count = 1
        }

        if (
          isFirstChunk &&
          currentCount + count > 7 &&
          currentChunk.length > 0
        ) {
          combinedData.push(currentChunk)
          currentChunk = []
          currentCount = 0
          isFirstChunk = false
        }

        currentChunk.push({
          skillsAndHobbies,
          nonAcademicDistinctions,
          orgMembership,
          count,
        })
        currentCount += count
      }

      // Push the remaining items if any
      if (currentChunk.length > 0) {
        combinedData.push(currentChunk)
      }

      if (combinedData && combinedData.length > 0) {
        let totalCount = 0
        combinedData[0].forEach((data) => (totalCount += data.count))
        setOtherInforCount(totalCount)
      }

      if (combinedData && combinedData.length > 1) {
        let totalCount = 0
        combinedData[1].forEach((data) => (totalCount += data.count))
        setOtherInforCountNextPage(totalCount)
      }

      setOtherInformationData(combinedData)
      return combinedData
    } catch (error) {
      console.error('Error combining data:', error)
      return []
    }
  }

  const generateWorkExperienceData = async (pdfData) => {
    try {
      if (pdfData.well4Well8DataGrid) {
        let count = 0
        const workExperienceData = []
        const remainingData = []

        for (const data of pdfData.well4Well8DataGrid) {
          const workExperienceCompanyLength = data.workExperienceCompany
            ? data.workExperienceCompany.length
            : 0
          const workExperiencePositionTitleLength =
            data.workExperiencePositionTitle
              ? data.workExperiencePositionTitle.length
              : 0
          const workExperienceStatus = data.workExperienceAppointmentStatus
            ? data.workExperienceAppointmentStatus.length
            : 0

          let itemCount = 1
          if (
            workExperienceCompanyLength >= 126 ||
            workExperiencePositionTitleLength >= 125 ||
            workExperienceStatus >= 37
          ) {
            itemCount = 6
          } else if (
            workExperienceCompanyLength >= 91 ||
            workExperiencePositionTitleLength >= 90 ||
            workExperienceStatus >= 30
          ) {
            itemCount = 5
          } else if (
            workExperienceCompanyLength >= 64 ||
            workExperiencePositionTitleLength >= 63 ||
            workExperienceStatus >= 22
          ) {
            itemCount = 4
          } else if (
            workExperienceCompanyLength >= 56 ||
            workExperiencePositionTitleLength >= 55 ||
            workExperienceStatus >= 22
          ) {
            itemCount = 3
          } else if (
            workExperienceCompanyLength >= 32 ||
            workExperiencePositionTitleLength >= 25 ||
            workExperienceStatus >= 13
          ) {
            itemCount = 2
          }

          count += itemCount
          const dataWithCount = { ...data, count: itemCount }
          if (count <= 61) {
            workExperienceData.push(dataWithCount)
          } else {
            remainingData.push(data)
          }
        }

        const workExperienceDataSorted = workExperienceData.sort(
          (a, b) =>
            new Date(b.workExperienceDateFrom) -
            new Date(a.workExperienceDateFrom)
        )

        const chunkArray = (array, maxCount) => {
          const result = []
          let currentChunk = []
          let currentCount = 0

          array.forEach((data) => {
            const workExperienceCompanyLength = data.workExperienceCompany
              ? data.workExperienceCompany.length
              : 0
            const workExperiencePositionTitleLength =
              data.workExperiencePositionTitle
                ? data.workExperiencePositionTitle.length
                : 0
            const workexperienceAppointmentLength = data.experienceAppointment
              ? data.experienceAppointment.length
              : 0
            let itemCount = 1

            if (
              workExperienceCompanyLength >= 125 ||
              workExperiencePositionTitleLength >= 126 ||
              workexperienceAppointmentLength >= 37
            ) {
              itemCount = 6
            } else if (
              workExperienceCompanyLength >= 91 ||
              workExperiencePositionTitleLength >= 90 ||
              workexperienceAppointmentLength >= 30
            ) {
              itemCount = 5
            } else if (
              workExperienceCompanyLength >= 64 ||
              workExperiencePositionTitleLength >= 63 ||
              workexperienceAppointmentLength >= 22
            ) {
              itemCount = 4
            } else if (
              workExperienceCompanyLength >= 56 ||
              workExperiencePositionTitleLength >= 55 ||
              workexperienceAppointmentLength >= 22
            ) {
              itemCount = 3
            } else if (
              workExperienceCompanyLength >= 32 ||
              workExperiencePositionTitleLength >= 25 ||
              workexperienceAppointmentLength >= 13
            ) {
              itemCount = 2
            }

            if (currentCount + itemCount > maxCount) {
              result.push(currentChunk)
              currentChunk = []
              currentCount = 0
            }

            currentChunk.push({ ...data, count: itemCount })
            currentCount += itemCount
          })

          if (currentChunk.length > 0) {
            result.push(currentChunk)
          }

          return result
        }

        const categorizedRemainingData = chunkArray(remainingData, 65).map(
          (chunk, index) => {
            let chunkCount = 0
            chunk.forEach((data) => {
              chunkCount += data.count
            })
            return { chunk, totalCount: chunkCount }
          }
        )
        const totalItemCountFirstPage = workExperienceDataSorted.reduce(
          (acc, item) => acc + item.count,
          0
        )

        setWorkExperienceData(workExperienceDataSorted)
        setWorkExperienceCount(totalItemCountFirstPage)
        setWorkExperienceNextPage(categorizedRemainingData)
      } else {
        setWorkExperienceData([])
        setWorkExperienceCount(0)
        setWorkExperienceNextPage([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const generateCivilServiceData = async (pdfData) => {
    try {
      if (pdfData.well4Well7DataGrid) {
        let count = 0
        const civilServiceData = []
        const remainingData = []

        for (const data of pdfData.well4Well7DataGrid) {
          const civilServiceExamPlaceCompanyLength = data.civilServiceExamPlace
            ? data.civilServiceExamPlace.length
            : 0
          const civilServiceAndOthersLicenseOthersEligibilityLength =
            data.civilServiceAndOthersLicenseOthersEligibility
              ? data.civilServiceAndOthersLicenseOthersEligibility.length
              : 0

          let itemCount = 1
          if (
            civilServiceExamPlaceCompanyLength >= 139 ||
            civilServiceAndOthersLicenseOthersEligibilityLength >= 140
          ) {
            itemCount = 3
          } else if (
            civilServiceExamPlaceCompanyLength >= 48 ||
            civilServiceAndOthersLicenseOthersEligibilityLength >= 49
          ) {
            itemCount = 2
          }

          count += itemCount
          const dataWithCount = { ...data, count: itemCount }
          if (count <= 8) {
            civilServiceData.push(dataWithCount)
          } else {
            remainingData.push(data)
          }
        }

        const chunkArray = (array, maxCount) => {
          const result = []
          let currentChunk = []
          let currentCount = 0

          array.forEach((data) => {
            const civilServiceExamPlaceCompanyLength =
              data.civilServiceExamPlace ? data.civilServiceExamPlace.length : 0
            const civilServiceAndOthersLicenseOthersEligibilityLength =
              data.civilServiceAndOthersLicenseOthersEligibility
                ? data.civilServiceAndOthersLicenseOthersEligibility.length
                : 0

            let itemCount = 1
            if (
              civilServiceExamPlaceCompanyLength >= 139 ||
              civilServiceAndOthersLicenseOthersEligibilityLength >= 140
            ) {
              itemCount = 3
            } else if (
              civilServiceExamPlaceCompanyLength >= 48 ||
              civilServiceAndOthersLicenseOthersEligibilityLength >= 49
            ) {
              itemCount = 2
            }

            if (currentCount + itemCount > maxCount) {
              result.push(currentChunk)
              currentChunk = []
              currentCount = 0
            }

            currentChunk.push({ ...data, count: itemCount })
            currentCount += itemCount
          })

          if (currentChunk.length > 0) {
            result.push(currentChunk)
          }

          return result
        }

        const categorizedRemainingData = chunkArray(remainingData, 75).map(
          (chunk, index) => {
            let chunkCount = 0
            chunk.forEach((data) => {
              chunkCount += data.count
            })
            return { chunk, totalCount: chunkCount }
          }
        )
        const totalItemCountFirstPage = civilServiceData.reduce(
          (acc, item) => acc + item.count,
          0
        )

        setCivilServiceData(civilServiceData)
        setCivilServiceDataCount(totalItemCountFirstPage)
        setCivilServiceDataNextPage(categorizedRemainingData)
      } else {
        setCivilServiceData([])
        setCivilServiceDataCount(0)
        setCivilServiceDataNextPage([])
      }
    } catch (err) {
      console.log(err)
    }
  }
  /**
   * Converts feet and inches to meters.
   * @param {number} feet - The feet part of the height.
   * @param {number} inches - The inches part of the height.
   * @returns {number} The height in meters.
   */
  function feetAndInchesToMeters(feet, inches) {
    try {
      const feetToMeters = feet * 0.3048
      const inchesToMeters = inches * 0.0254
      return Number((feetToMeters + inchesToMeters).toFixed(2))
    } catch (error) {
      console.log('Error converting feet and inches to meters:', error)
    }
  }

  /**
   * Converts pounds to kilograms.
   * @param {number} pounds - The weight in pounds.
   * @returns {number} The weight in kilograms.
   */
  function poundsToKilograms(pounds) {
    try {
      return Number((pounds * 0.45359237).toFixed(2))
    } catch (error) {
      console.log('Error converting pounds to kilograms:', error)
    }
  }

  function bloodTypeFormat(bloodType) {
    try {
      switch (bloodType) {
        case 'oMinus':
          return 'O-'
        case 'oPlus':
          return 'O+'
        case 'aPlus':
          return 'A+'
        case 'aMinus':
          return 'A-'
        case 'bPlus':
          return 'B+'
        case 'bMinus':
          return 'B-'
        case 'abPlus':
          return 'AB+'
        case 'abMinus':
          return 'AB-'
        case 'TBD':
          return 'TBD'
        default:
          return ''
      }
    } catch (error) {
      // Log the error or handle it as needed
      console.error('Error in bloodTypeFormat:', error)
      return 'Error' // Return a default or error-specific value
    }
  }

  /**
   * @function findSeniorHighSchoolValue
   * @description Finds the first entry with secondaryBasicEducation as "SENIOR HIGH SCHOOL" and returns its value.
   * @param {Array} dataGrid The data grid containing education entries.
   * @returns {Object|null} The found entry or null if not found.
   */
  const findSeniorHighSchoolValue = (dataGrid) => {
    try {
      const seniorHighSchoolEntry = dataGrid.find(
        (item) => item.secondaryBasicEducation === 'SENIOR HIGH SCHOOL'
      )
      return seniorHighSchoolEntry || null // Return the found entry or null if not found
    } catch (error) {
      console.log('Error in findSeniorHighSchoolValue:', error)
      return null // Return null in case of an error
    }
  }
  /**
   * @function findJuniorHighSchoolValue
   * @description Finds the first entry with secondaryBasicEducation as "JUNIOR HIGH SCHOOL" and returns its value.
   * @param {Array} dataGrid The data grid containing education entries.
   * @returns {Object|null} The found entry or null if not found.
   */
  const findJuniorHighSchoolValue = (dataGrid) => {
    try {
      const seniorHighSchoolEntry = dataGrid.find(
        (item) => item.secondaryBasicEducation === 'JUNIOR HIGH SCHOOL'
      )
      return seniorHighSchoolEntry || dataGrid[0] || null // Return the found entry or null if not found
    } catch (error) {
      console.log('Error in findJuniorHighSchoolValue:', error)
      return null // Return null in case of an error
    }
  }

  /**
   *@function selectedRadio
   *@description function that returns the selected radio button image in the PDF.
   *@param {Object} none No parameter needed.
   *@returns {Object} Returns the selected radio button image in the PDF.
   */
  const selectedRadio = () => {
    return (
      <Svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
        width='8'
        height='8'
        style={{ marginRight: '1px' }}
      >
        <Rect
          x='1'
          y='1'
          width='14'
          height='14'
          stroke='black'
          fill='none'
          strokeWidth='1'
        />
        <Path d='M4 8 l4 4 l8 -8' stroke='black' strokeWidth='2' fill='none' />
      </Svg>
    )
  }
  /**
   *@function unSelectedRadio
   *@description function that returns the unselected radio button image in the PDF.
   *@param {Object} none No parameter needed.
   *@returns {Object} Returns the unselected radio button image in the PDF.
   */
  const unSelectedRadio = () => {
    return (
      <Svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
        width='8'
        height='8'
        style={{ marginRight: '1px' }}
      >
        <Rect
          x='1'
          y='1'
          width='14'
          height='14'
          stroke='black'
          fill='none'
          strokeWidth='1'
        />
      </Svg>
    )
  }

  /**
   * Generates an array of reference row views.
   *
   * @param {number} count - The number of reference rows to generate.
   * @returns {JSX.Element[]} An array of JSX elements representing the reference rows.
   */
  const generateReferenceRow = (count) => {
    try {
      let views = []
      for (let i = 0; i < count; i++) {
        views.push(
          <View key={uuidv4()} style={styles.div3}>
            <Text
              style={[
                styles.textMediumB,
                styles.textBold,
                styles.bordered,
                styles.textCenter,
                { width: '194px', height: 20 },
              ]}
            >
              {' '}
            </Text>
            <Text
              style={[
                styles.textMediumB,
                styles.textBold,
                styles.bordered,
                styles.textCenter,
                { width: '173px', height: 20 },
              ]}
            >
              {' '}
            </Text>
            <Text
              style={[
                styles.textMediumB,
                styles.textBold,
                styles.bordered,
                styles.textCenter,
                { height: 20, width: 80 },
              ]}
            >
              {' '}
            </Text>
          </View>
        )
      }
      return views
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates an array of JSX elements representing rows with specific styles and structure.
   *
   * @param {number} count - The number of rows to generate.
   * @returns {JSX.Element[]} An array of JSX elements representing the rows.
   */
  const generateCSErow = (count) => {
    try {
      let views = []
      for (let i = 0; i < count; i++) {
        views.push(
          <View key={uuidv4()} style={[styles.div1a, { height: 12 }]}>
            <View style={[styles.div3b, styles.bordered, { width: 185.7 }]}>
              <View style={[styles.div2]}>
                <View style={styles.div2}>
                  <Text
                    style={[
                      styles.textMediumData,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 50.9 }]}>
              <View style={[styles.div2a, styles.width6b]}>
                <View style={styles.div2}>
                  <Text
                    style={[
                      styles.textMediumData,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 65.8 }]}>
              <View style={[styles.div2a, styles.width4b]}>
                <Text
                  style={[
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 181.8 }]}>
              <View
                style={[
                  // styles.div2,
                  { width: 181.8 },
                ]}
              >
                <Text
                  style={[
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                    // { marginLeft: '20px' },
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 57.4 }]}>
              <View style={[styles.div2, styles.width4b]}>
                <Text
                  style={[
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                { width: 42.3, borderRight: 'none' },
              ]}
            >
              <View style={[styles.div2, styles.width7]}>
                <Text
                  style={[
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
          </View>
        )
      }
      return views
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates an array of empty row views based on the provided grid data.
   *
   * @param {Array} gridData - An array of objects representing work experience data.
   * @param {string} gridData[].workExperienceCompany - The company name of the work experience.
   * @param {string} gridData[].workExperiencePositionTitle - The position title of the work experience.
   * @returns {Array} An array of React elements representing empty rows.
   */
  const generateWErow = (count) => {
    try {
      let createRow = count
      let views = []
      for (let i = 0; i < createRow; i++) {
        views.push(
          <View
            style={[styles.div1a, { height: i === createRow - 1 ? 16 : 12 }]}
            key={uuidv4()}
          >
            {/* <View style={[styles.div1a, { height: data.workExperienceCompany.length >= 33 || data.workExperiencePositionTitle.length >= 16 ? 40 : 20 }]} key={uuidv4()}> */}
            <View style={[styles.div3b, styles.bordered, { width: 51 }]}>
              <View style={[styles.div2, styles.width6b]}>
                <View style={styles.div2}>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 49.8 }]}>
              <View style={[styles.div2, styles.width6b]}>
                <View style={styles.div2}>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered]}>
              <View style={[styles.div2, { width: 134.7 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered]}>
              <View style={[styles.div2, { width: 154.5 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered]}>
              <View style={[styles.div2, { width: 40 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered]}>
              <View style={[styles.div2, styles.width1b3]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered]}>
              <View style={[styles.div2, { width: 56 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                { width: 43, borderRight: 'none' },
              ]}
            >
              <View style={styles.div2}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
          </View>
        )
      }
      return views
    } catch (error) {
      console.log(error)
    }
  }

  const generateVWrow = (count) => {
    try {
      let views = []
      for (let i = 0; i < count; i++) {
        views.push(
          <View style={[styles.div1a, { height: 12 }]}>
            <View style={[styles.div3b, styles.bordered, { width: 240 }]}>
              <View style={styles.div2}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 45.5 }]}>
              <View style={[styles.div2, styles.width6b]}>
                <View style={styles.div2}>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 44.5 }]}>
              <Text
                style={[styles.textMedium, styles.textCenter, styles.textBold]}
              >
                {' '}
              </Text>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 47 }]}>
              <Text
                style={[styles.textMedium, styles.textCenter, styles.textBold]}
              >
                {' '}
              </Text>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                { width: 207, borderRight: 'none' },
              ]}
            >
              <Text
                style={[styles.textMedium, styles.textCenter, styles.textBold]}
              >
                {' '}
              </Text>
            </View>
          </View>
        )
      }
      return views
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates a row for the Learning and Development (LnD) section.
   *
   * This function takes a count as an argument and generates a row for the
   * Learning and Development section of the PDF. The row includes details
   * such as the title of the learning and development interventions/training
   * programs, the date, and other relevant information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing a row in the LnD section.
   */
  const generateLnDRow = (count) => {
    try {
      let views = []
      for (let i = 0; i < count; i++) {
        views.push(
          <View
            style={[
              styles.div1a,
              {
                // height: i === count - 1 ? 24 : 20, // Set height to 24 for the last item
                height: 12,
              },
            ]}
            key={uuidv4()}
          >
            <View style={[styles.div3b, styles.bordered, { width: 239.5 }]}>
              <View style={[styles.div2]}>
                <Text
                  style={[
                    // styles.marginTop1,
                    styles.textMedium,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 45.5 }]}>
              <View style={[styles.div2]}>
                <Text
                  style={[
                    // styles.marginTop1,
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 44.5 }]}>
              <Text
                style={[
                  // styles.marginTop1,
                  styles.textMediumData,
                  styles.textCenter,
                  styles.textBold,
                ]}
              >
                {' '}
              </Text>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 47.8 }]}>
              <View style={[styles.div2, { width: 47.8 }]}>
                <Text
                  style={[
                    // styles.marginTop1,
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, { width: 53.9 }]}>
              <View style={[styles.div2, { width: 53.9 }]}>
                <Text
                  style={[
                    // styles.marginTop1,
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                { width: 152.8, borderRight: 'none' },
              ]}
            >
              <View style={[styles.div2, { width: 152.8 }]}>
                <Text
                  style={[
                    // styles.marginTop1,
                    styles.textMediumData,
                    styles.textCenter,
                    styles.textBold,
                  ]}
                >
                  {' '}
                </Text>
              </View>
            </View>
          </View>
        )
      }
      return views
    } catch (error) {
      console.log(error)
      return <View></View>
    }
  }

  /**
   * Generates a row for the Education Background section.
   *
   * This function takes a count as an argument and generates a row for the
   * Education Background section of the PDF. The row includes details such as
   * the name of the school, degree, period of attendance, and other relevant
   * information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing a row in the Education Background section.
   */
  const generateEducationBgRow = () => {
    try {
      const elementaryData = pdfData.well4DataGrid2
        ? pdfData.well4DataGrid2.slice(1)
        : []
      let secodaryData = pdfData.well4DataGrid ? pdfData.well4DataGrid : []
      const hasJuniorHighSchool = secodaryData.some(
        (item) => item.secondaryBasicEducation === 'JUNIOR HIGH SCHOOL'
      )
      const hasSeniorHighSchool = secodaryData.some(
        (item) => item.secondaryBasicEducation === 'SENIOR HIGH SCHOOL'
      )

      if (hasJuniorHighSchool) {
        let indexToRemoveJunior = secodaryData.findIndex(
          (item) => item.secondaryBasicEducation === 'JUNIOR HIGH SCHOOL'
        )
        if (indexToRemoveJunior !== -1) {
          secodaryData.splice(indexToRemoveJunior, 1)
        }
      }

      if (hasSeniorHighSchool) {
        let indexToRemoveSenior = secodaryData.findIndex(
          (item) => item.secondaryBasicEducation === 'SENIOR HIGH SCHOOL'
        )
        if (indexToRemoveSenior !== -1) {
          secodaryData.splice(indexToRemoveSenior, 1)
        }
      }

      if (!hasJuniorHighSchool) {
        secodaryData = pdfData.well4DataGrid.slice(1)
      }

      const vocationalCourseData = pdfData.well4Levelvocationaltradecourse
        ? pdfData.well4Levelvocationaltradecourse.slice(1)
        : []
      const collegeData = pdfData.well4DataGrid3
        ? pdfData.well4DataGrid3.slice(1)
        : []
      const graduateStudiesData = pdfData.well4Well5Levelgraduatestudies
        ? pdfData.well4Well5Levelgraduatestudies.slice(1)
        : []

      const totalCount =
        elementaryData.length +
        secodaryData.length +
        vocationalCourseData.length +
        collegeData.length +
        graduateStudiesData.length

      return (
        <View>
          <View style={styles.div1}>
            <View style={[styles.div3a]}>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { alignItems: 'left', justifyContent: 'left', width: 100 },
                ]}
              >
                <View>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textLeft,
                      { marginLeft: 1, marginTop: 3 },
                    ]}
                  >
                    26.
                  </Text>
                </View>
                <View style={{ width: 90 }}>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      { marginLeft: 1, marginTop: 12 },
                    ]}
                  >
                    LEVEL
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.bordered,
                  styles.backgroundCol,
                  { width: 130 },
                ]}
              >
                <View style={styles.div2}>
                  <Text style={[styles.textCenter, styles.textMedium]}>
                    NAME OF SCHOOL
                  </Text>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      { marginLeft: 8, fontSize: 5.8 },
                    ]}
                  >
                    (Write in full)
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { width: 130 },
                ]}
              >
                <View style={{ width: 130 }}>
                  <Text style={[styles.textMedium, styles.textCenter]}>
                    {`BASIC EDUCATION/DEGREE/COURSE`}
                  </Text>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      { fontSize: 5.8 },
                    ]}
                  >
                    (Write in full)
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { width: 80 },
                ]}
              >
                <View style={[styles.div2, { width: 80 }]}>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      styles.margin1Top,
                      { fontSize: 5.8 },
                    ]}
                  >
                    {`PERIOD OF ATTENDANCE`}
                  </Text>
                  <View
                    style={[styles.div3a, { marginTop: '5px', height: 11 }]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        styles.width9c,
                        styles.textCenter,
                        {
                          borderTop: '1px solid black',
                          borderRight: '1px solid black',
                          paddingTop: 2,
                        },
                        // styles.datePadding,
                      ]}
                    >
                      From
                    </Text>
                    <Text
                      style={[
                        styles.textMedium,
                        styles.width9c2,
                        styles.textCenter,
                        { borderTop: '1px solid black', paddingTop: 2 },
                        // styles.datePadding,
                      ]}
                    >
                      To
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { width: 54.5 },
                ]}
              >
                <View style={styles.div2}>
                  <Text style={{ fontSize: 5.8 }}>
                    {`HIGHEST LEVEL/\nUNITS EARNED`}
                  </Text>
                  <Text style={[styles.textCenter, { fontSize: 5.8 }]}>
                    (if not graduated)
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { width: 40 },
                ]}
              >
                <Text style={[styles.textMedium, styles.textCenter]}>
                  {`YEAR\nGRADUATED`}
                </Text>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.width9,
                  styles.backgroundCol,
                  { width: 50, borderRight: 'none' },
                ]}
              >
                <Text style={[styles.textMedium, { fontSize: 5.8, width: 50 }]}>
                  {`SCHOLARSHIP/\nACADEMIC\nHONORS\nRECEIVED`}{' '}
                </Text>
              </View>
            </View>
          </View>
          {elementaryData.map((data) => {
            return (
              <View style={[styles.div1a, { height: 25 }]} key={uuidv4()}>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    styles.backgroundCol,
                    { width: 100 },
                  ]}
                >
                  <Text
                    style={[styles.textMedium, { width: 100, paddingLeft: 12 }]}
                  >
                    ELEMENTARY
                  </Text>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={styles.div2}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        styles.tablePadding,
                        {
                          fontSize: (() => {
                            try {
                              if (data.elementaryNameOfSchoolNaAllowed) {
                                if (
                                  data.elementaryNameOfSchoolNaAllowed.length >=
                                  78
                                ) {
                                  return 5
                                } else if (
                                  data.elementaryNameOfSchoolNaAllowed.length >=
                                  64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.elementaryNameOfSchoolNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.elementaryBasicEducationNaAllowed) {
                                if (
                                  data.elementaryBasicEducationNaAllowed
                                    .length >= 78
                                ) {
                                  return 5
                                } else if (
                                  data.elementaryBasicEducationNaAllowed
                                    .length >= 64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.elementaryBasicEducationNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.elementaryDateFromNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textCenter,
                      styles.textMediumDataBold,
                      styles.textBold,
                      { fontSize: 7 },
                    ]}
                  >
                    {data?.elementaryDateToNaAllowed}
                  </Text>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize:
                            data &&
                            data?.elementaryUnitsEarnedNaAllowed.length >= 22
                              ? 6
                              : 7,
                        },
                      ]}
                    >
                      {data?.elementaryUnitsEarnedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.elementaryYearGraduatedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 50, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.elementaryScholarshipNaAllowed) {
                                if (
                                  data.elementaryScholarshipNaAllowed.length >=
                                  60
                                ) {
                                  return 3
                                } else if (
                                  data.elementaryScholarshipNaAllowed.length >=
                                  44
                                ) {
                                  return 4
                                } else if (
                                  data.elementaryScholarshipNaAllowed.length >=
                                  22
                                ) {
                                  return 5
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.elementaryScholarshipNaAllowed}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          {secodaryData.map((data) => {
            return (
              <View
                style={[
                  styles.div1a,
                  {
                    height: 25,
                  },
                ]}
                key={uuidv4()}
              >
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    styles.backgroundCol,
                    { width: 100 },
                  ]}
                >
                  <Text
                    style={[styles.textMedium, { width: 100, paddingLeft: 12 }]}
                  >
                    SECONDARY
                  </Text>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (data.secondaryNameOfSchoolNaAllowed) {
                                  if (
                                    data.secondaryNameOfSchoolNaAllowed
                                      .length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    data.secondaryNameOfSchoolNaAllowed
                                      .length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {data?.secondaryNameOfSchoolNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.secondaryBasicEducation) {
                                if (data.secondaryBasicEducation.length >= 78) {
                                  return 5
                                } else if (
                                  data.secondaryBasicEducation.length >= 64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.secondaryBasicEducation}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data.secondaryDateFromNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.secondaryDateToNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize:
                            data?.secondaryUnitsEarnedNaAllowed &&
                            data?.secondaryUnitsEarnedNaAllowed.length >= 22
                              ? 6
                              : 7,
                        },
                      ]}
                    >
                      {data?.secondaryUnitsEarnedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.secondaryYearGraduatedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 50, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.secondaryScholarshipNaAllowed) {
                                if (
                                  data.secondaryScholarshipNaAllowed.length >=
                                  60
                                ) {
                                  return 3
                                } else if (
                                  data.secondaryScholarshipNaAllowed.length >=
                                  44
                                ) {
                                  return 4
                                } else if (
                                  data.secondaryScholarshipNaAllowed.length >=
                                  22
                                ) {
                                  return 5
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.secondaryScholarshipNaAllowed}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          {vocationalCourseData.map((data) => {
            return (
              <View
                style={[
                  styles.div1a,
                  {
                    height: 25,
                  },
                ]}
                key={uuidv4()}
              >
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    styles.backgroundCol,
                    { width: 100 },
                  ]}
                >
                  <View style={{ paddingLeft: 12 }}>
                    <Text style={[styles.textMedium, { width: 100 }]}>
                      VOCATIONAL /
                    </Text>
                    <Text style={[styles.textMedium, { width: 100 }]}>
                      TRADE COURSE
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Levelvocationaltradecourse &&
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalNameOfSchoolNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalNameOfSchoolNaAllowed.length >=
                                    78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalNameOfSchoolNaAllowed.length >=
                                    64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalNameOfSchoolNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (
                                pdfData.well4Levelvocationaltradecourse &&
                                pdfData.well4Levelvocationaltradecourse[0]
                                  .vocationalBasicEducationNaAllowed
                              ) {
                                if (
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalBasicEducationNaAllowed.length >=
                                  78
                                ) {
                                  return 5
                                } else if (
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalBasicEducationNaAllowed.length >=
                                  64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalBasicEducationNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalDateFromNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalDateToNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize:
                            pdfData.well4Levelvocationaltradecourse &&
                            pdfData.well4Levelvocationaltradecourse[0]
                              .vocationalUnitsEarnedNaAllowed.length >= 22
                              ? 6
                              : 7,
                        },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalUnitsEarnedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalYeargraduatedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 50, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (
                                pdfData.well4Levelvocationaltradecourse &&
                                pdfData.well4Levelvocationaltradecourse[0]
                                  .vocationalUnitsEarnedNaAllowed
                              ) {
                                if (
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalUnitsEarnedNaAllowed.length >= 60
                                ) {
                                  return 3
                                } else if (
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalUnitsEarnedNaAllowed.length >= 44
                                ) {
                                  return 4
                                } else if (
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalUnitsEarnedNaAllowed.length >= 22
                                ) {
                                  return 5
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {pdfData.well4Levelvocationaltradecourse &&
                        pdfData.well4Levelvocationaltradecourse.length > 0 &&
                        pdfData.well4Levelvocationaltradecourse[0]
                          .vocationalScholarshipNaAllowed}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          {collegeData.map((data) => {
            return (
              <View style={[styles.div1a, { height: 25 }]} key={uuidv4()}>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    styles.backgroundCol,
                    { width: 100 },
                  ]}
                >
                  <Text
                    style={[styles.textMedium, { width: 100, paddingLeft: 12 }]}
                  >
                    COLLEGE
                  </Text>
                </View>

                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  {/* <View style={[styles.div2, { width: '136px', justifyContent: 'center', alignItems: 'center' }]}> */}
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.collegeNameOfSchoolNaAllowed) {
                                if (
                                  data.collegeNameOfSchoolNaAllowed.length >= 78
                                ) {
                                  return 5
                                } else if (
                                  data.collegeNameOfSchoolNaAllowed.length >= 64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.collegeNameOfSchoolNaAllowed}
                    </Text>
                  </View>
                  {/* </View> */}
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  {/* <View style={[styles.div2, { width: '136px', justifyContent: 'center', alignItems: 'center' }]}> */}
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.collegeCourseNaAllowed) {
                                if (data.collegeCourseNaAllowed.length >= 78) {
                                  return 5
                                } else if (
                                  data.collegeCourseNaAllowed.length >= 64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.collegeCourseNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.collegeDateFromNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.collegeDateToNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.collegeUnitsEarnedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.collegeYearGraduatedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 50, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2, { width: 50 }]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.collegeScholarshipNaAllowed) {
                                if (
                                  data.collegeScholarshipNaAllowed.length >= 60
                                ) {
                                  return 3
                                } else if (
                                  data.collegeScholarshipNaAllowed.length >= 44
                                ) {
                                  return 4
                                } else if (
                                  data.collegeScholarshipNaAllowed.length >= 22
                                ) {
                                  return 5
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.collegeScholarshipNaAllowed}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}

          {graduateStudiesData.map((data) => {
            return (
              <View style={[styles.div1a, { height: 25 }]} key={uuidv4()}>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    styles.backgroundCol,
                    { width: 100 },
                  ]}
                >
                  <Text
                    style={[styles.textMedium, { width: 100, paddingLeft: 12 }]}
                  >
                    GRADUATE STUDIES
                  </Text>
                </View>

                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (data.graduateNameOfSchoolNaAllowed) {
                                  if (
                                    data.graduateNameOfSchoolNaAllowed.length >=
                                    78
                                  ) {
                                    return 5
                                  } else if (
                                    data.graduateNameOfSchoolNaAllowed.length >=
                                    64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {data?.graduateNameOfSchoolNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (
                                data.well4Well5Levelgraduatestudies &&
                                data.graduateCourseNaAllowed
                              ) {
                                if (data.graduateCourseNaAllowed.length >= 78) {
                                  return 5
                                } else if (
                                  data.graduateCourseNaAllowed.length >= 64
                                ) {
                                  return 6
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.graduateCourseNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.graduateDateFromNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {data?.graduateDateToNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize:
                            data.graduateUnitsEarnedNaAllowed &&
                            data.graduateUnitsEarnedNaAllowed.length >= 22
                              ? 6
                              : 7,
                        },
                      ]}
                    >
                      {data?.graduateUnitsEarnedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                      ]}
                    >
                      {data?.graduateYearGraduatedNaAllowed}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 50, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumDataBold,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: (() => {
                            try {
                              if (data.graduateScholarshipNaAllowed) {
                                if (
                                  data.graduateScholarshipNaAllowed.length >= 60
                                ) {
                                  return 3
                                } else if (
                                  data.graduateScholarshipNaAllowed.length >= 45
                                ) {
                                  return 4
                                } else if (
                                  data.graduateScholarshipNaAllowed.length >= 22
                                ) {
                                  return 5
                                }
                              }
                              return 7
                            } catch (error) {
                              console.error(error)
                              return 7 // Default font size in case of error
                            }
                          })(),
                        },
                      ]}
                    >
                      {data?.graduateScholarshipNaAllowed}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          <View>{renderEducationalCell(totalCount)}</View>
          <View style={[styles.textSmallBold, styles.textColorRed]}>
            <Text
              style={[
                styles.textCenter,
                styles.textItalicBold,
                styles.backgroundCol,
                { fontSize: 6.6 },
              ]}
            >
              (Continue on separate sheet if necessary)
            </Text>
          </View>
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  const renderEducationalCell = (educationCount) => {
    const blocks = []
    let totalCellCount = 35 - educationCount
    for (let i = 0; i <= totalCellCount; i++) {
      blocks.push(
        <View
          key={uuidv4()}
          style={[styles.div1a, { height: i === totalCellCount ? 28 : 25 }]}
        >
          <View
            style={[
              styles.div3b,
              styles.bordered,
              styles.backgroundCol,
              { width: 100 },
            ]}
          >
            <Text> </Text>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
            <View style={styles.div2}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
            <View style={[styles.div2]}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 39.1 }]}>
            <View style={[styles.div2]}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 40.9 }]}>
            <Text> </Text>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 54.5 }]}>
            <View style={[styles.div2]}>
              <Text> </Text>
            </View>
          </View>
          <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
            <View style={[styles.div2]}>
              <Text
                style={[
                  styles.marginTop1,

                  styles.textCenter,
                  styles.textBold,
                  { fontSize: 7 },
                ]}
              >
                {' '}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.div3b,
              styles.bordered,
              { width: 50, borderRight: 'none' },
            ]}
          >
            <View style={[styles.div2]}>
              <Text> </Text>
            </View>
          </View>
        </View>
      )
    }
    return blocks
  }

  /**
   * Generates a row for the Family Children section.
   *
   * This function generates a row for the Family Children section of the PDF.
   * The row includes details such as the name of the child, date of birth, and other relevant information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing a row in the Family Children section.
   */
  const generatefamilyChildrenRow = () => {
    try {
      const childrenData = pdfData.well3Well2DataGrid
        ? pdfData.well3Well2DataGrid.slice(12)
        : []

      const childrenCount = childrenData ? childrenData.length : 0
      return (
        <View>
          <View style={[styles.div1, { width: '100%' }]}>
            <View style={[styles.div3a]}>
              <View
                style={[
                  styles.div3b,
                  styles.backgroundCol,
                  styles.bordered,
                  { width: '70%', height: '20' },
                ]}
              >
                <Text style={[styles.textMedium]}>
                  23. NAME of CHILDREN (Write full name and list all)
                </Text>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.bordered,
                  { width: '30%', height: '20', borderRight: 'none' },
                  styles.backgroundCol,
                ]}
              >
                <View style={styles.div2}>
                  <Text style={[styles.textMedium]}>
                    DATE OF BIRTH (mm/dd/yyyy)
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {childrenData.map((data) => {
            return (
              <View style={[styles.div1a]} key={uuidv4()}>
                <View
                  style={[styles.div3b, styles.bordered, { width: '100%' }]}
                >
                  <View style={[styles.div2, { width: '70%' }]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMediumData,
                        styles.textCenter,
                        styles.textBold,
                      ]}
                    >
                      {data.childrenFullnameNaAllowed || 'N/A'}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: '30%', borderRight: 'none' },
                  ]}
                >
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMediumData,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {data.childrenDOBNaAllowed
                      ? moment(data.childrenDOBNaAllowed).format('MM/DD/YYYY')
                      : 'N/A'}
                  </Text>
                </View>
              </View>
            )
          })}
          {Array.from({ length: 46 - childrenCount }, (_, index) => (
            <View key={index} style={[styles.div1a, { height: 20 }]}>
              <View style={[styles.div3b, styles.bordered, { width: '70%' }]}>
                <View style={[styles.div2, styles.fullWidth]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMedium,
                        styles.textCenter,
                        styles.textBold,
                      ]}
                    >
                      {' '}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.bordered,
                  { width: '30%', borderRight: 'none' },
                ]}
              >
                <View style={[styles.div2, styles.fullWidth]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
          ))}
          <View style={[styles.textSmallBold, styles.textColorRed]}>
            <Text
              style={[
                styles.textCenter,
                styles.textItalicBold,
                styles.backgroundCol,
                { fontSize: 6.6 },
              ]}
            >
              (Continue on separate sheet if necessary)
            </Text>
          </View>
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates additional Civil Service Eligibility (CSE) data.
   *
   * This function generates additional rows for the Civil Service Eligibility section of the PDF.
   * The rows include details such as the eligibility name, rating, date of examination, and other relevant information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing additional rows in the Civil Service Eligibility section.
   */
  const generateAdditionalCSEData = (data) => {
    try {
      const cseData = data ? data.chunk : []
      return (
        <View>
          <View style={[styles.div1a]}>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}
            >
              <View style={[styles.div2a, { width: 185 }]}>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: 25 }}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textCivilLabel,
                        styles.textCenter,
                        { marginRight: 10 },
                      ]}
                    >
                      {'27.'}
                    </Text>
                  </View>
                  <View style={{ width: 160 }}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textCivilLabel,
                        styles.textCenter,
                      ]}
                    >
                      {`CAREER SERVICE/ RA 1080(BOARD/ BAR) UNDER \n  SPECIAL LAWS/CES/ CSEE \n BARANGAY ELIGIBILITY /DRIVER'S LICENSE`}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}
            >
              <View style={[styles.div2a, { width: 50 }]}>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textCivilLabel,
                    styles.textCenter,
                  ]}
                >
                  {`RATING`}
                </Text>
                <Text
                  style={[
                    styles.textCivilLabel,
                    styles.textCenter,
                    { fontSize: 5.8 },
                  ]}
                >
                  {`(If Applicable)`}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}
            >
              <View style={[styles.div2a, { width: 65 }]}>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textCivilLabel,
                    styles.textCenter,
                  ]}
                >
                  {'DATE OF\nEXAMINATION /\nCONFERMENT'}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}
            >
              <View style={[styles.div2a, { width: 181 }]}>
                <Text
                  style={[
                    styles.textCivilLabel,
                    styles.textCenter,
                    { marginTop: 12 },
                  ]}
                >
                  {'PLACE OF EXAMINATION / CONFERMENT'}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                {
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}
            >
              <View style={[styles.div2, { width: 100 }]}>
                <View
                  style={[
                    styles.div2,
                    styles.bordered,
                    styles.backgroundCol,
                    { borderRight: 'none' },
                  ]}
                >
                  <Text style={[styles.textCivilLabel, styles.textCenter]}>
                    {'LICENSE (if applicable)'}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={[
                      styles.backgroundCol,
                      {
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        height: 22.5,
                        width: 57.4,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textCivilLabel,
                        styles.textCenter,
                        styles.marginTop1,
                      ]}
                    >
                      {'NUMBER'}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.backgroundCol,
                      {
                        borderBottom: '1px solid black',
                        borderRight: 'none',
                        height: 22.5,
                        width: 42.3,
                      },
                    ]}
                  >
                    <Text style={[styles.textCivilLabel, styles.textCenter]}>
                      {'Date of \n Validity'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {cseData.map((data) => {
            let height
            if (data.count === 1) {
              height = 12
            } else if (data.count === 2) {
              height = 24
            } else if (data.count === 3) {
              height = 36
            } else {
              height = 12
            }
            return (
              <View style={[styles.div1a, { height: height }]} key={uuidv4()}>
                <View style={[styles.div3b, styles.bordered, { width: 185.7 }]}>
                  <View style={[styles.div2]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 8.5 },
                        ]}
                      >
                        {data.civilServiceAndOthersLicense === 'OTHERS'
                          ? data.civilServiceAndOthersLicenseOthersEligibility ||
                            ' '
                          : data.civilServiceAndOthersLicense || ' '}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 50.9 }]}>
                  <View style={[styles.div2a, styles.width6b]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 8.5 },
                        ]}
                      >
                        {data.civilServiceRating
                          ? data.civilServiceRating
                          : ' '}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 65.8 }]}>
                  <View style={[styles.div2a, styles.width4b]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.7 },
                      ]}
                    >
                      {data.civilServiceExamDate
                        ? moment(data.civilServiceExamDate).format('MM/DD/YYYY')
                        : ' '}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 181.8 }]}>
                  <View
                    style={[
                      // styles.div2,
                      { width: 181.8 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.civilServiceExamPlace
                        ? data.civilServiceExamPlace
                        : ' '}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 57.4 }]}>
                  <View style={[styles.div2, styles.width4b]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.civilServiceLicenseNo
                        ? data.civilServiceLicenseNo
                        : ' '}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 42.3, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2, styles.width7]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.7 },
                      ]}
                    >
                      {data.civilServiceLicenseValidityDateIsNA === 'N/A'
                        ? 'N/A'
                        : data.civilServiceLicenseValidityDate
                          ? moment(data.civilServiceLicenseValidityDate).format(
                              'MM/DD/YYYY'
                            )
                          : 'N/A'}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          {generateCSErow(75 - data.totalCount)}
          <View
            style={[
              styles.textSmallBold,
              styles.textColorRed,
              styles.backgroundCol,
              { height: 16 },
            ]}
          >
            <Text
              style={[
                styles.textCenter,
                styles.textItalicBold,
                { fontSize: 6.6, marginTop: 3 },
              ]}
            >
              (Continue on separate sheet if necessary)
            </Text>
          </View>
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates additional Work Experience (WE) data.
   *
   * This function generates additional rows for the Work Experience section of the PDF.
   * The rows include details such as the position title, company name, period of employment, and other relevant information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing additional rows in the Work Experience section.
   */

  const generateAdditionalWEData = (data) => {
    try {
      const weData = data.chunk ? data.chunk : []
      return (
        <View>
          <View style={[styles.div1a, { height: 40 }]}>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, { width: 100 }]}>
                <View style={{ flexDirection: 'row' }}>
                  <View>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMedium,
                        styles.textLeft,
                        { fontSize: 6.9 },
                      ]}
                    >
                      {' 28. '}
                    </Text>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMedium,
                        styles.textCenter,
                        { fontSize: 6.9 },
                      ]}
                    >
                      {'INCLUSIVE DATES'}
                    </Text>
                    <Text
                      style={[
                        styles.textMedium,
                        styles.textCenter,
                        { marginLeft: 7, fontSize: 5.8 },
                      ]}
                    >
                      {'(mm/dd/yyyy)'}
                    </Text>
                  </View>
                </View>

                <View style={[styles.div4, { marginTop: 8 }]}>
                  <View
                    style={[
                      styles.div2,
                      styles.width4bc,
                      { borderTop: '1px solid black' },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        styles.textCenter,
                        { marginTop: 1, fontSize: 6.9 },
                      ]}
                    >
                      From
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.div2,
                      styles.width4bc,
                      {
                        borderTop: '1px solid black',
                        borderLeft: '1px solid black',
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        styles.textCenter,
                        { marginTop: 1, fontSize: 6.9 },
                      ]}
                    >
                      To
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, { width: 134.5 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 6.9 },
                  ]}
                >
                  {`POSITION TITLE`}
                </Text>
                <Text
                  style={[
                    styles.textCenter,
                    styles.textMedium,
                    { fontSize: 5.5 },
                  ]}
                >
                  {`(Write in full/Do not abbreviate)`}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, { width: 154.5 }]}>
                <Text
                  style={[
                    styles.textCenter,
                    styles.textMedium,
                    { fontSize: 6.9 },
                  ]}
                >
                  {'DEPARTMENT / AGENCY / OFFICE / COMPANY'}
                </Text>
                <Text
                  style={[
                    styles.textCenter,
                    styles.textMedium,
                    { fontSize: 5.5 },
                  ]}
                >
                  {'(Write in full/Do not abbreviate)'}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, { width: 40 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 6 },
                  ]}
                >
                  {'MONTHLY\nSALARY'}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, styles.width7, { width: 50 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 5.2 },
                  ]}
                >
                  {`SALARY/ JOB/ PAY \n GRADE (if\napplicable)& STEP\n(Format "00-0")/\nINCREMENT`}
                </Text>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2, { width: 56 }]}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 6 },
                  ]}
                >
                  {`STATUS OF\nAPPOINTMENT`}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                { borderRight: 'none', width: 43 },
              ]}
            >
              <View style={[styles.div2]}>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 6 },
                  ]}
                >
                  {`GOV'T\nSERVICE\n(Y / N)`}
                </Text>
              </View>
            </View>
          </View>
          {weData.map((data) => {
            return (
              <View
                style={[styles.div1a, { height: getHeight(data.count) }]}
                key={uuidv4()}
              >
                {/* <View style={[styles.div1a, { height: data.workExperienceCompany.length >= 33 || data.workExperiencePositionTitle.length >= 16 ? 40 : 20 }]} key={uuidv4()}> */}
                <View style={[styles.div3b, styles.bordered, { width: 51 }]}>
                  <View style={[styles.div2, styles.width6b]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7.7 },
                        ]}
                      >
                        {moment(data.workExperienceDateFrom).format(
                          'MM/DD/YYYY'
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 49.8 }]}>
                  <View style={[styles.div2, styles.width6b]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7.7 },
                        ]}
                      >
                        {data.workInclusiveDateToFormat === 'PRESENT'
                          ? data.workInclusiveDateToFormat
                          : moment(data.workExperienceDateTo).format(
                              'MM/DD/YYYY'
                            )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered]}>
                  <View style={[styles.div2, { width: 134.7 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.workExperiencePositionTitle}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered]}>
                  <View
                    style={[styles.div2, styles.tablePadding, { width: 154.5 }]}
                  >
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.7 },
                      ]}
                    >
                      {data.workExperienceCompany}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered]}>
                  <View style={[styles.div2, { width: 40 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 6.9 },
                      ]}
                    >
                      {data.workExperienceSalary.length
                        ? data.workExperienceSalary
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered]}>
                  <View style={[styles.div2, styles.width1b3]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.workExperiencePayGrade &&
                      data.workExperiencePayGrade.length
                        ? data.workExperiencePayGrade
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered]}>
                  <View style={[styles.div2, { width: 56 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.workExperienceAppointmentStatus
                        ? data.workExperienceAppointmentStatus
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 43, borderRight: 'none' },
                  ]}
                >
                  <View style={styles.div2}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 8.5 },
                      ]}
                    >
                      {data.workExperienceIsGovtService}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
          {generateWErow(67 - (data.totalCount || 0))}
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates additional Voluntary Work data.
   *
   * This function generates additional rows for the Voluntary Work section of the PDF.
   * The rows include details such as the organization name, position, period of service, and other relevant information.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing additional rows in the Voluntary Work section.
   */
  const generateAdditionalVoluntaryWork = () => {
    try {
      const volunteerWorkDataWhole =
        generateVoluntaryWorkData('remainData') || []
      const volunteerWorkData = volunteerWorkDataWhole.flatMap(
        (item) => item.chunk
      )
      const volunteerWorkDataCount = volunteerWorkDataWhole.map(
        (item) => item.totalCount
      )
      return (
        <View>
          <View style={[styles.div1a]}>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  width: 240,
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textMedium,
                    styles.textCenter,
                    { paddingLeft: 3 },
                  ]}
                >
                  {'29. '}
                </Text>
              </View>
              <View style={{ width: 247, flexDirection: 'column' }}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { marginTop: 1, fontSize: 6 },
                  ]}
                >
                  NAME & ADDRESS OF ORGANIZATION
                </Text>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 5.8 },
                  ]}
                >
                  {'(Write in full)'}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.width1,
                styles.bordered,
                styles.backgroundCol,
                { width: 90 },
              ]}
            >
              <View style={[styles.div2, { width: 90 }]}>
                <View style={[styles.div2, { marginBottom: 4 }]}>
                  <Text style={[styles.textMedium, styles.textCenter]}>
                    INCLUSIVE DATES
                  </Text>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.textCenter,
                      { fontSize: 5.8 },
                    ]}
                  >
                    (mm/dd/yyyy)
                  </Text>
                </View>
                <View style={[styles.div1, { marginTop: 5 }]}>
                  <View
                    style={[
                      styles.div2,
                      { borderTop: '1px solid black', width: 45 },
                      styles.backgroundCol,
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textCenter]}>
                      From
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.div2,
                      {
                        borderTop: '1px solid black',
                        borderLeft: '1px solid black',
                        width: 45,
                      },
                      styles.backgroundCol,
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textCenter]}>
                      To
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.div3b, styles.bordered, styles.backgroundCol]}>
              <View style={[styles.div2a, { width: 46 }]}>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 5.8 },
                  ]}
                >
                  {`NUMBER OF  HOURS`}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                { borderRight: 'none' },
              ]}
            >
              <View style={[styles.div2a, { width: 207 }]}>
                <Text
                  style={[
                    styles.marginTop1,
                    styles.textMedium,
                    styles.textCenter,
                  ]}
                >
                  POSITION / NATURE OF WORK
                </Text>
              </View>
            </View>
          </View>
          {volunteerWorkData.map((data) => {
            return (
              <View
                style={[
                  styles.div1a,
                  {
                    height: (() => {
                      if (data.count === 3) {
                        return 36
                      } else if (data.count === 2) {
                        return 24
                      } else {
                        return 12
                      }
                    })(),
                  },
                ]}
                key={uuidv4()}
              >
                <View style={[styles.div3b, styles.bordered, { width: 240 }]}>
                  <View style={styles.div2}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.6 },
                      ]}
                    >
                      {data.voluntaryWorkOrg ? data.voluntaryWorkOrg : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 45.5 }]}>
                  <View style={[styles.div2, styles.width6b]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7.6 },
                        ]}
                      >
                        {data.voluntaryWorkDateIsNA === 'N/A'
                          ? 'N/A'
                          : moment(data.voluntaryWorkDateFrom).format(
                              'MM/DD/YYYY'
                            )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 44.5 }]}>
                  <Text
                    style={[
                      styles.textMediumDataBold,
                      styles.tablePadding,
                      styles.textCenter,
                      styles.textBold,
                      { fontSize: 7.6 },
                    ]}
                  >
                    {data.voluntaryWorkDateIsNA === 'N/A'
                      ? 'N/A'
                      : moment(data.voluntaryWorkDateTo).format('MM/DD/YYYY')}
                  </Text>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 47 }]}>
                  <Text
                    style={[
                      styles.textMediumDataBold,
                      styles.tablePadding,
                      styles.textCenter,
                      styles.textBold,
                      { fontSize: 7.6 },
                    ]}
                  >
                    {data.voluntaryWorkHoursNo
                      ? data.voluntaryWorkHoursNo
                      : 'N/A'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 207, borderRight: 'none' },
                  ]}
                >
                  <Text
                    style={[
                      styles.textMediumDataBold,
                      styles.tablePadding,
                      styles.textCenter,
                      styles.textBold,
                      { fontSize: 7.6 },
                    ]}
                  >
                    {data.voluntaryWorkPosition
                      ? data.voluntaryWorkPosition
                      : 'N/A'}
                  </Text>
                </View>
              </View>
            )
          })}
          {generateVWrow(75 - volunteerWorkDataCount[0])}
          <View
            style={[
              styles.textSmallBold,
              styles.textColorRed,
              styles.backgroundCol,
              { height: 11 },
            ]}
          >
            <Text
              style={[
                styles.textCenter,
                styles.textItalicBold,
                { fontSize: 6.6, marginTop: 2 },
              ]}
            >
              (Continue on separate sheet if necessary)
            </Text>
          </View>
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates Learning and Development (LnD) data.
   *
   * This function processes the Learning and Development data from the PDF data,
   * sorts it, and categorizes it into chunks of 21 items each. It returns the
   * sorted limited data and the categorized remaining data.
   *
   * @returns {Object} An object containing:
   * - `lndData`: An array of the first 21 items of sorted LnD data.
   * - `categorizedRemainingData`: An array of arrays, each containing up to 21 items of the remaining LnD data.
   */
  const generateLnDData = (data) => {
    try {
      if (pdfData.well4Well11DataGrid) {
        let count = 0
        const lndData = []
        const remainingData = []

        for (const data of pdfData.well4Well11DataGrid) {
          const ldSponsoredByLength = data.ldSponsoredBy
            ? data.ldSponsoredBy.length
            : 0
          const ldTrainingTitleLength = data.ldTrainingTitle
            ? data.ldTrainingTitle.length
            : 0

          let itemCount = 1
          if (ldTrainingTitleLength >= 341 || ldSponsoredByLength >= 169) {
            itemCount = 7
          } else if (
            ldTrainingTitleLength >= 281 ||
            ldSponsoredByLength >= 150
          ) {
            itemCount = 6
          } else if (
            ldTrainingTitleLength >= 221 ||
            ldSponsoredByLength >= 133
          ) {
            itemCount = 5
          } else if (
            ldTrainingTitleLength >= 168 ||
            ldSponsoredByLength >= 92
          ) {
            itemCount = 4
          } else if (
            ldTrainingTitleLength >= 103 ||
            ldSponsoredByLength >= 59
          ) {
            itemCount = 3
          } else if (ldTrainingTitleLength >= 63 || ldSponsoredByLength >= 39) {
            itemCount = 2
          }

          // Check if adding the current itemCount would exceed the limit
          if (count + itemCount <= 51) {
            // if (count + itemCount <= 5) {
            count += itemCount
            const dataWithCount = { ...data, count: itemCount }
            lndData.push(dataWithCount)
          } else {
            remainingData.push(data)
          }
        }
        const lndDataSorted = lndData.sort(
          (a, b) =>
            new Date(b.ldTrainingDateFrom) - new Date(a.ldTrainingDateFrom)
        )

        const chunkArray = (array, maxCount) => {
          const result = []
          let currentChunk = []
          let currentCount = 0

          array.forEach((data) => {
            const ldSponsoredByLength = data.ldSponsoredBy
              ? data.ldSponsoredBy.length
              : 0
            const ldTrainingTitleLength = data.ldTrainingTitle
              ? data.ldTrainingTitle.length
              : 0
            let itemCount = 1
            if (ldTrainingTitleLength >= 341 || ldSponsoredByLength >= 169) {
              itemCount = 7
            } else if (
              ldTrainingTitleLength >= 281 ||
              ldSponsoredByLength >= 150
            ) {
              itemCount = 6
            } else if (
              ldTrainingTitleLength >= 221 ||
              ldSponsoredByLength >= 133
            ) {
              itemCount = 5
            } else if (
              ldTrainingTitleLength >= 168 ||
              ldSponsoredByLength >= 92
            ) {
              itemCount = 4
            } else if (
              ldTrainingTitleLength >= 103 ||
              ldSponsoredByLength >= 59
            ) {
              itemCount = 3
            } else if (
              ldTrainingTitleLength >= 63 ||
              ldSponsoredByLength >= 39
            ) {
              itemCount = 2
            }

            if (currentCount + itemCount > maxCount) {
              result.push(currentChunk)
              currentChunk = []
              currentCount = 0
            }

            currentChunk.push({ ...data, count: itemCount })
            currentCount += itemCount
          })

          if (currentChunk.length > 0) {
            result.push(currentChunk)
          }

          return result
        }

        const categorizedRemainingData = chunkArray(remainingData, 73).map(
          (chunk, index) => {
            let chunkCount = 0
            chunk.forEach((data) => {
              chunkCount += data.count
            })
            return { chunk, totalCount: chunkCount }
          }
        )

        if (data === 'firstPage') {
          return { lndDataSorted, count }
        } else {
          return categorizedRemainingData
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const generateVoluntaryWorkData = (pageIndex) => {
    try {
      if (pdfData && pdfData.well4Well10DataGrid) {
        let count = 0
        const voluntaryWorkData = []
        const remainingData = []

        for (const data of pdfData.well4Well10DataGrid) {
          const voluntaryWorkPositionLength = data?.voluntaryWorkPosition
            ? data?.voluntaryWorkPosition.length
            : 0
          const voluntaryWorkOrgLength = data?.voluntaryWorkOrg
            ? data?.voluntaryWorkOrg.length
            : 0

          let itemCount = 1

          if (
            voluntaryWorkPositionLength >= 135 ||
            voluntaryWorkOrgLength >= 171
          ) {
            itemCount = 3
          } else if (
            voluntaryWorkPositionLength >= 52 ||
            voluntaryWorkOrgLength >= 60
          ) {
            itemCount = 2
          }

          count += itemCount

          const dataWithCount = { ...data, count: itemCount }
          if (count <= 7) {
            voluntaryWorkData.push(dataWithCount)
          } else {
            remainingData.push(dataWithCount)
          }
        }

        const chunkArray = (array, maxCount) => {
          const result = []
          let currentChunk = []
          let currentCount = 0

          array.forEach((data) => {
            const voluntaryWorkPositionLength = data.voluntaryWorkPosition
              ? data.voluntaryWorkPosition.length
              : 0
            const voluntaryWorkOrgLength = data.voluntaryWorkOrg
              ? data.voluntaryWorkOrg.length
              : 0
            let itemCount = 1
            if (
              voluntaryWorkPositionLength >= 135 ||
              voluntaryWorkOrgLength >= 171
            ) {
              itemCount = 3
            } else if (
              voluntaryWorkPositionLength >= 52 ||
              voluntaryWorkOrgLength >= 60
            ) {
              itemCount = 2
            }

            if (currentCount + itemCount > maxCount) {
              result.push(currentChunk)
              currentChunk = []
              currentCount = 0
            }

            currentChunk.push({ ...data, count: itemCount })
            currentCount += itemCount
          })

          if (currentChunk.length > 0) {
            result.push(currentChunk)
          }

          return result
        }

        const categorizedRemainingData = chunkArray(remainingData, 44).map(
          (chunk, index) => {
            let chunkCount = 0
            chunk.forEach((data) => {
              chunkCount += data.count
            })
            return { chunk, totalCount: chunkCount }
          }
        )

        if (pageIndex === 'firstPage') {
          return { voluntaryWorkData: voluntaryWorkData, totalCount: count }
        } else {
          return categorizedRemainingData
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  // generateVoluntaryWorkData('firstPage')
  /**
   * Generates additional Learning and Development (LnD) data.
   *
   * This function processes additional Learning and Development data from the PDF data,
   * sorts it, and categorizes it into chunks of 21 items each. It returns the
   * sorted limited data and the categorized remaining data.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing additional rows in the Learning and Development section.
   */
  const generateAdditionalLNDdata = (data) => {
    try {
      const lndData = data ? data.chunk : []

      return (
        <View>
          {lndData.map((data) => {
            return (
              <View
                style={[styles.div1a, { height: getHeight(data.count) }]}
                key={uuidv4()}
              >
                <View style={[styles.div3b, styles.bordered, { width: 239.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize:
                            data.ldTrainingTitle &&
                            data.ldTrainingTitle.length > 214
                              ? 7
                              : 7.6,
                        },
                      ]}
                    >
                      {data.ldTrainingTitle ? data.ldTrainingTitle : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 45.5 }]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.6 },
                      ]}
                    >
                      {data.ldTrainingDateFrom
                        ? moment(data.ldTrainingDateFrom).format('MM/DD/YYYY')
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 44.5 }]}>
                  <Text
                    style={[
                      styles.textMediumDataBold,
                      styles.tablePadding,
                      styles.textCenter,
                      styles.textBold,
                      { fontSize: 7.6 },
                    ]}
                  >
                    {data.ldTrainingDateTo
                      ? moment(data.ldTrainingDateTo).format('MM/DD/YYYY')
                      : 'N/A'}
                  </Text>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 47.8 }]}>
                  <View style={[styles.div2, { width: 47.8 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.6 },
                      ]}
                    >
                      {data.ldTrainingHoursNo.length
                        ? data.ldTrainingHoursNo
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={[styles.div3b, styles.bordered, { width: 53.9 }]}>
                  <View style={[styles.div2, { width: 53.9 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        { fontSize: 7.6 },
                      ]}
                    >
                      {data.ldType.length ? data.ldType : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.div3b,
                    styles.bordered,
                    { width: 152.8, borderRight: 'none' },
                  ]}
                >
                  <View style={[styles.div2, { width: 152.8 }]}>
                    <Text
                      style={[
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textCenter,
                        styles.textBold,
                        {
                          fontSize: 7.6,
                        },
                      ]}
                    >
                      {data.ldSponsoredBy && data.ldSponsoredBy.length
                        ? data.ldSponsoredBy
                        : 'N/A'}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Generates additional Other Information data.
   *
   * This function processes additional Other Information data from the PDF data,
   * sorts it, and categorizes it into chunks of 21 items each. It returns the
   * sorted limited data and the categorized remaining data.
   *
   * @param {number} count - The current count of rows generated.
   * @returns {JSX.Element} A JSX element representing additional rows in the Other Information section.
   */
  const generateAdditionalOtherInformation = () => {
    try {
      return (
        <View>
          <View style={[styles.div1a, styles.fullWidth]}>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  width: '25%',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { paddingLeft: 3, marginTop: 7 },
                  ]}
                >
                  {'31. '}
                </Text>
              </View>
              <View style={{ width: 247, flexDirection: 'column' }}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { marginTop: 9, fontSize: 6 },
                  ]}
                >
                  SPECIAL SKILLS and HOBBIES
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  width: '49%',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { paddingLeft: 3, marginTop: 7 },
                  ]}
                >
                  {'32. '}
                </Text>
              </View>
              <View style={{ width: 247, flexDirection: 'column' }}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { marginTop: 6, fontSize: 6 },
                  ]}
                >
                  NON-ACADEMIC DISTINCTIONS / RECOGNITION
                </Text>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 5.8 },
                  ]}
                >
                  {'(Write in full)'}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.div3b,
                styles.bordered,
                styles.backgroundCol,
                {
                  width: '26%',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  borderRight: 'none',
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { paddingLeft: 3, marginTop: 7 },
                  ]}
                >
                  {'33. '}
                </Text>
              </View>
              <View style={{ width: 247, flexDirection: 'column' }}>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { marginTop: 6, fontSize: 6 },
                  ]}
                >
                  MEMBERSHIP IN ASSOCIATION/ORGANIZATION
                </Text>
                <Text
                  style={[
                    styles.textMedium,
                    styles.textCenter,
                    { fontSize: 5.8 },
                  ]}
                >
                  {'(Write in full)'}
                </Text>
              </View>
            </View>
          </View>
          {otherInformationData &&
            otherInformationData[1] &&
            otherInformationData[1].map((data) => {
              let height
              if (data.count === 1) {
                height = 12
              } else if (data.count === 2) {
                height = 24
              } else if (data.count === 3) {
                height = 36
              } else {
                height = 12
              }
              return (
                <View key={uuidv4()} style={[styles.div1a, { height: height }]}>
                  <View
                    style={[styles.div3b, styles.bordered, { width: '25%' }]}
                  >
                    <View style={[styles.div2, styles.fullWidth]}>
                      <View style={styles.div2}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                            { fontSize: 7.6 },
                          ]}
                        >
                          {data.skillsAndHobbies}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: '49%' }]}
                  >
                    <View style={[styles.div2, styles.fullWidth]}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7.6 },
                        ]}
                      >
                        {data.nonAcademicDistinctions}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: '26%', borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2, styles.fullWidth]}>
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7.6 },
                        ]}
                      >
                        {data.orgMembership}
                      </Text>
                    </View>
                  </View>
                </View>
              )
            })}
          {Array.from({ length: 75 - otherInforCountNextPage }, (_, index) => (
            <View key={index} style={[styles.div1a, { height: 12 }]}>
              <View style={[styles.div3b, styles.bordered, { width: '25%' }]}>
                <View style={[styles.div2, styles.fullWidth]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMedium,
                        styles.textCenter,
                        styles.textBold,
                      ]}
                    >
                      {' '}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[styles.div3b, styles.bordered, { width: '49%' }]}>
                <View style={[styles.div2, styles.fullWidth]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.bordered,
                  { width: '26%', borderRight: 'none' },
                ]}
              >
                <View style={[styles.div2, styles.fullWidth]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
          ))}
          {Array.from({ length: 1 }, (_, index) => (
            <View key={index} style={[styles.div1a, { height: 15 }]}>
              <View style={[styles.div3b, styles.bordered, { width: '25%' }]}>
                <View style={[styles.div2, styles.fullWidth]}>
                  <View style={[styles.div2]}>
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textMedium,
                        styles.textCenter,
                        styles.textBold,
                      ]}
                    >
                      {' '}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[styles.div3b, styles.bordered, { width: '49%' }]}>
                <View style={[styles.div2, styles.fullWidth]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.div3b,
                  styles.bordered,
                  { width: '26%', borderRight: 'none' },
                ]}
              >
                <View style={[styles.div2, styles.fullWidth]}>
                  <Text
                    style={[
                      styles.marginTop1,
                      styles.textMedium,
                      styles.textCenter,
                      styles.textBold,
                    ]}
                  >
                    {' '}
                  </Text>
                </View>
              </View>
            </View>
          ))}
          <View
            style={[
              styles.textSmallBold,
              styles.backgroundCol,
              styles.textColorRed,
            ]}
          >
            <Text
              style={[
                styles.textCenter,
                styles.textItalicBold,
                { fontSize: 6.6 },
              ]}
            >
              (Continue on separate sheet if necessary)
            </Text>
          </View>
        </View>
      )
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='mt-3'>
      {pdfData && isDone ? (
        <PDFViewer
          style={{ width: '100%', height: '800px' }}
          showToolbar={false}
        >
          <Document>
            <Page size='LEGAL' style={[styles.page, { paddingTop: 57 }]}>
              <View style={[styles.div1c, { paddingTop: 0 }]}>
                <View>
                  <View>
                    <Text style={[styles.headerFont, { fontSize: 9 }]}>
                      CS Form No. 212
                    </Text>
                  </View>
                  <View style={{ height: '43px', marginBottom: 0 }}>
                    <Text
                      style={[
                        styles.headerFont,
                        { marginBottom: 7, marginTop: 2, fontSize: 7.4 },
                      ]}
                    >
                      Revised 2017
                    </Text>
                    <View style={[styles.divTitle]}>
                      <Text style={[styles.title, styles.headerTitle]}>
                        PERSONAL DATA SHEET
                      </Text>
                    </View>
                  </View>

                  <View>
                    <Text
                      style={[styles.textArchivoBoldItalic, { fontSize: 6.6 }]}
                    >
                      WARNING: Any misrepresentation made in the Personal Data
                      Sheet and the Work Experience Sheet shall cause the filing
                      of administrative/criminal case/s against the person
                    </Text>
                    <Text
                      style={[
                        styles.textArchivoBoldItalic,
                        { fontSize: 6.6, marginBottom: 2 },
                      ]}
                    >
                      concerned.
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={[styles.textArchivoBoldItalic, { fontSize: 6.6 }]}
                    >
                      READ THE ATTACHED GUIDE TO FILLING OUT THE PERSONAL DATA
                      SHEET (PDS) BEFORE ACCOMPLISHING THE PDS FORM.
                    </Text>
                  </View>
                  <View style={[styles.div1]}>
                    <Text style={[styles.textMediumData, { fontSize: 7.5 }]}>
                      Print legibly. Tick appropriate boxes ({'     '} ) and use
                      separate sheet if necessary. Indicate N/A if not
                      applicable.{' '}
                      <Text
                        style={{
                          fontFamily: 'PTSansNarrow',
                          fontSize: 7.5,
                          fontWeight: 'bold',
                        }}
                      >
                        DO NOT ABBREVIATE.
                      </Text>
                    </Text>
                    <View
                      style={{
                        marginBottom: 1,
                        height: 8,
                        width: 8,
                        position: 'absolute',
                        bottom: 0.2,
                        left: 95.7,
                      }}
                    >
                      {unSelectedRadio()}
                    </View>
                    <View
                      style={[
                        { position: 'absolute', top: 0.5, right: 0 },
                        styles.div1a,
                      ]}
                    >
                      <View
                        style={[
                          styles.bordered,
                          {
                            width: '35px',
                            textAlign: 'center',
                            backgroundColor: 'gray',
                            borderRight: 'none',
                            borderBottom: 'none',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                          },
                        ]}
                      >
                        <Text
                          style={[styles.textMediumData, { fontSize: 6.6 }]}
                        >
                          1. CS ID No.
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.bordered,
                          {
                            width: '151px',
                            textAlign: 'right',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                          },
                        ]}
                      >
                        <Text
                          style={[styles.textMediumData, { fontSize: 6.6 }]}
                        >
                          (Do not fill up. For CSC use only)
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={[styles.div1, styles.titleBorder, { height: 15.5 }]}
                >
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                    ]}
                  >
                    I. PERSONAL INFORMATION
                  </Text>
                </View>

                <View style={[styles.div1a]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      styles.textAlignStart,
                      { width: 100, borderBottom: 'none', height: 18.5 },
                    ]}
                  >
                    <View style={[styles.div2, styles.width6b]}>
                      <View style={styles.div2}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textLeft,
                          ]}
                        >
                          {'2.  SURNAME'}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.borderRightNone,
                      { width: 484 },
                    ]}
                  >
                    <View
                      style={[styles.div2, styles.width6b, styles.fullWidth]}
                    >
                      <View style={[styles.div2, styles.paddingL1]}>
                        <Text
                          style={[
                            // styles.marginTop1,
                            styles.textMediumDataBold,
                            styles.textLeft,
                            styles.textBold,
                          ]}
                        >
                          {pdfData.surnameRequired || 'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: 18.5 }]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.nobordertpbtm,
                      styles.backgroundCol,
                      styles.textAlignStart,
                      styles.width6cd,
                      { height: 19 },
                    ]}
                  >
                    <View style={[styles.div2, styles.width6c]}>
                      <View style={[styles.div2, styles.paddingTitle]}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textLeft,
                          ]}
                        >
                          {' '}
                          {'  FIRST NAME'}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.width80,
                      styles.borderRightNone,
                      { width: 484 },
                    ]}
                  >
                    <View style={[styles.div2, styles.width6b, styles.width70]}>
                      <View style={[styles.div2, styles.paddingL1]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textLeft,
                            styles.textBold,
                          ]}
                        >
                          {pdfData.firstnameRequired || 'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div2,
                        styles.suffixCol,
                        styles.backgroundCol,
                        { height: 17.5 },
                      ]}
                    >
                      <View
                        style={[
                          styles.div2,
                          styles.paddingL5,
                          { height: 17.5 },
                        ]}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text
                            style={[
                              styles.textMedium,
                              styles.textLeft,
                              styles.textBold,
                              styles.paddingL5,
                              { fontSize: 5.8 },
                            ]}
                          >
                            {'NAME EXTENSION (JR., SR) '}{' '}
                          </Text>
                          <Text
                            style={[
                              styles.textMediumData,
                              styles.textLeft,
                              styles.textBold,
                              styles.paddingL1,
                            ]}
                          >
                            {pdfData.suffixNaAllowed}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      styles.textAlignStart,
                      styles.width6cd,
                      { borderTop: 'none', height: 18.5 },
                    ]}
                  >
                    <View style={[styles.div2, styles.width6b]}>
                      <View style={[styles.div2, styles.paddingTitle]}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textLeft,
                          ]}
                        >
                          {' '}
                          {'  MIDDLE NAME'}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.borderRightNone,
                      { width: 484 },
                    ]}
                  >
                    <View
                      style={[styles.div2, styles.width6b, styles.fullWidth]}
                    >
                      <View style={[styles.div2, styles.paddingL1]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textLeft,
                            styles.textBold,
                          ]}
                        >
                          {pdfData.middlenameRequired || 'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3]}>
                  <View style={{ width: '40%' }}>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          styles.width6cd,
                          { borderTop: 'none', height: 23 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text style={[styles.textMedium, styles.textLeft]}>
                              3. DATE OF BIRTH{' '}
                            </Text>
                            <Text style={[styles.textMedium, styles.marginL1]}>
                              (mm/dd/yyyy)
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View style={[styles.div2, styles.width6bd]}>
                          <View style={[styles.div2]}>
                            <Text
                              style={[
                                styles.textMediumData,
                                styles.textBold,
                                { textAlign: 'center' },
                              ]}
                            >
                              {moment(pdfData.dateofbirthRequired).format(
                                'MM/DD/YYYY'
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          styles.width6cd,
                          { borderTop: 'none', height: 19.5 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textLeft,
                                { marginTop: 5 },
                              ]}
                            >
                              4. PLACE OF BIRTH{' '}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2]}>
                            <Text
                              style={[
                                styles.textMediumData,
                                styles.textBold,
                                { textAlign: 'center', marginBottom: 1 },
                              ]}
                            >
                              {pdfData.placeofbirthRequired}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        { height: '19.5px' },
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          styles.width6cd,
                          { borderTop: 'none' },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              5. SEX{' '}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2]}>
                            <View style={[styles.div1]}>
                              <View style={[styles.div1, { marginLeft: 5 }]}>
                                <View style={[styles.margin1aBottom]}>
                                  {pdfData?.sexRequired === 'MALE'
                                    ? selectedRadio()
                                    : unSelectedRadio()}
                                </View>
                                <Text
                                  style={[styles.textSmall, styles.marginLeft2]}
                                >
                                  Male
                                </Text>
                              </View>
                              <View style={[styles.div1, { marginLeft: 25.5 }]}>
                                <View
                                  style={[
                                    styles.margin1aBottom,
                                    styles.marginL3,
                                  ]}
                                >
                                  {pdfData?.sexRequired === 'FEMALE'
                                    ? selectedRadio()
                                    : unSelectedRadio()}
                                </View>
                                <Text
                                  style={[styles.textSmall, styles.marginLeft2]}
                                >
                                  Female
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      { width: '140px', height: 62 },
                      styles.backgroundCol,
                    ]}
                  >
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        { width: 140.2, height: 62 },
                      ]}
                    >
                      <View>
                        <Text style={[styles.marginTop1, styles.textMedium]}>
                          16. CITIZENSHIP{' '}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '100%',
                          margin: 'auto',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text style={[styles.textMedium]}>
                          If holder of dual citizenship,
                        </Text>
                        <Text style={[styles.textMedium, { marginTop: 5 }]}>
                          please indicate the details.
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 212, height: 62 }}>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        { width: 212, height: 62 },
                        styles.borderRightNone,
                      ]}
                    >
                      <View
                        style={[
                          styles.div1,
                          styles.margin1Top,
                          {
                            paddingLeft: 2,
                            marginBottom: 3,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <View style={[styles.margin1aBottom]}>
                          {pdfData?.citizenshipQuestionRequired === 'FILIPINO'
                            ? selectedRadio()
                            : unSelectedRadio()}
                        </View>
                        <Text style={[styles.textSmall, { marginRight: 5 }]}>
                          Filipino
                        </Text>
                        <View style={[styles.margin1aBottom, styles.marginL1]}>
                          {pdfData?.citizenshipQuestionRequired ===
                          'DUAL CITIZENSHIP'
                            ? selectedRadio()
                            : unSelectedRadio()}
                        </View>
                        <Text style={[styles.textSmall]}>Dual Citizenship</Text>
                      </View>
                      <View style={[styles.div1, { marginLeft: 60 }]}>
                        <View style={[styles.margin1aBottom]}>
                          {pdfData?.birthOrNaturaliztion === 'BY BIRTH'
                            ? selectedRadio()
                            : unSelectedRadio()}
                        </View>
                        <Text style={[styles.textSmall]}>by birth</Text>
                        <View style={[styles.margin1aBottom, styles.marginL1]}>
                          {pdfData?.birthOrNaturaliztion === 'BY NATURALIZATION'
                            ? selectedRadio()
                            : unSelectedRadio()}
                        </View>
                        <Text style={[styles.textSmall]}>
                          by naturalization
                        </Text>
                      </View>
                      <View style={[styles.div1, { marginLeft: 65 }]}>
                        <Text style={[styles.textMedium]}>
                          Pls. indicate Country:
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        { width: 213, height: 30 },
                        styles.borderRightNone,
                      ]}
                    >
                      <Text style={[styles.textMediumData, styles.textCenter]}>
                        {pdfData.indicateCountry ? pdfData.indicateCountry : ''}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={[styles.div3]}>
                  <View style={{ width: '39.9%' }}>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 45 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          {
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          },
                        ]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            { marginRight: 8 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.margin1Bottom,
                              { marginLeft: 2, marginTop: 3 },
                            ]}
                          >
                            <Text style={[styles.textMedium, styles.textLeft]}>
                              6 CIVIL STATUS{' '}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.width6bd,
                          { paddingLeft: 2 },
                        ]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, { marginLeft: 2 }]}>
                            <View
                              style={[
                                styles.div1,
                                { paddingRight: 7.8, marginBottom: 3 },
                              ]}
                            >
                              <View style={[styles.margin1aBottom]}>
                                {pdfData?.civilStatusRequired === 'SINGLE'
                                  ? selectedRadio()
                                  : unSelectedRadio()}
                              </View>
                              <Text
                                style={[styles.textSmall, styles.marginLeft2]}
                              >
                                Single
                              </Text>
                              <View style={[styles.div1, { marginLeft: 23 }]}>
                                <View
                                  style={[
                                    styles.margin1aBottom,
                                    styles.marginL3,
                                    { marginRight: 0.5 },
                                  ]}
                                >
                                  {pdfData?.civilStatusRequired === 'MARRIED'
                                    ? selectedRadio()
                                    : unSelectedRadio()}
                                </View>
                                <Text
                                  style={[styles.textSmall, styles.marginLeft2]}
                                >
                                  Married {'  '}{' '}
                                </Text>
                              </View>
                            </View>
                            <View
                              style={[
                                styles.div1,
                                { paddingRight: 1, marginBottom: 3 },
                              ]}
                            >
                              <View style={[styles.margin1aBottom]}>
                                {pdfData?.civilStatusRequired === 'WIDOWED'
                                  ? selectedRadio()
                                  : unSelectedRadio()}
                              </View>
                              <Text
                                style={[styles.textSmall, styles.marginLeft2]}
                              >
                                Widowed
                              </Text>
                              <View style={[styles.div1, { marginLeft: 28.3 }]}>
                                <View
                                  style={[
                                    styles.margin1aBottom,
                                    styles.marginL2,
                                  ]}
                                >
                                  {pdfData?.civilStatusRequired === 'SEPARATED'
                                    ? selectedRadio()
                                    : unSelectedRadio()}
                                </View>
                                <Text
                                  style={[styles.textSmall, styles.marginLeft2]}
                                >
                                  Seperated
                                </Text>
                              </View>
                            </View>
                            <View style={styles.div1}>
                              <View style={[styles.margin1aBottom]}>
                                {pdfData?.civilStatusRequired === 'OTHERS'
                                  ? selectedRadio()
                                  : unSelectedRadio()}
                              </View>
                              <Text
                                style={[styles.textSmall, styles.marginLeft2]}
                              >
                                Other/s:{' '}
                              </Text>
                              <View style={[styles.div1, { marginLeft: 40 }]}>
                                <Text
                                  style={[
                                    styles.textMediumDataBold,
                                    styles.textSmall,
                                    styles.textBold,
                                    {
                                      fontSize:
                                        pdfData.othersSpecify &&
                                        pdfData.othersSpecify.length >= 15
                                          ? 6
                                          : 7,
                                    },
                                  ]}
                                >
                                  {pdfData.othersSpecify
                                    ? pdfData.othersSpecify
                                    : ' '}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 19 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              7. HEIGHT (m)
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.heightFormat === 'feetInches'
                                ? feetAndInchesToMeters(
                                    pdfData.heightFt,
                                    pdfData.heightIn
                                  )
                                : pdfData?.heightMeters}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 19 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              8. WEIGHT (kg)
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.weightFormat === 'pounds'
                                ? poundsToKilograms(pdfData.weightPounds)
                                : pdfData?.weightKg}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.width1e,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 92, height: '83px' },
                    ]}
                  >
                    <View>
                      <Text style={[styles.textMedium]}>
                        17. RESIDENTIAL ADDRESS{' '}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '35%',
                        margin: 'auto',
                        marginTop: '55px',
                      }}
                    >
                      <Text style={[styles.marginTop1, styles.textMedium]}>
                        ZIP CODE
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: 259, height: 83 }}>
                    <View
                      style={[
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialHouseBlockLotNoRequired &&
                                  pdfData.residentialHouseBlockLotNoRequired
                                    .length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialHouseBlockLotNoRequired ||
                              'N/A'}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialStreetRequired &&
                                  pdfData.residentialStreetRequired.length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialStreetRequired || 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            House/Block/Lot No.
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Street
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          justifyContent: 'flex-start',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialSubdivisionVillageRequired &&
                                  pdfData.residentialSubdivisionVillageRequired
                                    .length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialSubdivisionVillageRequired ||
                              'N/A'}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialBarangayRequired &&
                                  pdfData.residentialBarangayRequired.length >=
                                    29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialBarangayRequired || 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Subdivision/Village
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Barangay
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          justifyContent: 'flex-start',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialCityMunicipalityRequired &&
                                  pdfData.residentialCityMunicipalityRequired
                                    .length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialCityMunicipalityRequired ||
                              'N/A'}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.residentialProvinceRequired &&
                                  pdfData.residentialProvinceRequired.length >=
                                    29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.residentialProvinceRequired &&
                            (pdfData.residentialProvinceRequired.toLowerCase() ===
                              'ncr' ||
                              pdfData.residentialProvinceRequired.toLowerCase() ===
                                'national capital region (ncr)')
                              ? 'METRO MANILA'
                              : pdfData.residentialProvinceRequired}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            City/Municipality
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Province
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        { width: 259, height: '50px' },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.textCenter, styles.width100]}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textBold,
                            { fontSize: 7.3, marginTop: 3 },
                          ]}
                        >
                          {pdfData.residentialZipcodeNaAllowed
                            ? pdfData.residentialZipcodeNaAllowed
                            : 'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={[styles.div3]}>
                  <View style={{ width: '39.9%' }}>
                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        { height: '25px' },
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textLeft,
                                { marginTop: 7.2 },
                              ]}
                            >
                              9. BLOOD TYPE
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {bloodTypeFormat(pdfData.bloodtype)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 19 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              10. GSIS ID NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.gsisidnoRequired
                                ? pdfData.gsisidnoRequired
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.width6cd,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          { height: 20 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textLeft,
                                { marginTop: 5.5 },
                              ]}
                            >
                              11. PAG-IBIG ID NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                // styles.marginTop1,
                                styles.textMediumDataBold,
                                styles.textBold,
                                { marginTop: 1.5 },
                              ]}
                            >
                              {pdfData.pagibigIdNoRequired
                                ? pdfData.pagibigIdNoRequired
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 24 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.width6cd,
                          styles.backgroundCol,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textLeft,
                                { marginTop: 7 },
                              ]}
                            >
                              12. PHILHEALTH NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                // { marginTop: 3 }
                              ]}
                            >
                              {pdfData.philhealthNoRequired
                                ? pdfData.philhealthNoRequired
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 92, height: '88px' }}>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.backgroundCol,
                        { width: 92, height: '88px' },
                      ]}
                    >
                      <View>
                        <Text style={[styles.textMedium]}>
                          18. PERMANENT ADDRESS
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '35%',
                          margin: 'auto',
                          marginTop: '60px',
                        }}
                      >
                        <Text style={[styles.marginTop1, styles.textMedium]}>
                          ZIP CODE
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 259, height: '88px' }}>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentHouseBlockLotNoRequired &&
                                  pdfData.permanentHouseBlockLotNoRequired
                                    .length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentHouseBlockLotNoRequired || 'N/A'}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentStreetRequired &&
                                  pdfData.permanentStreetRequired.length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentStreetRequired || 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            House/Block/Lot No.
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Street
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          justifyContent: 'flex-start',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentSubdivisionVillageRequired &&
                                  pdfData.permanentSubdivisionVillageRequired
                                    .length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentSubdivisionVillageRequired ||
                              'N/A'}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentBarangayRequired &&
                                  pdfData.permanentBarangayRequired.length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentBarangayRequired || 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Subdivision/Village
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Barangay
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '50%',
                          justifyContent: 'flex-start',
                          borderBottomColor: 'rgb(166, 166, 166)',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentProvinceRequired &&
                                  pdfData.permanentProvinceRequired.length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentCityMunicipalityRequired}
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text
                            style={[
                              styles.textMediumData,
                              {
                                fontSize:
                                  pdfData.permanentProvinceRequired &&
                                  pdfData.permanentProvinceRequired.length >= 29
                                    ? 6
                                    : 8.2,
                              },
                            ]}
                          >
                            {pdfData.permanentProvinceRequired &&
                            (pdfData.permanentProvinceRequired.toLowerCase() ===
                              'ncr' ||
                              pdfData.permanentProvinceRequired.toLowerCase() ===
                                'national capital region (ncr)')
                              ? 'METRO MANILA'
                              : pdfData.permanentProvinceRequired}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.bordered,
                        styles.div3,
                        {
                          width: 259,
                          height: '40%',
                          justifyContent: 'flex-start',
                          borderTop: 'none',
                        },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.div3a]}>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            City/Municipality
                          </Text>
                        </View>
                        <View style={[styles.div3b, { width: '50%' }]}>
                          <Text style={[{ fontSize: 6.6 }, styles.textItalic]}>
                            Province
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.width1e,
                        styles.div3,
                        styles.bordered,
                        { width: 259, height: 45 },
                        styles.borderRightNone,
                      ]}
                    >
                      <View style={[styles.textCenter, styles.width100]}>
                        <Text
                          style={[
                            styles.textMediumData,
                            { fontSize: 7.3, marginTop: 3 },
                          ]}
                        >
                          {pdfData.residentialZipcodeNaAllowed
                            ? pdfData.residentialZipcodeNaAllowed
                            : 'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div3]}>
                  <View style={{ width: '39.9%' }}>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.width6cd,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          { height: 19 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              13. SSS NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.sssNumberRequired
                                ? pdfData.sssNumberRequired
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { height: 19 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textLeft,
                              ]}
                            >
                              14. TIN NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.tinNumberRequired
                                ? pdfData.tinNumberRequired.trim()
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div1a, styles.fullWidth]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.width50,
                          styles.backgroundCol,
                          styles.textAlignStart,
                          styles.width6cd,
                          { height: 19 },
                        ]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={[styles.div2, { width: '120%' }]}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textLeft,
                                styles.textMedium,
                              ]}
                            >
                              15. AGENCY EMPLOYEE NO.
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, styles.width6bd]}
                      >
                        <View
                          style={[
                            styles.div2,
                            styles.width6b,
                            styles.fullWidth,
                          ]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.agencyemployeenoNaAllowed
                                ? pdfData.agencyemployeenoNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 92 }}>
                    <View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.backgroundCol,
                            { height: 19 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              { marginRight: '22px' },
                            ]}
                          >
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMedium,
                                  styles.textLeft,
                                ]}
                              >
                                19. TELEPHONE NO.
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.backgroundCol,
                            { height: 19 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              { marginRight: '22px' },
                            ]}
                          >
                            <View style={styles.div2}>
                              <Text
                                style={[styles.textMedium, styles.textLeft]}
                              >
                                20. MOBILE NO.
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.backgroundCol,
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              { marginRight: '22px' },
                            ]}
                          >
                            <View
                              style={[
                                styles.div2,
                                { width: '120%', height: 18 },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.marginTop1,
                                  styles.textMedium,
                                  styles.textLeft,
                                ]}
                              >
                                21. E-MAIL ADDRESS(if any)
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: 259 }}>
                    <View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.borderRightNone,
                            { height: 19 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              styles.fullWidth,
                            ]}
                          >
                            <View style={[styles.div2, styles.fullWidth]}>
                              <Text
                                style={[
                                  styles.textMediumData,
                                  styles.textCenter,
                                  styles.textBold,
                                ]}
                              >
                                {pdfData.telephonenoNaAllowed
                                  ? pdfData.telephonenoNaAllowed
                                  : 'N/A'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.borderRightNone,
                            { height: 19 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              styles.fullWidth,
                            ]}
                          >
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumData,
                                  styles.textCenter,
                                  styles.textBold,
                                ]}
                              >
                                {pdfData.mobilenoNaAllowed
                                  ? pdfData.mobilenoNaAllowed
                                  : 'N/A'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View style={[styles.div1a, styles.fullWidth]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.fullWidth,
                            styles.borderRightNone,
                            { height: 19 },
                          ]}
                        >
                          <View
                            style={[
                              styles.div2,
                              styles.width6b,
                              styles.fullWidth,
                            ]}
                          >
                            <View style={[styles.div2, styles.fullWidth]}>
                              <Text
                                style={[
                                  styles.textCenter,
                                  {
                                    color:
                                      pdfData.emailAddressNaAllowed &&
                                      pdfData.emailAddressNaAllowed !== 'N/A'
                                        ? '#80007F'
                                        : 'black',
                                    textDecoration:
                                      pdfData.emailAddressNaAllowed &&
                                      pdfData.emailAddressNaAllowed !== 'N/A'
                                        ? 'underline'
                                        : 'none',
                                    fontSize: 9,
                                  },
                                ]}
                              >
                                {pdfData.emailAddressNaAllowed &&
                                pdfData.emailAddressNaAllowed !== 'N/A'
                                  ? pdfData.emailAddressNaAllowed.toLowerCase()
                                  : 'N/A'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={[styles.div1, styles.titleBorder, { height: 15.3 }]}
                >
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                    ]}
                  >
                    II. FAMILY BACKGROUND
                  </Text>
                </View>
                <View style={[styles.div3]}>
                  <View>
                    <View
                      style={[styles.div1a, styles.fullWidth, { height: 19 }]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: '1px solid #EAEAEA' },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            22. SPOUSE'S SURNAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                // styles.marginTop1,
                                styles.textMediumDataBold,
                                styles.textLeft,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.spouseSurnameNaAllowed
                                ? pdfData.spouseSurnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 170 },
                        ]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text style={[styles.textMedium]}>
                            {
                              '23. NAME of CHILDREN (Write full name and list all)'
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text style={[styles.textMedium, styles.textCenter]}>
                            {'DATE OF BIRTH (mm/dd/yyyy)'}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            FIRST NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 133 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 133 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textLeft,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.spouseFirstnameNaAllowed
                                ? pdfData.spouseFirstnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 92 },
                        ]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 92 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[styles.textMedium, { fontSize: 5.8 }]}
                            >
                              {'NAME EXTENSION (JR., SR) '}
                            </Text>
                          </View>
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text style={[styles.textMediumDataBold]}>
                              {pdfData.spouseSuffixNaAllowed
                                ? pdfData.spouseSuffixNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 1
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[0]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[0]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 1
                              ? pdfData.well3Well2DataGrid[0]
                                  .childrenFullnameNaAllowed
                              : 'N/A'}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 1 &&
                            pdfData.well3Well2DataGrid[0].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[0]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: '1px solid black' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            MIDDLE NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                styles.textLeft,
                              ]}
                            >
                              {pdfData.spouseMiddlenameNaAllowed
                                ? pdfData.spouseMiddlenameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 2
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[1]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[1]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 2
                              ? pdfData.well3Well2DataGrid[1]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 2 &&
                            pdfData.well3Well2DataGrid[1].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[1]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            OCCUPATION
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                styles.textLeft,
                              ]}
                            >
                              {pdfData.spouseOccupationNaAllowed
                                ? pdfData.spouseOccupationNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 3
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[2]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[2]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 3
                              ? pdfData.well3Well2DataGrid[2]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 3 &&
                            pdfData.well3Well2DataGrid[2].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[2]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { paddingLeft: 7 },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textLeft,
                              styles.textMedium,
                              { marginLeft: 5 },
                            ]}
                          >
                            EMPLOYER/BUSINESS NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textBold,
                                styles.textMediumDataBold,
                                (() => {
                                  try {
                                    if (pdfData?.spouseEmployerNameNaAllowed) {
                                      if (
                                        pdfData.spouseEmployerNameNaAllowed
                                          .length >= 63
                                      ) {
                                        return { fontSize: 6 }
                                      } else if (
                                        pdfData.spouseEmployerNameNaAllowed
                                          .length >= 54
                                      ) {
                                        return { fontSize: 7 }
                                      }
                                    }
                                    return styles.textMediumData
                                  } catch (error) {
                                    console.error(error)
                                    return styles.textMediumData // Default style in case of error
                                  }
                                })(),
                              ]}
                            >
                              {pdfData.spouseEmployerNameNaAllowed
                                ? pdfData.spouseEmployerNameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 4
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[3]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[3]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 4
                              ? pdfData.well3Well2DataGrid[3]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 4 &&
                            pdfData.well3Well2DataGrid[3].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[3]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { paddingLeft: 7 },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textLeft,
                              styles.textMedium,
                              { fontSize: 6.6, marginLeft: 5 },
                            ]}
                          >
                            BUSINESS ADDRESS
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textBold,
                                styles.textMediumDataBold,
                                (() => {
                                  try {
                                    if (
                                      pdfData?.spouseEmployerAddressNaAllowed
                                    ) {
                                      if (
                                        pdfData.spouseEmployerAddressNaAllowed
                                          .length >= 63
                                      ) {
                                        return { fontSize: 6 }
                                      } else if (
                                        pdfData.spouseEmployerAddressNaAllowed
                                          .length >= 54
                                      ) {
                                        return { fontSize: 7 }
                                      }
                                    }
                                    return styles.textMediumData
                                  } catch (error) {
                                    console.error(error)
                                    return styles.textMediumData // Default style in case of error
                                  }
                                })(),
                              ]}
                            >
                              {pdfData.spouseEmployerAddressNaAllowed
                                ? pdfData.spouseEmployerAddressNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 5
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[4]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[4]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 5
                              ? pdfData.well3Well2DataGrid[4]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 5 &&
                            pdfData.well3Well2DataGrid[4].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[4]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { paddingLeft: 7 },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textLeft,
                              styles.textMedium,
                              { fontSize: 6.6, marginLeft: 5 },
                            ]}
                          >
                            TELEPHONE NO.
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textBold,
                                styles.textMediumDataBold,
                              ]}
                            >
                              {pdfData.spouseEmployerTelephonenoNaAllowed
                                ? pdfData.spouseEmployerTelephonenoNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 6
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[5]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[5]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 6
                              ? pdfData.well3Well2DataGrid[5]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 6 &&
                            pdfData.well3Well2DataGrid[5].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[5]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none' },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            24. FATHER'S SURNAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.fatherSurnameNaAllowed
                                ? pdfData.fatherSurnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 7
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[6]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[6]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 7
                              ? pdfData.well3Well2DataGrid[6]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,

                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 7 &&
                            pdfData.well3Well2DataGrid[6].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[6]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            FIRST NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 133 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 133 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.fatherFirstnameNaAllowed
                                ? pdfData.fatherFirstnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 92 },
                        ]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 92 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[styles.textMedium, { fontSize: 5.8 }]}
                            >
                              {'NAME EXTENSION (JR., SR) '}
                            </Text>
                          </View>
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text style={[styles.textMediumDataBold]}>
                              {pdfData.fatherSuffixNaAllowed
                                ? pdfData.fatherSuffixNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 8
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[7]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[7]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 8
                              ? pdfData.well3Well2DataGrid[7]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 8 &&
                            pdfData.well3Well2DataGrid[7].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[7]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: '1px solid black' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            MIDDLE NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                styles.textLeft,
                              ]}
                            >
                              {pdfData.fatherMiddlenameNaAllowed
                                ? pdfData.fatherMiddlenameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 9
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[8]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[8]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 9
                              ? pdfData.well3Well2DataGrid[8]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 9 &&
                            pdfData.well3Well2DataGrid[8].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[8]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none', borderRight: 'none' },
                        ]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            25. MOTHER'S MAIDEN NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 225 },
                        ]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}></View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 10
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[9]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[9]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return { fontSize: 8.2 }
                                } catch (error) {
                                  console.error(error)
                                  return { fontSize: 8.2 } // Default font size in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 10
                              ? pdfData.well3Well2DataGrid[9]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,

                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 10 &&
                            pdfData.well3Well2DataGrid[9].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[9]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            SURNAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                              ]}
                            >
                              {pdfData.motherSurnameNaAllowed
                                ? pdfData.motherSurnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 11
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[10]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[10]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 11
                              ? pdfData.well3Well2DataGrid[10]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 11 &&
                            pdfData.well3Well2DataGrid[10].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[10]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: 'none' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            FIRST NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                styles.textLeft,
                              ]}
                            >
                              {pdfData.motherFirstnameNaAllowed
                                ? pdfData.motherFirstnameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 170 }]}
                      >
                        <View style={[styles.paddingL1, { width: 170 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                              (() => {
                                try {
                                  if (
                                    pdfData.well3Well2DataGrid &&
                                    pdfData.well3Well2DataGrid.length >= 12
                                  ) {
                                    if (
                                      pdfData.well3Well2DataGrid[11]
                                        .childrenFullnameNaAllowed.length >= 57
                                    ) {
                                      return { fontSize: 6 }
                                    } else if (
                                      pdfData.well3Well2DataGrid[11]
                                        .childrenFullnameNaAllowed.length >= 41
                                    ) {
                                      return { fontSize: 7 }
                                    }
                                  }
                                  return styles.textMediumData
                                } catch (error) {
                                  console.error(error)
                                  return styles.textMediumData // Default style in case of error
                                }
                              })(),
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 12
                              ? pdfData.well3Well2DataGrid[11]
                                  .childrenFullnameNaAllowed
                              : ' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 89, borderRight: 'none' },
                        ]}
                      >
                        <View style={{ width: 89 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.textBold,
                              styles.textCenter,
                            ]}
                          >
                            {pdfData.well3Well2DataGrid &&
                            pdfData.well3Well2DataGrid.length >= 12 &&
                            pdfData.well3Well2DataGrid[11].childrenDOBNaAllowed
                              ? moment(
                                  pdfData.well3Well2DataGrid[11]
                                    .childrenDOBNaAllowed
                                ).format('MM/DD/YYYY')
                              : ' '}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.div1a,
                        styles.fullWidth,
                        pdfData.well4DataGrid.some(
                          (item) =>
                            item.secondaryBasicEducation ===
                            'SENIOR HIGH SCHOOL'
                        )
                          ? styles.familyBackgroundHeightCellSH
                          : styles.familyBackgroundHeightCell,
                      ]}
                    >
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          styles.width6cd,
                          styles.textAlignStart,
                          { borderBottom: '1px solid black' },
                        ]}
                      >
                        <View style={[styles.div2, { marginLeft: 12 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                            ]}
                          >
                            MIDDLE NAME
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 225 }]}
                      >
                        <View
                          style={[styles.div2, styles.width6b, { width: 225 }]}
                        >
                          <View style={[styles.div2, styles.paddingL1]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textBold,
                                styles.textLeft,
                                { marginBottom: 1 },
                              ]}
                            >
                              {pdfData.motherMiddlenameNaAllowed
                                ? pdfData.motherMiddlenameNaAllowed
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 259, borderRight: 'none' },
                        ]}
                      >
                        <View style={[styles.paddingL1, { width: 259 }]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textColorRed,
                              styles.textSmallBold,
                              styles.textCenter,
                              { fontSize: 6.6 },
                              styles.textItalicBold,
                              styles.backgroundCol,
                            ]}
                          >
                            (Continue on separate sheet if necessary)
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={[styles.div1, styles.titleBorder, { height: 15.3 }]}
                >
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                    ]}
                  >
                    III. EDUCATIONAL BACKGROUND
                  </Text>
                </View>
                <View style={styles.div1}>
                  <View style={[styles.div3a]}>
                    <View
                      style={[
                        styles.div3b,
                        styles.backgroundCol,
                        styles.bordered,
                        {
                          alignItems: 'left',
                          justifyContent: 'left',
                          width: 100,
                        },
                      ]}
                    >
                      <View>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textLeft,
                            { marginLeft: 1, marginTop: 3 },
                          ]}
                        >
                          26.
                        </Text>
                      </View>
                      <View style={{ width: 90 }}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginLeft: 1, marginTop: 12 },
                          ]}
                        >
                          LEVEL
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        { width: 130 },
                      ]}
                    >
                      <View style={styles.div2}>
                        <Text style={[styles.textCenter, styles.textMedium]}>
                          NAME OF SCHOOL
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginLeft: 8, fontSize: 5.8 },
                          ]}
                        >
                          (Write in full)
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.backgroundCol,
                        styles.bordered,
                        { width: 130 },
                      ]}
                    >
                      <View style={{ width: 130 }}>
                        <Text style={[styles.textMedium, styles.textCenter]}>
                          {`BASIC EDUCATION/DEGREE/COURSE`}
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          (Write in full)
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.backgroundCol,
                        styles.bordered,
                        { width: 80 },
                      ]}
                    >
                      <View style={[styles.div2, { width: 80 }]}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            styles.margin1Top,
                            { fontSize: 5.8 },
                          ]}
                        >
                          {`PERIOD OF ATTENDANCE`}
                        </Text>
                        <View
                          style={[
                            styles.div3a,
                            { marginTop: '5px', height: 11 },
                          ]}
                        >
                          <Text
                            style={[
                              styles.textMedium,
                              styles.width9c,
                              styles.textCenter,
                              {
                                borderTop: '1px solid black',
                                borderRight: '1px solid black',
                                paddingTop: 2,
                              },
                              // styles.datePadding,
                            ]}
                          >
                            From
                          </Text>
                          <Text
                            style={[
                              styles.textMedium,
                              styles.width9c2,
                              styles.textCenter,
                              {
                                borderTop: '1px solid black',
                                paddingTop: 2,
                              },
                              // styles.datePadding,
                            ]}
                          >
                            To
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.backgroundCol,
                        styles.bordered,
                        { width: 54.5 },
                      ]}
                    >
                      <View style={styles.div2}>
                        <Text style={{ fontSize: 5.8 }}>
                          {`HIGHEST LEVEL/\nUNITS EARNED`}
                        </Text>
                        <Text style={[styles.textCenter, { fontSize: 5.8 }]}>
                          (if not graduated)
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.backgroundCol,
                        styles.bordered,
                        { width: 40 },
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textCenter]}>
                        {`YEAR\nGRADUATED`}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.width9,
                        styles.backgroundCol,
                        { width: 50, borderRight: 'none' },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textMedium,
                          { fontSize: 5.8, width: 50 },
                        ]}
                      >
                        {`SCHOLARSHIP/\nACADEMIC\nHONORS\nRECEIVED`}{' '}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: 25 }]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 100 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        { width: 100, paddingLeft: 12 },
                      ]}
                    >
                      ELEMENTARY
                    </Text>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={styles.div2}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid2 &&
                                  pdfData.well4DataGrid2[0]
                                    .elementaryNameOfSchoolNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryNameOfSchoolNaAllowed.length >=
                                    78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryNameOfSchoolNaAllowed.length >=
                                    64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0].elementaryNameOfSchoolNaAllowed.trim()}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid2 &&
                                  pdfData.well4DataGrid2[0]
                                    .elementaryBasicEducationNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryBasicEducationNaAllowed
                                      .length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryBasicEducationNaAllowed
                                      .length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0]
                            .elementaryBasicEducationNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0].elementaryDateFromNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                  >
                    <Text
                      style={[
                        styles.marginTop1,
                        styles.textCenter,
                        styles.textMediumDataBold,
                        styles.tablePadding,
                        styles.textBold,
                        { fontSize: 7 },
                      ]}
                    >
                      {pdfData.well4DataGrid2 &&
                        pdfData.well4DataGrid2.length > 0 &&
                        pdfData.well4DataGrid2[0].elementaryDateToNaAllowed}
                    </Text>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textCenter,
                          styles.textBold,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          {
                            fontSize:
                              pdfData.well4DataGrid2 &&
                              pdfData.well4DataGrid2[0]
                                .elementaryUnitsEarnedNaAllowed.length >= 22
                                ? 6
                                : 7,
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0]
                            .elementaryUnitsEarnedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0]
                            .elementaryYearGraduatedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: 50, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid2 &&
                                  pdfData.well4DataGrid2[0]
                                    .elementaryScholarshipNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryScholarshipNaAllowed.length >=
                                    60
                                  ) {
                                    return 3
                                  } else if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryScholarshipNaAllowed.length >=
                                    44
                                  ) {
                                    return 4
                                  } else if (
                                    pdfData.well4DataGrid2[0]
                                      .elementaryScholarshipNaAllowed.length >=
                                    22
                                  ) {
                                    return 5
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid2 &&
                          pdfData.well4DataGrid2.length > 0 &&
                          pdfData.well4DataGrid2[0]
                            .elementaryScholarshipNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={[
                    styles.div1a,
                    {
                      height: 25,
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 100 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        { width: 100, paddingLeft: 12 },
                      ]}
                    >
                      SECONDARY
                    </Text>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <View style={styles.div2}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                            {
                              fontSize: (() => {
                                try {
                                  const value = findJuniorHighSchoolValue(
                                    pdfData.well4DataGrid
                                  )
                                  if (
                                    value &&
                                    value.secondaryNameOfSchoolNaAllowed
                                  ) {
                                    if (
                                      value.secondaryNameOfSchoolNaAllowed
                                        .length >= 78
                                    ) {
                                      return 5
                                    } else if (
                                      value.secondaryNameOfSchoolNaAllowed
                                        .length >= 64
                                    ) {
                                      return 6
                                    }
                                  }
                                  return 7
                                } catch (error) {
                                  console.error(error)
                                  return 7 // Default font size in case of error
                                }
                              })(),
                            },
                          ]}
                        >
                          {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                            ?.secondaryNameOfSchoolNaAllowed || 'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                const value = findJuniorHighSchoolValue(
                                  pdfData.well4DataGrid
                                )
                                if (value && value.secondaryBasicEducation) {
                                  if (
                                    value.secondaryBasicEducation.length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    value.secondaryBasicEducation.length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryBasicEducation || 'N/A'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textCenter,
                          styles.textBold,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          { fontSize: 7 },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryDateFromNaAllowed || 'N/A'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryDateToNaAllowed || 'N/A'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize:
                              findJuniorHighSchoolValue(pdfData.well4DataGrid)
                                ?.secondaryUnitsEarnedNaAllowed.length >= 22
                                ? 6
                                : 7,
                          },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryUnitsEarnedNaAllowed || 'N/A'}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryYearGraduatedNaAllowed || 'N/A'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: 50, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                const value = findJuniorHighSchoolValue(
                                  pdfData.well4DataGrid
                                )
                                if (
                                  value &&
                                  value.secondaryScholarshipNaAllowed
                                ) {
                                  if (
                                    value.secondaryScholarshipNaAllowed
                                      .length >= 60
                                  ) {
                                    return 3
                                  } else if (
                                    value.secondaryScholarshipNaAllowed
                                      .length >= 44
                                  ) {
                                    return 4
                                  } else if (
                                    value.secondaryScholarshipNaAllowed
                                      .length >= 22
                                  ) {
                                    return 5
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {findJuniorHighSchoolValue(pdfData.well4DataGrid)
                          ?.secondaryScholarshipNaAllowed || 'N/A'}
                      </Text>
                    </View>
                  </View>
                </View>

                {pdfData.well4DataGrid &&
                  pdfData.well4DataGrid.some(
                    (item) =>
                      item.secondaryBasicEducation == 'SENIOR HIGH SCHOOL'
                  ) && (
                    <View style={[styles.div1a, { height: 25 }]}>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          styles.backgroundCol,
                          { width: 100 },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textMedium,
                            { width: 100, paddingLeft: 12 },
                          ]}
                        >
                          SECONDARY
                        </Text>
                      </View>

                      <View
                        style={[styles.div3b, styles.bordered, { width: 130 }]}
                      >
                        <View style={[styles.div2]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                {
                                  fontSize: (() => {
                                    try {
                                      const value = findSeniorHighSchoolValue(
                                        pdfData.well4DataGrid
                                      )
                                      if (
                                        value &&
                                        value.secondaryNameOfSchoolNaAllowed
                                      ) {
                                        if (
                                          value.secondaryNameOfSchoolNaAllowed
                                            .length >= 78
                                        ) {
                                          return 5
                                        } else if (
                                          value.secondaryNameOfSchoolNaAllowed
                                            .length >= 64
                                        ) {
                                          return 6
                                        }
                                      }
                                      return 7
                                    } catch (error) {
                                      console.error(error)
                                      return 7 // Default font size in case of error
                                    }
                                  })(),
                                },
                              ]}
                            >
                              {
                                findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                  .secondaryNameOfSchoolNaAllowed
                              }
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 130 }]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              {
                                fontSize: (() => {
                                  try {
                                    const value = findSeniorHighSchoolValue(
                                      pdfData.well4DataGrid
                                    )
                                    if (
                                      value &&
                                      value.secondaryBasicEducation
                                    ) {
                                      if (
                                        value.secondaryBasicEducation.length >=
                                        78
                                      ) {
                                        return 5
                                      } else if (
                                        value.secondaryBasicEducation.length >=
                                        64
                                      ) {
                                        return 6
                                      }
                                    }
                                    return 7
                                  } catch (error) {
                                    console.error(error)
                                    return 7 // Default font size in case of error
                                  }
                                })(),
                              },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryBasicEducation
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              { fontSize: 7 },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryDateFromNaAllowed
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              { fontSize: 7 },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryDateToNaAllowed
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              {
                                fontSize:
                                  findSeniorHighSchoolValue(
                                    pdfData.well4DataGrid
                                  ).secondaryUnitsEarnedNaAllowed >= 22
                                    ? 6
                                    : 7,
                              },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryUnitsEarnedNaAllowed
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 40 }]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              { fontSize: 7 },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryYearGraduatedNaAllowed
                            }
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 50, borderRight: 'none' },
                        ]}
                      >
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                              {
                                fontSize: (() => {
                                  try {
                                    const value = findSeniorHighSchoolValue(
                                      pdfData.well4DataGrid
                                    )
                                    if (
                                      value &&
                                      value.secondaryScholarshipNaAllowed
                                    ) {
                                      if (
                                        value.secondaryScholarshipNaAllowed
                                          .length >= 60
                                      ) {
                                        return 3
                                      } else if (
                                        value.secondaryScholarshipNaAllowed
                                          .length >= 44
                                      ) {
                                        return 4
                                      } else if (
                                        value.secondaryScholarshipNaAllowed
                                          .length >= 22
                                      ) {
                                        return 5
                                      }
                                    }
                                    return 7
                                  } catch (error) {
                                    console.error(error)
                                    return 7 // Default font size in case of error
                                  }
                                })(),
                              },
                            ]}
                          >
                            {
                              findSeniorHighSchoolValue(pdfData.well4DataGrid)
                                .secondaryScholarshipNaAllowed
                            }
                          </Text>
                        </View>
                      </View>
                    </View>
                  )}
                <View
                  style={[
                    styles.div1a,
                    {
                      height: 25,
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 100 },
                    ]}
                  >
                    <View style={{ paddingLeft: 12 }}>
                      <Text style={[styles.textMedium, { width: 100 }]}>
                        VOCATIONAL /
                      </Text>
                      <Text style={[styles.textMedium, { width: 100 }]}>
                        TRADE COURSE
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <View style={styles.div2}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                            {
                              fontSize: (() => {
                                try {
                                  if (
                                    pdfData.well4Levelvocationaltradecourse &&
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalNameOfSchoolNaAllowed
                                  ) {
                                    if (
                                      pdfData.well4Levelvocationaltradecourse[0]
                                        .vocationalNameOfSchoolNaAllowed
                                        .length >= 78
                                    ) {
                                      return 5
                                    } else if (
                                      pdfData.well4Levelvocationaltradecourse[0]
                                        .vocationalNameOfSchoolNaAllowed
                                        .length >= 64
                                    ) {
                                      return 6
                                    }
                                  }
                                  return 7
                                } catch (error) {
                                  console.error(error)
                                  return 7 // Default font size in case of error
                                }
                              })(),
                            },
                          ]}
                        >
                          {pdfData.well4Levelvocationaltradecourse &&
                            pdfData.well4Levelvocationaltradecourse.length >
                              0 &&
                            pdfData.well4Levelvocationaltradecourse[0]
                              .vocationalNameOfSchoolNaAllowed}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Levelvocationaltradecourse &&
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalBasicEducationNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalBasicEducationNaAllowed
                                      .length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalBasicEducationNaAllowed
                                      .length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalBasicEducationNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalDateFromNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalDateToNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize:
                              pdfData.well4Levelvocationaltradecourse &&
                              pdfData.well4Levelvocationaltradecourse[0]
                                .vocationalUnitsEarnedNaAllowed.length >= 22
                                ? 6
                                : 7,
                          },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalUnitsEarnedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalYeargraduatedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: 50, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Levelvocationaltradecourse &&
                                  pdfData.well4Levelvocationaltradecourse[0]
                                    .vocationalScholarshipNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalScholarshipNaAllowed.length >=
                                    60
                                  ) {
                                    return 3
                                  } else if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalScholarshipNaAllowed.length >=
                                    44
                                  ) {
                                    return 4
                                  } else if (
                                    pdfData.well4Levelvocationaltradecourse[0]
                                      .vocationalScholarshipNaAllowed.length >=
                                    22
                                  ) {
                                    return 5
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Levelvocationaltradecourse &&
                          pdfData.well4Levelvocationaltradecourse.length > 0 &&
                          pdfData.well4Levelvocationaltradecourse[0]
                            .vocationalScholarshipNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: 25 }]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 100 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        { width: 100, paddingLeft: 12 },
                      ]}
                    >
                      COLLEGE
                    </Text>
                  </View>

                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    {/* <View style={[styles.div2, { width: '136px', justifyContent: 'center', alignItems: 'center' }]}> */}
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid3 &&
                                  pdfData.well4DataGrid3[0]
                                    .collegeNameOfSchoolNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeNameOfSchoolNaAllowed.length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeNameOfSchoolNaAllowed.length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0]
                            .collegeNameOfSchoolNaAllowed}
                      </Text>
                    </View>
                    {/* </View> */}
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    {/* <View style={[styles.div2, { width: '136px', justifyContent: 'center', alignItems: 'center' }]}> */}
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid3 &&
                                  pdfData.well4DataGrid3[0]
                                    .collegeCourseNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeCourseNaAllowed.length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeCourseNaAllowed.length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0].collegeCourseNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0].collegeDateFromNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0].collegeDateToNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0].collegeUnitsEarnedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0]
                            .collegeYearGraduatedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: 50, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2, { width: 50 }]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4DataGrid3 &&
                                  pdfData.well4DataGrid3[0]
                                    .collegeScholarshipNaAllowed
                                ) {
                                  if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeScholarshipNaAllowed.length >= 60
                                  ) {
                                    return 3
                                  } else if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeScholarshipNaAllowed.length >= 44
                                  ) {
                                    return 4
                                  } else if (
                                    pdfData.well4DataGrid3[0]
                                      .collegeScholarshipNaAllowed.length >= 22
                                  ) {
                                    return 5
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4DataGrid3 &&
                          pdfData.well4DataGrid3.length > 0 &&
                          pdfData.well4DataGrid3[0].collegeScholarshipNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: 25 }]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 100 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium,
                        { width: 100, paddingLeft: 12 },
                      ]}
                    >
                      GRADUATE STUDIES
                    </Text>
                  </View>

                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <View style={[styles.div2]}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                            {
                              fontSize: (() => {
                                try {
                                  if (
                                    pdfData.well4Well5Levelgraduatestudies &&
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateNameOfSchoolNaAllowed
                                  ) {
                                    if (
                                      pdfData.well4Well5Levelgraduatestudies[0]
                                        .graduateNameOfSchoolNaAllowed.length >=
                                      78
                                    ) {
                                      return 5
                                    } else if (
                                      pdfData.well4Well5Levelgraduatestudies[0]
                                        .graduateNameOfSchoolNaAllowed.length >=
                                      64
                                    ) {
                                      return 6
                                    }
                                  }
                                  return 7
                                } catch (error) {
                                  console.error(error)
                                  return 7 // Default font size in case of error
                                }
                              })(),
                            },
                          ]}
                        >
                          {pdfData.well4Well5Levelgraduatestudies &&
                            pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                            pdfData.well4Well5Levelgraduatestudies[0]
                              .graduateNameOfSchoolNaAllowed}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 130 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Well5Levelgraduatestudies &&
                                  pdfData.well4Well5Levelgraduatestudies[0]
                                    .graduateCourseNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateCourseNaAllowed.length >= 78
                                  ) {
                                    return 5
                                  } else if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateCourseNaAllowed.length >= 64
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateCourseNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 39.1 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateDateFromNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 40.9 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateDateToNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[styles.div3b, styles.bordered, { width: 54.5 }]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Well5Levelgraduatestudies &&
                                  pdfData.well4Well5Levelgraduatestudies[0]
                                    .graduateUnitsEarnedNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateUnitsEarnedNaAllowed.length >= 33
                                  ) {
                                    return 4.5
                                  } else if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateUnitsEarnedNaAllowed.length >= 22
                                  ) {
                                    return 6
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateUnitsEarnedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.div3b, styles.bordered, { width: 40 }]}>
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          { fontSize: 7 },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateYearGraduatedNaAllowed}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      { width: 50, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMediumDataBold,
                          styles.tablePadding,
                          styles.textCenter,
                          styles.textBold,
                          {
                            fontSize: (() => {
                              try {
                                if (
                                  pdfData.well4Well5Levelgraduatestudies &&
                                  pdfData.well4Well5Levelgraduatestudies[0]
                                    .graduateScholarshipNaAllowed
                                ) {
                                  if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateScholarshipNaAllowed.length >= 60
                                  ) {
                                    return 3
                                  } else if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateScholarshipNaAllowed.length >= 45
                                  ) {
                                    return 4
                                  } else if (
                                    pdfData.well4Well5Levelgraduatestudies[0]
                                      .graduateScholarshipNaAllowed.length >= 22
                                  ) {
                                    return 5
                                  }
                                }
                                return 7
                              } catch (error) {
                                console.error(error)
                                return 7 // Default font size in case of error
                              }
                            })(),
                          },
                        ]}
                      >
                        {pdfData.well4Well5Levelgraduatestudies &&
                          pdfData.well4Well5Levelgraduatestudies.length > 0 &&
                          pdfData.well4Well5Levelgraduatestudies[0]
                            .graduateScholarshipNaAllowed}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    styles.textSmallBold,
                    styles.textColorRed,
                    { paddingTop: -1 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textCenter,
                      styles.textItalicBold,
                      styles.backgroundCol,
                      { fontSize: 6.6 },
                    ]}
                  >
                    (Continue on separate sheet if necessary)
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.bordered,
                  styles.signBelow,
                  styles.div3,
                  styles.titleBorderLeftRightNone,
                  {
                    marginTop: 51,
                    marginLeft: '14px',
                    position: 'absolute',
                  },
                ]}
              >
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow1,
                    styles.titleBorderLeftNone,
                    styles.backgroundCol,
                    { borderTop: '1px solid black', width: 100 },
                  ]}
                >
                  <Text style={[styles.textMedium, styles.textItalicBold]}>
                    SIGNATURE
                  </Text>
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow2,
                    { borderTop: '1px solid black', width: 260 },
                  ]}
                >
                  <Text style={styles.textMedium}></Text>
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow3,
                    styles.backgroundCol,
                    { borderTop: '1px solid black', width: 80 },
                  ]}
                >
                  <Text style={[styles.textMedium, styles.textItalicBold]}>
                    DATE
                  </Text>
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow4,
                    { borderTop: '1px solid black', width: 144.5 },
                  ]}
                >
                  <Text style={[styles.textXSmall, styles.formPageCountFont]}>
                    CS FORM 212 (Revised 2017), Page 1 of 4
                  </Text>
                </View>
              </View>
            </Page>

            <Page size='LEGAL' style={styles.page}>
              <View style={[styles.div1c, { paddingTop: '0' }]}>
                <View
                  style={[
                    styles.div1,
                    styles.titleBorder,
                    { borderTop: 'none' },
                  ]}
                >
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                    ]}
                  >
                    IV. CIVIL SERVICE ELIGIBILITY
                  </Text>
                </View>

                <View style={[styles.div1a]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      },
                    ]}
                  >
                    <View style={[styles.div2a, { width: 185 }]}>
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 25 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textCivilLabel,
                              styles.textCenter,
                              { marginRight: 10 },
                            ]}
                          >
                            {'27.'}
                          </Text>
                        </View>
                        <View style={{ width: 160 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textCivilLabel,
                              styles.textCenter,
                            ]}
                          >
                            {`CAREER SERVICE/ RA 1080(BOARD/ BAR) UNDER \n  SPECIAL LAWS/CES/ CSEE \n BARANGAY ELIGIBILITY /DRIVER'S LICENSE`}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      },
                    ]}
                  >
                    <View style={[styles.div2a, { width: 50 }]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textCivilLabel,
                          styles.textCenter,
                        ]}
                      >
                        {`RATING`}
                      </Text>
                      <Text
                        style={[
                          styles.textCivilLabel,
                          styles.textCenter,
                          { fontSize: 5.8 },
                        ]}
                      >
                        {`(If Applicable)`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      },
                    ]}
                  >
                    <View style={[styles.div2a, { width: 65 }]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textCivilLabel,
                          styles.textCenter,
                        ]}
                      >
                        {'DATE OF\nEXAMINATION /\nCONFERMENT'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      },
                    ]}
                  >
                    <View style={[styles.div2a, { width: 181 }]}>
                      <Text
                        style={[
                          styles.textCivilLabel,
                          styles.textCenter,
                          { marginTop: 12 },
                        ]}
                      >
                        {'PLACE OF EXAMINATION / CONFERMENT'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      },
                    ]}
                  >
                    <View style={[styles.div2, { width: 100 }]}>
                      <View
                        style={[
                          styles.div2,
                          styles.bordered,
                          styles.backgroundCol,
                          { borderRight: 'none' },
                        ]}
                      >
                        <Text
                          style={[styles.textCivilLabel, styles.textCenter]}
                        >
                          {'LICENSE (if applicable)'}
                        </Text>
                      </View>
                      <View style={{ flexDirection: 'row' }}>
                        <View
                          style={[
                            styles.backgroundCol,
                            {
                              borderBottom: '1px solid black',
                              borderRight: '1px solid black',
                              height: 22.5,
                              width: 57.4,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              styles.textCivilLabel,
                              styles.textCenter,
                              styles.marginTop1,
                            ]}
                          >
                            {'NUMBER'}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.backgroundCol,
                            {
                              borderBottom: '1px solid black',
                              borderRight: 'none',
                              height: 22.5,
                              width: 42.3,
                            },
                          ]}
                        >
                          <Text
                            style={[styles.textCivilLabel, styles.textCenter]}
                          >
                            {'Date of \n Validity'}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                {civilServiceData &&
                  civilServiceData.length > 0 &&
                  civilServiceData.map((data) => {
                    let height
                    if (data.count === 1) {
                      height = 12
                    } else if (data.count === 2) {
                      height = 24
                    } else if (data.count === 3) {
                      height = 36
                    } else {
                      height = 12
                    }
                    return (
                      <View
                        style={[styles.div1a, { height: height }]}
                        key={uuidv4()}
                      >
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 185.7 },
                          ]}
                        >
                          <View style={[styles.div2]}>
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 8.5 },
                                ]}
                              >
                                {data.civilServiceAndOthersLicense === 'OTHERS'
                                  ? data.civilServiceAndOthersLicenseOthersEligibility ||
                                    ' '
                                  : data.civilServiceAndOthersLicense || ' '}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 50.9 },
                          ]}
                        >
                          <View style={[styles.div2a, styles.width6b]}>
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 8.5 },
                                ]}
                              >
                                {data.civilServiceRating
                                  ? data.civilServiceRating
                                  : ' '}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 65.8 },
                          ]}
                        >
                          <View style={[styles.div2a, styles.width4b]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.7 },
                              ]}
                            >
                              {data.civilServiceExamDate
                                ? moment(data.civilServiceExamDate).format(
                                    'MM/DD/YYYY'
                                  )
                                : ' '}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 181.8 },
                          ]}
                        >
                          <View
                            style={[
                              // styles.div2,
                              { width: 181.8 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.civilServiceExamPlace
                                ? data.civilServiceExamPlace
                                : ' '}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 57.4 },
                          ]}
                        >
                          <View style={[styles.div2, styles.width4b]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.civilServiceLicenseNo
                                ? data.civilServiceLicenseNo
                                : ' '}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 42.3, borderRight: 'none' },
                          ]}
                        >
                          <View style={[styles.div2, styles.width7]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.7 },
                              ]}
                            >
                              {data.civilServiceLicenseValidityDateIsNA ===
                              'N/A'
                                ? 'N/A'
                                : data.civilServiceLicenseValidityDate
                                  ? moment(
                                      data.civilServiceLicenseValidityDate
                                    ).format('MM/DD/YYYY')
                                  : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )
                  })}
                {generateCSErow(8 - civilServiceDataCount)}
                <View style={[styles.textSmallBold, styles.textColorRed]}>
                  <Text
                    style={[
                      styles.textCenter,
                      styles.textItalicBold,
                      styles.backgroundCol,
                      { fontSize: 6.6 },
                    ]}
                  >
                    (Continue on separate sheet if necessary)
                  </Text>
                </View>
                <View
                  style={[styles.titleBorder, { height: 21, paddingBottom: 3 }]}
                >
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                      { marginBottom: 3, marginTop: -1 },
                    ]}
                  >
                    V. WORK EXPERIENCE
                  </Text>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.titleFontColor,
                      styles.textItalicBold,
                    ]}
                  >
                    (Include private employment. Start from your recent work)
                    Description of duties should be indicated in the attached
                    Work Experience sheet.
                  </Text>
                </View>

                <View style={[styles.div1a, { height: 40 }]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, { width: 100 }]}>
                      <View style={{ flexDirection: 'row' }}>
                        <View>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textLeft,
                              { fontSize: 6.9 },
                            ]}
                          >
                            {' 28. '}
                          </Text>
                        </View>
                        <View style={{ marginLeft: 20 }}>
                          <Text
                            style={[
                              styles.marginTop1,
                              styles.textMedium,
                              styles.textCenter,
                              { fontSize: 6.9 },
                            ]}
                          >
                            {'INCLUSIVE DATES'}
                          </Text>
                          <Text
                            style={[
                              styles.textMedium,
                              styles.textCenter,
                              { marginLeft: 7, fontSize: 5.8 },
                            ]}
                          >
                            {'(mm/dd/yyyy)'}
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.div4, { marginTop: 8 }]}>
                        <View
                          style={[
                            styles.div2,
                            styles.width4bc,
                            { borderTop: '1px solid black' },
                          ]}
                        >
                          <Text
                            style={[
                              styles.textMedium,
                              styles.textCenter,
                              { marginTop: 1, fontSize: 6.9 },
                            ]}
                          >
                            From
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.div2,
                            styles.width4bc,
                            {
                              borderTop: '1px solid black',
                              borderLeft: '1px solid black',
                            },
                          ]}
                        >
                          <Text
                            style={[
                              styles.textMedium,
                              styles.textCenter,
                              { marginTop: 1, fontSize: 6.9 },
                            ]}
                          >
                            To
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, { width: 134.5 }]}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 6.9 },
                        ]}
                      >
                        {`POSITION TITLE`}
                      </Text>
                      <Text
                        style={[
                          styles.textCenter,
                          styles.textMedium,
                          { fontSize: 5.5 },
                        ]}
                      >
                        {`(Write in full/Do not abbreviate)`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, { width: 154.5 }]}>
                      <Text
                        style={[
                          styles.textCenter,
                          styles.textMedium,
                          { fontSize: 6.9 },
                        ]}
                      >
                        {'DEPARTMENT / AGENCY / OFFICE / COMPANY'}
                      </Text>
                      <Text
                        style={[
                          styles.textCenter,
                          styles.textMedium,
                          { fontSize: 5.5 },
                        ]}
                      >
                        {'(Write in full/Do not abbreviate)'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, { width: 40 }]}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 6 },
                        ]}
                      >
                        {'MONTHLY\nSALARY'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, styles.width7, { width: 50 }]}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 5.2 },
                        ]}
                      >
                        {`SALARY/ JOB/ PAY \n GRADE (if\napplicable)& STEP\n(Format "00-0")/\nINCREMENT`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2, { width: 56 }]}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 6 },
                        ]}
                      >
                        {`STATUS OF\nAPPOINTMENT`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { borderRight: 'none', width: 43 },
                    ]}
                  >
                    <View style={[styles.div2]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 6 },
                        ]}
                      >
                        {`GOV'T\nSERVICE\n(Y / N)`}
                      </Text>
                    </View>
                  </View>
                </View>
                {workExperienceData && workExperienceData.length > 0 ? (
                  workExperienceData.map((data) => {
                    return (
                      <View
                        style={[
                          styles.div1a,
                          { height: getHeight(data.count) },
                        ]}
                        key={uuidv4()}
                      >
                        {/* <View style={[styles.div1a, { height: data.workExperienceCompany.length >= 33 || data.workExperiencePositionTitle.length >= 16 ? 40 : 20 }]} key={uuidv4()}> */}
                        <View
                          style={[styles.div3b, styles.bordered, { width: 51 }]}
                        >
                          <View style={[styles.div2, styles.width6b]}>
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.7 },
                                ]}
                              >
                                {moment(data.workExperienceDateFrom).format(
                                  'MM/DD/YYYY'
                                )}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 49.8 },
                          ]}
                        >
                          <View style={[styles.div2, styles.width6b]}>
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.7 },
                                ]}
                              >
                                {data.workInclusiveDateToFormat === 'PRESENT'
                                  ? data.workInclusiveDateToFormat
                                  : moment(data.workExperienceDateTo).isValid()
                                    ? moment(data.workExperienceDateTo).format(
                                        'MM/DD/YYYY'
                                      )
                                    : ''}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={[styles.div3b, styles.bordered]}>
                          <View style={[styles.div2, { width: 134.7 }]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.workExperiencePositionTitle}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.div3b, styles.bordered]}>
                          <View
                            style={[
                              styles.div2,
                              styles.tablePadding,
                              { width: 154.5 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.7 },
                              ]}
                            >
                              {data.workExperienceCompany}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.div3b, styles.bordered]}>
                          <View style={[styles.div2, { width: 40 }]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 6.9 },
                              ]}
                            >
                              {data.workExperienceSalary.length
                                ? data.workExperienceSalary
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.div3b, styles.bordered]}>
                          <View style={[styles.div2, styles.width1b3]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.workPayGradeIsNA === 'N/A'
                                ? 'N/A'
                                : `${data.workExperiencePayGrade} - ${data.workExperienceStepIncrement === 'notsure' ? '' : data.workExperienceStepIncrement}`}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.div3b, styles.bordered]}>
                          <View style={[styles.div2, { width: 56 }]}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.workExperienceAppointmentStatus
                                ? data.workExperienceAppointmentStatus
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: 43, borderRight: 'none' },
                          ]}
                        >
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 8.5 },
                              ]}
                            >
                              {data.workExperienceIsGovtService}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )
                  })
                ) : (
                  <View style={[styles.div1a, { height: 12 }]} key={uuidv4()}>
                    {/* <View style={[styles.div1a, { height: data.workExperienceCompany.length >= 33 || data.workExperiencePositionTitle.length >= 16 ? 40 : 20 }]} key={uuidv4()}> */}
                    <View
                      style={[styles.div3b, styles.bordered, { width: 51 }]}
                    >
                      <View style={[styles.div2, styles.width6b]}>
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                            ]}
                          >
                            {'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.div3b, styles.bordered, { width: 49.8 }]}
                    >
                      <View style={[styles.div2, styles.width6b]}>
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.tablePadding,
                              styles.textCenter,
                              styles.textBold,
                            ]}
                          >
                            {'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3b, styles.bordered]}>
                      <View style={[styles.div2, { width: 134.7 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.div3b, styles.bordered]}>
                      <View style={[styles.div2, { width: 154.5 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.div3b, styles.bordered]}>
                      <View style={[styles.div2, { width: 40 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.div3b, styles.bordered]}>
                      <View style={[styles.div2, styles.width1b3]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.div3b, styles.bordered]}>
                      <View style={[styles.div2, { width: 56 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        { width: 43, borderRight: 'none' },
                      ]}
                    >
                      <View style={styles.div2}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.tablePadding,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {'N/A'}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {workExperienceData && workExperienceData.length > 0
                  ? generateWErow(61 - workExperienceCount)
                  : generateWErow(60)}
                <View style={[styles.textSmallBold, styles.textColorRed]}>
                  <Text
                    style={[
                      styles.textCenter,
                      styles.textItalicBold,
                      styles.backgroundCol,
                      { fontSize: 6.6, height: 14 },
                    ]}
                  >
                    (Continue on separate sheet if necessary)
                  </Text>
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow5,
                    styles.div3,
                    styles.titleBorderLeftRightNone,
                  ]}
                >
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow1,
                      styles.titleBorderLeftNone,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 101 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      SIGNATURE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow2,
                      { borderTop: '1px solid black', width: 211 },
                    ]}
                  >
                    <Text style={styles.textMedium}></Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow3,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 80 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      DATE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow4,
                      { borderTop: '1px solid black', width: 192.5 },
                    ]}
                  >
                    <Text style={[styles.textXSmall, styles.formPageCountFont]}>
                      CS FORM 212 (Revised 2017), Page 2 of 4
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
            <Page size='LEGAL' style={styles.page}>
              <View style={[styles.div1c, { paddingTop: '0' }]}>
                <View>
                  <View
                    style={[
                      styles.div1,
                      styles.titleBorder,
                      { height: 15, borderTop: 'none' },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium2,
                        styles.textItalicBold,
                        styles.titleFontColor,
                      ]}
                    >
                      VI. VOLUNTARY WORK OR INVOLVEMENT IN CIVIC /
                      NON-GOVERNMENT / PEOPLE / VOLUNTARY ORGANIZATION/S
                    </Text>
                  </View>
                  <View style={[styles.div1a]}>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        {
                          width: 240,
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        },
                      ]}
                    >
                      <View>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textCenter,
                            { paddingLeft: 3 },
                          ]}
                        >
                          {'29. '}
                        </Text>
                      </View>
                      <View style={{ width: 247, flexDirection: 'column' }}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginTop: 1, fontSize: 6 },
                          ]}
                        >
                          NAME & ADDRESS OF ORGANIZATION
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          {'(Write in full)'}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.width1,
                        styles.bordered,
                        styles.backgroundCol,
                        { width: 90 },
                      ]}
                    >
                      <View style={[styles.div2, { width: 90 }]}>
                        <View style={[styles.div2, { marginBottom: 4 }]}>
                          <Text style={[styles.textMedium, styles.textCenter]}>
                            INCLUSIVE DATES
                          </Text>
                          <Text
                            style={[
                              styles.textMedium,
                              styles.textCenter,
                              { fontSize: 5.8 },
                            ]}
                          >
                            (mm/dd/yyyy)
                          </Text>
                        </View>
                        <View style={[styles.div1, { marginTop: 5 }]}>
                          <View
                            style={[
                              styles.div2,
                              { borderTop: '1px solid black', width: 45 },
                              styles.backgroundCol,
                            ]}
                          >
                            <Text
                              style={[styles.textMedium, styles.textCenter]}
                            >
                              From
                            </Text>
                          </View>

                          <View
                            style={[
                              styles.div2,
                              {
                                borderTop: '1px solid black',
                                borderLeft: '1px solid black',
                                width: 45,
                              },
                              styles.backgroundCol,
                            ]}
                          >
                            <Text
                              style={[styles.textMedium, styles.textCenter]}
                            >
                              To
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                      ]}
                    >
                      <View style={[styles.div2a, { width: 46 }]}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          {`NUMBER OF  HOURS`}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        { borderRight: 'none' },
                      ]}
                    >
                      <View style={[styles.div2a, { width: 207 }]}>
                        <Text
                          style={[
                            styles.marginTop1,
                            styles.textMedium,
                            styles.textCenter,
                          ]}
                        >
                          POSITION / NATURE OF WORK
                        </Text>
                      </View>
                    </View>
                  </View>

                  {pdfData.well4Well10DataGrid ? (
                    generateVoluntaryWorkData(
                      'firstPage'
                    )?.voluntaryWorkData.map((data) => {
                      return (
                        <View
                          style={[
                            styles.div1a,
                            {
                              height: getHeight(data.count),
                            },
                          ]}
                          key={uuidv4()}
                        >
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 240 },
                            ]}
                          >
                            <View style={styles.div2}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.voluntaryWorkOrg
                                  ? data.voluntaryWorkOrg
                                  : 'N/A'}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 45.5 },
                            ]}
                          >
                            <View style={[styles.div2, styles.width6b]}>
                              <View style={styles.div2}>
                                <Text
                                  style={[
                                    styles.textMediumDataBold,
                                    styles.tablePadding,
                                    styles.textCenter,
                                    styles.textBold,
                                    { fontSize: 7.6 },
                                  ]}
                                >
                                  {moment(data.voluntaryWorkDateFrom).isValid()
                                    ? moment(data.voluntaryWorkDateFrom).format(
                                        'MM/DD/YYYY'
                                      )
                                    : 'N/A'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 44.5 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.6 },
                              ]}
                            >
                              {data.voluntaryWorkDateIsNA === 'N/A'
                                ? 'N/A'
                                : moment(data.voluntaryWorkDateTo).isValid()
                                  ? moment(data.voluntaryWorkDateTo).format(
                                      'MM/DD/YYYY'
                                    )
                                  : 'N/A'}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 47 },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.6 },
                              ]}
                            >
                              {data.voluntaryWorkHoursNo
                                ? data.voluntaryWorkHoursNo
                                : 'N/A'}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 207, borderRight: 'none' },
                            ]}
                          >
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.6 },
                              ]}
                            >
                              {data.voluntaryWorkPosition
                                ? data.voluntaryWorkPosition
                                : 'N/A'}
                            </Text>
                          </View>
                        </View>
                      )
                    })
                  ) : (
                    <View style={[styles.div1a, { height: 12 }]}>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 240 }]}
                      >
                        <View style={styles.div2}>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textCenter,
                              styles.textBold,
                            ]}
                          >
                            {' '}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 45.5 }]}
                      >
                        <View style={[styles.div2, styles.width6b]}>
                          <View style={styles.div2}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textCenter,
                                styles.textBold,
                              ]}
                            >
                              {' '}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 44.5 }]}
                      >
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                      <View
                        style={[styles.div3b, styles.bordered, { width: 47 }]}
                      >
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.div3b,
                          styles.bordered,
                          { width: 207, borderRight: 'none' },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                  )}
                  {pdfData?.well4Well10DataGrid
                    ? generateVWrow(
                        7 - generateVoluntaryWorkData('firstPage').totalCount
                      )
                    : generateVWrow(7 - 1)}
                  <View style={[styles.textSmallBold, styles.textColorRed]}>
                    <Text
                      style={[
                        styles.textCenter,
                        styles.textItalicBold,
                        styles.backgroundCol,
                        { fontSize: 6.6 },
                      ]}
                    >
                      (Continue on separate sheet if necessary)
                    </Text>
                  </View>
                </View>
                <View style={[styles.titleBorder, { height: 24 }]}>
                  <Text
                    style={[
                      styles.textMedium2,
                      styles.textItalicBold,
                      styles.titleFontColor,
                      { marginBottom: 2 },
                    ]}
                  >
                    VII. LEARNING AND DEVELOPMENT (L&D) INTERVENTIONS/TRAINING
                    PROGRAMS ATTENDED
                  </Text>
                  <Text
                    style={[
                      styles.textMedium,
                      styles.titleFontColor,
                      styles.textItalicBold,
                    ]}
                  >
                    (Start from the most recent L&D/training program and include
                    only the relevant L&D/training taken for the last five (5)
                    years for Division Chief/Executive/-anagerial positions)
                  </Text>
                </View>

                <View style={[styles.div1a]}>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      {
                        width: 240,
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      },
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { paddingLeft: 3, marginTop: 7 },
                        ]}
                      >
                        {'30. '}
                      </Text>
                    </View>
                    <View style={{ width: 247, flexDirection: 'column' }}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { marginTop: 9, fontSize: 6 },
                        ]}
                      >
                        TITLE OF LEARNING AND DEVELOPMENT INTERVENTIONS/TRAINING
                        PROGRAMS
                      </Text>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 5.8 },
                        ]}
                      >
                        {'(Write in full)'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.width1,
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 90 },
                    ]}
                  >
                    <View style={[styles.div2, { width: 90 }]}>
                      <View style={styles.div2}>
                        <Text style={[styles.textMedium, styles.textCenter]}>
                          {`INCLUSIVE DATES OF \n ATTENDANCE`}
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          (mm/dd/yyyy)
                        </Text>
                      </View>
                      <View style={[styles.div1, { marginTop: 1 }]}>
                        <View
                          style={[
                            styles.div2,
                            { borderTop: '1px solid black', width: 45 },
                            styles.backgroundCol,
                          ]}
                        >
                          <Text style={[styles.textMedium, styles.textCenter]}>
                            From
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.div2,
                            {
                              borderTop: '1px solid black',
                              borderLeft: '1px solid black',
                              width: 45,
                            },
                            styles.backgroundCol,
                          ]}
                        >
                          <Text style={[styles.textMedium, styles.textCenter]}>
                            To
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2a, { width: 47 }]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 5.8 },
                        ]}
                      >
                        {`NUMBER OF HOURS`}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                    ]}
                  >
                    <View style={[styles.div2a, { width: 53 }]}>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 5.3 },
                        ]}
                      >
                        {`Type of LD \n ( Managerial/ \n Supervisory/ \n Technical/etc)`}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.div3b,
                      styles.bordered,
                      styles.backgroundCol,
                      { borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div2a, { width: 153 }]}>
                      <Text
                        style={[
                          styles.marginTop1,
                          styles.textMedium,
                          styles.textCenter,
                        ]}
                      >
                        CONDUCTED/ SPONSORED BY
                      </Text>
                      <Text
                        style={[
                          styles.textMedium,
                          styles.textCenter,
                          { fontSize: 5.8 },
                        ]}
                      >
                        (Write in full)
                      </Text>
                    </View>
                  </View>
                </View>

                {pdfData.well4Well11DataGrid ? (
                  generateLnDData('firstPage').lndDataSorted.map(
                    (data, index, array) => {
                      return (
                        <View
                          style={[
                            styles.div1a,
                            {
                              height: getHeight(data.count),
                            },
                          ]}
                          key={uuidv4()}
                        >
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 239.5 },
                            ]}
                          >
                            <View style={[styles.div2]}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  {
                                    fontSize:
                                      data.ldTrainingTitle &&
                                      data.ldTrainingTitle.length > 214
                                        ? 7
                                        : 7.6,
                                  },
                                ]}
                              >
                                {data.ldTrainingTitle
                                  ? data.ldTrainingTitle
                                  : ' '}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 45.5 },
                            ]}
                          >
                            <View style={[styles.div2]}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.ldTrainingDateFrom
                                  ? moment(data.ldTrainingDateFrom).format(
                                      'MM/DD/YYYY'
                                    )
                                  : ' '}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 44.5 },
                            ]}
                          >
                            <Text
                              style={[
                                // styles.marginTop1,
                                styles.textMediumDataBold,
                                styles.tablePadding,
                                styles.textCenter,
                                styles.textBold,
                                { fontSize: 7.6 },
                              ]}
                            >
                              {data.ldTrainingDateTo
                                ? moment(data.ldTrainingDateTo).format(
                                    'MM/DD/YYYY'
                                  )
                                : ' '}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 47.8 },
                            ]}
                          >
                            <View style={[styles.div2, { width: 47.8 }]}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.ldTrainingHoursNo
                                  ? data.ldTrainingHoursNo
                                  : ' '}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 53.9 },
                            ]}
                          >
                            <View style={[styles.div2, { width: 53.9 }]}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.ldType.length ? data.ldType : 'N/A'}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: 152.8, borderRight: 'none' },
                            ]}
                          >
                            <View style={[styles.div2, { width: 152.8 }]}>
                              <Text
                                style={[
                                  // styles.marginTop1,
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  {
                                    fontSize: 7.6,
                                  },
                                ]}
                              >
                                {data.ldSponsoredBy && data.ldSponsoredBy.length
                                  ? data.ldSponsoredBy
                                  : ' '}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )
                    }
                  )
                ) : (
                  <View
                    style={[
                      styles.div1a,
                      {
                        height: 12,
                      },
                    ]}
                  >
                    <View
                      style={[styles.div3b, styles.bordered, { width: 239.5 }]}
                    >
                      <View style={[styles.div2]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[styles.div3b, styles.bordered, { width: 45.5 }]}
                    >
                      <View style={[styles.div2]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[styles.div3b, styles.bordered, { width: 44.5 }]}
                    >
                      <Text
                        style={[
                          styles.textMediumDataBold,
                          styles.textCenter,
                          styles.textBold,
                        ]}
                      >
                        {' '}
                      </Text>
                    </View>
                    <View
                      style={[styles.div3b, styles.bordered, { width: 47.8 }]}
                    >
                      <View style={[styles.div2, { width: 47.8 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[styles.div3b, styles.bordered, { width: 53.9 }]}
                    >
                      <View style={[styles.div2, { width: 53.9 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        { width: 152.8, borderRight: 'none' },
                      ]}
                    >
                      <View style={[styles.div2, { width: 152.8 }]}>
                        <Text
                          style={[
                            styles.textMediumDataBold,
                            styles.textCenter,
                            styles.textBold,
                          ]}
                        >
                          {' '}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {generateLnDRow(
                  pdfData.well4Well11DataGrid
                    ? 51 - generateLnDData('firstPage').count
                    : 50
                )}
                <View
                  style={[
                    styles.textSmallBold,
                    styles.textColorRed,
                    styles.backgroundCol,
                    { paddingTop: 2 },
                  ]}
                >
                  <Text
                    style={[
                      styles.textCenter,
                      styles.textItalicBold,
                      { fontSize: 6.6 },
                    ]}
                  >
                    (Continue on separate sheet if necessary)
                  </Text>
                </View>
                <View style={{ position: 'absolute', bottom: 18, width: 584 }}>
                  <View
                    style={[styles.div1, styles.titleBorder, { height: 15.5 }]}
                  >
                    <Text
                      style={[
                        styles.textMedium2,
                        styles.textItalicBold,
                        styles.titleFontColor,
                      ]}
                    >
                      VIII. OTHER INFORMATION
                    </Text>
                  </View>
                  <View style={[styles.div1a, styles.fullWidth]}>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        {
                          width: '25%',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        },
                      ]}
                    >
                      <View>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { paddingLeft: 3, marginTop: 7 },
                          ]}
                        >
                          {'31. '}
                        </Text>
                      </View>
                      <View style={{ width: 247, flexDirection: 'column' }}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginTop: 9, fontSize: 6 },
                          ]}
                        >
                          SPECIAL SKILLS and HOBBIES
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        {
                          width: '49%',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        },
                      ]}
                    >
                      <View>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { paddingLeft: 3, marginTop: 7 },
                          ]}
                        >
                          {'32. '}
                        </Text>
                      </View>
                      <View style={{ width: 247, flexDirection: 'column' }}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginTop: 6, fontSize: 6 },
                          ]}
                        >
                          NON-ACADEMIC DISTINCTIONS / RECOGNITION
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          {'(Write in full)'}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3b,
                        styles.bordered,
                        styles.backgroundCol,
                        {
                          width: '26%',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          borderRight: 'none',
                        },
                      ]}
                    >
                      <View>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { paddingLeft: 3, marginTop: 7 },
                          ]}
                        >
                          {'33. '}
                        </Text>
                      </View>
                      <View style={{ width: 247, flexDirection: 'column' }}>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { marginTop: 6, fontSize: 6 },
                          ]}
                        >
                          MEMBERSHIP IN ASSOCIATION/ORGANIZATION
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.textCenter,
                            { fontSize: 5.8 },
                          ]}
                        >
                          {'(Write in full)'}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {otherInformationData[0] &&
                    otherInformationData[0].map((data, index, array) => {
                      const isLastElement = index === array.length - 1
                      const baseHeight = getHeight(data.count)
                      const finalHeight = isLastElement
                        ? baseHeight + 4
                        : baseHeight
                      return (
                        <View
                          key={uuidv4()}
                          style={[
                            styles.div1a,
                            {
                              height: finalHeight,
                            },
                          ]}
                        >
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: '25%' },
                            ]}
                          >
                            <View style={[styles.div2, styles.fullWidth]}>
                              <View style={[styles.div2]}>
                                <Text
                                  style={[
                                    styles.textMediumDataBold,
                                    styles.tablePadding,
                                    styles.textCenter,
                                    styles.textBold,
                                    {
                                      fontSize:
                                        data.skillsAndHobbies &&
                                        data.skillsAndHobbies.length >= 130
                                          ? 7
                                          : 7.6,
                                    },
                                  ]}
                                >
                                  {data.skillsAndHobbies}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: '49%' },
                            ]}
                          >
                            <View style={[styles.div2, styles.fullWidth]}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.nonAcademicDistinctions}
                                {/* WWWWWWWWWW WWWWWWWWWWWW WW WWWWWWWWWWWW WW WWWww ww wwww wWWW WWWWWWWWWW WWWWWWWWWWWW WW WWWWWWWWWWWW WW WWWww ww wwww wWWW */}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.div3b,
                              styles.bordered,
                              { width: '26%', borderRight: 'none' },
                            ]}
                          >
                            <View style={[styles.div2, styles.fullWidth]}>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.tablePadding,
                                  styles.textCenter,
                                  styles.textBold,
                                  { fontSize: 7.6 },
                                ]}
                              >
                                {data.orgMembership}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )
                    })}
                  {Array.from(
                    {
                      length:
                        7 - (otherInformationData[0] ? otherInforCount : 0),
                    },
                    (_, index) => (
                      <View key={index} style={[styles.div1a, { height: 12 }]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: '25%' },
                          ]}
                        >
                          <View style={[styles.div2, styles.fullWidth]}>
                            <View style={[styles.div2]}>
                              <Text
                                style={[
                                  styles.textMedium,
                                  styles.textCenter,
                                  styles.textBold,
                                ]}
                              >
                                {' '}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: '49%' },
                          ]}
                        >
                          <View style={[styles.div2, styles.fullWidth]}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                styles.textBold,
                              ]}
                            >
                              {' '}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            { width: '26%', borderRight: 'none' },
                          ]}
                        >
                          <View style={[styles.div2, styles.fullWidth]}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                styles.textBold,
                              ]}
                            >
                              {' '}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )
                  )}

                  <View
                    style={[
                      styles.textSmallBold,
                      styles.textColorRed,
                      styles.backgroundCol,
                      { width: '584px' },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textCenter,
                        styles.textItalicBold,
                        styles.backgroundCol,
                        { fontSize: 6.6 },
                      ]}
                    >
                      (Continue on separate sheet if necessary)
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow5,
                    styles.div3,
                    styles.titleBorderLeftRightNone,
                  ]}
                >
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow1,
                      styles.titleBorderLeftNone,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 146 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      SIGNATURE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow2,
                      { borderTop: '1px solid black', width: 211 },
                    ]}
                  >
                    <Text style={styles.textMedium}></Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow3,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 75 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      DATE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow4,
                      { borderTop: '1px solid black', width: 152.5 },
                    ]}
                  >
                    <Text style={[styles.textXSmall, styles.formPageCountFont]}>
                      CS FORM 212 (Revised 2017), Page 3 of 4
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
            <Page size='LEGAL' style={styles.page}>
              <View style={[styles.div1c, { paddingTop: '0' }]}>
                <View style={[styles.div1a, { height: '100px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View style={[styles.fullWidth, styles.div2]}>
                        <View style={[styles.div1a, { width: 389 }]}>
                          <Text style={[styles.textMediumB]}>{'34. '}</Text>
                          <Text style={[styles.textMediumB]}>
                            {`Are you related by consanguinity or affinity to the appointing or recommending authority, or to the
                                 chief of bureau or office or to the person who has immediate supervision over you in the Office,
                                 Bureau or Department where you will be appointed,
                                `}
                          </Text>
                        </View>
                      </View>
                      {/* <View style={[styles.width1b]}></View> */}
                      <View style={[styles.div3, { marginTop: -8 }]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text
                            style={[styles.textMediumB, { marginLeft: '15px' }]}
                          >
                            a. within the third degree?
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3, styles.margin1Bottom]}>
                      <View
                        style={[
                          styles.width3,
                          styles.div2,
                          { marginLeft: '15px' },
                        ]}
                      >
                        <Text style={[styles.textMediumB]}>
                          b. within the fourth degree (for Local Government Unit
                          - Career Employees)?
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.bordered,
                      { width: 230, borderRight: 'none' },
                    ]}
                  >
                    <View style={{ marginTop: 40 }}>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width1b]}>
                          <View style={[styles.div1, { paddingLeft: 10 }]}>
                            <View style={{ marginBottom: 2 }}>
                              {pdfData?.isThirdDegreeRequired === 'YES'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>YES</Text>
                            <View
                              style={[styles.marginL3, { marginBottom: 2 }]}
                            >
                              {pdfData?.isThirdDegreeRequired === 'NO' ||
                              pdfData?.isThirdDegreeRequired === 'no'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>NO</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3]}>
                      <View style={[styles.width1b]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.isFourthDegreeRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[styles.marginL3, { marginBottom: 2 }]}>
                            {pdfData?.isFourthDegreeRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View
                          style={[
                            styles.div2,
                            { marginTop: '5px', width: 210 },
                          ]}
                        >
                          <Text
                            style={[styles.textMediumB, { marginLeft: 10 }]}
                          >
                            If YES, give details:{' '}
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                styles.marginL3,
                                styles.textBold,
                              ]}
                            >
                              {pdfData?.relatedExplain || ' '}
                            </Text>
                          </Text>
                        </View>
                        <View style={{ position: 'absolute', top: 27 }}>
                          <View
                            style={{
                              borderBottomColor: 'black',
                              borderBottomWidth: 1,
                              width: 130,
                              marginLeft: 80,
                            }}
                          />
                          <View
                            style={{
                              borderBottomColor: 'black',
                              borderBottomWidth: 1,
                              width: 200,
                              marginTop: 9,
                              marginBottom: 3,
                              marginLeft: 10,
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: '110px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View style={[styles.fullWidth, styles.div2]}>
                        <Text style={[styles.textMediumB]}>
                          35. a. Have you ever been found guilty of any
                          administrative offense?
                        </Text>
                      </View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text style={[styles.textMediumB]}></Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3, styles.margin1Bottom]}>
                      <View
                        style={[
                          styles.width3,
                          styles.div2,
                          { marginLeft: '15px', marginTop: 20 },
                        ]}
                      >
                        <Text style={[styles.textMediumB]}>
                          b. Have you been criminally charged before any court?
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      { width: 230, borderRight: 'none' },
                    ]}
                  >
                    <View>
                      <View
                        style={[
                          styles.div3,
                          styles.margin1Bottom,
                          {
                            marginTop: '5px',
                            height: '40px',
                            borderBottom: '1px solid black',
                          },
                        ]}
                      >
                        <View style={[styles.width1b]}>
                          <View style={[styles.div1, { paddingLeft: 10 }]}>
                            <View style={{ marginBottom: 2 }}>
                              {pdfData?.offenseARequired === 'YES'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>YES</Text>
                            <View
                              style={[styles.marginL3, { marginBottom: 2 }]}
                            >
                              {pdfData?.offenseARequired === 'NO'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>NO</Text>
                          </View>
                          <View style={[styles.div2]}>
                            <Text
                              style={[
                                styles.textMediumB,
                                { marginLeft: 10, width: 205 },
                              ]}
                            >
                              If YES, give details:{' '}
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.textMediumB,
                                  styles.marginL3,
                                  styles.textBold,
                                ]}
                              >
                                {pdfData?.offenseADetails || ' '}
                              </Text>
                            </Text>
                          </View>
                          <View style={{ position: 'absolute', top: 23 }}>
                            <View
                              style={{
                                borderBottomColor: 'black',
                                borderBottomWidth: 1,
                                width: 130,
                                marginLeft: 80,
                              }}
                            />
                            <View
                              style={{
                                borderBottomColor: 'black',
                                borderBottomWidth: 1,
                                width: 200,
                                marginTop: 10,
                                marginBottom: 3,
                                marginLeft: 10,
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3, { paddingBottom: 5 }]}>
                      <View style={[styles.width1b]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.offenceCRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[styles.marginL3, { marginBottom: 2 }]}>
                            {pdfData?.offenceCRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text
                            style={[
                              styles.textMediumB,
                              { width: 73, marginLeft: '10px' },
                            ]}
                          >
                            If YES, give details:{' '}
                          </Text>

                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              { width: 120 },
                            ]}
                          >
                            {' '}
                            {pdfData.offenseCDetails || ' '}
                          </Text>
                        </View>

                        <View
                          style={{
                            borderTopColor: 'black',
                            borderTopWidth: 1,
                            width: 120,
                            marginLeft: 85,
                          }}
                        />
                        {/* <View style={{ borderTopColor: 'black', borderTopWidth: 1, width: 120, marginLeft: 100 }} /> */}
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            marginTop: 3,
                          }}
                        >
                          <Text
                            style={[
                              styles.textMediumB,
                              { width: 50, marginLeft: '40px' },
                            ]}
                          >
                            Date Filed:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              { width: 80 },
                            ]}
                          >
                            {pdfData?.offenseDateFiled
                              ? moment(pdfData?.offenseDateFiled).format(
                                  'MM/DD/YYYY'
                                )
                              : ' '}
                          </Text>
                        </View>

                        <View
                          style={{
                            borderTopColor: 'black',
                            borderTopWidth: 1,
                            width: 120,
                            marginLeft: 90,
                          }}
                        />
                        {/* <View style={[styles.div2, styles.fullWidth]}>
                               <Text style={[styles.textMediumB, styles.marginL3]}>
                                 {' Status of Case/s:'}
                               </Text>
                               <Text
                                 style={[
                                   styles.textMediumB,
                                   styles.marginL3,
                                   styles.textBold,
                                 ]}
                               >
                                 {pdfData?.offenseCaseStatus || ' '}
                               </Text>
                             </View> */}
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            marginTop: 3,
                          }}
                        >
                          <Text
                            style={[
                              styles.textMediumB,
                              { width: 70, marginLeft: '20px' },
                            ]}
                          >
                            Status of Case/s:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              { width: '120px' },
                            ]}
                          >
                            {pdfData?.offenseCaseStatus || ' '}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderTopColor: 'black',
                            borderTopWidth: 1,
                            width: 120,
                            marginLeft: 90,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: '55px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View
                        style={[
                          styles.fullWidth,
                          styles.div1a,
                          { width: '100%' },
                        ]}
                      >
                        <Text style={[styles.textMediumB]}>{'36. '}</Text>
                        <Text style={[styles.textMediumB]}>
                          {`Have you ever been convicted of any crime or violation of any law, decree, ordinance or regulation by 
                              any court or tribunal?`}
                        </Text>
                      </View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text style={[styles.textMediumB]}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      { width: 230, borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div3]}>
                      <View style={[styles.width1b, { marginTop: '5px' }]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.courtARequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[styles.marginL3, { marginBottom: 2 }]}>
                            {pdfData?.courtARequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              {
                                marginTop: 10,
                                marginLeft: 10,
                                width: 205,
                              },
                            ]}
                          >
                            If YES, give details:{' '}
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                styles.marginL3,
                                styles.textBold,
                              ]}
                            >
                              {pdfData?.courtADetails || ' '}
                            </Text>
                          </Text>
                        </View>
                        <View style={{ position: 'absolute', top: 32 }}>
                          <View
                            style={{
                              borderBottomColor: 'black',
                              borderBottomWidth: 1,
                              width: 130,
                              marginLeft: 80,
                            }}
                          />
                          <View
                            style={{
                              borderBottomColor: 'black',
                              borderBottomWidth: 1,
                              width: 200,
                              marginTop: 13,
                              marginBottom: 3,
                              marginLeft: 10,
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: '55px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View>
                      <View style={[styles.div1a]}>
                        <Text style={[styles.textMediumB]}>{'37. '}</Text>
                        <Text style={[styles.textMediumB]}>
                          {`Have you ever been separated from the service in any of the following modes: resignation, retirement, \n dropped from the rolls,dismissal,termination,end of term,finished contract or phased out (abolition) in the \n public or private sector?`}
                        </Text>
                      </View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text style={[styles.textMediumB]}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      { width: '230px', borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div3]}>
                      <View style={[styles.width1b, { marginTop: '5px' }]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.serviceSeparationARequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[{ marginBottom: 2 }, styles.marginL3]}>
                            {pdfData?.serviceSeparationARequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View style={[styles.div2, { marginTop: '5px' }]}>
                          <Text
                            style={[styles.textMediumB, { marginLeft: 10 }]}
                          >
                            If YES, give details:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              styles.textBold,
                              { marginLeft: 10 },
                            ]}
                          >
                            {pdfData?.serviceSeparationADetails || ' '}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 1,
                            width: 200,
                            marginBottom: 3,
                            marginLeft: 10,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={[styles.div1a, { height: '80px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View style={[styles.fullWidth, styles.div2]}>
                        <View style={[styles.div1a, { width: 360 }]}>
                          <Text style={[styles.textMediumB]}>{'38. '}</Text>
                          <Text style={[styles.textMediumB]}>
                            {`a. Have you ever been a candidate in a national or local election held within the last year
                                 (except Barangay election)?`}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.width1b]}></View>
                    </View>
                    <View
                      style={[
                        styles.div3,
                        styles.margin1Bottom,
                        { marginTop: '10px' },
                      ]}
                    >
                      <View
                        style={[
                          styles.width3,
                          styles.div2,
                          { marginLeft: '10px' },
                        ]}
                      >
                        <Text style={[styles.textMediumB]}>
                          b. Have you resigned from the government service
                          during the three (3)-month period before the {'\n'}{' '}
                          last election to promote/actively campaign for a
                          national or local candidate?
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      { width: '230px', borderRight: 'none' },
                    ]}
                  >
                    <View style={[{ marginBottom: '5px' }]}></View>

                    <View style={[styles.margin1Bottom, { marginTop: 5 }]}>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width1b]}>
                          <View style={[styles.div1, { paddingLeft: 10 }]}>
                            <View style={{ marginBottom: 2 }}>
                              {pdfData?.candidateRequired === 'YES'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>YES</Text>
                            <View
                              style={[{ marginBottom: 2 }, styles.marginL3]}
                            >
                              {pdfData?.candidateRequired === 'NO'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>NO</Text>
                          </View>
                          <View style={[styles.div2]}>
                            <View style={[styles.div2]}>
                              <Text
                                style={[styles.textMediumB, { marginLeft: 10 }]}
                              >
                                If YES, give details:
                              </Text>
                              <Text
                                style={[
                                  styles.textMediumDataBold,
                                  styles.textMediumB,
                                  styles.textBold,
                                  { marginLeft: 10, marginTop: 3 },
                                ]}
                              >
                                {pdfData?.candidateDetails || ' '}
                              </Text>
                            </View>
                            <View
                              style={{
                                borderBottomColor: 'black',
                                borderBottomWidth: 1,
                                width: 200,
                                marginTop: 5,
                                marginLeft: 10,
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3, { marginBottom: 3 }]}>
                      <View style={[styles.width1b]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.resignedRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[{ marginBottom: 2 }, styles.marginL3]}>
                            {pdfData?.resignedRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View style={[styles.div2]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              { marginBottom: 2, marginLeft: 10 },
                            ]}
                          >
                            If YES, give details:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              styles.textBold,
                              { marginLeft: 10 },
                            ]}
                          >
                            {pdfData?.resignedDetails || ' '}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 1,
                            width: 200,
                            marginTop: 4,
                            marginLeft: 10,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.div1a, { height: '55px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: '5' },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View style={[styles.fullWidth, styles.div2]}>
                        <Text style={[styles.textMediumB]}>
                          39. Have you acquired the status of an immigrant or
                          permanent resident of another country?
                        </Text>
                      </View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text style={[styles.textMediumB]}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      { width: '230px', borderRight: 'none' },
                    ]}
                  >
                    <View style={[styles.div3]}>
                      <View style={[styles.width1b, { marginTop: 7 }]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.immigrantRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[{ marginBottom: 2 }, styles.marginL3]}>
                            {pdfData?.immigrantRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View style={[styles.div2, { marginTop: '5px' }]}>
                          <Text
                            style={[styles.textMediumB, { marginLeft: 10 }]}
                          >
                            If YES, give details (country):
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              styles.textBold,
                              { marginLeft: 10 },
                            ]}
                          >
                            {pdfData?.immigrantDetails || ' '}
                          </Text>
                        </View>
                        <View
                          style={{
                            borderBottomColor: 'black',
                            borderBottomWidth: 1,
                            width: 200,
                            marginBottom: 5,
                            marginLeft: 10,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={[styles.div1a, { height: '130px' }]}>
                  <View
                    style={[
                      styles.bordered,
                      styles.backgroundCol,
                      { width: 387, padding: 5 },
                    ]}
                  >
                    <View style={[styles.margin1Bottom]}>
                      <View style={[styles.fullWidth, styles.div2]}>
                        <View style={[styles.div1a, { width: '335px' }]}>
                          <Text style={[styles.textMediumB]}>{'40. '}</Text>
                          <Text style={[styles.textMediumB]}>
                            {`Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for Disabled Persons (RA
                                7277); and (c) Solo Parents Welfare Act of 2000(RA 8972), please answer the following items:`}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, { marginTop: '10px' }]}>
                        <View style={[styles.width3, styles.div2]}>
                          <Text style={[styles.textMediumB]}>
                            a. Are you a member of any indigenous group?
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3,
                        styles.margin1Bottom,
                        { marginTop: '10px' },
                      ]}
                    >
                      <View style={[styles.width3, styles.div2]}>
                        <Text style={[styles.textMediumB]}>
                          b. Are you a person with disability?
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div3,
                        styles.margin1Bottom,
                        { marginTop: '15px' },
                      ]}
                    >
                      <View style={[styles.width3, styles.div2]}>
                        <Text style={[styles.textMediumB]}>
                          c. Are you a solo parent?
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      {
                        width: '230px',
                        borderRight: 'none',
                        paddingBottom: 5,
                      },
                    ]}
                  >
                    <View style={[{ marginBottom: '30px' }]}></View>

                    <View>
                      <View style={[styles.width1b]}></View>
                      <View style={[styles.div3, styles.margin1Bottom]}>
                        <View style={[styles.width1b, { marginTop: 10 }]}>
                          <View style={[styles.div1, { paddingLeft: 10 }]}>
                            <View style={{ marginBottom: 2 }}>
                              {pdfData?.isIndigenousRequired === 'YES'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>YES</Text>
                            <View
                              style={[{ marginBottom: 2 }, styles.marginL3]}
                            >
                              {pdfData?.isIndigenousRequired === 'NO'
                                ? selectedRadio()
                                : unSelectedRadio()}
                            </View>
                            <Text style={[styles.textMediumB]}>NO</Text>
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Text
                              style={[
                                styles.textMediumB,
                                { width: '107px', marginLeft: 2 },
                              ]}
                            >
                              If YES, please specify:
                            </Text>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                { width: '100px' },
                              ]}
                            >
                              {pdfData?.isIndigenousDetails || ' '}
                            </Text>
                          </View>

                          <View
                            style={{
                              borderTopColor: 'black',
                              borderTopWidth: 1,
                              width: 100,
                              marginLeft: 110,
                              marginTop: 2,
                            }}
                          />
                        </View>
                      </View>
                    </View>
                    <View style={[styles.div3]}>
                      <View style={[styles.width1b]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.isPersonWDisabilityRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[{ marginBottom: 2 }, styles.marginL3]}>
                            {pdfData?.isPersonWDisabilityRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text
                            style={[
                              styles.textMediumB,
                              { width: '107px', marginLeft: 2 },
                            ]}
                          >
                            If YES, please specify ID No:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              { width: '100px' },
                            ]}
                          >
                            {pdfData?.isPersonWDisabilityDetails || ' '}
                          </Text>
                        </View>

                        <View
                          style={{
                            borderTopColor: 'black',
                            borderTopWidth: 1,
                            width: 100,
                            marginLeft: 110,
                          }}
                        />
                      </View>
                    </View>
                    <View style={[styles.div3, { marginTop: '5px' }]}>
                      <View style={[styles.width1b]}>
                        <View style={[styles.div1, { paddingLeft: 10 }]}>
                          <View style={{ marginBottom: 2 }}>
                            {pdfData?.isSoloParentRequired === 'YES'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>YES</Text>
                          <View style={[{ marginBottom: 2 }, styles.marginL3]}>
                            {pdfData?.isSoloParentRequired === 'NO'
                              ? selectedRadio()
                              : unSelectedRadio()}
                          </View>
                          <Text style={[styles.textMediumB]}>NO</Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text
                            style={[
                              styles.textMediumB,
                              { width: '107px', marginLeft: 2 },
                            ]}
                          >
                            If YES, please specify ID No:
                          </Text>
                          <Text
                            style={[
                              styles.textMediumDataBold,
                              styles.textMediumB,
                              { width: '100px' },
                            ]}
                          >
                            {pdfData?.isSoloParentDetails || ' '}
                          </Text>
                        </View>

                        <View
                          style={{
                            borderTopColor: 'black',
                            borderTopWidth: 1,
                            width: 100,
                            marginLeft: 110,
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.div3}>
                  <View style={styles.div2}>
                    <View
                      style={[
                        styles.div2,
                        styles.width8,
                        styles.bordered,
                        styles.backgroundCol,
                        { height: 20, paddingTop: 3 },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textMediumB,
                          styles.textMediumData,
                          { fontSize: 7.8, marginLeft: 3 },
                        ]}
                      >
                        41. REFERENCES{' '}
                        <Text style={[styles.textColorRed]}>
                          (Person not related by consanguinity or affinity to
                          applicant /appointee)
                        </Text>
                      </Text>
                    </View>
                    <View>
                      <View style={styles.div3}>
                        <Text
                          style={[
                            styles.textMediumB,
                            styles.textCenter,
                            styles.bordered,
                            { width: '194px' },
                            styles.backgroundCol,
                          ]}
                        >
                          NAME
                        </Text>
                        <Text
                          style={[
                            styles.textMediumB,
                            styles.width6b,
                            styles.textCenter,
                            styles.bordered,

                            { width: '173px' },
                            styles.backgroundCol,
                          ]}
                        >
                          ADDRESS
                        </Text>
                        <Text
                          style={[
                            styles.textMediumB,
                            styles.textCenter,
                            styles.bordered,
                            styles.backgroundCol,
                            { width: 80 },
                          ]}
                        >
                          TEL. NO.
                        </Text>
                      </View>

                      {pdfData?.referencesRequired?.map((data) => {
                        return (
                          <View key={uuidv4()} style={styles.div3}>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                styles.textBold,
                                styles.bordered,
                                styles.textCenter,
                                { width: '194px', minHeight: 20 },
                              ]}
                            >
                              {data.referenceNameRequired}
                            </Text>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                styles.textBold,
                                styles.bordered,
                                styles.textCenter,
                                { width: '173px', minHeight: 20 },
                              ]}
                            >
                              {data.referenceAddressRequired}
                            </Text>
                            <Text
                              style={[
                                styles.textMediumDataBold,
                                styles.textMediumB,
                                styles.width6b,
                                styles.textBold,
                                styles.bordered,
                                styles.textCenter,
                                { minHeight: 20, width: 80 },
                              ]}
                            >
                              {data.referenceTel}
                            </Text>
                          </View>
                        )
                      })}
                      {generateReferenceRow(
                        pdfData.referencesRequired
                          ? 3 - pdfData.referencesRequired.length
                          : 3
                      )}
                    </View>
                    <View
                      style={[
                        styles.width8,
                        styles.bordered,
                        styles.backgroundCol,
                        styles.div1a,
                      ]}
                    >
                      <Text style={[styles.textMediumB, { marginLeft: 3 }]}>
                        {'42. '}
                      </Text>

                      <Text
                        style={[
                          styles.textMediumB,
                          {
                            marginLeft: 10,
                            textAlign: 'justify',
                            letterSpacing: 0.3,
                          },
                        ]}
                      >
                        I declare under oath that I have personally accomplished
                        this Personal Data Sheet which is a true, correct and
                        {'\n'}
                        complete statement pursuant to the provisions of
                        pertinent laws, rules and regulations of the Republic of
                        the{'\n'}
                        Philippines. I authorize the agency head/authorized
                        representative to verify/validate the contents stated
                        herein.{'\n'}I agree that any misrepresentation made in
                        this document and its attachments shall cause the filing
                        of{'\n'}
                        administrative/criminal case/s against me.
                      </Text>
                    </View>

                    <View style={[styles.div3]}>
                      <View
                        style={[
                          styles.bordered,
                          {
                            marginLeft: '10px',
                            width: '243px',
                            marginTop: '10px',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                          },
                        ]}
                      >
                        <View style={[styles.div2, styles.backgroundCol]}>
                          <View style={styles.div1a}>
                            <Text style={[styles.textMediumB]}>
                              Government Issued ID{' '}
                            </Text>
                            <Text
                              style={[
                                { fontSize: '6px', marginTop: 3 },
                                styles.textItalic,
                              ]}
                            >
                              {`(i.e.Passport, GSIS, SSS, PRC, Driver's License, etc.)`}
                            </Text>
                          </View>
                          <Text style={[styles.textMediumB, styles.textItalic]}>
                            PLEASE INDICATE ID Number and Date of Issuance
                          </Text>
                        </View>

                        <View style={styles.div2}>
                          <View
                            style={[
                              styles.bordered,
                              {
                                width: 203,
                                borderRight: 'none',
                              },
                            ]}
                          ></View>
                          <View
                            style={[
                              styles.bordered,
                              {
                                width: 203,
                                borderRight: 'none',
                                height: 20,
                              },
                            ]}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <View
                                style={{
                                  width: 93,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text style={[styles.textMediumB]}>
                                  Government Issued ID:{' '}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 145,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.textMediumB,
                                    styles.textBold,
                                    styles.textMediumDataBold,
                                    (() => {
                                      try {
                                        if (pdfData?.govtIssuedIDRequired) {
                                          if (
                                            pdfData.govtIssuedIDRequired
                                              .length >= 42
                                          ) {
                                            return { fontSize: 6 }
                                          } else if (
                                            pdfData.govtIssuedIDRequired
                                              .length >= 36
                                          ) {
                                            return { fontSize: 7 }
                                          }
                                        }
                                        return { fontSize: 9 }
                                      } catch (error) {
                                        console.error(error)
                                        return { fontSize: 9 } // Default font size in case of error
                                      }
                                    })(),
                                  ]}
                                >
                                  {pdfData?.govtIssuedIDRequired || 'N/A'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.bordered,
                              {
                                width: 203,
                                borderRight: 'none',
                                height: 20,
                              },
                            ]}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <View
                                style={{
                                  width: 93,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text style={[styles.textMediumB]}>
                                  ID/License/Passport No.:{' '}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 145,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.textMediumB,
                                    styles.textBold,
                                    styles.textMediumDataBold,
                                    (() => {
                                      try {
                                        if (pdfData?.govtIDNoRequired) {
                                          if (
                                            pdfData.govtIDNoRequired.length >=
                                            42
                                          ) {
                                            return { fontSize: 6 }
                                          } else if (
                                            pdfData.govtIDNoRequired.length >=
                                            35
                                          ) {
                                            return { fontSize: 7 }
                                          }
                                        }
                                        return { fontSize: 9 }
                                      } catch (error) {
                                        console.error(error)
                                        return { fontSize: 9 } // Default font size in case of error
                                      }
                                    })(),
                                  ]}
                                >
                                  {pdfData.govtIDNoRequired || 'N/A'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.bordered,
                              {
                                width: 236,
                                minHeight: 13,
                                borderRight: 'none',
                                borderBottom: 'none',
                              },
                            ]}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <View
                                style={{
                                  width: 81.5,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text style={[styles.textMediumB]}>
                                  Date/Place of Issuance:{' '}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 161.5,
                                  height: 20,
                                  paddingTop: 4,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.textBold,
                                    styles.textMediumDataBold,
                                    (() => {
                                      try {
                                        if (
                                          pdfData?.govtIDIssuancePlaceOrDateRequired
                                        ) {
                                          if (
                                            pdfData
                                              .govtIDIssuancePlaceOrDateRequired
                                              .length >= 42
                                          ) {
                                            return { fontSize: 6 }
                                          } else if (
                                            pdfData
                                              .govtIDIssuancePlaceOrDateRequired
                                              .length >= 35
                                          ) {
                                            return { fontSize: 7 }
                                          }
                                        }
                                        return { fontSize: 9 }
                                      } catch (error) {
                                        console.error(error)
                                        return { fontSize: 9 } // Default font size in case of error
                                      }
                                    })(),
                                  ]}
                                >
                                  {pdfData.govtIDIssuancePlaceOrDateRequired ||
                                    'N/A'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View
                        style={[
                          styles.div2,
                          styles.textCenter,
                          styles.width1b,
                          { marginTop: '10px', paddingRight: 11 },
                        ]}
                      >
                        <View style={[styles.div5]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              {
                                height: 50,
                                border: '1px solid black',
                                width: 220,
                              },
                            ]}
                          ></Text>
                        </View>

                        <View style={[styles.div5]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              styles.bordered,
                              styles.backgroundCol,
                              {
                                borderLeft: '1px solid black',
                                width: 220,
                              },
                            ]}
                          >
                            Signature (Sign inside the box)
                          </Text>
                        </View>

                        <View style={[styles.div5]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              styles.bordered,
                              {
                                borderLeft: '1px solid black',
                                width: 220,
                              },
                            ]}
                          >
                            {pdfData.dateAccomplishedRequired &&
                            moment(pdfData.dateAccomplishedRequired).isValid()
                              ? moment(pdfData.dateAccomplishedRequired).format(
                                  'MM/DD/YYYY'
                                )
                              : ' '}
                          </Text>
                        </View>
                        <View style={[styles.div5]}>
                          <Text
                            style={[
                              styles.textMediumB,
                              styles.bordered,
                              styles.backgroundCol,
                              {
                                borderLeft: '1px solid black',
                                width: 220,
                              },
                            ]}
                          >
                            Date Accomplished
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={[styles.div2, styles.width1d]}>
                    <View
                      style={[
                        styles.div2,
                        styles.textCenter,
                        styles.width1c,
                        { marginTop: 5 },
                      ]}
                    >
                      <View
                        style={[
                          styles.div2a,
                          {
                            width: '3.5cm',
                            height: '4.5cm',
                            border: '1px solid black',
                            marginRight: 28.5,
                          },
                        ]}
                      >
                        <Text style={[styles.textXSmall]}>
                          {`ID picture taken within\nthe last 6 months\n4.5 cm. X 3.5 cm\n(passport size)`}
                        </Text>

                        <Text style={[styles.textXSmall]}>
                          {`With full and handwritten\nname tag and signature
                               over\nprinted name`}
                        </Text>

                        <Text style={[styles.textXSmall]}>
                          {`Computer generated\nor photocopied picture is not
                               acceptable`}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.div2a,
                        {
                          width: '3.5cm',
                          marginRight: 28.5,
                        },
                      ]}
                    >
                      <Text style={[styles.textMediumB, styles.textCenter]}>
                        Photo
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.div2a,
                        {
                          width: '4cm',
                          height: '3cm',
                          border: '1px solid black',
                          marginRight: 28.5,
                          marginTop: 7.9,
                        },
                      ]}
                    ></View>

                    <View
                      style={[
                        styles.div2a,
                        styles.backgroundCol,
                        {
                          width: '4cm',
                          border: '1px solid black',
                          marginRight: 28.5,
                          borderTop: 'none',
                        },
                      ]}
                    >
                      <Text style={styles.textMediumB}>Right Thumbmark</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    borderTop: '1px solid black',
                    marginTop: 10,
                  }}
                ></View>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <View style={[styles.div3, styles.margin1Top]}>
                    <Text style={[styles.textMediumB]}>
                      SUBSCRIBED AND SWORN to before me this{' '}
                      <Text style={styles.textUnderline}>
                        _______________________________
                      </Text>
                      , affiant exhibiting his/her validly issued government ID
                      as indicated above.
                    </Text>
                  </View>
                </View>
                <View style={{ width: '100%', marginTop: 10 }}>
                  <View style={{ margin: 'auto' }}>
                    <Text
                      style={[
                        styles.textMediumB,
                        {
                          height: 60,
                          border: '1px solid black',
                          width: 230,
                          textAlign: 'center',
                        },
                      ]}
                    ></Text>
                  </View>
                  <View style={{ margin: 'auto' }}>
                    <Text
                      style={[
                        styles.textMediumB,
                        styles.bordered,
                        styles.backgroundCol,
                        {
                          borderLeft: '1px solid black',
                          width: 230,
                          textAlign: 'center',
                        },
                      ]}
                    >
                      Person Administering Oath
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[
                  styles.signBelow,
                  styles.div3,
                  {
                    marginTop: '7px',
                    marginLeft: '14px',
                    position: 'absolute',
                  },
                ]}
              >
                <View style={[styles.signBelow1]}>
                  <Text
                    style={[styles.textMedium, styles.textItalicBold]}
                  ></Text>
                </View>
                <View style={[styles.signBelow2]}>
                  <Text style={styles.textMedium}></Text>
                </View>
                <View style={[styles.signBelow3]}>
                  <Text
                    style={[styles.textMedium, styles.textItalicBold]}
                  ></Text>
                </View>
                <View style={[styles.signBelow4]}>
                  <Text style={[styles.textXSmall, styles.formPageCountFont]}>
                    CS FORM 212 (Revised 2017), Page 4 of 4
                  </Text>
                </View>
              </View>
            </Page>

            {pdfData.well3Well2DataGrid &&
              pdfData.well3Well2DataGrid.length > 11 && (
                <Page size='LEGAL' style={styles.page}>
                  <View style={[styles.div1c, { paddingTop: '0' }]}>
                    <View style={[styles.div1, styles.titleBorder]}>
                      <Text
                        style={[
                          styles.textMedium2,
                          styles.textItalicBold,
                          styles.titleFontColor,
                        ]}
                      >
                        II. FAMILY BACKGROUND
                      </Text>
                    </View>
                    {generatefamilyChildrenRow()}
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow,
                      styles.div3,
                      {
                        marginTop: '7px',
                        marginLeft: '14px',
                        position: 'absolute',
                        borderTop: '1px solid black',
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow1,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        SIGNATURE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow2]}>
                      <Text style={styles.textMedium}></Text>
                    </View>
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow3,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        DATE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow4]}></View>
                  </View>
                </Page>
              )}
            {(pdfData.well4DataGrid2?.length > 1 ||
              (pdfData.well4DataGrid &&
              pdfData.well4DataGrid.some(
                (item) => item.secondaryBasicEducation === 'SENIOR HIGH SCHOOL'
              )
                ? pdfData.well4DataGrid?.length > 2
                : pdfData.well4DataGrid?.length > 1) ||
              pdfData.well4Levelvocationaltradecourse?.length > 1 ||
              pdfData.well4DataGrid3?.length > 1 ||
              pdfData.well4Well5Levelgraduatestudies?.length > 1) && (
              <Page size='LEGAL' style={styles.page}>
                <View style={[styles.div1c, { paddingTop: '0' }]}>
                  <View
                    style={[styles.div1, styles.titleBorder, { height: 15.3 }]}
                  >
                    <Text
                      style={[
                        styles.textMedium2,
                        styles.textItalicBold,
                        styles.titleFontColor,
                      ]}
                    >
                      III. EDUCATIONAL BACKGROUND
                    </Text>
                  </View>
                  {generateEducationBgRow()}
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow,
                    styles.div3,
                    styles.titleBorderLeftRightNone,
                    {
                      marginTop: '5px',
                      marginLeft: '14px',
                      position: 'absolute',
                      borderTop: '1px solid black',
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow1,
                      styles.titleBorderLeftNone,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 100 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      SIGNATURE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow2,
                      { borderTop: '1px solid black', width: 260 },
                    ]}
                  >
                    <Text style={styles.textMedium}></Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow3,
                      styles.backgroundCol,
                      { borderTop: '1px solid black', width: 80 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      DATE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow4,
                      { borderTop: '1px solid black', width: 144.5 },
                    ]}
                  ></View>
                </View>
              </Page>
            )}

            {civilServiceDataNextPage &&
              civilServiceDataNextPage.map((data) => (
                <Page size='LEGAL' style={styles.page} key={uuidv4()}>
                  <View style={[styles.div1c, { paddingTop: 0 }]}>
                    <View
                      style={[styles.div1, styles.titleBorder, { height: 16 }]}
                    >
                      <Text
                        style={[
                          styles.textMedium2,
                          styles.textItalicBold,
                          styles.titleFontColor,
                        ]}
                      >
                        IV. CIVIL SERVICE ELIGIBILITY
                      </Text>
                    </View>
                    {generateAdditionalCSEData(data)}
                  </View>

                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow,
                      styles.div3,
                      {
                        marginTop: '7px',
                        marginLeft: '14px',
                        position: 'absolute',
                        borderTop: '1px solid black',
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow1,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        SIGNATURE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow2]}>
                      <Text style={styles.textMedium}></Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow3]}>
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        DATE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow4]}></View>
                  </View>
                </Page>
              ))}

            {workExperienceNextPage &&
              workExperienceNextPage.map((page) => (
                <Page size='LEGAL' style={styles.page} key={uuidv4()}>
                  <View style={[styles.div1c, { paddingTop: '0' }]}>
                    <View style={[styles.titleBorder, { height: '30px' }]}>
                      <Text
                        style={[
                          styles.textMedium2,
                          styles.textItalicBold,
                          styles.titleFontColor,
                        ]}
                      >
                        V. WORK EXPERIENCE
                      </Text>
                      <Text style={[styles.textMedium, styles.titleFontColor]}>
                        (Include private employment. Start from your recent
                        work) Description of duties should be indicated in the
                        attached Work Experience sheet.
                      </Text>
                    </View>
                    {generateAdditionalWEData(page)}
                    <View
                      style={[
                        styles.textSmallBold,
                        styles.textColorRed,
                        { width: '584px' },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textCenter,
                          styles.textItalicBold,
                          styles.backgroundCol,
                          { fontSize: 6.6, height: 13 },
                        ]}
                      >
                        (Continue on separate sheet if necessary)
                      </Text>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow,
                      styles.div3,
                      styles.titleBorderLeftRightNone,
                      {
                        marginTop: '7px',
                        marginLeft: '14px',
                        position: 'absolute',
                        borderTop: '1px solid black',
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow1,
                        styles.titleBorderLeftNone,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        SIGNATURE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow2]}>
                      <Text style={styles.textMedium}></Text>
                    </View>
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow3,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        DATE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow4]}></View>
                  </View>
                </Page>
              ))}

            {pdfData.well4Well10DataGrid &&
              generateVoluntaryWorkData('remainData').length !== 0 && (
                <Page size='LEGAL' style={styles.page}>
                  <View style={[styles.div1c, { paddingTop: '0' }]}>
                    <View
                      style={[
                        styles.div1,
                        styles.titleBorder,
                        { height: 15, borderTop: 'none' },
                      ]}
                    >
                      <Text
                        style={[
                          styles.textMedium2,
                          styles.textItalicBold,
                          styles.titleFontColor,
                        ]}
                      >
                        VI. VOLUNTARY WORK OR INVOLVEMENT IN CIVIC /
                        NON-GOVERNMENT / PEOPLE / VOLUNTARY ORGANIZATION/S
                      </Text>
                    </View>
                    {generateAdditionalVoluntaryWork()}
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow,
                      styles.div3,
                      styles.titleBorderLeftRightNone,
                      {
                        marginTop: '7px',
                        marginLeft: '14px',
                        position: 'absolute',
                        borderTop: '1px solid black',
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow1,
                        styles.titleBorderLeftNone,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        SIGNATURE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow2]}>
                      <Text style={styles.textMedium}></Text>
                    </View>
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow3,
                        styles.backgroundCol,
                      ]}
                    >
                      <Text style={[styles.textMedium, styles.textItalicBold]}>
                        DATE
                      </Text>
                    </View>
                    <View style={[styles.bordered, styles.signBelow4]}></View>
                  </View>
                </Page>
              )}
            {pdfData.well4Well11DataGrid &&
              generateLnDData('remainingData').map((data) => {
                return (
                  <Page size='LEGAL' style={styles.page} key={uuidv4()}>
                    <View style={[styles.div1c, { paddingTop: '0' }]}>
                      <View style={[styles.titleBorder, { height: 25 }]}>
                        <Text
                          style={[
                            styles.textMedium2,
                            styles.textItalicBold,
                            styles.titleFontColor,
                          ]}
                        >
                          VII. LEARNING AND DEVELOPMENT (L&D)
                          INTERVENTIONS/TRAINING PROGRAMS ATTENDED
                        </Text>
                        <Text
                          style={[
                            styles.textMedium,
                            styles.titleFontColor,
                            styles.textItalicBold,
                            { marginTop: 4 },
                          ]}
                        >
                          (Start from the most recent L&D/training program and
                          include only the relevant L&D/training taken for the
                          last five (5) years for Division
                          Chief/Executive/-anagerial positions)
                        </Text>
                      </View>
                      <View style={[styles.div1a]}>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.backgroundCol,
                            {
                              width: 240,
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                            },
                          ]}
                        >
                          <View>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                { paddingLeft: 3, marginTop: 7 },
                              ]}
                            >
                              {'30. '}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: 247,
                              flexDirection: 'column',
                            }}
                          >
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                { marginTop: 9, fontSize: 6 },
                              ]}
                            >
                              TITLE OF LEARNING AND DEVELOPMENT
                              INTERVENTIONS/TRAINING PROGRAMS
                            </Text>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                { fontSize: 5.8 },
                              ]}
                            >
                              {'(Write in full)'}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.width1,
                            styles.bordered,
                            styles.backgroundCol,
                            { width: 90 },
                          ]}
                        >
                          <View style={[styles.div2, { width: 90 }]}>
                            <View style={styles.div2}>
                              <Text
                                style={[styles.textMedium, styles.textCenter]}
                              >
                                {`INCLUSIVE DATES OF \n ATTENDANCE`}
                              </Text>
                              <Text
                                style={[styles.textMedium, styles.textCenter]}
                              >
                                (mm/dd/yyyy)
                              </Text>
                              <Text
                                style={[styles.textMedium, styles.textCenter]}
                              >
                                {' '}
                              </Text>
                            </View>
                            <View style={[styles.div1, { marginTop: 1 }]}>
                              <View
                                style={[
                                  styles.div2,
                                  {
                                    borderTop: '1px solid black',
                                    width: 45,
                                  },
                                  styles.backgroundCol,
                                ]}
                              >
                                <Text
                                  style={[styles.textMedium, styles.textCenter]}
                                >
                                  From
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.div2,
                                  {
                                    borderTop: '1px solid black',
                                    borderLeft: '1px solid black',
                                    width: 45,
                                  },
                                  styles.backgroundCol,
                                ]}
                              >
                                <Text
                                  style={[styles.textMedium, styles.textCenter]}
                                >
                                  To
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.backgroundCol,
                          ]}
                        >
                          <View style={[styles.div2a, { width: 47 }]}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textCenter,
                                { fontSize: 5.8 },
                              ]}
                            >
                              {`NUMBER OF HOURS`}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.backgroundCol,
                          ]}
                        >
                          <View style={[styles.div2a, { width: 53 }]}>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                { fontSize: 5.3 },
                              ]}
                            >
                              {`Type of LD \n ( Managerial/ \n Supervisory/ \n Technical/etc)`}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.div3b,
                            styles.bordered,
                            styles.backgroundCol,
                            { borderRight: 'none' },
                          ]}
                        >
                          <View style={[styles.div2a, { width: 153 }]}>
                            <Text
                              style={[
                                styles.marginTop1,
                                styles.textMedium,
                                styles.textCenter,
                              ]}
                            >
                              CONDUCTED/ SPONSORED BY
                            </Text>
                            <Text
                              style={[
                                styles.textMedium,
                                styles.textCenter,
                                { fontSize: 5.8 },
                              ]}
                            >
                              (Write in full)
                            </Text>
                          </View>
                        </View>
                      </View>
                      {generateAdditionalLNDdata(data)}
                      {generateLnDRow(73 - data.totalCount)}
                      {/* {generateLnDRow(73)} */}
                      <View
                        style={[
                          styles.textSmallBold,
                          styles.textColorRed,
                          styles.backgroundCol,
                          { width: '584px', paddingTop: 2, height: 15 },
                        ]}
                      >
                        <Text
                          style={[
                            styles.textCenter,
                            styles.textItalicBold,
                            { fontSize: 6.6 },
                          ]}
                        >
                          (Continue on separate sheet if necessary)
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.bordered,
                        styles.signBelow,
                        styles.div3,
                        styles.titleBorderLeftRightNone,
                        {
                          marginTop: '6px',
                          marginLeft: '14px',
                          position: 'absolute',
                          borderTop: '1px solid black',
                        },
                      ]}
                    >
                      <View
                        style={[
                          styles.bordered,
                          styles.signBelow1,
                          styles.titleBorderLeftNone,
                          styles.backgroundCol,
                        ]}
                      >
                        <Text
                          style={[styles.textMedium, styles.textItalicBold]}
                        >
                          SIGNATURE
                        </Text>
                      </View>
                      <View style={[styles.bordered, styles.signBelow2]}>
                        <Text style={styles.textMedium}></Text>
                      </View>
                      <View
                        style={[
                          styles.bordered,
                          styles.signBelow3,
                          styles.backgroundCol,
                        ]}
                      >
                        <Text
                          style={[styles.textMedium, styles.textItalicBold]}
                        >
                          DATE
                        </Text>
                      </View>
                      <View style={[styles.bordered, styles.signBelow4]}></View>
                    </View>
                  </Page>
                )
              })}

            {otherInformationData && otherInformationData.length > 1 && (
              <Page size='LEGAL' style={styles.page}>
                <View style={[styles.div1c, { paddingTop: '0' }]}>
                  <View
                    style={[
                      styles.div1,
                      styles.titleBorder,
                      { height: 15.5, borderTop: 'none' },
                    ]}
                  >
                    <Text
                      style={[
                        styles.textMedium2,
                        styles.textItalicBold,
                        styles.titleFontColor,
                      ]}
                    >
                      VIII. OTHER INFORMATION
                    </Text>
                  </View>
                  {generateAdditionalOtherInformation()}
                </View>
                <View
                  style={[
                    styles.bordered,
                    styles.signBelow,
                    styles.div3,
                    styles.titleBorderLeftRightNone,
                    {
                      marginTop: '6px',
                      marginLeft: '14px',
                      position: 'absolute',
                      borderTop: '1px solid black',
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow1,
                      styles.titleBorderLeftNone,
                      styles.backgroundCol,
                      { width: 146 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      SIGNATURE
                    </Text>
                  </View>
                  <View
                    style={[styles.bordered, styles.signBelow2, { width: 211 }]}
                  >
                    <Text style={styles.textMedium}></Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow3,
                      styles.backgroundCol,
                      { width: 75 },
                    ]}
                  >
                    <Text style={[styles.textMedium, styles.textItalicBold]}>
                      DATE
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.bordered,
                      styles.signBelow4,
                      { width: 152.5 },
                    ]}
                  ></View>
                </View>
              </Page>
            )}
          </Document>
        </PDFViewer>
      ) : (
        'loading'
      )}
    </div>
  )
}
