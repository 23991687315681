/**
 * Title: PDSRemarksHR.js
 * Description: This is a file that contains the components for the return PDS feature for HR roles.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSRemarksHR.js
 **/

/*
 *Changes made:
 * 2024.10.12  | Harry Lagunsad | implement a form-like component for when returning the PDS from HR to employee
 */
import { useState } from 'react'

import { Button, Col, Form, Input, Row, message, notification } from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import DOMPurify from 'dompurify'
import moment from 'moment'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'

import { updatePersonalDataSheet } from '../../api/mutations'
import { UserbyDepartment } from '../../api/queries'
import {
  getEmailBodyReturnedByHR,
  getEmailBodyReturnedByHREmployeeNotif,
} from './PDSEmailTemplates'
import DeleteConfirmationModal from './PDSRemarksDeleteRow'
import PDSRemarksSubmit from './PDSRemarksSubmit'

const { TextArea } = Input

const MAX_INPUT_LENGTH = 100
const MAX_TEXTAREA_LENGTH = 512
const MAX_FORM_ITEMS = 100

export default function PDSRemarksHR({
  initialItems,
  fetchUserData,
  setShowRemarksForm,
  record,
  userFound,
  setIsOpenPDF,
}) {
  const [form] = Form.useForm()
  const [formItems, setFormItems] = useState(
    initialItems.map((item) => ({ ...item, id: uuidv4() }))
  )

  const [isModalVisibleDeleteRow, setIsModalVisibleDeleteRow] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [isModalVisibleSubmitRemarks, setIsModalVisibleSubmitRemarks] =
    useState(false)
  const [submitValues, setSubmitValues] = useState(null)

  const emailSchema = Yup.string().email()

  /**
   * Adds a new form item to the formItems state.
   * @returns {void}
   * @function addFormItem
   * @throws {error} An error occurred while adding a form item.
   **/
  const addFormItem = () => {
    try {
      if (formItems.length < MAX_FORM_ITEMS) {
        setFormItems((prevItems) => [
          ...prevItems,
          { id: uuidv4(), input: '', textArea: '' },
        ])
      } else {
        message.warning(`Maximum of ${MAX_FORM_ITEMS} items allowed.`)
      }
    } catch (error) {
      console.log('Error adding form item:', error)
      message.error(
        'An error occurred while adding a form item. Please try again.'
      )
    }
  }

  const handleRowDelete = (itemId) => {
    setIsModalVisibleDeleteRow(true)
    setDeleteItemId(itemId)
  }

  const handleSubmitRemarksModal = (values) => {
    setIsModalVisibleSubmitRemarks(true)
    setSubmitValues(values)
  }

  /**
   * Removes a form item from the formItems state.
   * @param {string} id - The unique identifier of the form item to remove.
   * @returns {void}
   * @function removeFormItem
   * @throws {error} An error occurred while removing a form item.
   **/
  const removeFormItem = (id) => {
    try {
      setFormItems((prevItems) => prevItems.filter((item) => item.id !== id))
      message.info('Row deleted.')
      setIsModalVisibleDeleteRow(false)
      setDeleteItemId(null)
    } catch (error) {
      console.log('Error removing form item:', error)
      message.error(
        'An error occurred while removing a form item. Please try again.'
      )
    }
  }

  /**
   * Updates the input value of a form item in the formItems state.
   * @param {string} id - The unique identifier of the form item to update.
   * @param {string} field - The field to update in the form item.
   * @param {string} value - The new value to assign to the field.
   * @returns {void}
   * @function handleInputChange
   * @throws {error} An error occurred while updating a form item.
   **/
  const handleInputChange = (id, field, value) => {
    try {
      setFormItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      )
    } catch (error) {
      console.log('Error updating form item:', error)
      message.error(
        'An error occurred while updating the form item. Please try again.'
      )
    }
  }

  /**
   * Validates the input value of a form item.
   * @param {string} _ - The name of the field.
   * @param {string} value - The value to validate.
   * @returns {Promise} A Promise that resolves if the value is valid, and rejects with an Error otherwise.
   * @function validateInput
   * @throws {error} An error occurred while validating input.
   **/
  const validateInput = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('This field is required'))
    }
    if (value.length > MAX_INPUT_LENGTH) {
      return Promise.reject(
        new Error(`Maximum length is ${MAX_INPUT_LENGTH} characters`)
      )
    }
    if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
      return Promise.reject(
        new Error('Only alphanumeric characters and spaces are allowed')
      )
    }
    return Promise.resolve()
  }

  /**
   * Validates the text area value of a form item.
   * @param {string} _ - The name of the field.
   * @param {string} value - The value to validate.
   * @returns {Promise} A Promise that resolves if the value is valid, and rejects with an Error otherwise.
   * @callback
   * @function validateTextArea
   * @throws {error} An error occurred while validating text area.
   **/
  const validateTextArea = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('This field is required'))
    }
    if (value.length > MAX_TEXTAREA_LENGTH) {
      return Promise.reject(
        new Error(`Maximum length is ${MAX_TEXTAREA_LENGTH} characters`)
      )
    }

    if (!/^[a-zA-Z0-9\s.,!?@#$%&*()_+\-=\[\]\\:'"`]*$/.test(value)) {
      return Promise.reject(
        new Error(
          'Only alphanumeric characters, spaces, and common symbols (.@#$%&*_-+) are allowed'
        )
      )
    }
    return Promise.resolve()
  }

  /**
   * Fetches the department admin details array.
   * @returns {Promise} A Promise that resolves with the department admin details array, and rejects with an Error otherwise.
   * @callback
   * @function getDeptAdminDetailsArrayFunction
   * @throws {error} An error occurred while fetching the department admin details array.
   * @async
   **/
  const getDeptAdminDetailsArrayFunction = async () => {
    try {
      let getDeptAdminDetailsArray = []
      let token = null

      do {
        const getDeptAdminDetails = await API.graphql(
          graphqlOperation(UserbyDepartment, {
            assignedDept: record.department,
            filter: {
              or: [
                { pdsRole: { eq: 'deptApprover' } },
                { pdsRole: { eq: 'deptApproverAndEncoder' } },
              ],
            },
            limit: 1000,
            nextToken: token,
          })
        )

        getDeptAdminDetailsArray.push(
          ...getDeptAdminDetails.data.UserbyDepartment.items
        )
        token = getDeptAdminDetails.data.UserbyDepartment.nextToken
      } while (token)

      return getDeptAdminDetailsArray
    } catch (err) {
      return []
    }
  }

  /**
   * Generates the parameters for sending an email.
   * @param {string} username - The email address of the recipient.
   * @param {string} emailBody - The body of the email.
   * @param {string} emailTitle - The title of the email.
   * @returns {Object} The parameters for sending an email.
   * @function params
   * @throws {error} An error occurred while generating the parameters.
   **/
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [DOMPurify.sanitize(username)],
        },
        Message: {
          Body: {
            Text: {
              Data: DOMPurify.sanitize(emailBody),
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: DOMPurify.sanitize(emailTitle), // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: sourceEmail,
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while generating the email parameters. Please contact the system admin for help.',
      })
      throw err
    }
  }

  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
    }
  }
  /**
   * Sends an email to the user.
   * @param {Object} record - The Personal Data Sheet record.
   * @param {Object} sesClient - The SES client.
   * @param {Array} getDeptAdminDetailsArray - The department admin details array.
   * @returns {void}
   * @throws {error} An error occurred while sending the email.
   * @function sendEmail
   * @async
   **/
  async function sendEmail(record) {
    try {
      const sesClient = await getSesClientToken()
      const sourceEmail = (await getSourceEmail()) || ''

      const emailBody = getEmailBodyReturnedByHREmployeeNotif(record)
      const params = paramsGenerator(
        record.ownedBy.username,
        emailBody,
        'Return of your Personal Data Sheet (HR Admin)',
        sourceEmail
      )

      if (window.location.href !== 'http://localhost:3000/') {
        await sesClient.send(new SendEmailCommand(params))
        notification.success({
          message: 'Notification sent',
          description:
            'An email notification has been sent to the employee regarding the return of the Personal Data Sheet.',
          duration: 4.5, // Duration the notification stays open. Optional.
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the employee. Please contact the system admin for help.',
      })

      throw err
    }
  }

  /**
   * Sends an email to the department admin.
   * @param {Object} record - The Personal Data Sheet record.
   * @param {Object} sesClient - The SES client.
   * @param {Array} getDeptAdminDetailsArray - The department admin details array.
   * @returns {void}
   * @throws {error} An error occurred while sending the email.
   * @function sendEmailHR
   * @async
   **/
  async function sendEmailHR(record, getDeptAdminDetailsArray) {
    try {
      const sesClient = await getSesClientToken()
      const sourceEmail = (await getSourceEmail()) || ''

      const emailBodyHR = getEmailBodyReturnedByHR(record)
      const paramsHR = paramsGenerator(
        getDeptAdminDetailsArray[0].username,
        emailBodyHR,
        'Personal Data Sheet for HR Review (Returned)',
        sourceEmail
      )

      if (window.location.href !== 'http://localhost:3000/') {
        await sesClient.send(new SendEmailCommand(paramsHR))
        notification.success({
          message: 'Notification sent',
          description:
            'An email notification has been sent to the department approver regarding the return of the Personal Data Sheet of an employee.',
          duration: 4.5, // Duration the notification stays open. Optional.
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the department admin. Please contact the system admin for help.',
      })
    }
  }

  /**
   * Handles the submission of the form when PDS is returned.
   * @param {Object} values - The form values.
   * @returns {void}
   * @throws {error} An error occurred while submitting the form.
   * @function handleReturnSubmit
   * @async
   **/
  const handleReturnSubmit = async (sanitizedValues) => {
    try {
      const getDeptAdminDetailsArray = await getDeptAdminDetailsArrayFunction()

      await API.graphql(
        graphqlOperation(updatePersonalDataSheet, {
          input: {
            id: record.id,
            hrApproverID: userFound.id,
            isFinal: false,
            isHRApproved: 'Returned',
            commentsOfHRApprover: JSON.stringify([
              ...JSON.parse(record.commentsOfHRApprover || '[]'),
              {
                dateOfRemarks: moment().format('MMMM D, YYYY h:mm A'),
                commentsOfHRApprover: sanitizedValues,
              },
            ]),
          },
        })
      )

      notification.success({
        message: 'Submission returned to the user',
        description:
          'The Personal Data Sheet has been successfully returned to the user.',
        duration: 4.5, // Duration the notification stays open. Optional.
      })

      await sendEmail(record)

      if (
        !getDeptAdminDetailsArray?.length ||
        !(await emailSchema.isValid(
          DOMPurify.sanitize(getDeptAdminDetailsArray[0].username.trim())
        ))
      ) {
        notification.warning({
          message: 'No Department Admin found',
          description:
            "No valid Department approver email is found for the employee's department.",
        })
      } else {
        await sendEmailHR(record, getDeptAdminDetailsArray)
      }

      setSubmitValues(null)
      setIsModalVisibleSubmitRemarks(false)

      setTimeout(() => {
        setShowRemarksForm(false)
        setFormItems(initialItems.map((item) => ({ ...item, id: uuidv4() })))
        fetchUserData()
        setIsOpenPDF(false)
      }, 4500)
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while saving (Return) the Personal Data Sheet. Please contact the system admin for help.',
      })
    }
  }

  const handleSubmit = (values) => {
    try {
      const sanitizedValues = Object.keys(values.items).map((key) => ({
        input: DOMPurify.sanitize(values.items[key].input),
        textArea: DOMPurify.sanitize(values.items[key].textArea),
      }))
      handleReturnSubmit(sanitizedValues)
    } catch (error) {
      console.log('Error submitting form:', error)
      message.error(
        'An error occurred while submitting the form. Please try again.'
      )
    }
  }

  return (
    <div className='p-6 max-w-2xl mx-auto'>
      <Form form={form} onFinish={handleSubmitRemarksModal} layout='vertical'>
        {formItems.map((item) => (
          <div
            key={item.id}
            className='mb-4 p-4 border border-gray-200 rounded'
          >
            <Row>
              <Col span={10}>
                <Form.Item
                  name={['items', item.id, 'input']}
                  label='Field name'
                  rules={[{ validator: validateInput }]}
                >
                  <Input
                    value={item.input}
                    onChange={(e) =>
                      handleInputChange(item.id, 'input', e.target.value)
                    }
                    maxLength={MAX_INPUT_LENGTH}
                    style={{ width: '95%' }}
                    placeholder='Enter field name, e.g. SURNAME, CITIZENSHIP, etc.'
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  name={['items', item.id, 'textArea']}
                  label='Comments / Remarks'
                  rules={[{ validator: validateTextArea }]}
                >
                  <TextArea
                    value={item.textArea}
                    onChange={(e) =>
                      handleInputChange(item.id, 'textArea', e.target.value)
                    }
                    maxLength={MAX_TEXTAREA_LENGTH}
                    rows={4}
                    style={{ width: '100%' }}
                    showCount
                  />
                </Form.Item>
              </Col>
              {formItems.length > 1 && (
                <MinusCircleOutlined
                  onClick={() => handleRowDelete(item.id)}
                  style={{ marginLeft: 8, color: '#ff4d4f', fontSize: '18px' }}
                />
              )}
            </Row>
          </div>
        ))}
        <Form.Item>
          <Button
            type='dashed'
            onClick={addFormItem}
            block
            icon={<PlusOutlined />}
            disabled={formItems.length >= MAX_FORM_ITEMS}
          >
            Add Row
          </Button>
        </Form.Item>
        <Form.Item>
          <Row justify='end'>
            <Button type='primary' htmlType='submit'>
              Return to Employee
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <DeleteConfirmationModal
        open={isModalVisibleDeleteRow}
        onCancel={() => {
          setIsModalVisibleDeleteRow(false)
          setDeleteItemId(null)
        }}
        onConfirm={() => removeFormItem(deleteItemId)}
        itemName='this row' // Can be customized based on the selected item
      />
      <PDSRemarksSubmit
        open={isModalVisibleSubmitRemarks}
        onCancel={() => {
          setIsModalVisibleSubmitRemarks(false)
          setSubmitValues(null)
        }}
        onConfirm={() => handleSubmit(submitValues)}
      />
    </div>
  )
}

PDSRemarksHR.propTypes = {
  initialItems: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.string,
      textArea: PropTypes.string,
    })
  ),
  fetchUserData: PropTypes.func.isRequired,
  setShowRemarksForm: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  userFound: PropTypes.object.isRequired,
  setIsOpenPDF: PropTypes.func.isRequired,
}

PDSRemarksHR.defaultProps = {
  initialItems: [{ input: '', textArea: '' }],
}
