/**
 * Title: MainForm.js
 * Description: This is a file that contains the components for the Personal Data Sheet form.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/MainForm.js
 **/

/*
 *Changes made:
 * - 2024.14.08  | Raymart Mojado | convert the National Capital Region into Metro Manila when selected on the list of permanent address
 * and residential address
 * - 2024.14.08  | Raymart Mojado | disabled the province , municipality , barangay, zip code if the checkbox same as residential address
 * is selected
 * - 2024.22.08 | Harry Lagunsad | fixed issue when selecting zip code that are shared with other places or barangays that after selection, a
 * different label will show but correct value is being put in the main zip code field
 * - 2024.02.09 | Raymart Mojado | For #22 Spouse, if marked as N/A .disable the input about the spouse and make it N/A,
 * - 2024.02.09 | Raymart Mojado | Automatic spouse details should be populated based on civil status (e.g., N/A if single)
 * - 2024.06.09 | Harry Lagunsad | Implement an auto save feature for the form, where the progress is saved every 5 seconds in the local storage
 * - 2024.06.09 | Harry Lagunsad | Added a function to load and decrypt saved data when the component mounts
 * - 2024.06.09 | Harry Lagunsad | Added a modal to appear that will advise the user about auto-save form feature but not to rely on it totally
     and use the save as draft or save as final button to save the form permanently
 * - 2024.06.09 | Harry Lagunsad | Fix sonarlint issues
 * - 2024.06.09 | Harry Lagunsad | Add prop types
 * - 2024.06.09 | Raymart Mojado | Add Upload PDS
 * - 2024.09.09 | Harry Lagunsad | Sort the list of barangays via labels before display.
 * - 2024.18.09 | Raymart Mojado | Change the notification error from  Please answer character reference fields to This is a required field. Please fill it out before proceeding.
 * - 2024.25.09 | Raymart Mojado | add validation on each field if its only input white-space show error / fix some fields that validations doesnt work
 * - 2024.10.10 | Harry Lagunsad | fix incorrect suffix value showing upon first time PDS form load
 * - 2024.10.10 | Harry Lagunsad | Enhanced handling of the Save as draft and Save as final buttons to avoid spamming.
 * - 2024.11.10 | Harry Lagunsad | Fixed function that handles control of number of data row for character reference from min of
 *   1 to max of 3 persons only
 */
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  BackTop,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import PropTypes from 'prop-types'

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { Form } from '@formio/react'

import { createPersonalDataSheet } from '../../api/mutations'
import { UserbyDepartment, registrationByUserID } from '../../api/queries'
import { getEmailBodySubmittedByEmployeeNotifDeptApprover } from './PDSEmailTemplates'
import PDSGuide from './PDSGuide'
import PDSVideo from './PDSVideo'
import UploadPds from './UploadPds'
import './pds.css'
import placeDataArray from './zipcodePH'

const { Option } = Select
const { Text, Title } = Typography

const REQUIRED_FIELDS = {
  BASIC: [
    'surnameRequired',
    'firstnameRequired',
    'middlenameRequired',
    'suffixNaAllowed',
    'dateofbirthRequired',
    'placeofbirthRequired',
    'sexRequired',
    'civilStatusRequired',
    'gsisidnoRequired',
    'pagibigIdNoRequired',
    'philhealthNoRequired',
    'sssNumberRequired',
    'tinNumberRequired',
    'agencyemployeenoNaAllowed',
    'citizenshipQuestionRequired',
    'residentialHouseBlockLotNoRequired',
    'residentialStreetRequired',
    'residentialSubdivisionVillageRequired',
    'residentialProvinceRequired',
    'residentialCityMunicipalityRequired',
    'residentialBarangayRequired',
    'residentialZipcodeNaAllowed',
    'sameAsResidentialAddressBox',
    'permanentHouseBlockLotNoRequired',
    'permanentStreetRequired',
    'permanentSubdivisionVillageRequired',
    'permanentProvinceRequired',
    'permanentCityMunicipalityRequired',
    'permanentBarangayRequired',
    'permanentZipcodeNaAllowed',
    'telephonenoNaAllowed',
    'mobilenoNaAllowed',
    'emailAddressNaAllowed',
    'markSpouseAsNA',
    'spouseSurnameNaAllowed',
    'spouseFirstnameNaAllowed',
    'spouseMiddlenameNaAllowed',
    'spouseSuffixNaAllowed',
    'spouseOccupationNaAllowed',
    'spouseEmployerNameNaAllowed',
    'spouseEmployerAddressNaAllowed',
    'spouseEmployerTelephonenoNaAllowed',
    'heightFormat',
    'weightFormat',
    'birthOrNaturaliztion',
    'indicateCountry',
    'bloodtype',
    'ifChildren',
    'markFatherAsNA',
    'fatherSurnameNaAllowed',
    'fatherFirstnameNaAllowed',
    'fatherMiddlenameNaAllowed',
    'fatherSuffixNaAllowed',
    'markMotherAsNA',
    'motherSurnameNaAllowed',
    'motherFirstnameNaAllowed',
    'motherMiddlenameNaAllowed',
    'heightFt',
    'heightIn',
    'heightMeters',
    'weightPounds',
    'weightKg',
    'isWorkExperience',
    'isVoluntaryWork',
    'govtIssuedIDRequired',
    'govtIDNoRequired',
    'govtIDIssuancePlaceOrDateRequired',
  ],
  DETAILS: [
    'relatedExplain',
    'offenseADetails',
    'serviceSeparationADetails',
    'candidateDetails',
    'resignedDetails',
    'offenseCDetails',
    'offenseDateFiled',
    'courtADetails',
    'offenseCaseStatus',
    'immigrantDetails',
    'isIndigenousDetails',
    'isPersonWDisabilityDetails',
    'isSoloParentDetails',
    'isFourthDegreeRequired',
    'offenseARequired',
    'serviceSeparationARequired',
    'candidateRequired',
    'resignedRequired',
    'immigrantRequired',
    'isIndigenousRequired',
    'isPersonWDisabilityRequired',
    'isSoloParentRequired',
    'offenceCRequired',
    'offenseARequired',
    'courtARequired',
    'isThirdDegreeRequired',
  ],
}

export default function MainForm({ userDetails, afterSubmit, isProceedPDS }) {
  const [holdData, setHoldData] = useState({})
  const [dataReview, setDataReview] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [uploadedSignature, setUploadedSignature] = useState(null)
  const [uploadedThumbmark, setUploadedThumbmark] = useState(null)

  const [isModalOpenThumbmark, setIsModalOpenThumbmark] = useState(false)
  const [isModalOpenESign, setIsModalOpenESign] = useState(false)
  const [isModalOpenPassportPhoto, setIsModalOpenPassportPhoto] =
    useState(false)
  const [uploadedPassportPhoto, setUploadedPassportPhoto] = useState(null)
  const [removeAsterisk, setRemoveAsterisk] = useState(null)

  const [myTrainings, setMyTrainings] = useState([])
  const [isFinishedScan, setIsFinishedScan] = useState(false)

  const [isModalOpenProvince, setIsModalOpenProvince] = useState(false)
  const [regionGroupList, setRegionGroupList] = useState([])
  const [municipalityList, setMunicipalityList] = useState([])
  const [provinceData, setProvinceData] = useState({})
  const [isModalOpenCity, setIsModalOpenCity] = useState(false)
  const [allAreasData, setAllAreasData] = useState({})
  const [selectedCityData, setSelectedCityData] = useState({})
  const [isModalOpenBrgy, setIsModalOpenBrgy] = useState(false)
  const [barangayList, setBarangayList] = useState([])

  const [isProvincePerm, setIsProvincePerm] = useState(false)
  const [isBarangayPerm, setIsBarangayPerm] = useState(false)
  const [isCityPerm, setIsCityPerm] = useState(false)
  const [municipalityListPerm, setMunicipalityListPerm] = useState([])
  const [selectedCityDataPerm, setSelectedCityDataPerm] = useState({})
  const [allAreasDataPerm, setAllAreasDataPerm] = useState({})
  const [barangayListPerm, setBarangayListPerm] = useState([])
  const [provinceDataPerm, setProvinceDataPerm] = useState({})
  const [isModalOpenZipcode, setIsModalOpenZipcode] = useState(false)
  const [isZipcodePerm, setIsZipcodePerm] = useState(false)

  const [isError, setIsError] = useState(false)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isOpenPDSCacheMessage, setIsOpenPDSCacheMessage] = useState(false)

  const [isDisabledAfterSave, setIsDisabledAfterSave] = useState(false)
  const [doneRender, setDoneRender] = useState(false)

  const fileInputRefThumb = useRef(null)
  const fileInputRefESign = useRef(null)
  const fileInputRefPassportPhoto = useRef(null)

  const placeData = placeDataArray()

  const fieldCheckerFunction = (obj, key) => {
    try {
      let value = obj[key]
      if (
        typeof value === 'undefined' ||
        value === null ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        return {
          allRequiredValuesPresent: false,
          failedKey: key,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const validateGridData = (value, fields, gridType) => {
    try {
      if (!Array.isArray(value) || value.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: gridType,
          isGridData: true,
        }
      }

      for (let i = 0; i < value.length; i++) {
        const element = value[i]

        if (typeof element !== 'object' || !element) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[${gridType}][${i}]`,
            isGridData: true,
          }
        }

        for (const field of fields) {
          if (
            !element[field] ||
            (typeof element[field] === 'string' && !element[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[${gridType}][${i}][${field}]`,
              dataKey: `${gridType}[${i}].[${field}]`,
              isGridData: true,
            }
          }
        }
      }
      return null
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating grid data.',
      })
    }
  }

  const validateElementaryEducation = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4DataGrid2',
          isElementaryGrid: true,
        }
      }

      const requiredFields = [
        'elementaryNameOfSchoolNaAllowed',
        'elementaryBasicEducationNaAllowed',
        'elementaryDateFromNaAllowed',
        'elementaryDateToNaAllowed',
        'elementaryUnitsEarnedNaAllowed',
        'elementaryYearGraduatedNaAllowed',
        'elementaryScholarshipNaAllowed',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4DataGrid2[${i}]`,
            isElementaryGrid: true,
          }
        }

        for (const field of requiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4DataGrid2][${i}][${field}]`,
              dataKey: `well4DataGrid2[${i}].${field}`,
              isElementaryGrid: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating elementary education.',
      })
    }
  }

  const validateSecondaryEducation = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4DataGrid',
          isSecondaryGrid: true,
        }
      }

      const requiredFields = [
        'secondaryNameOfSchoolNaAllowed',
        'secondaryBasicEducation',
        'secondaryDateFromNaAllowed',
        'secondaryDateToNaAllowed',
        'secondaryUnitsEarnedNaAllowed',
        'secondaryYearGraduatedNaAllowed',
        'secondaryScholarshipNaAllowed',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4DataGrid[${i}]`,
            isSecondaryGrid: true,
          }
        }

        for (const field of requiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4DataGrid][${i}][${field}]`,
              dataKey: `well4DataGrid[${i}].${field}`,
              isSecondaryGrid: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating secondary education.',
      })
    }
  }

  const validateVocationalEducation = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Levelvocationaltradecourse',
          isVocationalGrid: true,
        }
      }

      const requiredFields = [
        'vocationalNameOfSchoolNaAllowed',
        'vocationalBasicEducationNaAllowed',
        'vocationalDateFromNaAllowed',
        'vocationalDateToNaAllowed',
        'vocationalUnitsEarnedNaAllowed',
        'vocationalYeargraduatedNaAllowed',
        'vocationalScholarshipNaAllowed',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Levelvocationaltradecourse[${i}]`,
            isVocationalGrid: true,
          }
        }

        for (const field of requiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Levelvocationaltradecourse][${i}][${field}]`,
              dataKey: `well4Levelvocationaltradecourse[${i}].${field}`,
              isVocationalGrid: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating vocational education.',
      })
    }
  }

  const validateCollegeEducation = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4DataGrid3',
          isCollegeGrid: true,
        }
      }

      const requiredFields = [
        'collegeNameOfSchoolNaAllowed',
        'collegeCourseNaAllowed',
        'collegeDateFromNaAllowed',
        'collegeDateToNaAllowed',
        'collegeUnitsEarnedNaAllowed',
        'collegeYearGraduatedNaAllowed',
        'collegeScholarshipNaAllowed',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4DataGrid3[${i}]`,
            isCollegeGrid: true,
          }
        }

        for (const field of requiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4DataGrid3][${i}][${field}]`,
              dataKey: `well4DataGrid3[${i}].${field}`,
              isCollegeGrid: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating college education.',
      })
    }
  }

  const validateGradStudies = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Well5Levelgraduatestudies',
          isGraduateStudies: true,
        }
      }

      const requiredFields = [
        'graduateNameOfSchoolNaAllowed',
        'graduateCourseNaAllowed',
        'graduateDateFromNaAllowed',
        'graduateDateToNaAllowed',
        'graduateUnitsEarnedNaAllowed',
        'graduateYearGraduatedNaAllowed',
        'graduateScholarshipNaAllowed',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Well5Levelgraduatestudies[${i}]`,
            isGraduateStudies: true,
          }
        }

        for (const field of requiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Well5Levelgraduatestudies][${i}][${field}]`,
              dataKey: `well4Well5Levelgraduatestudies[${i}].${field}`,
              isGraduateStudies: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating graduate studies.',
      })
    }
  }

  const validateCivilServiceEligibility = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Well7DataGrid',
          isCivilServiceEligibility: true,
        }
      }

      const baseRequiredFields = [
        'haveAnyEligibility',
        'civilServiceAndOthersLicense',
        'civilServiceRating',
        'civilServiceExamPlace',
        'civilServiceLicenseNo',
        'civilServiceExamDateIsNA',
        'civilServiceLicenseValidityDateIsNA',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Well7DataGrid[${i}]`,
            isCivilServiceEligibility: true,
          }
        }

        if (
          !entry.civilServiceExamDate &&
          entry?.civilServiceExamDateIsNA === 'enterDate'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well7DataGrid][${i}][civilServiceExamDate]`,
            dataKey: `well4Well7DataGrid[${i}].civilServiceExamDate`,
            isCivilServiceEligibility: true,
          }
        }

        if (
          !entry.civilServiceLicenseValidityDate &&
          entry?.civilServiceLicenseValidityDateIsNA === 'enterDate'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well7DataGrid][${i}][civilServiceLicenseValidityDate]`,
            dataKey: `well4Well7DataGrid[${i}].civilServiceLicenseValidityDate`,
            isCivilServiceEligibility: true,
          }
        }

        // Check base required fields
        for (const field of baseRequiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Well7DataGrid][${i}][${field}]`,
              dataKey: `well4Well7DataGrid[${i}].${field}`,
              isCivilServiceEligibility: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating civil service eligibility.',
      })
    }
  }

  const validateWorkExperience = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Well8DataGrid',
          isGridDataWorkExperience: true,
        }
      }

      const baseRequiredFields = [
        'workExperienceDateFrom',
        'workInclusiveDateToFormat',
        'workExperiencePositionTitle',
        'workExperienceCompany',
        'workExperienceSalary',
        'workPayGradeIsNA',
        'workExperienceAppointmentStatus',
        'workExperienceIsGovtService',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Well8DataGrid[${i}]`,
            isGridDataWorkExperience: true,
          }
        }

        if (
          !entry.workExperienceDateTo &&
          entry?.workInclusiveDateToFormat === 'selectDate'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well8DataGrid][${i}][workExperienceDateTo]`,
            dataKey: `well4Well8DataGrid[${i}].workExperienceDateTo`,
            isGridDataWorkExperience: true,
          }
        }

        if (
          !entry.workExperiencePayGrade &&
          entry?.workPayGradeIsNA === 'selectSalaryGradeStepIncrement'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well8DataGrid][${i}][workExperiencePayGrade]`,
            dataKey: `well4Well8DataGrid[${i}].workExperiencePayGrade`,
            isGridDataWorkExperience: true,
          }
        }

        if (
          !entry.workExperienceStepIncrement &&
          entry?.workPayGradeIsNA === 'selectSalaryGradeStepIncrement'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well8DataGrid][${i}][workExperienceStepIncrement]`,
            dataKey: `well4Well8DataGrid[${i}].workExperienceStepIncrement`,
            isGridDataWorkExperience: true,
          }
        }

        // Check base required fields
        for (const field of baseRequiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Well8DataGrid][${i}][${field}]`,
              dataKey: `well4Well8DataGrid[${i}].${field}`,
              isGridDataWorkExperience: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating work experience.',
      })
    }
  }

  const validateLDTrainings = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Well11DataGrid',
          isGridlNd: true,
        }
      }

      const baseRequiredFields = [
        'ldTrainingTitle',
        'ldTrainingDateIsNA',
        'ldTrainingHoursNo',
        'ldType',
        'ldSponsoredBy',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Well11DataGrid[${i}]`,
            isGridlNd: true,
          }
        }

        if (
          !entry.ldTrainingDateFrom &&
          entry?.ldTrainingDateIsNA === 'selectDates'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well11DataGrid][${i}][ldTrainingDateFrom]`,
            dataKey: `well4Well11DataGrid[${i}].ldTrainingDateFrom`,
            isGridlNd: true,
          }
        }

        if (
          !entry.ldTrainingDateTo &&
          entry?.ldTrainingDateIsNA === 'selectDates'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well11DataGrid][${i}][ldTrainingDateTo]`,
            dataKey: `well4Well11DataGrid[${i}].ldTrainingDateTo`,
            isGridlNd: true,
          }
        }

        // Check base required fields
        for (const field of baseRequiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Well11DataGrid][${i}][${field}]`,
              dataKey: `well4Well11DataGrid[${i}].${field}`,
              isGridlNd: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating training grid.',
      })
    }
  }

  const validateVoluntaryWork = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'well4Well10DataGrid',
          isGridVoluntaryWork: true,
        }
      }

      const baseRequiredFields = [
        'voluntaryWorkOrg',
        'voluntaryWorkDateIsNA',
        'voluntaryWorkHoursNo',
        'voluntaryWorkPosition',
      ]

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]

        if (!entry || typeof entry !== 'object') {
          return {
            allRequiredValuesPresent: false,
            failedKey: `well4Well10DataGrid[${i}]`,
            isGridVoluntaryWork: true,
          }
        }

        if (
          !entry.voluntaryWorkDateFrom &&
          entry?.voluntaryWorkDateIsNA === 'selectDates'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well10DataGrid][${i}][voluntaryWorkDateFrom]`,
            dataKey: `well4Well10DataGrid[${i}].voluntaryWorkDateFrom`,
            isGridVoluntaryWork: true,
          }
        }

        if (
          !entry.voluntaryWorkDateTo &&
          entry?.voluntaryWorkDateIsNA === 'selectDates'
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[well4Well10DataGrid][${i}][voluntaryWorkDateTo]`,
            dataKey: `well4Well10DataGrid[${i}].voluntaryWorkDateTo`,
            isGridVoluntaryWork: true,
          }
        }

        // Check base required fields
        for (const field of baseRequiredFields) {
          if (
            !entry[field] ||
            (typeof entry[field] === 'string' && !entry[field].trim())
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: `[well4Well10DataGrid][${i}][${field}]`,
              dataKey: `well4Well10DataGrid[${i}].${field}`,
              isGridVoluntaryWork: true,
            }
          }
        }
      }

      return null // Validation passed
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating voluntary work grid.',
      })
    }
  }

  const validateSkillsAndHobbies = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'skillsAndHobbiesGrid',
          isGridDataHobbies: true,
        }
      }

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]
        if (!entry.skillsAndHobbies || !entry.skillsAndHobbies.trim()) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[skillsAndHobbiesGrid][${i}]`,
            isGridDataHobbies: true,
          }
        }
      }
      return null
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating skills and hobbies.',
      })
    }
  }

  const validateNonAcademicDistinctions = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'nonAcademicdistinctionsrecognitionGrid',
          isGridDatanonAcademicdistinctionsrecognition: true,
        }
      }

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]
        if (
          !entry.nonAcademicdistinctionsrecognition ||
          !entry.nonAcademicdistinctionsrecognition.trim()
        ) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[nonAcademicdistinctionsrecognitionGrid][${i}]`,
            isGridDatanonAcademicdistinctionsrecognition: true,
          }
        }
      }
      return null
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating non-academic distinctions.',
      })
    }
  }

  const validateOrgMembership = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        return {
          allRequiredValuesPresent: false,
          failedKey: 'orgMembershipGrid',
          isGridDataorgMembership: true,
        }
      }

      for (let i = 0; i < data.length; i++) {
        const entry = data[i]
        if (!entry.orgMembership || !entry.orgMembership.trim()) {
          return {
            allRequiredValuesPresent: false,
            failedKey: `[orgMembershipGrid][${i}]`,
            isGridDataorgMembership: true,
          }
        }
      }
      return null
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Error validating organization membership.',
      })
    }
  }

  const getEncryptionKey = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrm-2023@secrets'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)[
        'ENCRYPTION_KEY_PDS_CACHE'
      ]

      return foundSecret
    } catch (err) {
      console.log(err)
    }
  }

  const loadPDSCache = useCallback(async () => {
    // Load and decrypt saved data when the component mounts
    const encryptedData = localStorage.getItem('encryptedFormData')

    if (!encryptedData) {
      console.warn('No encrypted data found in localStorage.')
      setIsLoaded(true)
      return
    }

    try {
      const encryptionKey = await getEncryptionKey()
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8)

      if (decryptedData) {
        setHoldData(JSON.parse(decryptedData))
        setDoneRender(true)
      } else {
        console.warn('Decrypted data is empty.')
      }
    } catch (error) {
      console.error('Failed to decrypt data:', error)
    } finally {
      setIsLoaded(true)
      setTimeout(() => {
        handleReferencesRemoveRowButtons()
      }, 1000)
    }
  }, [])

  /**
   * @function getSesClientToken
   * @description Gets the SES client token.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Object>} Returns an SES client object.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
      throw err
    }
  }

  /**
   * @function getSourceEmail
   * @description Gets the source email.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<String>} Returns the source email.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  /**
   * @function paramsGenerator
   * @description Generates email parameters for SES
   * @param {string} username - Recipient email
   * @param {string} emailBody - Email content
   * @param {string} emailTitle - Email subject
   * @returns {Object} SES email parameters
   */
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [username],
        },
        Message: {
          Body: {
            Text: {
              Data: emailBody,
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: emailTitle, // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: sourceEmail,
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function sendEmail(usernameFound, sourceEmail) {
    try {
      const emailBody =
        getEmailBodySubmittedByEmployeeNotifDeptApprover(userDetails)
      const params = paramsGenerator(
        usernameFound,
        emailBody,
        'Submission of Personal Data Sheet',
        sourceEmail
      )
      const sesClient = await getSesClientToken()
      await sesClient.send(new SendEmailCommand(params))
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email. Please contact the system admin for help.',
      })
    }
  }

  useEffect(() => {
    if (!isLoaded) return

    // Define an async function for auto-save
    const autoSave = async () => {
      try {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(holdData),
          await getEncryptionKey()
        ).toString()
        localStorage.setItem('encryptedFormData', encryptedData)
      } catch (err) {
        console.log(err)
      }
    }

    // Set up auto-save interval
    const autoSaveInterval = setInterval(autoSave, 5000) // Auto-save every 5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(autoSaveInterval)
  }, [holdData, isLoaded])

  useEffect(() => {
    fetch('https://www.sparksoft-demo.com/formio/pdsformmobilepage1', {
      method: 'HEAD',
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 503) {
          console.log('Server responded with error:', response.status)
          setIsError(true)
        }
      })
      .catch((error) => {
        console.log('Server is down or network request failed:', error)
        setIsError(true)
      })
  }, [])

  const getAllMyTrainings = async () => {
    try {
      let nextToken = null
      let allMyTrainings = []

      do {
        const { data } = await API.graphql(
          graphqlOperation(registrationByUserID, {
            userID: userDetails.id,
            nextToken,
          })
        )
        allMyTrainings = [...allMyTrainings, ...data.registrationByUserID.items]
        nextToken = data.registrationByUserID.nextToken
      } while (nextToken)

      return allMyTrainings.filter(
        (item) =>
          (!item?.training?.isPassPreAssessmentRequired ||
            item?.isPreAssessmentPassed) &&
          item?.isSubmitEndorsementLetter &&
          item?.isPostEvalDone &&
          item?.isAttended &&
          item?.isCourseOutputDone
      )
    } catch (err) {
      console.error(err)
      return []
    }
  }

  function transformGeoRegionData(geoRegionData) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return []
    }

    const dataArray = Object.values(geoRegionData)
    const regions = {}
    const allAreas = {} // This will store both provinces and regions without provinces

    dataArray.forEach((item) => {
      if (!item || typeof item !== 'object') return

      if (item.geographic_level === 'Reg') {
        regions[item.code] = {
          label: item.name,
          value: item.code,
          options: [],
        }
        // Add region to allAreas
        allAreas[item.code] = {
          name: item.name,
          code: item.code,
          geographic_level: 'Reg',
        }
      } else if (item.geographic_level === 'Prov') {
        const province = {
          ...item,
          label: item.name,
          value: item.code,
          regionCode: item.code.substring(0, 3) + '0000000',
        }
        allAreas[item.code] = province

        const regionEntry = regions[province.regionCode]
        if (regionEntry) {
          regionEntry.options.push(province)
        }
      }
    })

    const transformedRegions = Object.values(regions).map((region) => {
      if (region.options.length === 0) {
        // If a region has no provinces, return it as its own option
        return {
          label: region.label,
          value: region.value,
        }
      }
      return region
    })

    return {
      regionGroupList: transformedRegions,
      allAreas: allAreas,
    }
  }

  const loadAndTransformData = async () => {
    let combinedRegionGroupList = []
    let allAreasData = {}
    for (let i = 1; i <= 17; i++) {
      try {
        const geoRegionData = await import(`./../../pgsc/geo-reg-${i}.json`)
        const { regionGroupList, allAreas } = transformGeoRegionData(
          geoRegionData.default
        )
        combinedRegionGroupList =
          combinedRegionGroupList.concat(regionGroupList)
        Object.assign(allAreasData, allAreas)
      } catch (error) {
        console.error(`Error loading data for region ${i}:`, error)
      }
    }

    combinedRegionGroupList.forEach((region) => {
      if (region.label === 'Metro Manila') {
        region.label = 'National Capital Region (NCR)'
      }
    })

    setRegionGroupList(combinedRegionGroupList)
    setProvinceData(allAreasData) // This now includes both provinces and regions
    setProvinceDataPerm(allAreasData) // This now includes both provinces and regions
  }

  function transformMunicipalityData(geoRegionData, selectedProvinceCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { municipalityGroupList: [], allAreas: {} }
    }

    const municipalityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'Mun' || item.geographic_level === 'City') &&
        item.code.startsWith(selectedProvinceCode.substring(0, 5))
      ) {
        const municipalityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        municipalityList.push(municipalityItem)
        allAreas[item.code] = municipalityItem
      }
    })

    return {
      municipalityGroupList: municipalityList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformMunicipalityData = async (provinceCode) => {
    let municipalityList = []
    let allAreasData = {}

    try {
      const regionNumber = provinceCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { municipalityGroupList, allAreas } = transformMunicipalityData(
        geoRegionData.default,
        provinceCode
      )

      municipalityList = municipalityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for province ${provinceCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(municipalityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(municipalityList)
      setSelectedCityData(allAreasData)
    }
  }

  const loadAndTransformCityData = async (regionCode) => {
    let cityList = []
    let allAreasData = {}

    try {
      const regionNumber = regionCode.substring(0, 2)

      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { cityGroupList, allAreas } = transformCityData(
        geoRegionData.default,
        regionCode
      )

      cityList = cityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for region ${regionCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(cityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(cityList)
      setSelectedCityData(allAreasData)
    }
  }

  function transformCityData(geoRegionData, selectedRegionCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { cityGroupList: [], allAreas: {} }
    }

    const cityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'City' || item.geographic_level === 'Mun') &&
        item.code.startsWith(selectedRegionCode.substring(0, 2))
      ) {
        const cityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        cityList.push(cityItem)
        allAreas[item.code] = cityItem
      }
    })

    return {
      cityGroupList: cityList,
      allAreas: allAreas,
    }
  }

  function transformBarangayData(geoRegionData, selectedMunicipalityCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { barangayGroupList: [], allAreas: {} }
    }
    const barangayList = []
    const allAreas = {}
    if (
      selectedMunicipalityCode === '1380600000' ||
      selectedMunicipalityCode === 1380600000
    ) {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          (item.code.startsWith(13806) || item.code.startsWith('13806'))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    } else {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          item.code.startsWith(selectedMunicipalityCode.substring(0, 7))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    }

    return {
      barangayGroupList: barangayList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformBarangayData = async (municipalityCode) => {
    let brgyList = []
    let brgyAreasData = {}
    try {
      const regionNumber = municipalityCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )
      const { barangayGroupList, allAreas } = transformBarangayData(
        geoRegionData.default,
        municipalityCode
      )
      brgyList = barangayGroupList
      brgyAreasData = allAreas
    } catch (error) {
      console.error(
        `Error loading data for municipality ${municipalityCode}:`,
        error
      )
    }

    const sortedArray = brgyList.sort((a, b) => a.label.localeCompare(b.label))

    if (isCityPerm) {
      setBarangayListPerm(sortedArray)
      setAllAreasDataPerm(brgyAreasData)
    } else {
      setBarangayList(sortedArray)
      setAllAreasData(brgyAreasData)
    }
  }

  const fetchTrainings = async () => {
    try {
      const foundTrainings = await getAllMyTrainings()
      if (foundTrainings.length) {
        setMyTrainings([...foundTrainings])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsFinishedScan(true)
    }
  }

  useEffect(async () => {
    loadAndTransformData()

    fetchTrainings()
  }, [])

  const elementaryAsNALoadFunc = (holdData) => {
    try {
      const well4DataGrid2 = holdData.well4DataGrid2
      for (const [index, item] of well4DataGrid2.entries()) {
        const elementaryDateFromNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryDateFromNaAllowed]"]`
        )
        const elementaryDateToNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryDateToNaAllowed]"]`
        )
        const elementaryNameOfSchoolNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryNameOfSchoolNaAllowed]"]`
        )
        const elementaryScholarshipNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryScholarshipNaAllowed]"]`
        )
        const elementaryUnitsEarnedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryUnitsEarnedNaAllowed]"]`
        )
        const elementaryYearGraduatedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid2][${index}][elementaryYearGraduatedNaAllowed]"]`
        )

        const parent =
          elementaryDateFromNaAllowed.parentNode.parentNode.parentNode
            .parentNode.parentNode
        const hideElem = parent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )

        if (index < well4DataGrid2.length - 1) {
          const disableAddRowButton = elementaryDateFromNaAllowed
            .closest('tr')
            .querySelector('.formio-button-remove-row')

          disableAddRowButton.disabled = true
        }

        if (item.elementaryAsNA === true) {
          elementaryDateFromNaAllowed.disabled = true
          elementaryDateToNaAllowed.disabled = true
          elementaryNameOfSchoolNaAllowed.disabled = true
          elementaryScholarshipNaAllowed.disabled = true
          elementaryUnitsEarnedNaAllowed.disabled = true
          elementaryYearGraduatedNaAllowed.disabled = true

          hideElem.style.display = 'none'

          createElemSpanTemp(hideElem, 'BASIC EDUCATION/DEGREE/COURSE')
        }
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading elementary details. Contact the system admin for help.',
      })
    }
  }

  const secondaryAsNALoadFunc = (holdData) => {
    try {
      const well4DataGrid = holdData.well4DataGrid
      for (const [index, item] of well4DataGrid.entries()) {
        const secondaryDateFromNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryDateFromNaAllowed]"]`
        )
        const secondaryDateToNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryDateToNaAllowed]"]`
        )
        const secondaryNameOfSchoolNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryNameOfSchoolNaAllowed]"]`
        )
        const secondaryScholarshipNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryScholarshipNaAllowed]"]`
        )
        const secondaryUnitsEarnedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryUnitsEarnedNaAllowed]"]`
        )
        const secondaryYearGraduatedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid][${index}][secondaryYearGraduatedNaAllowed]"]`
        )

        const parent =
          secondaryDateFromNaAllowed.parentNode.parentNode.parentNode.parentNode
            .parentNode
        const hideElem = parent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )

        if (index < well4DataGrid.length - 1) {
          const disableAddRowButton = secondaryDateFromNaAllowed
            .closest('tr')
            .querySelector('.formio-button-remove-row')

          disableAddRowButton.disabled = true
        }

        if (item.secondaryAsNA === true) {
          secondaryDateFromNaAllowed.disabled = true
          secondaryDateToNaAllowed.disabled = true
          secondaryNameOfSchoolNaAllowed.disabled = true
          secondaryScholarshipNaAllowed.disabled = true
          secondaryUnitsEarnedNaAllowed.disabled = true
          secondaryYearGraduatedNaAllowed.disabled = true

          hideElem.style.display = 'none'

          createElemSpanTemp(hideElem, 'BASIC EDUCATION/DEGREE/COURSE')
        }
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading secondary details. Contact the system admin for help.',
      })
    }
  }

  const vocationalAsNALoadFunc = (holdData) => {
    try {
      const well4Levelvocationaltradecourse =
        holdData.well4Levelvocationaltradecourse
      for (const [index, item] of well4Levelvocationaltradecourse.entries()) {
        const vocationalBasicEducationNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalBasicEducationNaAllowed]"]`
        )
        const vocationalDateFromNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalDateFromNaAllowed]"]`
        )
        const vocationalDateToNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalDateToNaAllowed]"]`
        )
        const vocationalNameOfSchoolNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalNameOfSchoolNaAllowed]"]`
        )
        const vocationalScholarshipNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalScholarshipNaAllowed]"]`
        )
        const vocationalUnitsEarnedNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalUnitsEarnedNaAllowed]"]`
        )
        const vocationalYeargraduatedNaAllowed = document.querySelector(
          `input[name="data[well4Levelvocationaltradecourse][${index}][vocationalYeargraduatedNaAllowed]"]`
        )

        if (index < well4Levelvocationaltradecourse.length - 1) {
          const disableAddRowButton = vocationalBasicEducationNaAllowed
            .closest('tr')
            .querySelector('.formio-button-remove-row')

          disableAddRowButton.disabled = true
        }

        if (item.vocationalAsNA === true) {
          vocationalBasicEducationNaAllowed.disabled = true
          vocationalDateFromNaAllowed.disabled = true
          vocationalDateToNaAllowed.disabled = true
          vocationalNameOfSchoolNaAllowed.disabled = true
          vocationalScholarshipNaAllowed.disabled = true
          vocationalUnitsEarnedNaAllowed.disabled = true
          vocationalYeargraduatedNaAllowed.disabled = true
        }
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading vocational details. Contact the system admin for help.',
      })
    }
  }

  const collegeAsNALoadFunc = (holdData) => {
    try {
      const well4DataGrid3 = holdData.well4DataGrid3
      for (const [index, item] of well4DataGrid3.entries()) {
        const collegeCourseNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeCourseNaAllowed]"]`
        )
        const collegeDateFromNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeDateFromNaAllowed]"]`
        )
        const collegeDateToNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeDateToNaAllowed]"]`
        )
        const collegeNameOfSchoolNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeNameOfSchoolNaAllowed]"]`
        )
        const collegeScholarshipNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeScholarshipNaAllowed]"]`
        )
        const collegeUnitsEarnedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeUnitsEarnedNaAllowed]"]`
        )
        const collegeYearGraduatedNaAllowed = document.querySelector(
          `input[name="data[well4DataGrid3][${index}][collegeYearGraduatedNaAllowed]"]`
        )

        if (index < well4DataGrid3.length - 1) {
          const disableAddRowButton = collegeCourseNaAllowed
            .closest('tr')
            .querySelector('.formio-button-remove-row')

          disableAddRowButton.disabled = true
        }

        if (item.collegeAsNA === true) {
          collegeCourseNaAllowed.disabled = true
          collegeDateFromNaAllowed.disabled = true
          collegeDateToNaAllowed.disabled = true
          collegeNameOfSchoolNaAllowed.disabled = true
          collegeScholarshipNaAllowed.disabled = true
          collegeUnitsEarnedNaAllowed.disabled = true
          collegeYearGraduatedNaAllowed.disabled = true
        }
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading college details. Contact the system admin for help.',
      })
    }
  }

  const gradStudiesAsNALoadFunc = (holdData) => {
    try {
      const well4Well5Levelgraduatestudies =
        holdData.well4Well5Levelgraduatestudies
      for (const [index, item] of well4Well5Levelgraduatestudies.entries()) {
        const graduateCourseNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateCourseNaAllowed]"]`
        )
        const graduateDateFromNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateDateFromNaAllowed]"]`
        )
        const graduateDateToNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateDateToNaAllowed]"]`
        )
        const graduateNameOfSchoolNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateNameOfSchoolNaAllowed]"]`
        )
        const graduateUnitsEarnedNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateUnitsEarnedNaAllowed]"]`
        )
        const graduateYearGraduatedNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateYearGraduatedNaAllowed]"]`
        )
        const graduateScholarshipNaAllowed = document.querySelector(
          `input[name="data[well4Well5Levelgraduatestudies][${index}][graduateScholarshipNaAllowed]"]`
        )

        if (index < well4Well5Levelgraduatestudies.length - 1) {
          const disableAddRowButton = graduateCourseNaAllowed
            .closest('tr')
            .querySelector('.formio-button-remove-row')

          disableAddRowButton.disabled = true
        }

        if (item.gradStudiesAsNA === true) {
          graduateCourseNaAllowed.disabled = true
          graduateDateFromNaAllowed.disabled = true
          graduateDateToNaAllowed.disabled = true
          graduateNameOfSchoolNaAllowed.disabled = true
          graduateUnitsEarnedNaAllowed.disabled = true
          graduateYearGraduatedNaAllowed.disabled = true
          graduateScholarshipNaAllowed.disabled = true
        }
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading graduate studies details. Contact the system admin for help.',
      })
    }
  }

  const permanentAddrLoadFunc = (holdData) => {
    try {
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )
      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"]'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )
      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )
      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )
      if (holdData.sameAsResidentialAddressBox === true) {
        chooseProvinceButtonPermElem.style.display = 'none'
        chooseProvinceButtonPermElem.style.display = 'none'
        chooseCityButtonPermElem.style.display = 'none'
        chooseBarangayButtonPermElem.style.display = 'none'
        chooseZipCodeButtonPermElem.style.display = 'none'
        permanentHouseBlockLotNoRequiredElem.disabled = true
        permanentStreetRequiredElem.disabled = true
        permanentSubdivisionVillageElem.disabled = true
        permanentBarangayRequiredElem.disabled = true
        permanentCityMunicipalityRequiredElem.disabled = true
        permanentProvinceRequiredElem.disabled = true
        permanentZipcodeElem.disabled = true
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading permanent address details. Contact the system admin for help.',
      })
    }
  }

  const createElemSpanTemp = (elemFound, text, correctString) => {
    try {
      // Create a new input element
      const elemValueInput = document.createElement('input')
      const elemValueInputLabel = document.createElement('label')

      // Set attributes for the input
      elemValueInput.type = 'text'
      elemValueInput.value = correctString || 'N/A'
      elemValueInput.disabled = true // Disables the input field

      elemValueInputLabel.classList.add('col-form-label')
      elemValueInputLabel.classList.add('elem-val-temp-label')
      elemValueInputLabel.innerHTML = text

      // Optional: Add some styling or classes if desired
      elemValueInput.classList.add('form-control')
      elemValueInput.classList.add('elem-val-temp')

      // Insert the input next to where the select was
      elemFound.parentNode.insertBefore(elemValueInput, elemFound.nextSibling)
      elemFound.parentNode.insertBefore(
        elemValueInputLabel,
        elemFound.nextSibling
      )
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error creating span element. Contact the system admin for help.',
      })
    }
  }

  const removeElemSpanTemp = (elemFound) => {
    try {
      const elemValueInput = elemFound.querySelectorAll('.elem-val-temp')
      const elemValueInputLabel = elemFound.querySelectorAll(
        '.elem-val-temp-label'
      )

      if (elemValueInput) {
        elemValueInput.forEach((i) => {
          i.remove()
        })
      }

      if (elemValueInputLabel) {
        elemValueInputLabel.forEach((i) => {
          i.remove()
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error removing span element. Contact the system admin for help.',
      })
    }
  }

  const createSpouseSuffixSpanTemp = (spouseSuffixparentElement) => {
    try {
      // Create a new input element
      const spouseSuffixInput = document.createElement('input')
      const spouseSuffixInputLabel = document.createElement('label')

      // Set attributes for the input
      spouseSuffixInput.type = 'text'
      spouseSuffixInput.value = 'N/A'
      spouseSuffixInput.disabled = true // Disables the input field

      spouseSuffixInputLabel.classList.add('col-form-label')
      spouseSuffixInputLabel.classList.add('spouse-suffix-temp-label')
      spouseSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

      // Optional: Add some styling or classes if desired
      spouseSuffixInput.classList.add('form-control')
      spouseSuffixInput.classList.add('spouse-suffix-temp')

      // Insert the input next to where the select was
      spouseSuffixparentElement.parentNode.insertBefore(
        spouseSuffixInput,
        spouseSuffixparentElement.nextSibling
      )
      spouseSuffixparentElement.parentNode.insertBefore(
        spouseSuffixInputLabel,
        spouseSuffixparentElement.nextSibling
      )
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error creating spouse suffix span element. Contact the system admin for help.',
      })
    }
  }

  const removeSpouseSuffixSpanTemp = () => {
    try {
      const spouseSuffixInput = document.querySelectorAll('.spouse-suffix-temp')
      const spouseSuffixInputLabel = document.querySelectorAll(
        '.spouse-suffix-temp-label'
      )

      if (spouseSuffixInput) {
        spouseSuffixInput.forEach((i) => {
          i.remove()
        })
      }

      if (spouseSuffixInputLabel) {
        spouseSuffixInputLabel.forEach((i) => {
          i.remove()
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error removing spouse suffix span element. Contact the system admin for help.',
      })
    }
  }

  const spouseFieldLoadFunc = (holdData) => {
    try {
      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
      } = spouseFields

      if (holdData?.markSpouseAsNA === true) {
        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseEmployerTelephoneno.disabled = true

        createSpouseSuffixSpanTemp(spouseSuffixparentElement)

        spouseSuffixparentElement.style.display = 'none'
        spouseSuffixparentElement.style.visibility = 'hidden'
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading spouse details. Contact the system admin for help.',
      })
    }
  }

  const motherFieldsLoadFunc = (holdData) => {
    try {
      const motherFields = callMotherFieldsFunc()
      const {
        motherSurnameNaAllowed,
        motherFirstnameNaAllowed,
        motherMiddlenameNaAllowed,
      } = motherFields
      if (holdData?.markMotherAsNA === true) {
        motherSurnameNaAllowed.disabled = true
        motherFirstnameNaAllowed.disabled = true
        motherMiddlenameNaAllowed.disabled = true
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading mother details. Contact the system admin for help.',
      })
    }
  }

  const createFatherSuffixSpanTemp = (fatherSuffixparentElement) => {
    try {
      // Create a new input element
      const fatherSuffixInput = document.createElement('input')
      const fatherSuffixInputLabel = document.createElement('label')

      // Set attributes for the input
      fatherSuffixInput.type = 'text'
      fatherSuffixInput.value = 'N/A'
      fatherSuffixInput.disabled = true // Disables the input field

      fatherSuffixInputLabel.classList.add('col-form-label')
      fatherSuffixInputLabel.classList.add('father-suffix-temp-label')
      fatherSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

      // Optional: Add some styling or classes if desired
      fatherSuffixInput.classList.add('form-control')
      fatherSuffixInput.classList.add('father-suffix-temp')

      // Insert the input next to where the select was
      fatherSuffixparentElement.parentNode.insertBefore(
        fatherSuffixInput,
        fatherSuffixparentElement.nextSibling
      )
      fatherSuffixparentElement.parentNode.insertBefore(
        fatherSuffixInputLabel,
        fatherSuffixparentElement.nextSibling
      )
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error creating father suffix span element. Contact the system admin for help.',
      })
    }
  }

  const removeFatherSuffixSpanTemp = () => {
    try {
      const fatherSuffixInput = document.querySelectorAll('.father-suffix-temp')
      const fatherSuffixInputLabel = document.querySelectorAll(
        '.father-suffix-temp-label'
      )

      if (fatherSuffixInput) {
        fatherSuffixInput.forEach((i) => {
          i.remove()
        })
      }

      if (fatherSuffixInputLabel) {
        fatherSuffixInputLabel.forEach((i) => {
          i.remove()
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error removing father suffix span element. Contact the system admin for help.',
      })
    }
  }

  const fatherFieldsLoadFunc = (holdData) => {
    try {
      const fatherFields = callFatherFieldsFunc()
      const {
        fatherSurnameNaAllowed,
        fatherFirstnameNaAllowed,
        fatherMiddlenameNaAllowed,
        fatherSuffixparentElement,
      } = fatherFields

      if (holdData?.markFatherAsNA === true) {
        fatherSurnameNaAllowed.disabled = true
        fatherFirstnameNaAllowed.disabled = true
        fatherMiddlenameNaAllowed.disabled = true

        createFatherSuffixSpanTemp(fatherSuffixparentElement)

        fatherSuffixparentElement.style.display = 'none'
        fatherSuffixparentElement.style.visibility = 'hidden'
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading father details. Contact the system admin for help.',
      })
    }
  }

  const civilStatusRequiredLoadFunc = (holdData) => {
    try {
      if (holdData.civilStatusRequired === 'SINGLE') {
        const spouseSuffixInput = document.querySelectorAll(
          '.spouse-suffix-temp'
        )
        const spouseFields = callSpouseFieldsFunc()
        const {
          spouseSurname,
          spouseFirstName,
          spouseMiddleName,
          spouseSuffixparentElement,
          spouseOccupation,
          spouseEmployerName,
          spouseEmployerAddress,
          spouseEmployerTelephoneno,
          markSpouseAsNA,
        } = spouseFields

        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseEmployerTelephoneno.disabled = true

        if (spouseSuffixInput.length === 0) {
          createSpouseSuffixSpanTemp(spouseSuffixparentElement)
        }

        spouseSuffixparentElement.style.display = 'none'
        spouseSuffixparentElement.style.visibility = 'hidden'

        markSpouseAsNA.checked = true
        markSpouseAsNA.disabled = true
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading civil status details. Contact the system admin for help.',
      })
    }
  }

  const civilServiceLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-well4Well7DataGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-well4Well7DataGrid table'
      )
      const well4Well7DataGrid = holdData.well4Well7DataGrid
      well4Well7DataGrid.forEach((dataPath, index) => {
        if (index < well4Well7DataGrid.length - 1) {
          const disableRemoveRowButton = foundTR[index].querySelector(
            '.formio-button-remove-row'
          )
          disableRemoveRowButton.disabled = true
        }

        if (dataPath.haveAnyEligibility === 'NO') {
          const parentElem = foundTR[index]
          const radioToDisable = parentElem.querySelectorAll(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = parentElem.querySelectorAll(
            'input[type="radio"][value="YES"]'
          )
          radioToDisable.forEach((elem) => {
            elem.disabled = true
          })
          radioToEnable.forEach((elem) => {
            elem.disabled = false
          })

          const searchCheckboxParent = foundTR[index]

          const textInputs = searchCheckboxParent.querySelectorAll(
            'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
          )
          const nonTextInputs = searchCheckboxParent.querySelector(
            '.formio-component-civilServiceAndOthersLicense'
          )
          const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
            '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
          )
          const radioDateValidityDisable =
            searchCheckboxParent.querySelectorAll(
              '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
            )
          radioDateExamDisable.forEach((elem) => {
            elem.disabled = true
            if (elem.value === 'N/A') {
              elem.checked = true
            }
          })
          radioDateValidityDisable.forEach((elem) => {
            elem.disabled = true
            if (elem.value === 'N/A') {
              elem.checked = true
            }
          })

          createElemSpanTemp(
            nonTextInputs,
            `CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE`
          )
          nonTextInputs.style.display = 'none'

          textInputs.forEach((input) => {
            input.value = 'N/A'
            input.disabled = true
          })
        }
      })
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading civil service details. Contact the system admin for help.',
      })
    }
  }

  const handleIfChildrenLoadFunc = (holdData) => {
    try {
      const ifChildren = holdData.ifChildren

      const mainElement = document.querySelector('.formio-component-ifChildren')
      const yesRadio = mainElement.querySelectorAll(
        'input[type="radio"][value="YES"]'
      )
      const noRadio = mainElement.querySelectorAll(
        'input[type="radio"][value="NO"]'
      )

      if (ifChildren === 'NO') {
        yesRadio.forEach((elem) => {
          elem.disabled = false
        })
        noRadio.forEach((elem) => {
          elem.disabled = true
        })
      }

      if (ifChildren === 'YES') {
        yesRadio.forEach((elem) => {
          elem.disabled = true
        })
        noRadio.forEach((elem) => {
          elem.disabled = false
        })

        setTimeout(() => {
          const foundGrid = document.querySelector(
            '.formio-component-well3Well2DataGrid'
          )
          const foundTable = foundGrid.querySelectorAll('table tr')

          const dataPath = holdData.well3Well2DataGrid

          const isValidValue = (value) =>
            value !== '' && value !== null && value !== undefined

          const allValuesPresent = dataPath.every((obj) =>
            Object.entries(obj).every(([key, value]) => {
              return isValidValue(value)
            })
          )

          const disableAddRowButton = foundGrid.querySelector(
            '.formio-button-add-row'
          )

          disableAddRowButton.disabled = !allValuesPresent

          dataPath.forEach((elem, index) => {
            if (index < dataPath.length - 1) {
              const disableRemoveRowButton = foundTable[index].querySelector(
                '.formio-button-remove-row'
              )

              disableRemoveRowButton.disabled = true
            }
          })
        }, 500)
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading children details. Contact the system admin for help.',
      })
    }
  }

  const handleWorkExperienceLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-well4Well8DataGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-well4Well8DataGrid table'
      )

      if (holdData?.well4Well8DataGrid?.length > 0) {
        const well4Well8DataGrid = holdData.well4Well8DataGrid
        well4Well8DataGrid.forEach((dataPath, index) => {
          if (index < well4Well8DataGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading work experience details. Contact the system admin for help.',
      })
    }
  }

  const handleVoluntaryWorkLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-well4Well10DataGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-well4Well10DataGrid table'
      )

      if (holdData?.well4Well10DataGrid?.length > 0) {
        const well4Well10DataGrid = holdData.well4Well10DataGrid

        well4Well10DataGrid.forEach((dataPath, index) => {
          if (index < well4Well10DataGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading voluntary work details. Contact the system admin for help.',
      })
    }
  }

  const handleLDTrainingsLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-well4Well11DataGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-well4Well11DataGrid table'
      )
      const encryptedData = localStorage.getItem('encryptedFormData')

      if (holdData?.well4Well11DataGrid?.length > 0 && encryptedData) {
        const well4Well11DataGrid = holdData.well4Well11DataGrid
        well4Well11DataGrid.forEach((dataPath, index) => {
          if (index < well4Well11DataGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading learning and development details. Contact the system admin for help.',
      })
    }
  }

  const handleNonAcademicdistinctionsrecognitionLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-nonAcademicdistinctionsrecognitionGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-nonAcademicdistinctionsrecognitionGrid table'
      )

      if (holdData?.nonAcademicdistinctionsrecognitionGrid?.length > 0) {
        const nonAcademicdistinctionsrecognitionGrid =
          holdData.nonAcademicdistinctionsrecognitionGrid

        nonAcademicdistinctionsrecognitionGrid.forEach((dataPath, index) => {
          if (index < nonAcademicdistinctionsrecognitionGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading non-academic distinctions and recognition details. Contact the system admin for help.',
      })
    }
  }

  const handleOrgMembershipLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-orgMembershipGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-orgMembershipGrid table'
      )

      if (holdData?.orgMembershipGrid?.length > 0) {
        const orgMembershipGrid = holdData.orgMembershipGrid

        orgMembershipGrid.forEach((dataPath, index) => {
          if (index < orgMembershipGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading organization membership details. Contact the system admin for help.',
      })
    }
  }

  const handleSkillsAndHobbiesLoadFunc = (holdData) => {
    try {
      const foundTR = document.querySelectorAll(
        '.formio-component-skillsAndHobbiesGrid tr'
      )
      const foundTable = document.querySelector(
        '.formio-component-skillsAndHobbiesGrid table'
      )

      if (holdData?.skillsAndHobbiesGrid?.length > 0) {
        const skillsAndHobbiesGrid = holdData.skillsAndHobbiesGrid

        skillsAndHobbiesGrid.forEach((dataPath, index) => {
          if (index < skillsAndHobbiesGrid.length - 1) {
            const disableRemoveRowButton = foundTR[index].querySelector(
              '.formio-button-remove-row'
            )
            disableRemoveRowButton.disabled = true
          }
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error loading skills and hobbies details. Contact the system admin for help.',
      })
    }
  }

  const checkIfForDisableAddButton = (e, buttonGrid) => {
    try {
      const dataFound = e
      const dataPath = dataFound[buttonGrid]

      const isValidValue = (value) =>
        value !== '' && value !== null && value !== undefined

      if (buttonGrid === 'well4DataGrid2') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4DataGrid') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Levelvocationaltradecourse') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4DataGrid3') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Well5Levelgraduatestudies') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Well7DataGrid') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            // Skip validation for empty dates when NA is set
            if (key === 'civilServiceLicenseValidityDate') {
              return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceExamDate') {
              return obj.civilServiceExamDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
              return obj.civilServiceAndOthersLicense === 'OTHERS'
                ? isValidValue(value)
                : true
            }

            if (key === 'civilServiceAndOthersLicense') {
              if (value === 'OTHERS') {
                return isValidValue(
                  obj.civilServiceAndOthersLicenseOthersEligibility
                )
              }
              return isValidValue(value)
            }

            if (key.includes('IsNA')) {
              return value === 'N/A' || value === 'enterDate'
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Well8DataGrid') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (
              key === 'workPayGradeIsNA' &&
              value === 'selectSalaryGradeStepIncrement'
            ) {
              return (
                isValidValue(obj.workExperienceStepIncrement) &&
                isValidValue(obj.workExperiencePayGrade)
              )
            }

            if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
              return isValidValue(obj.workExperienceDateTo)
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Well10DataGrid') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.voluntaryWorkDateFrom) &&
                isValidValue(obj.voluntaryWorkDateTo)
              )
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }

      if (buttonGrid === 'well4Well11DataGrid') {
        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.ldTrainingDateFrom) &&
                isValidValue(obj.ldTrainingDateTo)
              )
            }

            return isValidValue(value)
          })
        )
        return !allValuesPresent
      }
      return false
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (doneRender && Object.entries(holdData).length > 0) {
      const timeoutForNA = setTimeout(() => {
        permanentAddrLoadFunc(holdData)
        spouseFieldLoadFunc(holdData)
        fatherFieldsLoadFunc(holdData)
        motherFieldsLoadFunc(holdData)
        elementaryAsNALoadFunc(holdData)
        secondaryAsNALoadFunc(holdData)
        vocationalAsNALoadFunc(holdData)
        collegeAsNALoadFunc(holdData)
        gradStudiesAsNALoadFunc(holdData)
        civilStatusRequiredLoadFunc(holdData)
        civilServiceLoadFunc(holdData)
        handleIfChildrenLoadFunc(holdData)
        handleWorkExperienceLoadFunc(holdData)
        handleVoluntaryWorkLoadFunc(holdData)
        handleLDTrainingsLoadFunc(holdData)
        handleNonAcademicdistinctionsrecognitionLoadFunc(holdData)
        handleOrgMembershipLoadFunc(holdData)
        handleSkillsAndHobbiesLoadFunc(holdData)
      }, 1000)

      if (Object.keys(holdData).length > 0) {
        setTimeout(() => {
          const disableAddRowButton = document.querySelectorAll(
            '.formio-button-add-row'
          )

          disableAddRowButton.forEach((elem) => {
            const extractedRef = elem
              .getAttribute('ref')
              .replace('datagrid-', '')
              .replace('-addRow', '')

            if (extractedRef === 'referencesRequired') {
              elem.parentNode.remove()
            }

            elem.disabled = checkIfForDisableAddButton(holdData, extractedRef)
          })
        }, 1000)
      }

      return () => clearTimeout(timeoutForNA)
    }
  }, [doneRender, holdData])

  /**
   *@function handlePrefill
   *@description Prefills the form with the user's details.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const handlePrefill = () => {
    try {
      const encryptedData = localStorage.getItem('encryptedFormData')

      if (encryptedData) {
        loadPDSCache()
      } else {
        setIsLoaded(true)
        const prefillData = {
          ...holdData,
          surnameRequired: userDetails.lname.toUpperCase(),
          firstnameRequired: userDetails.name.toUpperCase(),
          middlenameRequired: userDetails.mname
            ? userDetails.mname.toUpperCase()
            : '',
          suffixNaAllowed: userDetails.suffix
            ? userDetails.suffix.toUpperCase()
            : '',
          sexRequired: userDetails.gender.toUpperCase(),
          mobileno: userDetails.contact.replace('+63', '0'),
          emailAddress: userDetails.username.toUpperCase(),

          referencesRequired: [
            {
              referenceNameRequired: '',
              referenceAddressRequired: '',
              referenceTelNoRequired: '',
            },
            {
              referenceNameRequired: '',
              referenceAddressRequired: '',
              referenceTelNoRequired: '',
            },
            {
              referenceNameRequired: '',
              referenceAddressRequired: '',
              referenceTelNoRequired: '',
            },
          ],
        }

        if (myTrainings.length) {
          const well4Well11DataGrid = []
          for (const item of myTrainings) {
            // Sample array of dates
            const arrayOfDates = item.training.arrayOfDates

            function getLastDate(arrayOfDates) {
              if (arrayOfDates) {
                const datesArray = JSON.parse(arrayOfDates)

                if (datesArray && datesArray.length > 1) {
                  // Convert dates to moment objects
                  const momentDates = datesArray.map((date) => moment(date))
                  const latestDate = moment.max(momentDates)
                  return latestDate.format('YYYY-MM-DD')
                }
              }
            }

            const trainingDetails = {
              ldTrainingTitle: item.training.title.toUpperCase(),
              ldTrainingDateFrom: item.training.date,
              ldTrainingDateIsNA: 'selectDates',
            }

            if (arrayOfDates) {
              const datesArray = JSON.parse(arrayOfDates)
              if (datesArray && datesArray.length > 1) {
                trainingDetails.ldTrainingDateTo = getLastDate(arrayOfDates)
              }
            }

            well4Well11DataGrid.push(trainingDetails)
          }
          prefillData.well4Well11DataGrid = well4Well11DataGrid
          prefillData.isLDAttended = 'YES'
        }
        setHoldData((prevState) => ({ ...prevState, ...prefillData }))
      }

      handleReferencesRemoveRowButtons()
    } catch (err) {
      console.log(err)
    }
  }

  const handleReferencesRemoveRowButtons = () => {
    try {
      const referencesGrid = document.querySelector(
        '.formio-component-referencesRequired'
      )
      const removeButtons = referencesGrid.querySelectorAll(
        '.formio-button-remove-row'
      )

      if (removeButtons) {
        removeButtons.forEach((elem) => {
          const delTD = elem.closest('td')
          delTD.remove()
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@description Sets the component's state to open a modal and updates the data to be displayed in the modal.
   *@function
   *@name showModal
   *@param {Object} e - An object containing the data and form properties to be displayed in the modal.
   *@param {Object} e.data - The data to be displayed in the modal.
   *@param {Object} e.form - The form to be displayed in the modal.
   *@return {void}
   **/
  const showModal = (e) => {
    setDataReview({
      data: {
        ...e.data,
      },
      form: e.form,
    })
    setIsModalOpen(true)
  }

  /**
   *@function closeModal
   *@description Closes the modal.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const closeModal = () => {
    try {
      setIsModalOpen(false)
      afterSubmit('SubmittedPDSForms')
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function forUpload
   *@description Uploads the files to the S3 bucket.
   *@param {Object} none No parameter needed.
   *@returns {Object} An object containing the URLs of the uploaded files.
   */
  const forUpload = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      let url, urlThumb, urlPassport

      if (uploadedSignature) {
        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedSignature.name
          }`,
          Body: uploadedSignature,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        await s3.send(command)
        url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
      }

      if (uploadedThumbmark) {
        const paramsThumb = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedThumbmark.name
          }`,
          Body: uploadedThumbmark,
        }

        const commandThumb = new PutObjectCommand(paramsThumb)
        await s3.send(commandThumb)
        urlThumb = `https://${paramsThumb.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsThumb.Key}`
      }

      if (uploadedPassportPhoto) {
        const paramsPassport = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedPassportPhoto.name
          }`,
          Body: uploadedPassportPhoto,
        }

        const commandPassport = new PutObjectCommand(paramsPassport)
        await s3.send(commandPassport)
        urlPassport = `https://${paramsPassport.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsPassport.Key}`
      }

      return { url, urlThumb, urlPassport }
    } catch (err) {
      console.log(err)
    }
  }

  const handleExportData = async (data) => {
    try {
      setHoldData(data.data)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function triggerSave
   *@description Saves the data to the database.
   *@param {Object} url - The URL of the uploaded e-Signature.
   *@param {Object} urlThumb - The URL of the uploaded thumbmark.
   *@param {Object} urlPassport - The URL of the uploaded passport photo.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} The response from the API.
   */
  const triggerSave = async (url, urlThumb, urlPassport, isFinal) => {
    try {
      setHoldData(dataReview.data)

      return await API.graphql(
        graphqlOperation(createPersonalDataSheet, {
          input: {
            data: JSON.stringify(dataReview),
            userID: userDetails.id,
            isFinal: isFinal,
            department: userDetails.assignedDept,
            division: userDetails.division,
            isApproved: 'Pending Approval',
            isHRApproved: 'Pending Approval',
            isAgreeDataPrivacyPDS: isProceedPDS,
            eSignatureLoc: url || '',
            thumbmarkSignLoc: urlThumb || '',
            passportPhotoLoc: urlPassport || '',
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  function checkRequiredValues(obj) {
    try {
      // Get list of fields to check based on citizenship
      const fieldsToCheck = [
        ...REQUIRED_FIELDS.DETAILS.filter((field) => {
          // Only include relatedExplain if isFourthDegreeRequired is "YES"
          if (field === 'relatedExplain') {
            return obj.isFourthDegreeRequired === 'YES'
          }
          // Only include offenseADetails if offenseARequired is "YES"
          if (field === 'offenseADetails') {
            return obj.offenseARequired === 'YES'
          }
          // Only include serviceSeparationADetails if serviceSeparationARequired is "YES"
          if (field === 'serviceSeparationADetails') {
            return obj.serviceSeparationARequired === 'YES'
          }
          // Only include candidateDetails if candidateRequired is "YES"
          if (field === 'candidateDetails') {
            return obj.candidateRequired === 'YES'
          }
          // Only include resignedDetails if resignedRequired is "YES"
          if (field === 'resignedDetails') {
            return obj.resignedRequired === 'YES'
          }
          // Only include immigrantDetails if immigrantRequired is "YES"
          if (field === 'immigrantDetails') {
            return obj.immigrantRequired === 'YES'
          }
          // Only include isIndigenousDetails if isIndigenousRequired is "YES"
          if (field === 'isIndigenousDetails') {
            return obj.isIndigenousRequired === 'YES'
          }
          // Only include isPersonWDisabilityDetails if isPersonWDisabilityRequired is "YES"
          if (field === 'isPersonWDisabilityDetails') {
            return obj.isPersonWDisabilityRequired === 'YES'
          }
          // Only include isSoloParentDetails if isSoloParentRequired is "YES"
          if (field === 'isSoloParentDetails') {
            return obj.isSoloParentRequired === 'YES'
          }
          // Only include offenseCDetails if offenceCRequired is "YES"
          if (field === 'offenseCDetails') {
            return obj.offenceCRequired === 'YES'
          }
          // Only include offenseDateFiled if offenceCRequired is "YES"
          if (field === 'offenseDateFiled') {
            return obj.offenceCRequired === 'YES'
          }
          // Only include offenseCaseStatus if offenceCRequired is "YES"
          if (field === 'offenseCaseStatus') {
            return obj.offenceCRequired === 'YES'
          }
          // Only include courtADetails if offenseARequired is "YES"
          if (field === 'courtADetails') {
            return obj.courtARequired === 'YES'
          }

          return true
        }),
      ]

      if (obj.citizenshipQuestionRequired !== 'DUAL CITIZENSHIP') {
        // Remove citizenship-related fields from basic fields when Filipino
        fieldsToCheck.push(
          ...REQUIRED_FIELDS.BASIC.filter((field) => {
            if (!['birthOrNaturaliztion', 'indicateCountry'].includes(field)) {
              return true
            }
            // Height format validation
            if (field === 'heightFt') {
              return obj.heightFormat === 'feetInches'
            }
            if (field === 'heightIn') {
              return obj.heightFormat === 'feetInches'
            }
            if (field === 'heightMeters') {
              return obj.heightFormat === 'meters'
            }
          })
        )
      } else {
        // Include all basic fields including citizenship-related fields for non-Filipino
        fieldsToCheck.push(...REQUIRED_FIELDS.BASIC)
      }

      const finalFieldsToCheck = fieldsToCheck.filter((field) => {
        // Height format validation
        if (field === 'heightFt') {
          return obj.heightFormat === 'feetInches'
        }
        if (field === 'heightIn') {
          return obj.heightFormat === 'feetInches'
        }
        if (field === 'heightMeters') {
          return obj.heightFormat === 'meters'
        }
        if (field === 'heightMeters') {
          return obj.heightFormat === 'meters'
        }
        if (field === 'weightPounds') {
          return obj.weightFormat === 'pounds'
        }
        if (field === 'weightKg') {
          return obj.weightFormat === 'kilograms'
        }

        return field
      })

      // Check basic required fields
      for (const field of finalFieldsToCheck) {
        if (
          obj[field] === undefined ||
          obj[field] === null ||
          (typeof obj[field] === 'string' && !obj[field].trim())
        ) {
          return { allRequiredValuesPresent: false, failedKey: field }
        }

        if (field === 'govtIssuedIDRequired') {
          if (
            obj.govtIssuedIDRequired === undefined ||
            obj.govtIssuedIDRequired === null ||
            obj.govtIssuedIDRequired === '' ||
            obj.govtIssuedIDRequired === 'N/A'
          ) {
            return {
              allRequiredValuesPresent: false,
              failedKey: 'govtIssuedIDRequired',
            }
          }
        }
      }

      // Validate references grid
      if (obj.referencesRequired) {
        const refResult = validateGridData(
          obj.referencesRequired,
          ['referenceNameRequired', 'referenceAddressRequired', 'referenceTel'],
          'referencesRequired'
        )
        if (refResult) return refResult
      }

      // Validate children grid if applicable
      if (obj.ifChildren === 'YES') {
        const childrenResult = validateGridData(
          obj.well3Well2DataGrid,
          ['childrenFullnameNaAllowed', 'childrenDOBNaAllowed'],
          'well3Well2DataGrid'
        )
        if (childrenResult) return childrenResult
      }

      if (obj.well4DataGrid2) {
        const elementaryResult = validateElementaryEducation(obj.well4DataGrid2)
        if (elementaryResult) return elementaryResult
      }

      if (obj.well4DataGrid) {
        const secondaryResult = validateSecondaryEducation(obj.well4DataGrid)
        if (secondaryResult) return secondaryResult
      }

      if (obj.well4Levelvocationaltradecourse) {
        const vocationalResult = validateVocationalEducation(
          obj.well4Levelvocationaltradecourse
        )
        if (vocationalResult) return vocationalResult
      }

      if (obj.well4DataGrid3) {
        const collegeResult = validateCollegeEducation(obj.well4DataGrid3)
        if (collegeResult) return collegeResult
      }

      if (obj.well4Well5Levelgraduatestudies) {
        const gradStudiesResult = validateGradStudies(
          obj.well4Well5Levelgraduatestudies
        )
        if (gradStudiesResult) return gradStudiesResult
      }

      if (obj.well4Well7DataGrid) {
        const civilServiceResult = validateCivilServiceEligibility(
          obj.well4Well7DataGrid
        )
        if (civilServiceResult) return civilServiceResult
      }

      if (obj.skillsAndHobbiesGrid) {
        const skillsResult = validateSkillsAndHobbies(obj.skillsAndHobbiesGrid)
        if (skillsResult) return skillsResult
      }

      if (obj.nonAcademicdistinctionsrecognitionGrid) {
        const distinctionsResult = validateNonAcademicDistinctions(
          obj.nonAcademicdistinctionsrecognitionGrid
        )
        if (distinctionsResult) return distinctionsResult
      }

      if (obj.orgMembershipGrid) {
        const orgResult = validateOrgMembership(obj.orgMembershipGrid)
        if (orgResult) return orgResult
      }

      if (obj.well4Well8DataGrid) {
        const workExperienceResult = validateWorkExperience(
          obj.well4Well8DataGrid
        )
        if (workExperienceResult) return workExperienceResult
      }

      if (obj.well4Well11DataGrid) {
        const ldTrainingsResult = validateLDTrainings(obj.well4Well11DataGrid)
        if (ldTrainingsResult) return ldTrainingsResult
      }

      if (obj.well4Well10DataGrid) {
        const voluntaryWorkResult = validateVoluntaryWork(
          obj.well4Well10DataGrid
        )
        if (voluntaryWorkResult) return voluntaryWorkResult
      }

      return { allRequiredValuesPresent: true, failedKey: null }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'Error checking required values. Contact the system admin for help.',
      })
    }
  }

  function errorNonTextType(element, picture) {
    try {
      if (picture) {
        const errorSpan = document.createElement('span')

        errorSpan.textContent = '* upload here *'
        errorSpan.classList.add('errorSpan')

        element.insertAdjacentElement('afterend', errorSpan)

        notification.error({
          message: 'Error on a required field.',
          description: `Please upload your ${picture}.`,
        })

        setRemoveAsterisk(errorSpan)
      } else {
        const labelElement = element.querySelector('label')
        const errorSpan = document.createElement('span')

        errorSpan.textContent = '*'
        errorSpan.classList.add('errorSpan')

        labelElement.insertAdjacentElement('afterend', errorSpan)
        setRemoveAsterisk(errorSpan)
      }

      element.scrollIntoView({ behavior: 'smooth' })

      const elementRect = element.getBoundingClientRect()
      const offset = (window.innerHeight - elementRect.height) / 2
      const scrollOffset = elementRect.top - offset

      element.scrollIntoView({ behavior: 'smooth' })

      window.scrollBy(0, scrollOffset)

      setIsModalOpen(false)
      setIsDisabledAfterSave(false)
    } catch (err) {
      console.log(err)
    }
  }

  function repeatingNotif() {
    notification.error({
      message: 'Error on a required field.',
      description:
        'This is a required field. Please fill it out before proceeding.',
    })
  }

  /**
   *@function handleSave
   *@description Handles the saving of the data.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} none No return value.
   */
  const handleSave = async (isFinal) => {
    try {
      setIsDisabledAfterSave(true)
      const { url, urlThumb, urlPassport } = await forUpload()

      if (removeAsterisk) {
        removeAsterisk.remove()
      }

      if (isFinal) {
        let errorSpans = document.getElementsByClassName('errorSpan')
        let errorSpansArray = Array.from(errorSpans)

        errorSpansArray.forEach(function (span) {
          span.parentNode.removeChild(span)
        })

        let errorSpansTemp = document.getElementsByClassName('errorSpanTemp')

        let result = checkRequiredValues(dataReview.data)

        const isGridData = result.isGridData
        const allRequiredValuesPresent = result.allRequiredValuesPresent
        const isGridDataChildren = result.isGridDataChildren
        const isGridDataElementary = result.isElementaryGrid
        const isGridDataSecondary = result.isSecondaryGrid
        const isGridDataVocational = result.isVocationalGrid
        const isGridDataCollege = result.isCollegeGrid
        const isGridDataGraduateStudies = result.isGraduateStudies
        const isGridDataWorkExperience = result.isGridDataWorkExperience
        const isGridDataVoluntaryWork = result.isGridVoluntaryWork
        const isGridDataLNDAtendee = result.isGridlNd
        const isGridDataHobbies = result.isGridDataHobbies
        const isGridDatanonAcademicdistinctionsrecognition =
          result.isGridDatanonAcademicdistinctionsrecognition
        const isGridDataorgMembership = result.isGridDataorgMembership
        const isCivilServiceEligibility = result.isCivilServiceEligibility

        if (allRequiredValuesPresent === false) {
          const editData = { ...dataReview.data }

          if (isGridData) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            const labelElement =
              element.parentNode.parentNode.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
            element.classList.add('is-invalid')
            element.scrollIntoView({ behavior: 'smooth' })

            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            isGridDataElementary ||
            isGridDataSecondary ||
            isGridDataVocational ||
            isGridDataCollege ||
            isGridDataGraduateStudies
          ) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              const labelElement =
                element.parentNode.parentNode.querySelector('label')
              if (labelElement) {
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              } else {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )

              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (isGridDataLNDAtendee) {
            const element = document.querySelectorAll(
              '.formio-component-well4Well11DataGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]

            if (elementSelectedWithIndex) {
              const elementInput = elementSelectedWithIndex.querySelector(
                `input[name="data${result.failedKey}"]`
              )

              if (elementInput) {
                const labelElement =
                  elementInput.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                if (labelElement) {
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementInput.classList.add('is-invalid')
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                } else {
                  const labelElementIn =
                    elementInput.parentNode.parentNode.parentNode.querySelector(
                      'label'
                    )
                  labelElementIn.insertAdjacentElement('afterend', errorSpan)
                  labelElementIn.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementInput.classList.add('is-invalid')
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              } else {
                const elementSelect = elementSelectedWithIndex.querySelector(
                  `select[name="data${result.failedKey}"]`
                )

                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )

                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (isGridDataWorkExperience || isGridDataVoluntaryWork) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              if (
                result.failedKey.includes('[workExperienceDateFrom]') ||
                result.failedKey.includes('[workExperienceSalary]') ||
                result.failedKey.includes('[voluntaryWorkOrg]') ||
                result.failedKey.includes('[voluntaryWorkDateFrom]') ||
                result.failedKey.includes('[voluntaryWorkDateTo]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else if (
                result.failedKey.includes('[workExperiencePositionTitle]') ||
                result.failedKey.includes('[voluntaryWorkHoursNo]') ||
                result.failedKey.includes('[voluntaryWorkPosition]') ||
                result.failedKey.includes('[workExperienceCompany]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelectorAll(
                  '.formio-component-voluntaryWorkDateIsNA'
                )
                const parts = result.failedKey.split(']')
                const indexPart = parts[1]
                const indexStr = indexPart.substring(1)
                const index = parseInt(indexStr, 10)

                let elementSelectedWithIndex = elementSelect[index]
                if (elementSelectedWithIndex) {
                  const labelElement =
                    elementSelectedWithIndex.parentNode.querySelector('span')
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelectedWithIndex.classList.add('is-invalid')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }

            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (isGridDataHobbies) {
            const element = document.querySelectorAll(
              '.formio-component-skillsAndHobbiesGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')

              setIsModalOpen(false)
              setIsDisabledAfterSave(false)
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDatanonAcademicdistinctionsrecognition) {
            const element = document.querySelectorAll(
              '.formio-component-nonAcademicdistinctionsrecognitionGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              setIsModalOpen(false)
              setIsDisabledAfterSave(false)
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDataorgMembership) {
            const element = document.querySelectorAll(
              '.formio-component-orgMembershipGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              setIsModalOpen(false)
              setIsDisabledAfterSave(false)
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isCivilServiceEligibility) {
            if (result.failedKey.includes('[haveAnyEligibility]')) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-haveAnyEligibility'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes('[civilServiceExamDateIsNA]') ||
              result.failedKey.includes('[civilServiceExamDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceExamDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes(
                '[civilServiceLicenseValidityDateIsNA]'
              ) ||
              result.failedKey.includes('[civilServiceLicenseValidityDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (elementSelect) {
                  const labelElement =
                    elementSelect.parentNode.parentNode.parentNode.querySelector(
                      'label'
                    )
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelect.classList.add('is-invalid')
                  elementSelect.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }

            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (isGridDataChildren) {
            if (result.failedKey.includes('[childrenFullnameNaAllowed]')) {
              const element = document.querySelector(
                `input[name="data${result.failedKey}"]`
              )

              if (element) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')

                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')

                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')

                element.scrollIntoView({ behavior: 'smooth' })

                const elementRect = element.getBoundingClientRect()
                const offset = (window.innerHeight - elementRect.height) / 2
                const scrollOffset = elementRect.top - offset

                element.scrollIntoView({ behavior: 'smooth', block: 'center' })

                window.scrollBy(0, scrollOffset)
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else if (result.failedKey.includes('[childrenDOBNaAllowed]')) {
              if (result.failedKey) {
                const element = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (element) {
                  // Use closest() to find the nearest ancestor which matches the selector
                  const labelElement = element
                    .closest('.form-group')
                    .querySelector('label')
                  if (labelElement) {
                    const errorSpan = document.createElement('span')
                    errorSpan.textContent = '*'
                    errorSpan.classList.add('errorSpan')
                    labelElement.appendChild(errorSpan)
                    setRemoveAsterisk(errorSpan)
                  }
                  element.classList.add('is-invalid')
                  const errorElement = document.querySelector('.errorSpan')
                  if (errorElement) {
                    errorElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (
            result.failedKey === 'suffixNaAllowed' ||
            result.failedKey === 'spouseSuffixNaAllowed' ||
            result.failedKey === 'fatherSuffixNaAllowed'
          ) {
            const dataSuffix = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            errorNonTextType(dataSuffix)
            dataSuffix.parentNode.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'serviceSeparationADetails') {
            const elementSelect = document.querySelector(
              '.formio-component-serviceSeparationADetails'
            )

            if (elementSelect) {
              const labelElement = elementSelect.querySelector('label')
              const errorSpan = document.createElement('span')
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }

            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (
            result.failedKey === 'isWorkExperience' ||
            result.failedKey === 'isVoluntaryWork'
          ) {
            const element = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            const labelElement = element.querySelector('label')
            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            labelElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
            repeatingNotif()
          } else if (
            result.failedKey === 'offenseDateFiled' ||
            result.failedKey === 'offenseCaseStatus'
          ) {
            const element = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            const labelElement = element.querySelector('label')
            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            labelElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
            repeatingNotif()
          } else if (result.failedKey === 'govtIssuedIDRequired') {
            const element = document.querySelector(
              `input[name="data[${result.failedKey}]"]`
            )

            const labelElement =
              element.parentElement?.parentNode?.querySelector('label')
            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '* N/A is not allowed *'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding. N/A is not allowed.',
            })
          } else if (
            result.failedKey !== 'sexRequired' &&
            result.failedKey !== 'heightFormat' &&
            result.failedKey !== 'weightFormat' &&
            result.failedKey !== 'dateofbirthRequired' &&
            result.failedKey !== 'civilStatusRequired' &&
            result.failedKey !== 'citizenshipQuestionRequired' &&
            result.failedKey !== 'birthOrNaturaliztion' &&
            result.failedKey !== 'indicateCountry' &&
            result.failedKey !== 'isThirdDegreeRequired' &&
            result.failedKey !== 'isFourthDegreeRequired' &&
            result.failedKey !== 'offenseARequired' &&
            result.failedKey !== 'offenceCRequired' &&
            result.failedKey !== 'courtARequired' &&
            result.failedKey !== 'serviceSeparationARequired' &&
            result.failedKey !== 'candidateRequired' &&
            result.failedKey !== 'resignedRequired' &&
            result.failedKey !== 'immigrantRequired' &&
            result.failedKey !== 'isIndigenousRequired' &&
            result.failedKey !== 'isPersonWDisabilityRequired' &&
            result.failedKey !== 'isSoloParentRequired' &&
            result.failedKey !== 'bloodtype' &&
            result.failedKey !== 'ifChildren' &&
            result.failedKey !== 'well3Well2DataGrid' &&
            result.failedKey !== 'isLDAttended'
          ) {
            const element = document.querySelector(
              `input[name="data[${result.failedKey}]"]`
            )

            const labelElement =
              element.parentElement?.parentNode?.querySelector('label')
            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            repeatingNotif()
          }

          setHoldData(editData)

          const element = document.querySelector(
            `input[name="data[${result.failedKey}]"]`
          )

          function repeatingNotif() {
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          }

          if (element) {
            if (result.failedKey === 'dateofbirthRequired') {
              const labelElement =
                element.parentNode.parentNode.parentNode.querySelector('label')
              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              labelElement.classList.add('is-invalid')

              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.parentNode.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              const modalBody = document.querySelector('.ant-modal-body') // Select the modal body element using a class

              if (modalBody) {
                modalBody.scrollTo({
                  top: scrollOffset,
                  behavior: 'smooth',
                })
              }

              notification.error({
                message: 'Error',
                description: 'Please select your valid date of birth.',
              })
            } else {
              element.classList.add('is-invalid')
              element.scrollIntoView({ behavior: 'smooth', block: 'center' })

              const modalContainer = document.querySelector('.ant-modal-wrap') // Select the modal's container element
              const modalContent =
                modalContainer.querySelector('.ant-modal-content') // Select the scrollable container within the modal

              element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              modalContent.scrollTop += modalContent.getBoundingClientRect().top
            }

            setIsModalOpen(false)
            setIsDisabledAfterSave(false)
          } else if (result.failedKey === 'bloodtype') {
            const element = document.querySelector(
              '.formio-component-bloodtype'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your blood type.',
            })
          } else if (
            result.failedKey.includes('[workExperienceIsGovtService]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workExperienceIsGovtService'
            )

            errorNonTextType(element[index])
          } else if (result.failedKey.includes('[workPayGradeIsNA]')) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workPayGradeIsNA'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            result.failedKey.includes('[workInclusiveDateToFormat]') ||
            result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workInclusiveDateToFormat'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'civilStatusRequired') {
            const element = document.querySelector(
              '.formio-component-civilStatusRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your civil status.',
            })
          } else if (result.failedKey === 'citizenshipQuestionRequired') {
            const element = document.querySelector(
              '.formio-component-citizenshipQuestionRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your citizenship.',
            })
          } else if (result.failedKey === 'birthOrNaturaliztion') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-birthOrNaturaliztion'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description: `Please select between "by birth" or "by naturalization".`,
              })
            }
          } else if (result.failedKey === 'indicateCountry') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-indicateCountry'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description:
                  'Please select if country if you have dual citizenship.',
              })
            }
          } else if (result.failedKey === 'isLDAttended') {
            const element = document.querySelector(
              '.formio-component-isLDAttended'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isThirdDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isThirdDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isFourthDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isFourthDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenseARequired') {
            const element = document.querySelector(
              '.formio-component-offenseARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenceCRequired') {
            const element = document.querySelector(
              '.formio-component-offenceCRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'courtARequired') {
            const element = document.querySelector(
              '.formio-component-courtARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'serviceSeparationARequired') {
            const element = document.querySelector(
              '.formio-component-serviceSeparationARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'candidateRequired') {
            const element = document.querySelector(
              '.formio-component-candidateRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'resignedRequired') {
            const element = document.querySelector(
              '.formio-component-resignedRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'immigrantRequired') {
            const element = document.querySelector(
              '.formio-component-immigrantRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isIndigenousRequired') {
            const element = document.querySelector(
              '.formio-component-isIndigenousRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isPersonWDisabilityRequired') {
            const element = document.querySelector(
              '.formio-component-isPersonWDisabilityRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isSoloParentRequired') {
            const element = document.querySelector(
              '.formio-component-isSoloParentRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'sexRequired') {
            const element = document.querySelector(
              '.formio-component-sexRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'heightFormat') {
            const element = document.querySelector(
              '.formio-component-heightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'weightFormat') {
            const element = document.querySelector(
              '.formio-component-weightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'ifChildren') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'well3Well2DataGrid') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )
            errorNonTextType(element)
            repeatingNotif()
          }
        } else {
          if (uploadedSignature && uploadedThumbmark && uploadedPassportPhoto) {
            await triggerSave(url, urlThumb, urlPassport, isFinal)

            const getDeptApproverDetailsArray =
              await getDeptApproverDetailsArrayFunction()

            const sourceEmail = (await getSourceEmail()) || ''

            if (
              getDeptApproverDetailsArray?.length >= 1 &&
              !window.location.href.includes('localhost')
            ) {
              await sendEmail(
                getDeptApproverDetailsArray[0].username,
                sourceEmail
              )

              notification.success({
                message: 'Success',
                description:
                  'You have submitted for review your Personal Data Sheet successfully.',
              })
            } else if (!getDeptApproverDetailsArray?.length >= 1) {
              notification.info({
                message: 'NOTICE',
                duration: 120000,
                description: `We didn't see any Department Approver for your PDS. Please reach out to HRM Department to inform them of your submitted PDS Form`,
              })
            }

            closeModal()
          } else if (!uploadedSignature) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6ColumnsSignature'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'e-Signature and thumbmark photo')
          } else if (!uploadedThumbmark) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6Content'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'thumbmark photo / e-Signature')
          } else if (!uploadedPassportPhoto) {
            let divElement = document.querySelector(
              '.formio-component-well4Well22ColumnsContent'
            )

            let spanElement = divElement.querySelector('span')
            errorNonTextType(spanElement, 'passport photo')
          }
        }
      } else {
        await triggerSave(url, urlThumb, urlPassport, isFinal)

        closeModal()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getDeptApproverDetailsArrayFunction = async () => {
    try {
      let getDeptApproverDetailsArray = []
      let token = null

      do {
        const getDeptApproverDetails = await API.graphql(
          graphqlOperation(UserbyDepartment, {
            assignedDept: userDetails.assignedDept,
            filter: {
              or: [
                { pdsRole: { eq: 'deptApprover' } },
                { pdsRole: { eq: 'deptApproverAndEncoder' } },
              ],
            },
            nextToken: token,
          })
        )

        getDeptApproverDetailsArray.push(
          ...getDeptApproverDetails.data.UserbyDepartment.items
        )

        token = getDeptApproverDetails.data.UserbyDepartment.nextToken
      } while (token)

      return getDeptApproverDetailsArray
    } catch (err) {
      console.log(err)
      return []
    }
  }

  /**
   *@function handleSignType
   *@description Handles the event type of the button from Formio
   *@param {Object} type - The type of the event.
   *@returns {Object} none No return value.
   */
  const handleSignType = (type) => {
    try {
      if (type === 'customEventThumbSign') {
        setIsModalOpenThumbmark(true)
      }

      if (type === 'customEventESign') {
        setIsModalOpenESign(true)
      }

      if (type === 'customEventPassportPhoto') {
        setIsModalOpenPassportPhoto(true)
      }

      if (type === 'chooseProvince') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(false)
      }

      if (type === 'chooseBarangay') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(false)
      }

      if (type === 'chooseCity') {
        setIsModalOpenCity(true)
        setIsCityPerm(false)
      }

      if (type === 'chooseProvincePerm') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(true)
      }

      if (type === 'chooseBarangayPerm') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(true)
      }

      if (type === 'chooseCityPerm') {
        setIsModalOpenCity(true)
        setIsCityPerm(true)
      }

      if (type === 'choosezipcode') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(false)
      }

      if (type === 'choosezipcodePerm') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangePassportPhoto
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangePassportPhoto = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const passportPhoto =
          document.getElementsByClassName('passport-photo')[0]

        const imgElementDels = passportPhoto.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            passportPhoto.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          passportPhoto.appendChild(imgElement)

          setIsModalOpenESign(false)
          setIsModalOpenThumbmark(false)
          setIsModalOpenPassportPhoto(false)
        }

        reader.readAsDataURL(file)
        setUploadedPassportPhoto(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeESign
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeESign = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eSignDiv = document.getElementsByClassName(
          'electronic-signature'
        )[0]

        const imgElementDels = eSignDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eSignDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eSignDiv.appendChild(imgElement)
          setIsModalOpenESign(false)
        }

        reader.readAsDataURL(file)
        setUploadedSignature(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeThumb
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeThumb = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eThumbDiv = document.getElementsByClassName(
          'electronic-thumbmark'
        )[0]

        const imgElementDels = eThumbDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eThumbDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader() // Create a FileReader instance

        reader.onload = (event) => {
          const dataURL = event.target.result // Get the data URL from the FileReader

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eThumbDiv.appendChild(imgElement)
          setIsModalOpenThumbmark(false)
        }

        reader.readAsDataURL(file)
        setUploadedThumbmark(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const inputChangeHandler = (inputElement, val) => {
    try {
      if (inputElement) {
        if (inputElement.type === 'radio') {
          // For radio inputs, find the corresponding radio option and set its checked property
          const radioOption = document.querySelector(
            `input[name="${inputElement.name}"][value="${val}"]`
          )
          if (radioOption) {
            radioOption.checked = true

            const event = new Event('change', { bubbles: true })
            radioOption.dispatchEvent(event)
          }
        } else {
          // For other input types, set the value property
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
          ).set
          nativeInputValueSetter.call(inputElement, val)

          const event = new Event('input', { bubbles: true })
          inputElement.dispatchEvent(event)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callSpouseFieldsFunc = () => {
    try {
      const spouseSurname = document.querySelector(
        'input[name="data[spouseSurnameNaAllowed]"]'
      )
      const spouseFirstName = document.querySelector(
        'input[name="data[spouseFirstnameNaAllowed]"]'
      )
      const spouseMiddleName = document.querySelector(
        'input[name="data[spouseMiddlenameNaAllowed]"]'
      )
      const spouseSuffixparentElement = document.querySelector(
        '.formio-component-spouseSuffixNaAllowed'
      )
      const dropdownList = spouseSuffixparentElement.querySelectorAll(
        '.choices__list.choices__list--dropdown'
      )
      const suffixDropDown = dropdownList[0]
      const allChoices = spouseSuffixparentElement.querySelectorAll(
        '.choices__item.choices__placeholder.choices__item--selectable'
      )
      const spouseSuffixSelect = allChoices[0]
      const spouseOccupation = document.querySelector(
        'input[name="data[spouseOccupationNaAllowed]"]'
      )
      const spouseEmployerName = document.querySelector(
        'input[name="data[spouseEmployerNameNaAllowed]"]'
      )
      const spouseEmployerAddress = document.querySelector(
        'input[name="data[spouseEmployerAddressNaAllowed]"]'
      )
      const spouseEmployerTelephoneno = document.querySelector(
        'input[name="data[spouseEmployerTelephonenoNaAllowed]"]'
      )
      const markSpouseAsNA = document.querySelector(
        'input[name="data[markSpouseAsNA]"]'
      )

      return {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        dropdownList,
        suffixDropDown,
        allChoices,
        spouseSuffixSelect,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
        markSpouseAsNA,
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callFatherFieldsFunc = () => {
    try {
      const fatherSurnameNaAllowed = document.querySelector(
        'input[name="data[fatherSurnameNaAllowed]"]'
      )
      const fatherFirstnameNaAllowed = document.querySelector(
        'input[name="data[fatherFirstnameNaAllowed]"]'
      )
      const fatherMiddlenameNaAllowed = document.querySelector(
        'input[name="data[fatherMiddlenameNaAllowed]"]'
      )
      const fatherSuffixparentElement = document.querySelector(
        '.formio-component-fatherSuffixNaAllowed'
      )
      const dropdownListFather = fatherSuffixparentElement.querySelectorAll(
        '.choices__list.choices__list--dropdown'
      )
      const suffixDropDownFather = dropdownListFather[0]
      const allChoicesFather = fatherSuffixparentElement.querySelectorAll(
        '.choices__item.choices__placeholder.choices__item--selectable'
      )
      const fatherSuffixSelect = allChoicesFather[0]

      return {
        fatherSurnameNaAllowed,
        fatherFirstnameNaAllowed,
        fatherMiddlenameNaAllowed,
        fatherSuffixparentElement,
        dropdownListFather,
        suffixDropDownFather,
        allChoicesFather,
        fatherSuffixSelect,
      }
    } catch (err) {
      console.log(err)
    }
  }

  const callMotherFieldsFunc = () => {
    try {
      const motherSurnameNaAllowed = document.querySelector(
        'input[name="data[motherSurnameNaAllowed]"]'
      )
      const motherFirstnameNaAllowed = document.querySelector(
        'input[name="data[motherFirstnameNaAllowed]"]'
      )
      const motherMiddlenameNaAllowed = document.querySelector(
        'input[name="data[motherMiddlenameNaAllowed]"]'
      )

      return {
        motherSurnameNaAllowed,
        motherFirstnameNaAllowed,
        motherMiddlenameNaAllowed,
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCivilStatusRequiredToSingleFunc = (e) => {
    try {
      const spouseSuffixInput = document.querySelectorAll('.spouse-suffix-temp')
      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
        markSpouseAsNA,
      } = spouseFields

      markSpouseAsNA.checked = true
      markSpouseAsNA.disabled = true

      spouseSurname.disabled = true
      spouseFirstName.disabled = true
      spouseMiddleName.disabled = true
      spouseOccupation.disabled = true
      spouseEmployerName.disabled = true
      spouseEmployerAddress.disabled = true
      spouseEmployerTelephoneno.disabled = true

      spouseSurname.value = 'N/A'
      spouseFirstName.value = 'N/A'
      spouseMiddleName.value = 'N/A'
      spouseOccupation.value = 'N/A'
      spouseEmployerName.value = 'N/A'
      spouseEmployerAddress.value = 'N/A'
      spouseEmployerTelephoneno.value = 'N/A'

      if (spouseSuffixInput.length === 0) {
        createSpouseSuffixSpanTemp(spouseSuffixparentElement)
      }

      spouseSuffixparentElement.style.display = 'none'
      spouseSuffixparentElement.style.visibility = 'hidden'

      e.data.markSpouseAsNA = true
      e.data.spouseSurnameNaAllowed = 'N/A'
      e.data.spouseFirstnameNaAllowed = 'N/A'
      e.data.spouseMiddlenameNaAllowed = 'N/A'
      e.data.spouseOccupationNaAllowed = 'N/A'
      e.data.spouseEmployerNameNaAllowed = 'N/A'
      e.data.spouseEmployerAddressNaAllowed = 'N/A'
      e.data.spouseEmployerTelephonenoNaAllowed = 'N/A'
      e.data.spouseSuffixNaAllowed = 'N/A'

      setHoldData(e.data)
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred when loading values for Spouse when Civil status is Single. Contact system admin for help.',
      })
    }
  }

  const handleCivilStatusRequiredToNOTSingleFunc = (e) => {
    try {
      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
        markSpouseAsNA,
      } = spouseFields

      e.data.markSpouseAsNA = false
      e.data.spouseSurname = ''
      e.data.spouseFirstName = ''
      e.data.spouseMiddleName = ''
      e.data.spouseOccupation = ''
      e.data.spouseEmployerName = ''
      e.data.spouseEmployerAddress = ''
      e.data.spouseEmployerTelephoneno = ''
      e.data.spouseSuffixNaAllowed = ''

      markSpouseAsNA.checked = false
      markSpouseAsNA.disabled = false

      spouseSurname.disabled = false
      spouseFirstName.disabled = false
      spouseMiddleName.disabled = false
      spouseOccupation.disabled = false
      spouseEmployerName.disabled = false
      spouseEmployerAddress.disabled = false
      spouseMiddleName.disabled = false
      spouseEmployerTelephoneno.disabled = false

      spouseSurname.value = ''
      spouseFirstName.value = ''
      spouseMiddleName.value = ''
      spouseOccupation.value = ''
      spouseEmployerName.value = ''
      spouseEmployerAddress.value = ''
      spouseMiddleName.value = ''
      spouseEmployerTelephoneno.value = ''

      spouseSuffixparentElement.style = undefined

      removeSpouseSuffixSpanTemp()

      setHoldData(e.data)
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred when loading values for Spouse when Civil status is NOT Single. Contact system admin for help.',
      })
    }
  }

  const handleChangeForm = (e) => {
    try {
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )
      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"]'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )

      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )

      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )

      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
      } = spouseFields

      const fatherFields = callFatherFieldsFunc()
      const {
        fatherSurnameNaAllowed,
        fatherFirstnameNaAllowed,
        fatherMiddlenameNaAllowed,
        fatherSuffixparentElement,
      } = fatherFields

      const motherFields = callMotherFieldsFunc()
      const {
        motherSurnameNaAllowed,
        motherFirstnameNaAllowed,
        motherMiddlenameNaAllowed,
      } = motherFields

      if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === true
      ) {
        setHoldData(e.data)

        inputChangeHandler(
          permanentHouseBlockLotNoRequiredElem,
          e.data.residentialHouseBlockLotNoRequired
        )

        inputChangeHandler(
          permanentStreetRequiredElem,
          e.data.residentialStreetRequired
        )

        inputChangeHandler(
          permanentSubdivisionVillageElem,
          e.data.residentialSubdivisionVillageRequired
        )

        inputChangeHandler(
          permanentBarangayRequiredElem,
          e.data.residentialBarangayRequired
        )

        inputChangeHandler(
          permanentCityMunicipalityRequiredElem,
          e.data.residentialCityMunicipalityRequired
        )

        inputChangeHandler(
          permanentProvinceRequiredElem,
          e.data.residentialProvinceRequired
        )

        inputChangeHandler(
          permanentZipcodeElem,
          e.data.residentialZipcodeNaAllowed
        )

        setTimeout(() => {
          chooseProvinceButtonPermElem.style.display = 'none'
          chooseCityButtonPermElem.style.display = 'none'
          chooseBarangayButtonPermElem.style.display = 'none'
          chooseZipCodeButtonPermElem.style.display = 'none'
          permanentHouseBlockLotNoRequiredElem.disabled = true
          permanentStreetRequiredElem.disabled = true
          permanentSubdivisionVillageElem.disabled = true
          permanentBarangayRequiredElem.disabled = true
          permanentCityMunicipalityRequiredElem.disabled = true
          permanentProvinceRequiredElem.disabled = true
          permanentZipcodeElem.disabled = true
        }, 500)
      } else if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === false
      ) {
        setHoldData(e.data)
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false
      } else if (
        (e?.changed?.component?.key === 'residentialHouseBlockLotNoRequired' ||
          e?.changed?.component?.key === 'residentialStreetRequired' ||
          e?.changed?.component?.key ===
            'residentialSubdivisionVillageRequired' ||
          e?.changed?.component?.key === 'residentialBarangayRequired' ||
          e?.changed?.component?.key ===
            'residentialCityMunicipalityRequired' ||
          e?.changed?.component?.key === 'residentialProvinceRequired' ||
          e?.changed?.component?.key === 'residentialZipcodeNaAllowed') &&
        e.data.sameAsResidentialAddressBox === true
      ) {
        const sameAsResidentialAddressBox = document.querySelector(
          'input[name="data[sameAsResidentialAddressBox]"]'
        )
        sameAsResidentialAddressBox.checked = false

        const event = new Event('change', { bubbles: true })
        sameAsResidentialAddressBox.dispatchEvent(event)
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false

        inputChangeHandler(permanentHouseBlockLotNoRequiredElem, '')

        inputChangeHandler(permanentStreetRequiredElem, '')

        inputChangeHandler(permanentSubdivisionVillageElem, '')

        inputChangeHandler(permanentBarangayRequiredElem, '')

        inputChangeHandler(permanentCityMunicipalityRequiredElem, '')

        inputChangeHandler(permanentProvinceRequiredElem, '')

        inputChangeHandler(permanentZipcodeElem, '')

        setHoldData({
          ...e.data,
          sameAsResidentialAddressBox: false,
          [`${e?.changed?.component?.key}`]: e?.changed?.value,
          permanentHouseBlockLotNoRequired: '',
          permanentStreetRequired: '',
          permanentSubdivisionVillageRequired: '',
          permanentBarangayRequired: '',
          permanentCityMunicipalityRequired: '',
          permanentProvinceRequired: '',
          permanentZipcodeNaAllowed: '',
        })
      } else if (e?.changed?.component?.key === 'well4DataGrid2') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid2']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key.includes('IsNA')) {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (
        e?.changed?.component?.key === 'well4Well5Levelgraduatestudies'
      ) {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Well5Levelgraduatestudies']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4DataGrid3') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid3']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (
        e?.changed?.component?.key === 'well4Levelvocationaltradecourse'
      ) {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Levelvocationaltradecourse']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4DataGrid') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key.includes('IsNA')) {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4Well11DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well11DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well11DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.ldTrainingDateFrom) &&
                isValidValue(obj.ldTrainingDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        const encryptedData = localStorage.getItem('encryptedFormData')

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1 && encryptedData) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'skillsAndHobbiesGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-skillsAndHobbiesGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.skillsAndHobbiesGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (
        e?.changed?.component?.key === 'nonAcademicdistinctionsrecognitionGrid'
      ) {
        const foundGrid = document.querySelector(
          '.formio-component-nonAcademicdistinctionsrecognitionGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.nonAcademicdistinctionsrecognitionGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'orgMembershipGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-orgMembershipGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.orgMembershipGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well4Well10DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well10DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well10DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.voluntaryWorkDateFrom) &&
                isValidValue(obj.voluntaryWorkDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well4Well8DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well8DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well8DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (
              key === 'workPayGradeIsNA' &&
              value === 'selectSalaryGradeStepIncrement'
            ) {
              return (
                isValidValue(obj.workExperienceStepIncrement) &&
                isValidValue(obj.workExperiencePayGrade)
              )
            }

            if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
              return isValidValue(obj.workExperienceDateTo)
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well3Well2DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well3Well2DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well3Well2DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'ifChildren') {
        const value = e.changed.value
        const searchCheckboxParent = e.changed.instance.element

        const yesRadio =
          searchCheckboxParent.querySelectorAll('input[value="YES"]')
        const noRadio =
          searchCheckboxParent.querySelectorAll('input[value="NO"]')

        if (value === 'NO') {
          yesRadio.forEach((elem) => {
            elem.disabled = false
          })
          noRadio.forEach((elem) => {
            elem.disabled = true
          })
        }

        if (value === 'YES') {
          yesRadio.forEach((elem) => {
            elem.disabled = true
          })
          noRadio.forEach((elem) => {
            elem.disabled = false
          })

          const foundGrid = searchCheckboxParent.parentNode
          const foundTable = foundGrid.querySelectorAll('table tr')

          const dataFound = e.data
          const dataPath = dataFound['well3Well2DataGrid']

          const isValidValue = (value) =>
            value !== '' && value !== null && value !== undefined

          const allValuesPresent = dataPath.every((obj) =>
            Object.entries(obj).every(([key, value]) => {
              return isValidValue(value)
            })
          )

          const disableAddRowButton = foundGrid.querySelector(
            '.formio-button-add-row'
          )

          disableAddRowButton.disabled = !allValuesPresent

          dataPath.forEach((elem, index) => {
            if (index < dataPath.length - 1) {
              const disableRemoveRowButton = foundTable[index].querySelector(
                '.formio-button-remove-row'
              )

              disableRemoveRowButton.disabled = true
            }
          })
        }
      } else if (
        ['childrenFullnameNaAllowed', 'childrenDOBNaAllowed'].includes(
          e?.changed?.component?.key
        )
      ) {
        const searchCheckboxParent = e.changed.instance.element
        const foundGrid = searchCheckboxParent.closest(
          '.formio-component-well3Well2DataGrid'
        )

        const dataFound = e.data
        const dataPath = dataFound['well3Well2DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4Well7DataGrid') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Well7DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            // Skip validation for empty dates when NA is set
            if (key === 'civilServiceLicenseValidityDate') {
              return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceExamDate') {
              return obj.civilServiceExamDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
              return obj.civilServiceAndOthersLicense === 'OTHERS'
                ? isValidValue(value)
                : true
            }

            if (key === 'civilServiceAndOthersLicense') {
              if (value === 'OTHERS') {
                return isValidValue(
                  obj.civilServiceAndOthersLicenseOthersEligibility
                )
              }
              return isValidValue(value)
            }

            if (key.includes('IsNA')) {
              return value === 'N/A' || value === 'enterDate'
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'isLDAttended') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well11DataGrid) {
            delete dataFound.well4Well11DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well11DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (e?.changed?.component?.key === 'isVoluntaryWork') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well10DataGrid) {
            delete dataFound.well4Well10DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well10DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (e?.changed?.component?.key === 'isWorkExperience') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well8DataGrid) {
            delete dataFound.well4Well8DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well8DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'nonAcademicdistinctionsrecognition'
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['nonAcademicdistinctionsrecognitionGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'orgMembership') {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['orgMembershipGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'skillsAndHobbies') {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['skillsAndHobbiesGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'voluntaryWorkOrg',
          'voluntaryWorkDateIsNA',
          'voluntaryWorkHoursNo',
          'voluntaryWorkPosition',
          'voluntaryWorkDateFrom',
          'voluntaryWorkDateTo',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well10DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.voluntaryWorkDateFrom) &&
                isValidValue(obj.voluntaryWorkDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'workExperienceAppointmentStatus',
          'workExperienceCompany',
          'workExperienceDateFrom',
          'workExperienceDateTo',
          'workExperienceIsGovtService',
          'workExperiencePositionTitle',
          'workExperienceSalary',
          'workInclusiveDateToFormat',
          'workPayGradeIsNA',
          'workExperienceStepIncrement',
          'workExperiencePayGrade',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well8DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (
              key === 'workPayGradeIsNA' &&
              value === 'selectSalaryGradeStepIncrement'
            ) {
              return (
                isValidValue(obj.workExperienceStepIncrement) &&
                isValidValue(obj.workExperiencePayGrade)
              )
            }

            if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
              return isValidValue(obj.workExperienceDateTo)
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'haveAnyEligibility') {
        if (e?.changed?.value === 'NO') {
          const path = e.changed.instance.path.replace('haveAnyEligibility', '')
          const dataFound = e.data
          const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
          const gridName = pathParts[1] // "well4Well7DataGrid"
          const index = parseInt(pathParts[2], 10) // 0
          const dataPath = dataFound[gridName][index]

          // Set all values to "N/A" except haveAnyEligibility
          Object.keys(dataPath).forEach((key) => {
            if (key !== 'haveAnyEligibility') {
              dataPath[key] = 'N/A'
            }
          })

          e.data = { ...e.data, ...dataFound }

          setTimeout(() => {
            const parentElem = e.changed.instance.element
            const radioToDisable = parentElem.querySelectorAll(
              'input[type="radio"][value="NO"]'
            )
            const radioToEnable = parentElem.querySelectorAll(
              'input[type="radio"][value="YES"]'
            )
            radioToDisable.forEach((elem) => {
              elem.disabled = true
            })
            radioToEnable.forEach((elem) => {
              elem.disabled = false
            })

            const searchCheckboxParent = e.changed.instance.element.parentNode

            const textInputs = searchCheckboxParent.querySelectorAll(
              'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
            )
            const nonTextInputs = searchCheckboxParent.querySelector(
              '.formio-component-civilServiceAndOthersLicense'
            )
            const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
              '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
            )
            const radioDateValidityDisable =
              searchCheckboxParent.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
              )
            radioDateExamDisable.forEach((elem) => {
              elem.disabled = true
              if (elem.value === 'N/A') {
                elem.checked = true
              }
            })
            radioDateValidityDisable.forEach((elem) => {
              elem.disabled = true
              if (elem.value === 'N/A') {
                elem.checked = true
              }
            })

            const disableAddRowButton = searchCheckboxParent
              .closest('table')
              .querySelector('.formio-button-add-row')
            const disableRemoveRowButton = searchCheckboxParent
              .closest('table')
              .querySelectorAll('.formio-button-remove-row')

            disableAddRowButton && (disableAddRowButton.disabled = true)
            disableRemoveRowButton.forEach((elem) => {
              elem.disabled = true
            })

            createElemSpanTemp(
              nonTextInputs,
              `CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE`
            )
            nonTextInputs.style.display = 'none'

            textInputs.forEach((input) => {
              input.value = 'N/A'
              input.disabled = true
            })
          }, 500)
        }

        if (e?.changed?.value === 'YES') {
          const path = e.changed.instance.path.replace('haveAnyEligibility', '')
          const dataFound = e.data
          const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
          const gridName = pathParts[1] // "well4Well7DataGrid"
          const index = parseInt(pathParts[2], 10) // 0
          const dataPath = dataFound[gridName][index]

          // Set all values to "" except haveAnyEligibility
          Object.keys(dataPath).forEach((key) => {
            if (
              key !== 'haveAnyEligibility' &&
              key !== 'civilServiceLicenseValidityDateIsNA' &&
              key !== 'civilServiceExamDateIsNA'
            ) {
              dataPath[key] = ''
            }

            if (
              key === 'civilServiceLicenseValidityDateIsNA' ||
              key === 'civilServiceExamDateIsNA'
            ) {
              dataPath[key] = 'enterDate'
            }
          })

          e.data = { ...e.data, ...dataFound }

          setTimeout(() => {
            const parentElem = e.changed.instance.element
            const searchCheckboxParent = e.changed.instance.element.parentNode

            const textInputs = searchCheckboxParent.querySelectorAll(
              'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
            )
            const nonTextInputs = searchCheckboxParent.querySelector(
              '.formio-component-civilServiceAndOthersLicense'
            )
            const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
              '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
            )
            const radioDateValidityDisable =
              searchCheckboxParent.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
              )
            const radioToEnable = parentElem.querySelectorAll(
              'input[type="radio"][value="NO"]'
            )
            const radioToDisable = parentElem.querySelectorAll(
              'input[type="radio"][value="YES"]'
            )

            radioToEnable.forEach((elem) => {
              elem.disabled = false
            })

            radioToDisable.forEach((elem) => {
              elem.disabled = true
            })

            radioDateExamDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })

            radioDateExamDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })
            radioDateValidityDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })

            removeElemSpanTemp(parentElem.closest('tr'))

            nonTextInputs.style = undefined

            textInputs.forEach((input) => {
              input.value = ''
              input.disabled = false
            })

            const disableRemoveRowButton = searchCheckboxParent
              .closest('tr')
              .querySelectorAll('.formio-button-remove-row')
            disableRemoveRowButton.forEach((elem) => {
              elem.disabled = false
            })
          }, 500)
        }
      } else if (
        [
          'ldTrainingTitle',
          'ldTrainingDateIsNA',
          'ldTrainingHoursNo',
          'ldType',
          'ldSponsoredBy',
          'ldTrainingDateFrom',
          'ldTrainingDateTo',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well11DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.ldTrainingDateFrom) &&
                isValidValue(obj.ldTrainingDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'civilServiceRating',
          'civilServiceExamDateIsNA',
          'civilServiceExamPlace',
          'civilServiceAndOthersLicense',
          'civilServiceLicenseNo',
          'civilServiceLicenseValidityDateIsNA',
          'civilServiceExamDate',
          'civilServiceLicenseValidityDate',
          'civilServiceAndOthersLicenseOthersEligibility',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const gridName = pathParts[1] // "well4Well7DataGrid"
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound[gridName]

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            // Skip validation for empty dates when NA is set
            if (key === 'civilServiceLicenseValidityDate') {
              return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceExamDate') {
              return obj.civilServiceExamDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
              return obj.civilServiceAndOthersLicense === 'OTHERS'
                ? isValidValue(value)
                : true
            }

            if (key === 'civilServiceAndOthersLicense') {
              if (value === 'OTHERS') {
                return isValidValue(
                  obj.civilServiceAndOthersLicenseOthersEligibility
                )
              }
              return isValidValue(value)
            }

            if (key.includes('IsNA')) {
              return value === 'N/A' || value === 'enterDate'
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'graduateCourseNaAllowed',
          'graduateDateFromNaAllowed',
          'graduateDateToNaAllowed',
          'graduateNameOfSchoolNaAllowed',
          'graduateUnitsEarnedNaAllowed',
          'graduateYearGraduatedNaAllowed',
          'graduateScholarshipNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4Well5Levelgraduatestudies']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'collegeCourseNaAllowed',
          'collegeDateFromNaAllowed',
          'collegeDateToNaAllowed',
          'collegeNameOfSchoolNaAllowed',
          'collegeScholarshipNaAllowed',
          'collegeUnitsEarnedNaAllowed',
          'collegeYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid3']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'vocationalBasicEducationNaAllowed',
          'vocationalDateFromNaAllowed',
          'vocationalDateToNaAllowed',
          'vocationalNameOfSchoolNaAllowed',
          'vocationalScholarshipNaAllowed',
          'vocationalUnitsEarnedNaAllowed',
          'vocationalYeargraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4Levelvocationaltradecourse']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'secondaryBasicEducationNaAllowed',
          'secondaryDateFromNaAllowed',
          'secondaryDateToNaAllowed',
          'secondaryNameOfSchoolNaAllowed',
          'secondaryScholarshipNaAllowed',
          'secondaryUnitsEarnedNaAllowed',
          'secondaryYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'elementaryBasicEducationNaAllowed',
          'elementaryDateFromNaAllowed',
          'elementaryDateToNaAllowed',
          'elementaryNameOfSchoolNaAllowed',
          'elementaryScholarshipNaAllowed',
          'elementaryUnitsEarnedNaAllowed',
          'elementaryYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid2']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value === 'SINGLE'
      ) {
        handleCivilStatusRequiredToSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value !== 'SINGLE'
      ) {
        handleCivilStatusRequiredToNOTSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(spouseSurname, 'N/A')
        inputChangeHandler(spouseFirstName, 'N/A')
        inputChangeHandler(spouseMiddleName, 'N/A')
        inputChangeHandler(spouseOccupation, 'N/A')
        inputChangeHandler(spouseEmployerName, 'N/A')
        inputChangeHandler(spouseEmployerAddress, 'N/A')
        inputChangeHandler(spouseEmployerTelephoneno, 'N/A')

        spouseSuffixparentElement.style.display = 'none'

        e.data.spouseSuffixNaAllowed = 'N/A'

        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseMiddleName.disabled = true
        spouseEmployerTelephoneno.disabled = true

        createSpouseSuffixSpanTemp(spouseSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === false
      ) {
        removeSpouseSuffixSpanTemp()

        inputChangeHandler(spouseSurname, '')
        inputChangeHandler(spouseFirstName, '')
        inputChangeHandler(spouseMiddleName, '')
        inputChangeHandler(spouseOccupation, '')
        inputChangeHandler(spouseEmployerName, '')
        inputChangeHandler(spouseEmployerAddress, '')
        inputChangeHandler(spouseEmployerTelephoneno, '')

        spouseSuffixparentElement.style = undefined

        e.data.spouseSuffixNaAllowed = ''

        spouseSurname.disabled = false
        spouseFirstName.disabled = false
        spouseMiddleName.disabled = false
        spouseOccupation.disabled = false
        spouseEmployerName.disabled = false
        spouseEmployerAddress.disabled = false
        spouseMiddleName.disabled = false
        spouseEmployerTelephoneno.disabled = false
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(fatherSurnameNaAllowed, 'N/A')
        inputChangeHandler(fatherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(fatherMiddlenameNaAllowed, 'N/A')

        e.data.fatherSuffixNaAllowed = 'N/A'

        fatherSurnameNaAllowed.disabled = true
        fatherFirstnameNaAllowed.disabled = true
        fatherMiddlenameNaAllowed.disabled = true

        fatherSuffixparentElement.style.display = 'none'
        fatherSuffixparentElement.style.visibility = 'hidden'

        createFatherSuffixSpanTemp(fatherSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === false
      ) {
        removeFatherSuffixSpanTemp()

        inputChangeHandler(fatherSurnameNaAllowed, '')
        inputChangeHandler(fatherFirstnameNaAllowed, '')
        inputChangeHandler(fatherMiddlenameNaAllowed, '')

        fatherSuffixparentElement.style = undefined

        e.data.fatherSuffixNaAllowed = null

        fatherSurnameNaAllowed.disabled = false
        fatherFirstnameNaAllowed.disabled = false
        fatherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(motherSurnameNaAllowed, 'N/A')
        inputChangeHandler(motherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(motherMiddlenameNaAllowed, 'N/A')

        motherSurnameNaAllowed.disabled = true
        motherFirstnameNaAllowed.disabled = true
        motherMiddlenameNaAllowed.disabled = true
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === false
      ) {
        inputChangeHandler(motherSurnameNaAllowed, '')
        inputChangeHandler(motherFirstnameNaAllowed, '')
        inputChangeHandler(motherMiddlenameNaAllowed, '')

        motherSurnameNaAllowed.disabled = false
        motherFirstnameNaAllowed.disabled = false
        motherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('elementaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid2'][index]

        // Set all values to "N/A" except elementaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'elementaryAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid2 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid2'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp(e.changed.instance.element.closest('tr'))
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('elementaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid2'][index]

        // Set all values to "N/A" except elementaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'elementaryAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid2 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid2'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('secondaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid'][index]

        // Set all values to "N/A" except secondaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'secondaryAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp(e.changed.instance.element.closest('tr'))
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('secondaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid'][index]

        // Set all values to "N/A" except secondaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'secondaryAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('vocationalAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Levelvocationaltradecourse'][index]

        // Set all values to "N/A" except vocationalAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'vocationalAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Levelvocationaltradecourse table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Levelvocationaltradecourse'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-vocationalBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('vocationalAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Levelvocationaltradecourse'][index]

        // Set all values to "N/A" except vocationalAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'vocationalAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Levelvocationaltradecourse table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Levelvocationaltradecourse'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        createElemSpanTemp(
          nonTextInputs,
          'BASIC EDUCATION/DEGREE/COURSE (Write in full)'
        )
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('collegeAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid3'][index]

        // Set all values to "N/A" except collegeAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'collegeAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid3 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid3'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('collegeAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid3'][index]

        // Set all values to "N/A" except collegeAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'collegeAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid3 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid3'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed'
        )

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('gradStudiesAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Well5Levelgraduatestudies'][index]

        // Set all values to "N/A" except gradStudiesAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'gradStudiesAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Well5Levelgraduatestudies table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Well5Levelgraduatestudies'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed '
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('gradStudiesAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Well5Levelgraduatestudies'][index]

        // Set all values to "N/A" except gradStudiesAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'gradStudiesAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Well5Levelgraduatestudies table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Well5Levelgraduatestudies'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (e?.isValid) {
        setHoldData(e.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeProvinces = (value) => {
    const selectedArea = isProvincePerm
      ? provinceDataPerm[value]
      : provinceData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isProvincePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentProvinceRequired: selectedArea.name.toUpperCase(),
        permanentCityMunicipalityRequired: '',
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialProvinceRequired: selectedArea.name.toUpperCase(),
        residentialCityMunicipalityRequired: '',
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenProvince(false)

    // Check if the selected area is a region or a province
    if (selectedArea.geographic_level === 'Reg') {
      // If it's a region (like NCR), load cities directly
      loadAndTransformCityData(value)
    } else {
      // If it's a province, load municipalities and cities
      loadAndTransformMunicipalityData(value)
    }
  }

  const handleChangeCities = (value) => {
    const selectedArea = isCityPerm
      ? selectedCityDataPerm[value]
      : selectedCityData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isCityPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        residentialBarangayRequired: '',
      }))
    }
    setIsModalOpenCity(false)
    loadAndTransformBarangayData(value)
  }

  const handleChangeBarangay = (value) => {
    const selectedArea = isBarangayPerm
      ? allAreasDataPerm[value]
      : allAreasData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }
    if (isBarangayPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    }

    setIsModalOpenBrgy(false)
  }

  const handleChangeZipcode = (selectedOption) => {
    const { value } = JSON.parse(selectedOption.value)
    if (isZipcodePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentZipcodeNaAllowed: value,
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialZipcodeNaAllowed: value,
      }))
    }
    setIsModalOpenZipcode(false)
  }

  const zipcodeList = placeData.map((place, index) => ({
    value: place.post_code,
    label: `${place.municipality}, ${place.location} (${place.post_code})`,
    key: `${place.post_code}-${place.municipality}-${index}`,
    uniqueId: `${place.post_code}-${index}`,
  }))

  return (
    <>
      {isError ? (
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Col>
            <p>
              <em>
                We're experiencing technical difficulties. Our team has been
                notified and is working on the issue. Please try again in a few
                minutes. We apologize for the inconvenience.
              </em>
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <div className='pds-answer-form'>
            <PDSGuide className='mb-3' />
            <PDSVideo
              setIsOpenPDSCacheMessage={(e) => setIsOpenPDSCacheMessage(e)}
            />
            <Card>
              <UploadPds
                exportData={handleExportData}
                exportFrom={'mainForm'}
              />
              {isFinishedScan ? (
                <Form
                  onChange={(e) => handleChangeForm(e)}
                  onSubmit={(e) => showModal(e)}
                  submission={{ data: holdData }}
                  onRender={handlePrefill}
                  src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
                  onCustomEvent={(e) => handleSignType(e.type)}
                />
              ) : null}
              <BackTop duration={200}>
                <div className='pds-backtop-div'>BACK TO TOP</div>
              </BackTop>
            </Card>
          </div>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet'
            visible={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false)
              setIsDisabledAfterSave(false)
            }}
            footer={[]}
            destroyOnClose={true}
          >
            <div style={{ textAlign: 'center' }}>
              <Button
                className={'me-4'}
                size='large'
                style={{ backgroundColor: '#635380' }}
                key='draft'
                ghost
                onClick={() => handleSave(false)}
                disabled={isDisabledAfterSave}
              >
                Save as draft
              </Button>
              <Button
                size='large'
                style={{ backgroundColor: '#87C38F' }}
                key='final'
                ghost
                onClick={() => handleSave(true)}
                disabled={isDisabledAfterSave}
              >
                Save as final
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Right Thumbmark) Agreement'
            visible={isModalOpenThumbmark}
            onCancel={() => setIsModalOpenThumbmark(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefThumb}
                style={{ display: 'none' }}
                onChange={handleFileChangeThumb}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefThumb.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (e-Signature) Agreement'
            visible={isModalOpenESign}
            onCancel={() => setIsModalOpenESign(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefESign}
                style={{ display: 'none' }}
                onChange={handleFileChangeESign}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefESign.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Passport Photo) Agreement'
            visible={isModalOpenPassportPhoto}
            onCancel={() => setIsModalOpenPassportPhoto(false)}
            footer={[]}
            destroyOnClose
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefPassportPhoto}
                style={{ display: 'none' }}
                onChange={handleFileChangePassportPhoto}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefPassportPhoto.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Province'
            visible={isModalOpenProvince}
            onCancel={() => setIsModalOpenProvince(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a province'
                optionFilterProp='children'
                onChange={handleChangeProvinces}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={regionGroupList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose City or Municipality'
            visible={isModalOpenCity}
            onCancel={() => setIsModalOpenCity(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a city or municipality'
                optionFilterProp='children'
                onChange={handleChangeCities}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isCityPerm ? municipalityListPerm : municipalityList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Barangay'
            visible={isModalOpenBrgy}
            onCancel={() => setIsModalOpenBrgy(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a barangay'
                optionFilterProp='children'
                onChange={handleChangeBarangay}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isBarangayPerm ? barangayListPerm : barangayList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Zip Code'
            visible={isModalOpenZipcode}
            onCancel={() => setIsModalOpenZipcode(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a place'
                optionFilterProp='children'
                onChange={handleChangeZipcode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
              >
                {zipcodeList.map((item) => (
                  <Option
                    key={item.key}
                    value={JSON.stringify({
                      value: item.value,
                      label: item.label,
                    })}
                    className='option-item'
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
          <Modal
            title={null}
            open={isOpenPDSCacheMessage}
            width={'50vw'}
            footer={null}
            onCancel={() => setIsOpenPDSCacheMessage(false)}
            destroyOnClose={true}
          >
            <Title level={4}>Important: Auto-Save Information</Title>
            <List
              size='small'
              dataSource={[
                <Text key='info01'>
                  Your progress is automatically saved every few seconds to
                  prevent data loss in case of unexpected interruptions.
                </Text>,
                <Text key='info02' strong>
                  This is only a temporary save and will be deleted when you log
                  out.
                </Text>,
                <Text key='info03'>
                  To permanently save your work, use the 'Save as draft' or
                  'Submit as Final' options.
                </Text>,
                <Text key='info04'>
                  Do not rely on auto-save for long-term storage of your
                  information.
                </Text>,
                <Text key='info05'>
                  Always save your work properly before logging out to avoid
                  losing your progress.
                </Text>,
              ]}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Modal>
        </>
      )}
    </>
  )
}

MainForm.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignedDept: PropTypes.string.isRequired,
    division: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mname: PropTypes.string,
    suffix: PropTypes.string,
    gender: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  afterSubmit: PropTypes.func.isRequired,
  isProceedPDS: PropTypes.bool.isRequired,
}
