/**
 * Title: PDSEncode.js
 * Description: This is a file that contains the components for the Personal Data Sheet form.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSEncode.js
 **/
import { useEffect, useRef, useState } from 'react'

import {
  BackTop,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { Form } from '@formio/react'

import { createPersonalDataSheet } from '../../api/mutations'
import { UserbyDepartment, registrationByUserID } from '../../api/queries'
import { getEmailBodySubmittedByEmployeeNotifDeptApprover } from './PDSEmailTemplates'
import PDSGuide from './PDSGuide'
import PDSVideo from './PDSVideo'
import UploadPds from './UploadPds'
import './pds.css'
import placeDataArray from './zipcodePH'

const { Option } = Select
const { Text, Title } = Typography

export default function PDSEncode({
  employeeToBeEncoded,
  afterSubmit,
  userData,
}) {
  const encoder = userData

  const [holdData, setHoldData] = useState({})
  const [dataReview, setDataReview] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [uploadedSignature, setUploadedSignature] = useState(null)
  const [uploadedThumbmark, setUploadedThumbmark] = useState(null)

  const [isModalOpenThumbmark, setIsModalOpenThumbmark] = useState(false)
  const [isModalOpenESign, setIsModalOpenESign] = useState(false)
  const [isModalOpenPassportPhoto, setIsModalOpenPassportPhoto] =
    useState(false)
  const [uploadedPassportPhoto, setUploadedPassportPhoto] = useState(null)
  const [removeAsterisk, setRemoveAsterisk] = useState(null)

  const [myTrainings, setMyTrainings] = useState([])
  const [isFinishedScan, setIsFinishedScan] = useState(false)

  const [isModalOpenProvince, setIsModalOpenProvince] = useState(false)
  const [regionGroupList, setRegionGroupList] = useState([])
  const [municipalityList, setMunicipalityList] = useState([])
  const [provinceData, setProvinceData] = useState({})
  const [isModalOpenCity, setIsModalOpenCity] = useState(false)
  const [allAreasData, setAllAreasData] = useState({})
  const [selectedCityData, setSelectedCityData] = useState({})
  const [isModalOpenBrgy, setIsModalOpenBrgy] = useState(false)
  const [barangayList, setBarangayList] = useState([])

  const [isProvincePerm, setIsProvincePerm] = useState(false)
  const [isBarangayPerm, setIsBarangayPerm] = useState(false)
  const [isCityPerm, setIsCityPerm] = useState(false)
  const [municipalityListPerm, setMunicipalityListPerm] = useState([])
  const [selectedCityDataPerm, setSelectedCityDataPerm] = useState({})
  const [allAreasDataPerm, setAllAreasDataPerm] = useState({})
  const [barangayListPerm, setBarangayListPerm] = useState([])
  const [provinceDataPerm, setProvinceDataPerm] = useState({})
  const [isModalOpenZipcode, setIsModalOpenZipcode] = useState(false)
  const [isZipcodePerm, setIsZipcodePerm] = useState(false)

  const [isError, setIsError] = useState(false)

  const [isDisabledAfterSave, setIsDisabledAfterSave] = useState(false)

  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const fileInputRefThumb = useRef(null)
  const fileInputRefESign = useRef(null)
  const fileInputRefPassportPhoto = useRef(null)

  const placeData = placeDataArray()

  const fieldCheckerFunction = (obj, key) => {
    try {
      let value = obj[key]
      if (
        typeof value === 'undefined' ||
        value === null ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        return {
          allRequiredValuesPresent: false,
          failedKey: key,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetch('https://www.sparksoft-demo.com/formio/pdsformmobilepage1', {
      method: 'HEAD',
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 503) {
          notification.error({
            message: 'Server is down or network request failed',
            description: 'Please try again later.',
          })

          setIsError(true)
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Server is down or network request failed',
          description: 'Please try again later.',
        })
        setIsError(true)
      })
  }, [])

  const getAllMyTrainings = async () => {
    try {
      let nextToken = null
      let allMyTrainings = []

      do {
        const { data } = await API.graphql(
          graphqlOperation(registrationByUserID, {
            userID: employeeToBeEncoded.id,
            nextToken,
          })
        )
        allMyTrainings = [...allMyTrainings, ...data.registrationByUserID.items]
        nextToken = data.registrationByUserID.nextToken
      } while (nextToken)

      return allMyTrainings.filter(
        (item) =>
          (!item?.training?.isPassPreAssessmentRequired ||
            item?.isPreAssessmentPassed) &&
          item?.isSubmitEndorsementLetter &&
          item?.isPostEvalDone &&
          item?.isAttended &&
          item?.isCourseOutputDone
      )
    } catch (err) {
      console.error(err)
      return []
    }
  }

  function transformGeoRegionData(geoRegionData) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return []
    }

    const dataArray = Object.values(geoRegionData)
    const regions = {}
    const allAreas = {} // This will store both provinces and regions without provinces

    dataArray.forEach((item) => {
      if (!item || typeof item !== 'object') return

      if (item.geographic_level === 'Reg') {
        regions[item.code] = {
          label: item.name,
          value: item.code,
          options: [],
        }
        // Add region to allAreas
        allAreas[item.code] = {
          name: item.name,
          code: item.code,
          geographic_level: 'Reg',
        }
      } else if (item.geographic_level === 'Prov') {
        const province = {
          ...item,
          label: item.name,
          value: item.code,
          regionCode: item.code.substring(0, 3) + '0000000',
        }
        allAreas[item.code] = province

        const regionEntry = regions[province.regionCode]
        if (regionEntry) {
          regionEntry.options.push(province)
        }
      }
    })

    const transformedRegions = Object.values(regions).map((region) => {
      if (region.options.length === 0) {
        // If a region has no provinces, return it as its own option
        return {
          label: region.label,
          value: region.value,
        }
      }
      return region
    })

    return {
      regionGroupList: transformedRegions,
      allAreas: allAreas,
    }
  }

  const loadAndTransformData = async () => {
    let combinedRegionGroupList = []
    let allAreasData = {}
    for (let i = 1; i <= 17; i++) {
      try {
        const geoRegionData = await import(`./../../pgsc/geo-reg-${i}.json`)
        const { regionGroupList, allAreas } = transformGeoRegionData(
          geoRegionData.default
        )
        combinedRegionGroupList =
          combinedRegionGroupList.concat(regionGroupList)
        Object.assign(allAreasData, allAreas)
      } catch (error) {
        console.error(`Error loading data for region ${i}:`, error)
      }
    }

    combinedRegionGroupList.forEach((region) => {
      if (region.label === 'Metro Manila') {
        region.label = 'National Capital Region (NCR)'
      }
    })

    setRegionGroupList(combinedRegionGroupList)
    setProvinceData(allAreasData) // This now includes both provinces and regions
    setProvinceDataPerm(allAreasData) // This now includes both provinces and regions
  }

  function transformMunicipalityData(geoRegionData, selectedProvinceCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { municipalityGroupList: [], allAreas: {} }
    }

    const municipalityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'Mun' || item.geographic_level === 'City') &&
        item.code.startsWith(selectedProvinceCode.substring(0, 5))
      ) {
        const municipalityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        municipalityList.push(municipalityItem)
        allAreas[item.code] = municipalityItem
      }
    })

    return {
      municipalityGroupList: municipalityList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformMunicipalityData = async (provinceCode) => {
    let municipalityList = []
    let allAreasData = {}

    try {
      const regionNumber = provinceCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { municipalityGroupList, allAreas } = transformMunicipalityData(
        geoRegionData.default,
        provinceCode
      )

      municipalityList = municipalityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for province ${provinceCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(municipalityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(municipalityList)
      setSelectedCityData(allAreasData)
    }
  }

  const loadAndTransformCityData = async (regionCode) => {
    let cityList = []
    let allAreasData = {}

    try {
      const regionNumber = regionCode.substring(0, 2)

      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { cityGroupList, allAreas } = transformCityData(
        geoRegionData.default,
        regionCode
      )

      cityList = cityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for region ${regionCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(cityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(cityList)
      setSelectedCityData(allAreasData)
    }
  }

  function transformCityData(geoRegionData, selectedRegionCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { cityGroupList: [], allAreas: {} }
    }

    const cityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'City' || item.geographic_level === 'Mun') &&
        item.code.startsWith(selectedRegionCode.substring(0, 2))
      ) {
        const cityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        cityList.push(cityItem)
        allAreas[item.code] = cityItem
      }
    })

    return {
      cityGroupList: cityList,
      allAreas: allAreas,
    }
  }

  function transformBarangayData(geoRegionData, selectedMunicipalityCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { barangayGroupList: [], allAreas: {} }
    }
    const barangayList = []
    const allAreas = {}
    if (
      selectedMunicipalityCode === '1380600000' ||
      selectedMunicipalityCode === 1380600000
    ) {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          (item.code.startsWith(13806) || item.code.startsWith('13806'))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    } else {
      Object.entries(geoRegionData).forEach(([key, item]) => {
        if (!item || typeof item !== 'object') return
        if (
          item.geographic_level === 'Bgy' &&
          item.code.startsWith(selectedMunicipalityCode.substring(0, 7))
        ) {
          const barangayItem = {
            label: item.name,
            value: item.code,
            ...item,
          }
          barangayList.push(barangayItem)
          allAreas[item.code] = barangayItem
        }
      })
    }

    return {
      barangayGroupList: barangayList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformBarangayData = async (municipalityCode) => {
    let brgyList = []
    let brgyAreasData = {}
    try {
      const regionNumber = municipalityCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )
      const { barangayGroupList, allAreas } = transformBarangayData(
        geoRegionData.default,
        municipalityCode
      )
      brgyList = barangayGroupList
      brgyAreasData = allAreas
    } catch (error) {
      console.error(
        `Error loading data for municipality ${municipalityCode}:`,
        error
      )
    }

    const sortedArray = brgyList.sort((a, b) => a.label.localeCompare(b.label))

    if (isCityPerm) {
      setBarangayListPerm(sortedArray)
      setAllAreasDataPerm(brgyAreasData)
    } else {
      setBarangayList(sortedArray)
      setAllAreasData(brgyAreasData)
    }
  }

  const fetchTrainings = async () => {
    try {
      const foundTrainings = await getAllMyTrainings()
      if (foundTrainings.length) {
        setMyTrainings([...foundTrainings])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsFinishedScan(true)
    }
  }

  useEffect(async () => {
    loadAndTransformData()

    fetchTrainings()
  }, [])

  /**
   * @function getSesClientToken
   * @description Gets the SES client token.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Object>} Returns an SES client object.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
      throw err
    }
  }

  /**
   * @function getSourceEmail
   * @description Gets the source email.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<String>} Returns the source email.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  /**
   * @function paramsGenerator
   * @description Generates email parameters for SES
   * @param {string} username - Recipient email
   * @param {string} emailBody - Email content
   * @param {string} emailTitle - Email subject
   * @returns {Object} SES email parameters
   */
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [username],
        },
        Message: {
          Body: {
            Text: {
              Data: emailBody,
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: emailTitle, // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: sourceEmail,
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function sendEmail(usernameFound, sourceEmail) {
    const emailBody =
      getEmailBodySubmittedByEmployeeNotifDeptApprover(employeeToBeEncoded)

    const params = paramsGenerator(
      usernameFound,
      emailBody,
      'Submission of Personal Data Sheet',
      sourceEmail
    )

    const sesClient = await getSesClientToken()
    await sesClient.send(new SendEmailCommand(params))
  }

  /**
   *@function handlePrefill
   *@description Prefills the form with the user's details.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const handlePrefill = () => {
    try {
      const prefillData = {
        ...holdData,
        surnameRequired: employeeToBeEncoded.lname.toUpperCase(),
        firstnameRequired: employeeToBeEncoded.name.toUpperCase(),
        middlenameRequired: employeeToBeEncoded.mname
          ? employeeToBeEncoded.mname.toUpperCase()
          : '',
        suffixNaAllowed: employeeToBeEncoded.suffix
          ? employeeToBeEncoded.suffix.toUpperCase()
          : '',
        sexRequired: employeeToBeEncoded.gender.toUpperCase(),
        mobileno: employeeToBeEncoded.contact.replace('+63', '0'),
        emailAddress: employeeToBeEncoded.username.toUpperCase(),
        referencesRequired: [
          {
            referenceNameRequired: '',
            referenceAddressRequired: '',
            referenceTelNoRequired: '',
          },
          {
            referenceNameRequired: '',
            referenceAddressRequired: '',
            referenceTelNoRequired: '',
          },
          {
            referenceNameRequired: '',
            referenceAddressRequired: '',
            referenceTelNoRequired: '',
          },
        ],
      }

      if (myTrainings.length) {
        const well4Well11DataGrid = []
        for (const item of myTrainings) {
          // Sample array of dates
          const arrayOfDates = item.training.arrayOfDates

          function getLastDate(arrayOfDates) {
            if (arrayOfDates) {
              const datesArray = JSON.parse(arrayOfDates)

              if (datesArray && datesArray.length > 1) {
                // Convert dates to moment objects
                const momentDates = datesArray.map((date) => moment(date))
                const latestDate = moment.max(momentDates)
                return latestDate.format('YYYY-MM-DD')
              }
            }
          }

          const trainingDetails = {
            ldTrainingTitle: item.training.title.toUpperCase(),
            ldTrainingDateFrom: item.training.date,
            ldTrainingDateIsNA: 'selectDates',
          }

          if (arrayOfDates) {
            const datesArray = JSON.parse(arrayOfDates)
            if (datesArray && datesArray.length > 1) {
              trainingDetails.ldTrainingDateTo = getLastDate(arrayOfDates)
            }
          }

          well4Well11DataGrid.push(trainingDetails)
        }
        prefillData.well4Well11DataGrid = well4Well11DataGrid
        prefillData.isLDAttended = 'YES'
      }

      setHoldData((prevState) => ({ ...prevState, ...prefillData }))

      handleReferencesRemoveRowButtons()
    } catch (err) {
      console.log(err)
    }
  }

  const handleReferencesRemoveRowButtons = () => {
    try {
      const referencesGrid = document.querySelector(
        '.formio-component-referencesRequired'
      )
      const removeButtons = referencesGrid.querySelectorAll(
        '.formio-button-remove-row'
      )

      if (removeButtons) {
        removeButtons.forEach((elem) => {
          const delTD = elem.closest('td')
          delTD.remove()
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@description Sets the component's state to open a modal and updates the data to be displayed in the modal.
   *@function
   *@name showModal
   *@param {Object} e - An object containing the data and form properties to be displayed in the modal.
   *@param {Object} e.data - The data to be displayed in the modal.
   *@param {Object} e.form - The form to be displayed in the modal.
   *@return {void}
   **/
  const showModal = (e) => {
    setDataReview({
      data: e.data,
      form: e.form,
    })
    setIsModalOpen(true)
  }

  /**
   *@function closeModal
   *@description Closes the modal.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const closeModal = () => {
    try {
      setIsModalOpen(false)
      afterSubmit()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function forUpload
   *@description Uploads the files to the S3 bucket.
   *@param {Object} none No parameter needed.
   *@returns {Object} An object containing the URLs of the uploaded files.
   */
  const forUpload = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      let url, urlThumb, urlPassport

      if (uploadedSignature) {
        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `${employeeToBeEncoded.id}/${moment().valueOf()}-${
            uploadedSignature.name
          }`,
          Body: uploadedSignature,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        await s3.send(command)
        url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
      }

      if (uploadedThumbmark) {
        const paramsThumb = {
          Bucket: 's3etnahris133956-dev',
          Key: `${employeeToBeEncoded.id}/${moment().valueOf()}-${
            uploadedThumbmark.name
          }`,
          Body: uploadedThumbmark,
        }

        const commandThumb = new PutObjectCommand(paramsThumb)
        await s3.send(commandThumb)
        urlThumb = `https://${paramsThumb.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsThumb.Key}`
      }

      if (uploadedPassportPhoto) {
        const paramsPassport = {
          Bucket: 's3etnahris133956-dev',
          Key: `${employeeToBeEncoded.id}/${moment().valueOf()}-${
            uploadedPassportPhoto.name
          }`,
          Body: uploadedPassportPhoto,
        }

        const commandPassport = new PutObjectCommand(paramsPassport)
        await s3.send(commandPassport)
        urlPassport = `https://${paramsPassport.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsPassport.Key}`
      }

      return { url, urlThumb, urlPassport }
    } catch (err) {
      console.log(err)
    }
  }

  const handleExportData = async (data) => {
    try {
      setHoldData(data.data)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function triggerSave
   *@description Saves the data to the database.
   *@param {Object} url - The URL of the uploaded e-Signature.
   *@param {Object} urlThumb - The URL of the uploaded thumbmark.
   *@param {Object} urlPassport - The URL of the uploaded passport photo.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} The response from the API.
   */
  const triggerSave = async (url, urlThumb, urlPassport, isFinal) => {
    try {
      setHoldData({ ...dataReview.data })

      return await API.graphql(
        graphqlOperation(createPersonalDataSheet, {
          input: {
            data: JSON.stringify(dataReview),
            userID: employeeToBeEncoded.id,
            isFinal: isFinal,
            department: employeeToBeEncoded.assignedDept,
            division: employeeToBeEncoded.division,
            isApproved: 'Pending Approval',
            isHRApproved: 'Pending Approval',
            isAgreeDataPrivacyPDS: true,
            eSignatureLoc: url || '',
            thumbmarkSignLoc: urlThumb || '',
            passportPhotoLoc: urlPassport || '',
            encodedBy: encoder.id,
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleSave
   *@description Handles the saving of the data.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} none No return value.
   */
  const handleSave = async (isFinal) => {
    try {
      setIsDisabledAfterSave(true)
      const { url, urlThumb, urlPassport } = await forUpload()

      if (removeAsterisk) {
        removeAsterisk.remove()
      }

      if (isFinal) {
        let errorSpans = document.getElementsByClassName('errorSpan')
        let errorSpansArray = Array.from(errorSpans)

        errorSpansArray.forEach(function (span) {
          span.parentNode.removeChild(span)
        })

        function checkRequiredValues(obj) {
          const heightFormatInputs = document.querySelectorAll(
            'input[name*="heightFormat"]'
          )

          const weightFormatInputs = document.querySelectorAll(
            'input[name*="weightFormat"]'
          )

          const childrenFormatInputs = document.querySelectorAll(
            'input[name*="ifChildren"]'
          )

          const workExperienceFormatinputs = document.querySelectorAll(
            'input[name*="isWorkExperience"]'
          )

          const voluntaryWorkFormInputs = document.querySelectorAll(
            'input[name*="data[isVoluntaryWork]"]'
          )

          const lAndDFormInputs = document.querySelectorAll(
            'input[name*="isLDAttended"]'
          )
          for (let key in obj) {
            if (
              key.includes('Required') ||
              key.includes('NaAllowed') ||
              key === 'heightFormat' ||
              key === 'weightFormat' ||
              key === 'birthOrNaturaliztion' ||
              key === 'indicateCountry' ||
              key === 'bloodtype' ||
              key === 'ifChildren' ||
              key === 'heightFt' ||
              key === 'heightIn' ||
              key === 'relatedExplain' ||
              key === 'offenseADetails' ||
              key === 'serviceSeparationADetails' ||
              key === 'candidateDetails' ||
              key === 'resignedDetails' ||
              key === 'offenseCDetails' ||
              key === 'offenseDateFiled' ||
              key === 'courtADetails' ||
              key === 'offenseCaseStatus' ||
              key === 'immigrantDetails' ||
              key === 'isIndigenousDetails' ||
              key === 'isPersonWDisabilityDetails' ||
              key === 'isSoloParentDetails'
            ) {
              if (key === 'referencesRequired') {
                let value = obj[key]

                if (!Array.isArray(value) || value.length === 0) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                    isGridData: true,
                  }
                }

                for (let i = 0; i < value.length; i++) {
                  let element = value[i]
                  if (typeof element !== 'object' || element === null) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}]`,
                      isGridData: true,
                    }
                  }

                  for (let elementKey in element) {
                    if (
                      element[elementKey] === undefined ||
                      element[elementKey] === null ||
                      element[elementKey] === ''
                    ) {
                      return {
                        allRequiredValuesPresent: false,
                        failedKey: `[${key}][${i}][${elementKey}]`,
                        dataKey: `${key}[${i}].[${elementKey}]`,
                        isGridData: true,
                      }
                    }
                  }
                }
              } else {
                let value = obj[key]
                if (
                  typeof value === 'undefined' ||
                  value === null ||
                  value === '' ||
                  (typeof value === 'string' && value.trim() === '')
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                  }
                }
              }
            } else if (
              weightFormatInputs[0].value === 'kilograms' &&
              key === 'weightKg'
            ) {
              let value = obj[key]
              if (
                typeof value === 'undefined' ||
                value === null ||
                value === '' ||
                value.trim() === ''
              ) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                }
              }
            } else if (
              childrenFormatInputs[0].value === 'YES' &&
              key === 'well3Well2DataGrid'
            ) {
              let value = obj[key]
              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataChildren: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataChildren: true,
                  }
                }
                for (let elementKey in element) {
                  if (
                    element[elementKey] === undefined ||
                    element[elementKey] === null ||
                    (typeof element[elementKey] === 'string' &&
                      element[elementKey].trim() === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${elementKey}]`,
                      dataKey: `${key}[${i}].[${elementKey}]`,
                      isGridDataChildren: true,
                    }
                  }
                }
              }
            } else if (
              weightFormatInputs[1].value === 'pounds' &&
              key === 'weightPounds'
            ) {
              fieldCheckerFunction(obj, key)
            } else if (
              heightFormatInputs[0].value === 'meters' &&
              key === 'heightMeters'
            ) {
              fieldCheckerFunction(obj, key)
            } else if (key === 'well4DataGrid2') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isElementaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isElementaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'elementaryNameOfSchoolNaAllowed',
                  'elementaryBasicEducationNaAllowed',
                  'elementaryDateFromNaAllowed',
                  'elementaryDateToNaAllowed',
                  'elementaryUnitsEarnedNaAllowed',
                  'elementaryYearGraduatedNaAllowed',
                  'elementaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isElementaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isSecondaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isSecondaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'secondaryNameOfSchoolNaAllowed',
                  'secondaryBasicEducation',
                  'secondaryDateFromNaAllowed',
                  'secondaryDateToNaAllowed',
                  'secondaryUnitsEarnedNaAllowed',
                  'secondaryYearGraduatedNaAllowed',
                  'secondaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isSecondaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Levelvocationaltradecourse') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isVocationalGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isVocationalGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'vocationalNameOfSchoolNaAllowed',
                  'vocationalBasicEducationNaAllowed',
                  'vocationalDateFromNaAllowed',
                  'vocationalDateToNaAllowed',
                  'vocationalUnitsEarnedNaAllowed',
                  'vocationalYeargraduatedNaAllowed',
                  'vocationalScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isVocationalGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid3') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCollegeGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCollegeGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'collegeNameOfSchoolNaAllowed',
                  'collegeCourseNaAllowed',
                  'collegeDateFromNaAllowed',
                  'collegeDateToNaAllowed',
                  'collegeUnitsEarnedNaAllowed',
                  'collegeYearGraduatedNaAllowed',
                  'collegeScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCollegeGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well7DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCivilServiceEligibility: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCivilServiceEligibility: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'haveAnyEligibility',
                  'civilServiceAndOthersLicense',
                  'civilServiceRating',
                  'civilServiceExamDateIsNA',
                  'civilServiceExamPlace',
                  'civilServiceLicenseNo',
                  'civilServiceLicenseValidityDateIsNA',
                ]
                const civilServiceExamDateIsNAFormat =
                  element['civilServiceExamDateIsNA']
                if (civilServiceExamDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(4, 0, 'civilServiceExamDate')
                }
                const civilServiceLicenseValidityDateIsNAFormat =
                  element['civilServiceLicenseValidityDateIsNA']
                if (civilServiceLicenseValidityDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(9, 0, 'civilServiceLicenseValidityDate')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCivilServiceEligibility: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well5Levelgraduatestudies') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGraduateStudies: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGraduateStudies: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'graduateNameOfSchoolNaAllowed',
                  'graduateCourseNaAllowed',
                  'graduateDateFromNaAllowed',
                  'graduateDateToNaAllowed',
                  'graduateUnitsEarnedNaAllowed',
                  'graduateYearGraduatedNaAllowed',
                  'graduateScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGraduateStudies: true,
                    }
                  }
                }
              }
            } else if (key === 'isWorkExperience') {
              fieldCheckerFunction(obj, key)
            } else if (
              workExperienceFormatinputs[0].value === 'YES' &&
              key === 'well4Well8DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataWorkExperience: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataWorkExperience: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'workExperienceDateFrom',
                  'workExperiencePositionTitle',
                  'workExperienceCompany',
                  'workExperienceSalary',
                  'workPayGradeIsNA',
                  'workExperienceAppointmentStatus',
                  'workExperienceIsGovtService',
                ]
                if (elementKeys.includes('workExperienceDateTo')) {
                  fieldsToCheck.splice(1, 0, 'workExperienceDateTo')
                }
                if (elementKeys.includes('workExperiencePayGrade')) {
                  fieldsToCheck.splice(5, 0, 'workExperiencePayGrade')
                }
                if (elementKeys.includes('workExperienceStepIncrement')) {
                  fieldsToCheck.splice(6, 0, 'workExperienceStepIncrement')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (
              voluntaryWorkFormInputs[0].value === 'YES' &&
              key === 'well4Well10DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridVoluntaryWork: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridVoluntaryWork: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'voluntaryWorkOrg',
                  'voluntaryWorkDateIsNA',
                  'voluntaryWorkHoursNo',
                  'voluntaryWorkPosition',
                ]

                if (elementKeys.includes('voluntaryWorkDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'voluntaryWorkDateFrom')
                }

                if (elementKeys.includes('voluntaryWorkDateTo')) {
                  fieldsToCheck.splice(2, 0, 'voluntaryWorkDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (key === 'isVoluntaryWork') {
              fieldCheckerFunction(obj, key)
            } else if (key === 'isLDAttended') {
              fieldCheckerFunction(obj, key)
            } else if (
              lAndDFormInputs[0].value === 'YES' &&
              key === 'well4Well11DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridlNd: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridlNd: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'ldTrainingTitle',
                  'ldTrainingDateIsNA',
                  'ldTrainingHoursNo',
                  'ldType',
                  'ldSponsoredBy',
                ]
                if (elementKeys.includes('ldTrainingDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'ldTrainingDateFrom')
                }
                if (elementKeys.includes('ldTrainingDateTo')) {
                  fieldsToCheck.splice(2, 0, 'ldTrainingDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridlNd: true,
                    }
                  }
                }
              }
            } else if (key === 'skillsAndHobbiesGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.skillsAndHobbies.length === 0 ||
                  (typeof element.skillsAndHobbies === 'string' &&
                    element.skillsAndHobbies.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataHobbies: true,
                  }
                }
              }
            } else if (key === 'nonAcademicdistinctionsrecognitionGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if the specific property is an empty string
                if (
                  element.nonAcademicdistinctionsrecognition.length === 0 ||
                  (typeof element.nonAcademicdistinctionsrecognition ===
                    'string' &&
                    element.nonAcademicdistinctionsrecognition.trim().length ===
                      0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDatanonAcademicdistinctionsrecognition: true,
                  }
                }
              }
            } else if (key === 'orgMembershipGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.orgMembership.length === 0 ||
                  (typeof element.orgMembership === 'string' &&
                    element.orgMembership.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataorgMembership: true,
                  }
                }
              }
            }
          }
          return {
            allRequiredValuesPresent: true,
            failedKey: null,
          }
        }
        let result = checkRequiredValues(dataReview.data)
        const isGridData = result.isGridData
        const allRequiredValuesPresent = result.allRequiredValuesPresent
        const isGridDataChildren = result.isGridDataChildren
        const isGridDataElementary = result.isElementaryGrid
        const isGridDataSecondary = result.isSecondaryGrid
        const isGridDataVocational = result.isVocationalGrid
        const isGridDataCollege = result.isCollegeGrid
        const isGridDataGraduateStudies = result.isGraduateStudies
        const isGridDataWorkExperience = result.isGridDataWorkExperience
        const isGridDataVoluntaryWork = result.isGridVoluntaryWork
        const isGridDataLNDAtendee = result.isGridlNd
        const isGridDataHobbies = result.isGridDataHobbies
        const isGridDatanonAcademicdistinctionsrecognition =
          result.isGridDatanonAcademicdistinctionsrecognition
        const isGridDataorgMembership = result.isGridDataorgMembership
        const isCivilServiceEligibility = result.isCivilServiceEligibility
        function errorNonTextType(element, picture) {
          if (picture) {
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '* upload here *'
            errorSpan.classList.add('errorSpan')

            element.insertAdjacentElement('afterend', errorSpan)

            notification.error({
              message: 'Error on a required field.',
              description: `Please upload your ${picture}.`,
            })

            setRemoveAsterisk(errorSpan)
          } else {
            const labelElement = element.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
          }

          element.scrollIntoView({ behavior: 'smooth' })

          const elementRect = element.getBoundingClientRect()
          const offset = (window.innerHeight - elementRect.height) / 2
          const scrollOffset = elementRect.top - offset

          element.scrollIntoView({ behavior: 'smooth' })

          window.scrollBy(0, scrollOffset)

          setIsModalOpen(false)
          setIsDisabledAfterSave(false)
        }

        if (allRequiredValuesPresent === false) {
          const editData = { ...dataReview.data }
          if (isGridData) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            const labelElement =
              element.parentNode.parentNode.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
            element.classList.add('is-invalid')
            element.scrollIntoView({ behavior: 'smooth' })

            const elementRect = element.getBoundingClientRect()
            const offset = (window.innerHeight - elementRect.height) / 2
            const scrollOffset = elementRect.top - offset

            element.scrollIntoView({ behavior: 'smooth' })

            window.scrollBy(0, scrollOffset)

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            isGridDataElementary ||
            isGridDataSecondary ||
            isGridDataVocational ||
            isGridDataCollege ||
            isGridDataGraduateStudies ||
            isGridDataLNDAtendee
          ) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              const labelElement =
                element.parentNode.parentNode.querySelector('label')
              if (labelElement) {
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              } else {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              isGridDataLNDAtendee &&
              result.failedKey.includes('[ldTrainingDateIsNA]')
            ) {
              const elementSelect = document.querySelectorAll(
                '.formio-component-wellContent3'
              )
              const parts = result.failedKey.split(']') // Split by ']'
              const indexPart = parts[1] // Get the part with the index, which will be "[1"
              const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
              const index = parseInt(indexStr, 10) // Convert to integer

              let elementSelectedWithIndex = elementSelect[index]

              if (elementSelectedWithIndex) {
                const labelElement =
                  elementSelectedWithIndex.parentNode.parentNode.parentNode.querySelector(
                    'span'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelectedWithIndex.classList.add('is-invalid')
                elementSelectedWithIndex.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )

              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
          } else if (isGridDataWorkExperience || isGridDataVoluntaryWork) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              if (
                result.failedKey.includes('[workExperienceDateFrom]') ||
                result.failedKey.includes('[workExperienceSalary]') ||
                result.failedKey.includes('[voluntaryWorkOrg]') ||
                result.failedKey.includes('[voluntaryWorkDateFrom]') ||
                result.failedKey.includes('[voluntaryWorkDateTo]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else if (
                result.failedKey.includes('[workExperiencePositionTitle]') ||
                result.failedKey.includes('[voluntaryWorkHoursNo]') ||
                result.failedKey.includes('[voluntaryWorkPosition]') ||
                result.failedKey.includes('[workExperienceCompany]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelectorAll(
                  '.formio-component-voluntaryWorkDateIsNA'
                )
                const parts = result.failedKey.split(']')
                const indexPart = parts[1]
                const indexStr = indexPart.substring(1)
                const index = parseInt(indexStr, 10)

                let elementSelectedWithIndex = elementSelect[index]
                if (elementSelectedWithIndex) {
                  const labelElement =
                    elementSelectedWithIndex.parentNode.querySelector('span')
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelectedWithIndex.classList.add('is-invalid')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataHobbies) {
            const element = document.querySelectorAll(
              '.formio-component-skillsAndHobbiesGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDatanonAcademicdistinctionsrecognition) {
            const element = document.querySelectorAll(
              '.formio-component-nonAcademicdistinctionsrecognitionGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDataorgMembership) {
            const element = document.querySelectorAll(
              '.formio-component-orgMembershipGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isCivilServiceEligibility) {
            if (result.failedKey.includes('[haveAnyEligibility]')) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-haveAnyEligibility'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes('[civilServiceExamDateIsNA]') ||
              result.failedKey.includes('[civilServiceExamDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceExamDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes(
                '[civilServiceLicenseValidityDateIsNA]'
              ) ||
              result.failedKey.includes('[civilServiceLicenseValidityDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (elementSelect) {
                  const labelElement =
                    elementSelect.parentNode.parentNode.parentNode.querySelector(
                      'label'
                    )
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelect.classList.add('is-invalid')
                  elementSelect.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataChildren) {
            if (result.failedKey.includes('[childrenFullnameNaAllowed]')) {
              const element = document.querySelector(
                `input[name="data${result.failedKey}"]`
              )
              if (element) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')

                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth' })

                const elementRect = element.getBoundingClientRect()
                const offset = (window.innerHeight - elementRect.height) / 2
                const scrollOffset = elementRect.top - offset

                element.scrollIntoView({ behavior: 'smooth' })

                window.scrollBy(0, scrollOffset)

                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else if (result.failedKey.includes('[childrenDOBNaAllowed]')) {
              if (result.failedKey) {
                const element = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (element) {
                  // Use closest() to find the nearest ancestor which matches the selector
                  const labelElement = element
                    .closest('.form-group')
                    .querySelector('label')
                  if (labelElement) {
                    const errorSpan = document.createElement('span')
                    errorSpan.textContent = '*'
                    errorSpan.classList.add('errorSpan')
                    labelElement.appendChild(errorSpan)
                    setRemoveAsterisk(errorSpan)
                  }
                  element.classList.add('is-invalid')
                }
                const errorElement = document.querySelector('.errorSpan')
                if (errorElement) {
                  errorElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                // Display notification error
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
          } else if (
            result.failedKey === 'suffixNaAllowed' ||
            result.failedKey === 'spouseSuffixNaAllowed' ||
            result.failedKey === 'fatherSuffixNaAllowed'
          ) {
            const dataSuffix = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            errorNonTextType(dataSuffix)
            dataSuffix.parentNode.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'serviceSeparationADetails') {
            const elementSelect = document.querySelector(
              '.formio-component-serviceSeparationADetails'
            )

            if (elementSelect) {
              const labelElement =
                elementSelect.parentNode.parentNode.parentNode.querySelector(
                  'label'
                )
              const errorSpan = document.createElement('span')
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (result.failedKey === 'offenseDateFiled') {
            const elementSelect = document.querySelector(
              '.formio-component-offenseDateFiled'
            )

            if (elementSelect) {
              const labelElement = elementSelect.querySelector('label')
              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
              window.scrollBy({
                top: 100,
                behavior: 'smooth',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (
            result.failedKey !== 'sexRequired' &&
            result.failedKey !== 'dateofbirthRequired' &&
            result.failedKey !== 'civilStatusRequired' &&
            result.failedKey !== 'citizenshipQuestionRequired' &&
            result.failedKey !== 'birthOrNaturaliztion' &&
            result.failedKey !== 'indicateCountry' &&
            result.failedKey !== 'isThirdDegreeRequired' &&
            result.failedKey !== 'isFourthDegreeRequired' &&
            result.failedKey !== 'offenseARequired' &&
            result.failedKey !== 'offenceCRequired' &&
            result.failedKey !== 'courtARequired' &&
            result.failedKey !== 'serviceSeparationARequired' &&
            result.failedKey !== 'candidateRequired' &&
            result.failedKey !== 'resignedRequired' &&
            result.failedKey !== 'immigrantRequired' &&
            result.failedKey !== 'isIndigenousRequired' &&
            result.failedKey !== 'isPersonWDisabilityRequired' &&
            result.failedKey !== 'isSoloParentRequired' &&
            result.failedKey !== 'heightFormat' &&
            result.failedKey !== 'weightFormat' &&
            result.failedKey !== 'bloodtype' &&
            result.failedKey !== 'ifChildren' &&
            result.failedKey !== 'well3Well2DataGrid' &&
            result.failedKey !== 'isWorkExperience' &&
            result.failedKey !== 'isVoluntaryWork' &&
            result.failedKey !== 'isLDAttended' &&
            result.failedKey !== 'offenseDateFiled' &&
            !result.failedKey.includes('[workExperienceIsGovtService]') &&
            !result.failedKey.includes('[workPayGradeIsNA]') &&
            !result.failedKey.includes('[workInclusiveDateToFormat]') &&
            !result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const element = document.querySelector(
              `input[name="data[${result.failedKey}]"]`
            )
            const labelElement =
              element.parentElement.parentNode.querySelector('label')

            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            repeatingNotif()
          }

          setHoldData({ ...editData })

          const element = document.querySelector(
            `input[name="data[${result.failedKey}]"]`
          )

          function repeatingNotif() {
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          }

          if (element) {
            if (result.failedKey === 'dateofbirthRequired') {
              const elementLabel = document.getElementsByClassName(
                'formio-component-dateofbirthRequired'
              )

              const labelElement = elementLabel[0].querySelector('label')
              const errorSpan = document.createElement('span')

              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')

              labelElement.insertAdjacentElement('afterend', errorSpan)
              setRemoveAsterisk(errorSpan)

              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.parentNode.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              window.scrollBy(0, scrollOffset)

              notification.error({
                message: 'Error',
                description: 'Please select your valid date of birth.',
              })
            } else {
              element.classList.add('is-invalid')
              element.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              element.scrollIntoView({ behavior: 'smooth' })

              window.scrollBy(0, scrollOffset)
            }
          } else if (result.failedKey === 'bloodtype') {
            const element = document.querySelector(
              '.formio-component-bloodtype'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your blood type.',
            })
          } else if (
            result.failedKey.includes('[workExperienceIsGovtService]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workExperienceIsGovtService'
            )

            errorNonTextType(element[index])
          } else if (result.failedKey.includes('[workPayGradeIsNA]')) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workPayGradeIsNA'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            result.failedKey.includes('[workInclusiveDateToFormat]') ||
            result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workInclusiveDateToFormat'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'civilStatusRequired') {
            const element = document.querySelector(
              '.formio-component-civilStatusRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your civil status.',
            })
          } else if (result.failedKey === 'citizenshipQuestionRequired') {
            const element = document.querySelector(
              '.formio-component-citizenshipQuestionRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your citizenship.',
            })
          } else if (result.failedKey === 'birthOrNaturaliztion') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-birthOrNaturaliztion'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description: `Please select between "by birth" or "by naturalization".`,
              })
            }
          } else if (result.failedKey === 'indicateCountry') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-indicateCountry'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description:
                  'Please select if country if you have dual citizenship.',
              })
            }
          } else if (result.failedKey === 'isWorkExperience') {
            const element = document.querySelector(
              '.formio-component-isWorkExperience'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isVoluntaryWork') {
            const element = document.querySelector(
              '.formio-component-isVoluntaryWork'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isLDAttended') {
            const element = document.querySelector(
              '.formio-component-isLDAttended'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isThirdDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isThirdDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isFourthDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isFourthDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenseARequired') {
            const element = document.querySelector(
              '.formio-component-offenseARequired'
            )

            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'offenceCRequired') {
            const element = document.querySelector(
              '.formio-component-offenceCRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'courtARequired') {
            const element = document.querySelector(
              '.formio-component-courtARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'serviceSeparationARequired') {
            const element = document.querySelector(
              '.formio-component-serviceSeparationARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'candidateRequired') {
            const element = document.querySelector(
              '.formio-component-candidateRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'resignedRequired') {
            const element = document.querySelector(
              '.formio-component-resignedRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'immigrantRequired') {
            const element = document.querySelector(
              '.formio-component-immigrantRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isIndigenousRequired') {
            const element = document.querySelector(
              '.formio-component-isIndigenousRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isPersonWDisabilityRequired') {
            const element = document.querySelector(
              '.formio-component-isPersonWDisabilityRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isSoloParentRequired') {
            const element = document.querySelector(
              '.formio-component-isSoloParentRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'sexRequired') {
            const element = document.querySelector(
              '.formio-component-sexRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'heightFormat') {
            const element = document.querySelector(
              '.formio-component-heightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'weightFormat') {
            const element = document.querySelector(
              '.formio-component-weightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'ifChildren') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'well3Well2DataGrid') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )
            errorNonTextType(element)
            repeatingNotif()
          }

          setIsModalOpen(false)
          setIsDisabledAfterSave(false)
        } else {
          if (uploadedSignature && uploadedThumbmark && uploadedPassportPhoto) {
            await triggerSave(url, urlThumb, urlPassport, isFinal)

            const getDeptApproverDetailsArray =
              await getDeptApproverDetailsArrayFunction()

            const sourceEmail = (await getSourceEmail()) || ''

            if (
              getDeptApproverDetailsArray?.length >= 1 &&
              !window.location.href.includes('localhost')
            ) {
              await sendEmail(
                getDeptApproverDetailsArray[0].username,
                sourceEmail
              )

              notification.success({
                message: 'Success',
                description:
                  'You have submitted for review your Personal Data Sheet successfully.',
              })
            } else if (!getDeptApproverDetailsArray?.length >= 1) {
              notification.info({
                message: 'NOTICE',
                duration: 120000,
                description: `We didn't see any Department Approver for your PDS. Please reach out to HRM Department to inform them of your submitted PDS Form`,
              })
            }

            closeModal()
          } else if (!uploadedSignature) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6ColumnsSignature'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'e-Signature and thumbmark photo')
          } else if (!uploadedThumbmark) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6Content'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'thumbmark photo / e-Signature')
          } else if (!uploadedPassportPhoto) {
            let divElement = document.querySelector(
              '.formio-component-well4Well22ColumnsContent'
            )

            let spanElement = divElement.querySelector('span')
            errorNonTextType(spanElement, 'passport photo')
          }
        }
      } else {
        await triggerSave(url, urlThumb, urlPassport, isFinal)

        closeModal()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getDeptApproverDetailsArrayFunction = async () => {
    try {
      let getDeptApproverDetailsArray = []
      let token = null

      do {
        const getDeptApproverDetails = await API.graphql(
          graphqlOperation(UserbyDepartment, {
            assignedDept: employeeToBeEncoded.assignedDept,
            filter: {
              or: [
                { pdsRole: { eq: 'deptApprover' } },
                { pdsRole: { eq: 'deptApproverAndEncoder' } },
              ],
            },
            nextToken: token,
          })
        )

        getDeptApproverDetailsArray.push(
          ...getDeptApproverDetails.data.UserbyDepartment.items
        )

        token = getDeptApproverDetails.data.UserbyDepartment.nextToken
      } while (token)

      return getDeptApproverDetailsArray
    } catch (err) {
      console.log(err)
      return []
    }
  }

  /**
   *@function handleSignType
   *@description Handles the event type of the button from Formio
   *@param {Object} type - The type of the event.
   *@returns {Object} none No return value.
   */
  const handleSignType = (type) => {
    try {
      if (type === 'customEventThumbSign') {
        setIsModalOpenThumbmark(true)
      }

      if (type === 'customEventESign') {
        setIsModalOpenESign(true)
      }

      if (type === 'customEventPassportPhoto') {
        setIsModalOpenPassportPhoto(true)
      }

      if (type === 'chooseProvince') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(false)
      }

      if (type === 'chooseBarangay') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(false)
      }

      if (type === 'chooseCity') {
        setIsModalOpenCity(true)
        setIsCityPerm(false)
      }

      if (type === 'chooseProvincePerm') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(true)
      }

      if (type === 'chooseBarangayPerm') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(true)
      }

      if (type === 'chooseCityPerm') {
        setIsModalOpenCity(true)
        setIsCityPerm(true)
      }

      if (type === 'choosezipcode') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(false)
      }

      if (type === 'choosezipcodePerm') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangePassportPhoto
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangePassportPhoto = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const passportPhoto =
          document.getElementsByClassName('passport-photo')[0]

        const imgElementDels = passportPhoto.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            passportPhoto.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          passportPhoto.appendChild(imgElement)

          setIsModalOpenESign(false)
          setIsModalOpenThumbmark(false)
          setIsModalOpenPassportPhoto(false)
        }

        reader.readAsDataURL(file)
        setUploadedPassportPhoto(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeESign
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeESign = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eSignDiv = document.getElementsByClassName(
          'electronic-signature'
        )[0]

        const imgElementDels = eSignDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eSignDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eSignDiv.appendChild(imgElement)
          setIsModalOpenESign(false)
        }

        reader.readAsDataURL(file)
        setUploadedSignature(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeThumb
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeThumb = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eThumbDiv = document.getElementsByClassName(
          'electronic-thumbmark'
        )[0]

        const imgElementDels = eThumbDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eThumbDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader() // Create a FileReader instance

        reader.onload = (event) => {
          const dataURL = event.target.result // Get the data URL from the FileReader

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eThumbDiv.appendChild(imgElement)
          setIsModalOpenThumbmark(false)
        }

        reader.readAsDataURL(file)
        setUploadedThumbmark(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const inputChangeHandler = (inputElement, val) => {
    try {
      if (inputElement) {
        if (inputElement.type === 'radio') {
          // For radio inputs, find the corresponding radio option and set its checked property
          const radioOption = document.querySelector(
            `input[name="${inputElement.name}"][value="${val}"]`
          )
          if (radioOption) {
            radioOption.checked = true

            const event = new Event('change', { bubbles: true })
            radioOption.dispatchEvent(event)
          }
        } else {
          // For other input types, set the value property
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
          ).set
          nativeInputValueSetter.call(inputElement, val)

          const event = new Event('input', { bubbles: true })
          inputElement.dispatchEvent(event)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  function handleClick(event) {
    event.preventDefault()
  }

  const callFatherFieldsFunc = () => {
    const fatherSurnameNaAllowed = document.querySelector(
      'input[name="data[fatherSurnameNaAllowed]"]'
    )
    const fatherFirstnameNaAllowed = document.querySelector(
      'input[name="data[fatherFirstnameNaAllowed]"]'
    )
    const fatherMiddlenameNaAllowed = document.querySelector(
      'input[name="data[fatherMiddlenameNaAllowed]"]'
    )
    const fatherSuffixparentElement = document.querySelector(
      '.formio-component-fatherSuffixNaAllowed'
    )
    const dropdownListFather = fatherSuffixparentElement.querySelectorAll(
      '.choices__list.choices__list--dropdown'
    )
    const suffixDropDownFather = dropdownListFather[0]
    const allChoicesFather = fatherSuffixparentElement.querySelectorAll(
      '.choices__item.choices__placeholder.choices__item--selectable'
    )
    const fatherSuffixSelect = allChoicesFather[0]

    return {
      fatherSurnameNaAllowed,
      fatherFirstnameNaAllowed,
      fatherMiddlenameNaAllowed,
      fatherSuffixparentElement,
      dropdownListFather,
      suffixDropDownFather,
      allChoicesFather,
      fatherSuffixSelect,
    }
  }

  const callMotherFieldsFunc = () => {
    const motherSurnameNaAllowed = document.querySelector(
      'input[name="data[motherSurnameNaAllowed]"]'
    )
    const motherFirstnameNaAllowed = document.querySelector(
      'input[name="data[motherFirstnameNaAllowed]"]'
    )
    const motherMiddlenameNaAllowed = document.querySelector(
      'input[name="data[motherMiddlenameNaAllowed]"]'
    )

    return {
      motherSurnameNaAllowed,
      motherFirstnameNaAllowed,
      motherMiddlenameNaAllowed,
    }
  }

  const callSpouseFieldsFunc = () => {
    const spouseSurname = document.querySelector(
      'input[name="data[spouseSurnameNaAllowed]"]'
    )
    const spouseFirstName = document.querySelector(
      'input[name="data[spouseFirstnameNaAllowed]"]'
    )
    const spouseMiddleName = document.querySelector(
      'input[name="data[spouseMiddlenameNaAllowed]"]'
    )
    const spouseSuffixparentElement = document.querySelector(
      '.formio-component-spouseSuffixNaAllowed'
    )
    const dropdownList = spouseSuffixparentElement.querySelectorAll(
      '.choices__list.choices__list--dropdown'
    )
    const suffixDropDown = dropdownList[0]
    const allChoices = spouseSuffixparentElement.querySelectorAll(
      '.choices__item.choices__placeholder.choices__item--selectable'
    )
    const spouseSuffixSelect = allChoices[0]
    const spouseOccupation = document.querySelector(
      'input[name="data[spouseOccupationNaAllowed]"]'
    )
    const spouseEmployerName = document.querySelector(
      'input[name="data[spouseEmployerNameNaAllowed]"]'
    )
    const spouseEmployerAddress = document.querySelector(
      'input[name="data[spouseEmployerAddressNaAllowed]"]'
    )
    const spouseEmployerTelephoneno = document.querySelector(
      'input[name="data[spouseEmployerTelephonenoNaAllowed]"]'
    )
    const markSpouseAsNA = document.querySelector(
      'input[name="data[markSpouseAsNA]"]'
    )

    return {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      dropdownList,
      suffixDropDown,
      allChoices,
      spouseSuffixSelect,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    }
  }

  const createElemSpanTemp = (elemFound, text) => {
    // Create a new input element
    const elemValueInput = document.createElement('input')
    const elemValueInputLabel = document.createElement('label')

    // Set attributes for the input
    elemValueInput.type = 'text'
    elemValueInput.value = 'N/A'
    elemValueInput.disabled = true // Disables the input field

    elemValueInputLabel.classList.add('col-form-label')
    elemValueInputLabel.classList.add('elem-val-temp-label')
    elemValueInputLabel.innerHTML = text

    // Optional: Add some styling or classes if desired
    elemValueInput.classList.add('form-control')
    elemValueInput.classList.add('elem-val-temp')

    // Insert the input next to where the select was
    elemFound.parentNode.insertBefore(elemValueInput, elemFound.nextSibling)
    elemFound.parentNode.insertBefore(
      elemValueInputLabel,
      elemFound.nextSibling
    )
  }

  const removeElemSpanTemp = () => {
    const elemValueInput = document.querySelectorAll('.elem-val-temp')
    const elemValueInputLabel = document.querySelectorAll(
      '.elem-val-temp-label'
    )

    if (elemValueInput) {
      elemValueInput.forEach((i) => {
        i.remove()
      })
    }

    if (elemValueInputLabel) {
      elemValueInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const createSpouseSuffixSpanTemp = (spouseSuffixparentElement) => {
    // Create a new input element
    const spouseSuffixInput = document.createElement('input')
    const spouseSuffixInputLabel = document.createElement('label')

    // Set attributes for the input
    spouseSuffixInput.type = 'text'
    spouseSuffixInput.value = 'N/A'
    spouseSuffixInput.disabled = true // Disables the input field

    spouseSuffixInputLabel.classList.add('col-form-label')
    spouseSuffixInputLabel.classList.add('spouse-suffix-temp-label')
    spouseSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

    // Optional: Add some styling or classes if desired
    spouseSuffixInput.classList.add('form-control')
    spouseSuffixInput.classList.add('spouse-suffix-temp')

    // Insert the input next to where the select was
    spouseSuffixparentElement.parentNode.insertBefore(
      spouseSuffixInput,
      spouseSuffixparentElement.nextSibling
    )
    spouseSuffixparentElement.parentNode.insertBefore(
      spouseSuffixInputLabel,
      spouseSuffixparentElement.nextSibling
    )
  }

  const removeSpouseSuffixSpanTemp = () => {
    const spouseSuffixInput = document.querySelectorAll('.spouse-suffix-temp')
    const spouseSuffixInputLabel = document.querySelectorAll(
      '.spouse-suffix-temp-label'
    )

    if (spouseSuffixInput) {
      spouseSuffixInput.forEach((i) => {
        i.remove()
      })
    }

    if (spouseSuffixInputLabel) {
      spouseSuffixInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const createFatherSuffixSpanTemp = (fatherSuffixparentElement) => {
    // Create a new input element
    const fatherSuffixInput = document.createElement('input')
    const fatherSuffixInputLabel = document.createElement('label')

    // Set attributes for the input
    fatherSuffixInput.type = 'text'
    fatherSuffixInput.value = 'N/A'
    fatherSuffixInput.disabled = true // Disables the input field

    fatherSuffixInputLabel.classList.add('col-form-label')
    fatherSuffixInputLabel.classList.add('father-suffix-temp-label')
    fatherSuffixInputLabel.innerHTML = 'NAME EXTENSION (JR., SR)'

    // Optional: Add some styling or classes if desired
    fatherSuffixInput.classList.add('form-control')
    fatherSuffixInput.classList.add('father-suffix-temp')

    // Insert the input next to where the select was
    fatherSuffixparentElement.parentNode.insertBefore(
      fatherSuffixInput,
      fatherSuffixparentElement.nextSibling
    )
    fatherSuffixparentElement.parentNode.insertBefore(
      fatherSuffixInputLabel,
      fatherSuffixparentElement.nextSibling
    )
  }

  const removeFatherSuffixSpanTemp = () => {
    const fatherSuffixInput = document.querySelectorAll('.father-suffix-temp')
    const fatherSuffixInputLabel = document.querySelectorAll(
      '.father-suffix-temp-label'
    )

    if (fatherSuffixInput) {
      fatherSuffixInput.forEach((i) => {
        i.remove()
      })
    }

    if (fatherSuffixInputLabel) {
      fatherSuffixInputLabel.forEach((i) => {
        i.remove()
      })
    }
  }

  const handleCivilStatusRequiredToSingleFunc = (e) => {
    const spouseFields = callSpouseFieldsFunc()
    const {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    } = spouseFields

    markSpouseAsNA.checked = true
    markSpouseAsNA.disabled = true

    spouseSurname.disabled = true
    spouseFirstName.disabled = true
    spouseMiddleName.disabled = true
    spouseOccupation.disabled = true
    spouseEmployerName.disabled = true
    spouseEmployerAddress.disabled = true
    spouseEmployerTelephoneno.disabled = true

    spouseSurname.value = 'N/A'
    spouseFirstName.value = 'N/A'
    spouseMiddleName.value = 'N/A'
    spouseOccupation.value = 'N/A'
    spouseEmployerName.value = 'N/A'
    spouseEmployerAddress.value = 'N/A'
    spouseEmployerTelephoneno.value = 'N/A'

    createSpouseSuffixSpanTemp(spouseSuffixparentElement)

    spouseSuffixparentElement.style.display = 'none'
    spouseSuffixparentElement.style.visibility = 'hidden'

    e.data.markSpouseAsNA = true
    e.data.spouseSurnameNaAllowed = 'N/A'
    e.data.spouseFirstnameNaAllowed = 'N/A'
    e.data.spouseMiddlenameNaAllowed = 'N/A'
    e.data.spouseOccupationNaAllowed = 'N/A'
    e.data.spouseEmployerNameNaAllowed = 'N/A'
    e.data.spouseEmployerAddressNaAllowed = 'N/A'
    e.data.spouseEmployerTelephonenoNaAllowed = 'N/A'
    e.data.spouseSuffixNaAllowed = 'N/A'

    setHoldData(e.data)
  }

  const handleCivilStatusRequiredToNOTSingleFunc = (e) => {
    const spouseFields = callSpouseFieldsFunc()
    const {
      spouseSurname,
      spouseFirstName,
      spouseMiddleName,
      spouseSuffixparentElement,
      spouseOccupation,
      spouseEmployerName,
      spouseEmployerAddress,
      spouseEmployerTelephoneno,
      markSpouseAsNA,
    } = spouseFields

    e.data.markSpouseAsNA = false
    e.data.spouseSurname = ''
    e.data.spouseFirstName = ''
    e.data.spouseMiddleName = ''
    e.data.spouseOccupation = ''
    e.data.spouseEmployerName = ''
    e.data.spouseEmployerAddress = ''
    e.data.spouseEmployerTelephoneno = ''
    e.data.spouseSuffixNaAllowed = ''

    markSpouseAsNA.checked = false
    markSpouseAsNA.disabled = false

    spouseSurname.disabled = false
    spouseFirstName.disabled = false
    spouseMiddleName.disabled = false
    spouseOccupation.disabled = false
    spouseEmployerName.disabled = false
    spouseEmployerAddress.disabled = false
    spouseMiddleName.disabled = false
    spouseEmployerTelephoneno.disabled = false

    spouseSurname.value = ''
    spouseFirstName.value = ''
    spouseMiddleName.value = ''
    spouseOccupation.value = ''
    spouseEmployerName.value = ''
    spouseEmployerAddress.value = ''
    spouseMiddleName.value = ''
    spouseEmployerTelephoneno.value = ''

    spouseSuffixparentElement.style = undefined

    removeSpouseSuffixSpanTemp()

    setHoldData(e.data)
  }

  const handleChangeForm = (e) => {
    console.log(e)
    try {
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )
      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"]'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )

      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )

      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )

      const spouseFields = callSpouseFieldsFunc()
      const {
        spouseSurname,
        spouseFirstName,
        spouseMiddleName,
        spouseSuffixparentElement,
        spouseOccupation,
        spouseEmployerName,
        spouseEmployerAddress,
        spouseEmployerTelephoneno,
      } = spouseFields

      const fatherFields = callFatherFieldsFunc()
      const {
        fatherSurnameNaAllowed,
        fatherFirstnameNaAllowed,
        fatherMiddlenameNaAllowed,
        fatherSuffixparentElement,
      } = fatherFields

      const motherFields = callMotherFieldsFunc()
      const {
        motherSurnameNaAllowed,
        motherFirstnameNaAllowed,
        motherMiddlenameNaAllowed,
      } = motherFields

      if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === true
      ) {
        setHoldData(e.data)

        inputChangeHandler(
          permanentHouseBlockLotNoRequiredElem,
          e.data.residentialHouseBlockLotNoRequired
        )

        inputChangeHandler(
          permanentStreetRequiredElem,
          e.data.residentialStreetRequired
        )

        inputChangeHandler(
          permanentSubdivisionVillageElem,
          e.data.residentialSubdivisionVillageRequired
        )

        inputChangeHandler(
          permanentBarangayRequiredElem,
          e.data.residentialBarangayRequired
        )

        inputChangeHandler(
          permanentCityMunicipalityRequiredElem,
          e.data.residentialCityMunicipalityRequired
        )

        inputChangeHandler(
          permanentProvinceRequiredElem,
          e.data.residentialProvinceRequired
        )

        inputChangeHandler(
          permanentZipcodeElem,
          e.data.residentialZipcodeNaAllowed
        )

        setTimeout(() => {
          chooseProvinceButtonPermElem.style.display = 'none'
          chooseCityButtonPermElem.style.display = 'none'
          chooseBarangayButtonPermElem.style.display = 'none'
          chooseZipCodeButtonPermElem.style.display = 'none'
          permanentHouseBlockLotNoRequiredElem.disabled = true
          permanentStreetRequiredElem.disabled = true
          permanentSubdivisionVillageElem.disabled = true
          permanentBarangayRequiredElem.disabled = true
          permanentCityMunicipalityRequiredElem.disabled = true
          permanentProvinceRequiredElem.disabled = true
          permanentZipcodeElem.disabled = true
        }, 500)
      } else if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === false
      ) {
        setHoldData(e.data)
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false
      } else if (
        (e?.changed?.component?.key === 'residentialHouseBlockLotNoRequired' ||
          e?.changed?.component?.key === 'residentialStreetRequired' ||
          e?.changed?.component?.key ===
            'residentialSubdivisionVillageRequired' ||
          e?.changed?.component?.key === 'residentialBarangayRequired' ||
          e?.changed?.component?.key ===
            'residentialCityMunicipalityRequired' ||
          e?.changed?.component?.key === 'residentialProvinceRequired' ||
          e?.changed?.component?.key === 'residentialZipcodeNaAllowed') &&
        e.data.sameAsResidentialAddressBox === true
      ) {
        const sameAsResidentialAddressBox = document.querySelector(
          'input[name="data[sameAsResidentialAddressBox]"]'
        )
        sameAsResidentialAddressBox.checked = false

        const event = new Event('change', { bubbles: true })
        sameAsResidentialAddressBox.dispatchEvent(event)
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false

        inputChangeHandler(permanentHouseBlockLotNoRequiredElem, '')

        inputChangeHandler(permanentStreetRequiredElem, '')

        inputChangeHandler(permanentSubdivisionVillageElem, '')

        inputChangeHandler(permanentBarangayRequiredElem, '')

        inputChangeHandler(permanentCityMunicipalityRequiredElem, '')

        inputChangeHandler(permanentProvinceRequiredElem, '')

        inputChangeHandler(permanentZipcodeElem, '')

        setHoldData({
          ...e.data,
          sameAsResidentialAddressBox: false,
          [`${e?.changed?.component?.key}`]: e?.changed?.value,
          permanentHouseBlockLotNoRequired: '',
          permanentStreetRequired: '',
          permanentSubdivisionVillageRequired: '',
          permanentBarangayRequired: '',
          permanentCityMunicipalityRequired: '',
          permanentProvinceRequired: '',
          permanentZipcodeNaAllowed: '',
        })
      } else if (e?.changed?.component?.key === 'well4DataGrid2') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid2']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key.includes('IsNA')) {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (
        e?.changed?.component?.key === 'well4Well5Levelgraduatestudies'
      ) {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Well5Levelgraduatestudies']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4DataGrid3') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid3']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (
        e?.changed?.component?.key === 'well4Levelvocationaltradecourse'
      ) {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Levelvocationaltradecourse']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4DataGrid') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key.includes('IsNA')) {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4Well11DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well11DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well11DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.ldTrainingDateFrom) &&
                isValidValue(obj.ldTrainingDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        const encryptedData = localStorage.getItem('encryptedFormData')

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1 && encryptedData) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'skillsAndHobbiesGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-skillsAndHobbiesGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.skillsAndHobbiesGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (
        e?.changed?.component?.key === 'nonAcademicdistinctionsrecognitionGrid'
      ) {
        const foundGrid = document.querySelector(
          '.formio-component-nonAcademicdistinctionsrecognitionGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.nonAcademicdistinctionsrecognitionGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'orgMembershipGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-orgMembershipGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.orgMembershipGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well4Well10DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well10DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well10DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.voluntaryWorkDateFrom) &&
                isValidValue(obj.voluntaryWorkDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well4Well8DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well4Well8DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well4Well8DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (
              key === 'workPayGradeIsNA' &&
              value === 'selectSalaryGradeStepIncrement'
            ) {
              return (
                isValidValue(obj.workExperienceStepIncrement) &&
                isValidValue(obj.workExperiencePayGrade)
              )
            }

            if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
              return isValidValue(obj.workExperienceDateTo)
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'well3Well2DataGrid') {
        const foundGrid = document.querySelector(
          '.formio-component-well3Well2DataGrid'
        )
        const foundTable = foundGrid.querySelectorAll('table tr')

        const dataPath = e.data.well3Well2DataGrid

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent

        dataPath.forEach((elem, index) => {
          if (index < dataPath.length - 1) {
            const disableRemoveRowButton = foundTable[index].querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })
      } else if (e?.changed?.component?.key === 'ifChildren') {
        const value = e.changed.value
        const searchCheckboxParent = e.changed.instance.element

        const yesRadio =
          searchCheckboxParent.querySelectorAll('input[value="YES"]')
        const noRadio =
          searchCheckboxParent.querySelectorAll('input[value="NO"]')

        if (value === 'NO') {
          yesRadio.forEach((elem) => {
            elem.disabled = false
          })
          noRadio.forEach((elem) => {
            elem.disabled = true
          })
        }

        if (value === 'YES') {
          yesRadio.forEach((elem) => {
            elem.disabled = true
          })
          noRadio.forEach((elem) => {
            elem.disabled = false
          })

          const foundGrid = searchCheckboxParent.parentNode
          const foundTable = foundGrid.querySelectorAll('table tr')

          const dataFound = e.data
          const dataPath = dataFound['well3Well2DataGrid']

          const isValidValue = (value) =>
            value !== '' && value !== null && value !== undefined

          const allValuesPresent = dataPath.every((obj) =>
            Object.entries(obj).every(([key, value]) => {
              return isValidValue(value)
            })
          )

          const disableAddRowButton = foundGrid.querySelector(
            '.formio-button-add-row'
          )

          disableAddRowButton.disabled = !allValuesPresent

          dataPath.forEach((elem, index) => {
            if (index < dataPath.length - 1) {
              const disableRemoveRowButton = foundTable[index].querySelector(
                '.formio-button-remove-row'
              )

              disableRemoveRowButton.disabled = true
            }
          })
        }
      } else if (
        ['childrenFullnameNaAllowed', 'childrenDOBNaAllowed'].includes(
          e?.changed?.component?.key
        )
      ) {
        const searchCheckboxParent = e.changed.instance.element
        const foundGrid = searchCheckboxParent.closest(
          '.formio-component-well3Well2DataGrid'
        )

        const dataFound = e.data
        const dataPath = dataFound['well3Well2DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const disableAddRowButton = foundGrid.querySelector(
          '.formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'well4Well7DataGrid') {
        const searchCheckboxParent = e.changed.instance.element

        const searchedTR = searchCheckboxParent.querySelectorAll('tbody tr')

        searchedTR.forEach((elem, index) => {
          if (index < searchedTR.length - 1) {
            const disableRemoveRowButton = elem.querySelector(
              '.formio-button-remove-row'
            )

            disableRemoveRowButton.disabled = true
          }
        })

        const dataFound = e.data
        const dataPath = dataFound['well4Well7DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            // Skip validation for empty dates when NA is set
            if (key === 'civilServiceLicenseValidityDate') {
              return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceExamDate') {
              return obj.civilServiceExamDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
              return obj.civilServiceAndOthersLicense === 'OTHERS'
                ? isValidValue(value)
                : true
            }

            if (key === 'civilServiceAndOthersLicense') {
              if (value === 'OTHERS') {
                return isValidValue(
                  obj.civilServiceAndOthersLicenseOthersEligibility
                )
              }
              return isValidValue(value)
            }

            if (key.includes('IsNA')) {
              return value === 'N/A' || value === 'enterDate'
            }

            return isValidValue(value)
          })
        )

        const disableAddRowButton = searchCheckboxParent.querySelector(
          'table .formio-button-add-row'
        )

        disableAddRowButton.disabled = !allValuesPresent
      } else if (e?.changed?.component?.key === 'isLDAttended') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well11DataGrid) {
            delete dataFound.well4Well11DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well11DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (e?.changed?.component?.key === 'isVoluntaryWork') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well10DataGrid) {
            delete dataFound.well4Well10DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well10DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (e?.changed?.component?.key === 'isWorkExperience') {
        if (e?.changed?.value === 'NO') {
          const dataFound = e.data

          if (dataFound.well4Well8DataGrid) {
            delete dataFound.well4Well8DataGrid
          }

          e.data = { ...e.data, ...dataFound }

          const elemFound = e.changed.instance.element

          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
        }

        if (e?.changed?.value === 'YES') {
          const elemFound = e.changed.instance.element
          const radioToDisable = elemFound.querySelector(
            'input[type="radio"][value="YES"]'
          )
          const radioToEnable = elemFound.querySelector(
            'input[type="radio"][value="NO"]'
          )
          const disableAddRowButton = elemFound.parentNode.querySelector(
            '.formio-component-well4Well8DataGrid .formio-button-add-row'
          )

          radioToDisable.disabled = true
          radioToEnable.disabled = false
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'nonAcademicdistinctionsrecognition'
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['nonAcademicdistinctionsrecognitionGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'orgMembership') {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['orgMembershipGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'skillsAndHobbies') {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['skillsAndHobbiesGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'voluntaryWorkOrg',
          'voluntaryWorkDateIsNA',
          'voluntaryWorkHoursNo',
          'voluntaryWorkPosition',
          'voluntaryWorkDateFrom',
          'voluntaryWorkDateTo',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well10DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'voluntaryWorkDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.voluntaryWorkDateFrom) &&
                isValidValue(obj.voluntaryWorkDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'workExperienceAppointmentStatus',
          'workExperienceCompany',
          'workExperienceDateFrom',
          'workExperienceDateTo',
          'workExperienceIsGovtService',
          'workExperiencePositionTitle',
          'workExperienceSalary',
          'workInclusiveDateToFormat',
          'workPayGradeIsNA',
          'workExperienceStepIncrement',
          'workExperiencePayGrade',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well8DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (
              key === 'workPayGradeIsNA' &&
              value === 'selectSalaryGradeStepIncrement'
            ) {
              return (
                isValidValue(obj.workExperienceStepIncrement) &&
                isValidValue(obj.workExperiencePayGrade)
              )
            }

            if (key === 'workInclusiveDateToFormat' && value === 'selectDate') {
              return isValidValue(obj.workExperienceDateTo)
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (e?.changed?.component?.key === 'haveAnyEligibility') {
        if (e?.changed?.value === 'NO') {
          const path = e.changed.instance.path.replace('haveAnyEligibility', '')
          const dataFound = e.data
          const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
          const gridName = pathParts[1] // "well4Well7DataGrid"
          const index = parseInt(pathParts[2], 10) // 0
          const dataPath = dataFound[gridName][index]

          // Set all values to "N/A" except haveAnyEligibility
          Object.keys(dataPath).forEach((key) => {
            if (key !== 'haveAnyEligibility') {
              dataPath[key] = 'N/A'
            }
          })

          e.data = { ...e.data, ...dataFound }

          setTimeout(() => {
            const parentElem = e.changed.instance.element
            const radioToDisable = parentElem.querySelectorAll(
              'input[type="radio"][value="NO"]'
            )
            const radioToEnable = parentElem.querySelectorAll(
              'input[type="radio"][value="YES"]'
            )
            radioToDisable.forEach((elem) => {
              elem.disabled = true
            })
            radioToEnable.forEach((elem) => {
              elem.disabled = false
            })

            const searchCheckboxParent = e.changed.instance.element.parentNode

            const textInputs = searchCheckboxParent.querySelectorAll(
              'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
            )
            const nonTextInputs = searchCheckboxParent.querySelector(
              '.formio-component-civilServiceAndOthersLicense'
            )
            const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
              '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
            )
            const radioDateValidityDisable =
              searchCheckboxParent.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
              )
            radioDateExamDisable.forEach((elem) => {
              elem.disabled = true
              if (elem.value === 'N/A') {
                elem.checked = true
              }
            })
            radioDateValidityDisable.forEach((elem) => {
              elem.disabled = true
              if (elem.value === 'N/A') {
                elem.checked = true
              }
            })

            const disableAddRowButton = searchCheckboxParent
              .closest('table')
              .querySelector('.formio-button-add-row')
            const disableRemoveRowButton = searchCheckboxParent
              .closest('table')
              .querySelectorAll('.formio-button-remove-row')

            disableAddRowButton && (disableAddRowButton.disabled = true)
            disableRemoveRowButton.forEach((elem) => {
              elem.disabled = true
            })

            createElemSpanTemp(
              nonTextInputs,
              `CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE`
            )
            nonTextInputs.style.display = 'none'

            textInputs.forEach((input) => {
              input.value = 'N/A'
              input.disabled = true
            })
          }, 500)
        }

        if (e?.changed?.value === 'YES') {
          const path = e.changed.instance.path.replace('haveAnyEligibility', '')
          const dataFound = e.data
          const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
          const gridName = pathParts[1] // "well4Well7DataGrid"
          const index = parseInt(pathParts[2], 10) // 0
          const dataPath = dataFound[gridName][index]

          // Set all values to "" except haveAnyEligibility
          Object.keys(dataPath).forEach((key) => {
            if (
              key !== 'haveAnyEligibility' &&
              key !== 'civilServiceLicenseValidityDateIsNA' &&
              key !== 'civilServiceExamDateIsNA'
            ) {
              dataPath[key] = ''
            }

            if (
              key === 'civilServiceLicenseValidityDateIsNA' ||
              key === 'civilServiceExamDateIsNA'
            ) {
              dataPath[key] = 'enterDate'
            }
          })

          e.data = { ...e.data, ...dataFound }

          setTimeout(() => {
            const parentElem = e.changed.instance.element
            const searchCheckboxParent = e.changed.instance.element.parentNode

            const textInputs = searchCheckboxParent.querySelectorAll(
              'div input[type="text"]:not([type="checkbox"]):not([type="select"])'
            )
            const nonTextInputs = searchCheckboxParent.querySelector(
              '.formio-component-civilServiceAndOthersLicense'
            )
            const radioDateExamDisable = searchCheckboxParent.querySelectorAll(
              '.formio-component-civilServiceExamDateIsNA input[type="radio"]'
            )
            const radioDateValidityDisable =
              searchCheckboxParent.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA input[type="radio"]'
              )
            const radioToEnable = parentElem.querySelectorAll(
              'input[type="radio"][value="NO"]'
            )
            const radioToDisable = parentElem.querySelectorAll(
              'input[type="radio"][value="YES"]'
            )

            radioToEnable.forEach((elem) => {
              elem.disabled = false
            })

            radioToDisable.forEach((elem) => {
              elem.disabled = true
            })

            radioDateExamDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })

            radioDateExamDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })
            radioDateValidityDisable.forEach((elem) => {
              elem.disabled = false
              if (elem.value === 'enterDate') {
                elem.checked = true
              }
            })

            removeElemSpanTemp(parentElem.closest('tr'))

            nonTextInputs.style = undefined

            textInputs.forEach((input) => {
              input.value = ''
              input.disabled = false
            })

            const disableRemoveRowButton = searchCheckboxParent
              .closest('tr')
              .querySelectorAll('.formio-button-remove-row')
            disableRemoveRowButton.forEach((elem) => {
              elem.disabled = false
            })
          }, 500)
        }
      } else if (
        [
          'ldTrainingTitle',
          'ldTrainingDateIsNA',
          'ldTrainingHoursNo',
          'ldType',
          'ldSponsoredBy',
          'ldTrainingDateFrom',
          'ldTrainingDateTo',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound['well4Well11DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'ldTrainingDateIsNA' && value === 'selectDates') {
              return (
                isValidValue(obj.ldTrainingDateFrom) &&
                isValidValue(obj.ldTrainingDateTo)
              )
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'civilServiceRating',
          'civilServiceExamDateIsNA',
          'civilServiceExamPlace',
          'civilServiceAndOthersLicense',
          'civilServiceLicenseNo',
          'civilServiceLicenseValidityDateIsNA',
          'civilServiceExamDate',
          'civilServiceLicenseValidityDate',
          'civilServiceAndOthersLicenseOthersEligibility',
        ].includes(e?.changed?.component?.key)
      ) {
        const path = e.changed.instance.path.replace(
          e.changed.component.key,
          ''
        )
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const gridName = pathParts[1] // "well4Well7DataGrid"
        const index = parseInt(pathParts[2], 10) // 0
        const dataPath = dataFound[gridName]

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            // Skip validation for empty dates when NA is set
            if (key === 'civilServiceLicenseValidityDate') {
              return obj.civilServiceLicenseValidityDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceExamDate') {
              return obj.civilServiceExamDateIsNA === 'N/A'
                ? true
                : isValidValue(value)
            }

            if (key === 'civilServiceAndOthersLicenseOthersEligibility') {
              return obj.civilServiceAndOthersLicense === 'OTHERS'
                ? isValidValue(value)
                : true
            }

            if (key === 'civilServiceAndOthersLicense') {
              if (value === 'OTHERS') {
                return isValidValue(
                  obj.civilServiceAndOthersLicenseOthersEligibility
                )
              }
              return isValidValue(value)
            }

            if (key.includes('IsNA')) {
              return value === 'N/A' || value === 'enterDate'
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'graduateCourseNaAllowed',
          'graduateDateFromNaAllowed',
          'graduateDateToNaAllowed',
          'graduateNameOfSchoolNaAllowed',
          'graduateUnitsEarnedNaAllowed',
          'graduateYearGraduatedNaAllowed',
          'graduateScholarshipNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4Well5Levelgraduatestudies']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'collegeCourseNaAllowed',
          'collegeDateFromNaAllowed',
          'collegeDateToNaAllowed',
          'collegeNameOfSchoolNaAllowed',
          'collegeScholarshipNaAllowed',
          'collegeUnitsEarnedNaAllowed',
          'collegeYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid3']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'vocationalBasicEducationNaAllowed',
          'vocationalDateFromNaAllowed',
          'vocationalDateToNaAllowed',
          'vocationalNameOfSchoolNaAllowed',
          'vocationalScholarshipNaAllowed',
          'vocationalUnitsEarnedNaAllowed',
          'vocationalYeargraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4Levelvocationaltradecourse']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'secondaryBasicEducationNaAllowed',
          'secondaryDateFromNaAllowed',
          'secondaryDateToNaAllowed',
          'secondaryNameOfSchoolNaAllowed',
          'secondaryScholarshipNaAllowed',
          'secondaryUnitsEarnedNaAllowed',
          'secondaryYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        [
          'elementaryBasicEducationNaAllowed',
          'elementaryDateFromNaAllowed',
          'elementaryDateToNaAllowed',
          'elementaryNameOfSchoolNaAllowed',
          'elementaryScholarshipNaAllowed',
          'elementaryUnitsEarnedNaAllowed',
          'elementaryYearGraduatedNaAllowed',
        ].includes(e?.changed?.component?.key)
      ) {
        const dataFound = e.data
        const dataPath = dataFound['well4DataGrid2']

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataPath.every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return value === false
            }

            return isValidValue(value)
          })
        )

        const searchCheckboxParent = e.changed.instance.element.parentNode
        const enableAddRowButton = searchCheckboxParent
          .closest('table')
          .querySelector('.formio-button-add-row')

        if (allValuesPresent === true) {
          enableAddRowButton && (enableAddRowButton.disabled = false)
        } else {
          enableAddRowButton && (enableAddRowButton.disabled = true)
        }
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value === 'SINGLE'
      ) {
        handleCivilStatusRequiredToSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value !== 'SINGLE'
      ) {
        handleCivilStatusRequiredToNOTSingleFunc(e)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(spouseSurname, 'N/A')
        inputChangeHandler(spouseFirstName, 'N/A')
        inputChangeHandler(spouseMiddleName, 'N/A')
        inputChangeHandler(spouseOccupation, 'N/A')
        inputChangeHandler(spouseEmployerName, 'N/A')
        inputChangeHandler(spouseEmployerAddress, 'N/A')
        inputChangeHandler(spouseEmployerTelephoneno, 'N/A')

        spouseSuffixparentElement.style.display = 'none'

        e.data.spouseSuffixNaAllowed = 'N/A'

        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseMiddleName.disabled = true
        spouseEmployerTelephoneno.disabled = true

        createSpouseSuffixSpanTemp(spouseSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === false
      ) {
        removeSpouseSuffixSpanTemp()

        inputChangeHandler(spouseSurname, '')
        inputChangeHandler(spouseFirstName, '')
        inputChangeHandler(spouseMiddleName, '')
        inputChangeHandler(spouseOccupation, '')
        inputChangeHandler(spouseEmployerName, '')
        inputChangeHandler(spouseEmployerAddress, '')
        inputChangeHandler(spouseEmployerTelephoneno, '')

        spouseSuffixparentElement.style = undefined

        e.data.spouseSuffixNaAllowed = ''

        spouseSurname.disabled = false
        spouseFirstName.disabled = false
        spouseMiddleName.disabled = false
        spouseOccupation.disabled = false
        spouseEmployerName.disabled = false
        spouseEmployerAddress.disabled = false
        spouseMiddleName.disabled = false
        spouseEmployerTelephoneno.disabled = false
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(fatherSurnameNaAllowed, 'N/A')
        inputChangeHandler(fatherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(fatherMiddlenameNaAllowed, 'N/A')

        e.data.fatherSuffixNaAllowed = 'N/A'

        fatherSurnameNaAllowed.disabled = true
        fatherFirstnameNaAllowed.disabled = true
        fatherMiddlenameNaAllowed.disabled = true

        fatherSuffixparentElement.style.display = 'none'
        fatherSuffixparentElement.style.visibility = 'hidden'

        createFatherSuffixSpanTemp(fatherSuffixparentElement)
      } else if (
        e?.changed?.component?.key === 'markFatherAsNA' &&
        e?.changed?.value === false
      ) {
        removeFatherSuffixSpanTemp()

        inputChangeHandler(fatherSurnameNaAllowed, '')
        inputChangeHandler(fatherFirstnameNaAllowed, '')
        inputChangeHandler(fatherMiddlenameNaAllowed, '')

        fatherSuffixparentElement.style = undefined

        e.data.fatherSuffixNaAllowed = null

        fatherSurnameNaAllowed.disabled = false
        fatherFirstnameNaAllowed.disabled = false
        fatherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(motherSurnameNaAllowed, 'N/A')
        inputChangeHandler(motherFirstnameNaAllowed, 'N/A')
        inputChangeHandler(motherMiddlenameNaAllowed, 'N/A')

        motherSurnameNaAllowed.disabled = true
        motherFirstnameNaAllowed.disabled = true
        motherMiddlenameNaAllowed.disabled = true
      } else if (
        e?.changed?.component?.key === 'markMotherAsNA' &&
        e?.changed?.value === false
      ) {
        inputChangeHandler(motherSurnameNaAllowed, '')
        inputChangeHandler(motherFirstnameNaAllowed, '')
        inputChangeHandler(motherMiddlenameNaAllowed, '')

        motherSurnameNaAllowed.disabled = false
        motherFirstnameNaAllowed.disabled = false
        motherMiddlenameNaAllowed.disabled = false
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('elementaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid2'][index]

        // Set all values to "N/A" except elementaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'elementaryAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid2 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid2'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'elementaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp(e.changed.instance.element.closest('tr'))
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-elementaryBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('elementaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid2'][index]

        // Set all values to "N/A" except elementaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'elementaryAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid2 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid2'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'elementaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        createElemSpanTemp(nonTextInputs, 'BASIC EDUCATION/DEGREE/COURSE')
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('secondaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid'][index]

        // Set all values to "N/A" except secondaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'secondaryAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'secondaryAsNA' &&
        e?.changed?.value === false
      ) {
        removeElemSpanTemp(e.changed.instance.element.closest('tr'))
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-secondaryBasicEducation'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('secondaryAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid'][index]

        // Set all values to "N/A" except secondaryAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'secondaryAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'secondaryAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('vocationalAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Levelvocationaltradecourse'][index]

        // Set all values to "N/A" except vocationalAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'vocationalAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Levelvocationaltradecourse table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Levelvocationaltradecourse'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'vocationalAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-vocationalBasicEducationNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('vocationalAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Levelvocationaltradecourse'][index]

        // Set all values to "N/A" except vocationalAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'vocationalAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Levelvocationaltradecourse table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Levelvocationaltradecourse'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'vocationalAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        createElemSpanTemp(
          nonTextInputs,
          'BASIC EDUCATION/DEGREE/COURSE (Write in full)'
        )
        nonTextInputs.style.display = 'none'

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('collegeAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid3'][index]

        // Set all values to "N/A" except collegeAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'collegeAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid3 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid3'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'collegeAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-collegeCourseNaAllowed'
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('collegeAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4DataGrid3'][index]

        // Set all values to "N/A" except collegeAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'collegeAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4DataGrid3 table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound['well4DataGrid3'].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'collegeAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === true
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed'
        )

        textInputs.forEach((input) => {
          input.value = 'N/A'
          input.disabled = true
        })

        const path = e.changed.instance.path.replace('gradStudiesAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Well5Levelgraduatestudies'][index]

        // Set all values to "N/A" except gradStudiesAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'gradStudiesAsNA') {
            dataPath[key] = 'N/A'
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Well5Levelgraduatestudies table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Well5Levelgraduatestudies'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (
        e?.changed?.component?.key === 'gradStudiesAsNA' &&
        e?.changed?.value === false
      ) {
        const searchCheckboxParent = document.getElementById(
          e.changed.instance.id
        ).parentNode

        const textInputs = searchCheckboxParent.querySelectorAll(
          'div input[type="text"]:not([type="radio"]):not([type="checkbox"]):not([type="select"])'
        )

        const nonTextInputs = searchCheckboxParent.querySelector(
          '.formio-component-graduateCourseNaAllowed '
        )
        nonTextInputs.style.display = ''

        textInputs.forEach((input) => {
          input.value = ''
          input.disabled = false
        })

        const path = e.changed.instance.path.replace('gradStudiesAsNA', '')
        const dataFound = e.data
        const pathParts = path.match(/^([a-zA-Z_]\w*)\[(\d+)\](?:$|\.)/)
        const index = parseInt(pathParts[2], 10)
        const dataPath = dataFound['well4Well5Levelgraduatestudies'][index]

        // Set all values to "N/A" except gradStudiesAsNA
        Object.keys(dataPath).forEach((key) => {
          if (key !== 'gradStudiesAsNA') {
            dataPath[key] = ''
          }
        })

        e.data = { ...e.data, ...dataFound }

        const foundTable = document.querySelector(
          '.formio-component-well4Well5Levelgraduatestudies table'
        )
        const disableAddRowButton = foundTable.querySelector(
          '.formio-button-add-row'
        )

        const isValidValue = (value) =>
          value !== '' && value !== null && value !== undefined

        const allValuesPresent = dataFound[
          'well4Well5Levelgraduatestudies'
        ].every((obj) =>
          Object.entries(obj).every(([key, value]) => {
            if (key === 'gradStudiesAsNA') {
              return (
                value !== true &&
                value !== null &&
                value !== undefined &&
                value !== ''
              )
            }
            return isValidValue(value)
          })
        )

        if (!allValuesPresent) {
          disableAddRowButton.disabled = true
        }
      } else if (e?.isValid) {
        setHoldData(e.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeProvinces = (value) => {
    const selectedArea = isProvincePerm
      ? provinceDataPerm[value]
      : provinceData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isProvincePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentProvinceRequired: selectedArea.name.toUpperCase(),
        permanentCityMunicipalityRequired: '',
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialProvinceRequired: selectedArea.name.toUpperCase(),
        residentialCityMunicipalityRequired: '',
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenProvince(false)

    // Check if the selected area is a region or a province
    if (selectedArea.geographic_level === 'Reg') {
      // If it's a region (like NCR), load cities directly
      loadAndTransformCityData(value)
    } else {
      // If it's a province, load municipalities and cities
      loadAndTransformMunicipalityData(value)
    }
  }

  const handleChangeCities = (value) => {
    const selectedArea = isCityPerm
      ? selectedCityDataPerm[value]
      : selectedCityData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isCityPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenCity(false)

    loadAndTransformBarangayData(value)
  }

  const handleChangeBarangay = (value) => {
    const selectedArea = isBarangayPerm
      ? allAreasDataPerm[value]
      : allAreasData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }
    if (isBarangayPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    }

    setIsModalOpenBrgy(false)
  }

  const handleChangeZipcode = (selectedOption) => {
    const { value } = JSON.parse(selectedOption.value)
    if (isZipcodePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentZipcodeNaAllowed: value,
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialZipcodeNaAllowed: value,
      }))
    }
  }

  const zipcodeList = placeData.map((place, index) => ({
    value: place.post_code,
    label: `${place.municipality}, ${place.location} (${place.post_code})`,
    key: `${place.post_code}-${place.municipality}-${index}`,
    uniqueId: `${place.post_code}-${index}`,
  }))

  return (
    <>
      {isError ? (
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Col>
            <p>
              <em>
                We're experiencing technical difficulties. Our team has been
                notified and is working on the issue. Please try again in a few
                minutes. We apologize for the inconvenience.
              </em>
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <div className='pds-answer-form'>
            <Row className='mt-4' justify='center'>
              <span>
                <em>
                  <b>*** Encoder Page ***</b>
                </em>
              </span>
            </Row>
            <PDSGuide className='mb-3' />
            <PDSVideo setIsOpenPDSCacheMessage={(e) => console.log(e)} />
            <Card>
              <UploadPds
                exportData={handleExportData}
                exportFrom={'PDSEncode'}
              />
              {isFinishedScan ? (
                <Form
                  onChange={(e) => handleChangeForm(e)}
                  onSubmit={(e) => showModal(e)}
                  submission={{ data: holdData }}
                  onRender={handlePrefill}
                  src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
                  onCustomEvent={(e) => handleSignType(e.type)}
                />
              ) : null}
              <BackTop duration={200}>
                <div className='pds-backtop-div'>BACK TO TOP</div>
              </BackTop>
            </Card>
          </div>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet'
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={[]}
            destroyOnClose={true}
          >
            <div style={{ textAlign: 'center' }}>
              <Button
                className={'me-4'}
                size='large'
                style={{ backgroundColor: '#635380' }}
                key='draft'
                ghost
                onClick={() => handleSave(false)}
                disabled={isDisabledAfterSave}
              >
                Save as draft
              </Button>
              <Button
                size='large'
                style={{ backgroundColor: '#87C38F' }}
                key='final'
                ghost
                onClick={() => handleSave(true)}
                disabled={isDisabledAfterSave}
              >
                Save as final
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Right Thumbmark) Agreement'
            visible={isModalOpenThumbmark}
            onCancel={() => setIsModalOpenThumbmark(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefThumb}
                style={{ display: 'none' }}
                onChange={handleFileChangeThumb}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefThumb.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (e-Signature) Agreement'
            visible={isModalOpenESign}
            onCancel={() => setIsModalOpenESign(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefESign}
                style={{ display: 'none' }}
                onChange={handleFileChangeESign}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefESign.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Passport Photo) Agreement'
            visible={isModalOpenPassportPhoto}
            onCancel={() => setIsModalOpenPassportPhoto(false)}
            footer={[]}
            destroyOnClose
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefPassportPhoto}
                style={{ display: 'none' }}
                onChange={handleFileChangePassportPhoto}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefPassportPhoto.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Province'
            visible={isModalOpenProvince}
            onCancel={() => setIsModalOpenProvince(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a province'
                optionFilterProp='children'
                onChange={handleChangeProvinces}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={regionGroupList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose City or Municipality'
            visible={isModalOpenCity}
            onCancel={() => setIsModalOpenCity(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a city or municipality'
                optionFilterProp='children'
                onChange={handleChangeCities}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isCityPerm ? municipalityListPerm : municipalityList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Barangay'
            visible={isModalOpenBrgy}
            onCancel={() => setIsModalOpenBrgy(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a barangay'
                optionFilterProp='children'
                onChange={handleChangeBarangay}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isBarangayPerm ? barangayListPerm : barangayList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Zip Code'
            visible={isModalOpenZipcode}
            onCancel={() => setIsModalOpenZipcode(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a place'
                optionFilterProp='children'
                onChange={handleChangeZipcode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
              >
                {zipcodeList.map((item) => (
                  <Option
                    key={item.key}
                    value={JSON.stringify({
                      value: item.value,
                      label: item.label,
                    })}
                    className='option-item'
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
          <Modal
            title={null}
            open={false}
            width={'50vw'}
            footer={null}
            onCancel={() => console.log(false)}
            destroyOnClose={true}
          >
            <Title level={4}>Important: Auto-Save Information</Title>
            <List
              size='small'
              dataSource={[
                <Text key='info01'>
                  Your progress is automatically saved every few seconds to
                  prevent data loss in case of unexpected interruptions.
                </Text>,
                <Text key='info02' strong>
                  This is only a temporary save and will be deleted when you log
                  out.
                </Text>,
                <Text key='info03'>
                  To permanently save your work, use the 'Save as draft' or
                  'Submit as Final' options.
                </Text>,
                <Text key='info04'>
                  Do not rely on auto-save for long-term storage of your
                  information.
                </Text>,
                <Text key='info05'>
                  Always save your work properly before logging out to avoid
                  losing your progress.
                </Text>,
              ]}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Modal>
        </>
      )}
    </>
  )
}

PDSEncode.propTypes = {
  employeeToBeEncoded: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assignedDept: PropTypes.string.isRequired,
    division: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mname: PropTypes.string,
    suffix: PropTypes.string,
    gender: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  afterSubmit: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}
