import React, { useState } from 'react'

import { Spin, Typography, message } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { UserbyUsername } from '../../api/queries.js'
import NextPageButton from './EncoderPage/NextPageButton'
import ResultsDisplay from './EncoderPage/ResultsDisplay'
import SearchForm from './EncoderPage/SearchForm'
import './pds.css'

const { Title } = Typography

/**
 * MainEncoderPage component that allows searching for a user by username.
 *
 * This component maintains the state of the user and loading status.
 * It provides a search form for user input and displays the results.
 *
 * @component
 * @example
 * return (
 *   <MainEncoderPage />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 */
const MainEncoderPage = ({ nextPage, employeeToBeEncoded, encoderData }) => {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const emailSchema = Yup.string().email()
  /**
   * Handles the search for a user by their sanitized email.
   * Sets the loading state while the search is in progress.
   * If a user is found, sets the user state with the user data.
   * If no user is found, sets the user state to null and displays an info message.
   * If an error occurs during the search, sets the user state to null and displays an error message.
   * Finally, sets the loading state to false.
   *
   * @param {string} sanitisedEmail - The sanitized email of the user to search for.
   * @returns {Promise<void>} - A promise that resolves when the search is complete.
   */
  const handleSearch = async (sanitisedEmail) => {
    setIsLoading(true)
    try {
      if (await emailSchema.isValid(sanitisedEmail)) {
        const result = await API.graphql(
          graphqlOperation(UserbyUsername, {
            username: sanitisedEmail,
          })
        )

        if (result.data.UserbyUsername.items.length !== 0) {
          const filterByAssignedDept = result.data.UserbyUsername.items.filter(
            (item) => item.assignedDept === encoderData.assignedDept
          )

          if (filterByAssignedDept?.length) {
            setUser(filterByAssignedDept[0])
          } else {
            setUser(null)
            message.info('No user found in your assigned department.')
          }
        } else {
          setUser(null)
          message.info('No user found with this username.')
        }
      } else {
        message.error('Please enter a valid username.')
        setUser(null)
      }
    } catch (err) {
      message.error(
        'An error occurred while searching for the user. Please try again.'
      )
      setUser(null)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='pds-encoder-mainDiv'>
      <Title level={2} className='pds-encoder-mainDiv-title'>
        User Search
      </Title>
      <SearchForm onSearch={handleSearch} />
      {isLoading && (
        <Spin size='large' className='pds-encoder-mainDiv-spinner' />
      )}
      <ResultsDisplay user={user} />
      <NextPageButton
        employeeToBeEncoded={employeeToBeEncoded}
        nextPage={nextPage}
        isVisible={!!user}
        user={user}
      />
    </div>
  )
}

export default MainEncoderPage

MainEncoderPage.propTypes = {
  nextPage: PropTypes.func.isRequired,
  employeeToBeEncoded: PropTypes.func.isRequired,
  encoderData: PropTypes.object.isRequired,
}
