/**
 * Title: SubmittedPDSFormsHR.js
 * Description: This is a file that contains the components for the Submitted Personal Data Sheet Forms for the HR Admin.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/SubmittedPDSFormsHR.js
 **/

/*
 *Changes made:
 * - 2024.12.09  | Harry Lagunsad | Make breadcrumbs bold text then indicate that the table the user is seeing is for HR Approver
 */
import { useEffect, useState } from 'react'

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import {
  CommentOutlined,
  FilePdfOutlined,
  FundViewOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Form } from '@formio/react'

import { UserbyUsername, listPersonalDataSheets } from '../../api/queries'
import PDFModal from './PDFModal'
import RenderRemarksContent from './PDSRemarksContentComponent'
import './pds.css'

const { TextArea } = Input

export default function SubmittedPDSFormsHR() {
  const [userFound, setUserFound] = useState(null)
  const [userDataSheets, setUserDataSheets] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [holdData, setHoldData] = useState({})
  const [formIsFinal, setFormIsFinal] = useState(false)

  const [isModalOpenEditApprover, setIsModalOpenEditApprover] = useState(false)
  const [isModalOpenEditHRApprover, setIsModalOpenEditHRApprover] =
    useState(false)
  const [foundRecord, setFoundRecord] = useState(null)
  const [isOpenPDF, setIsOpenPDF] = useState(false)
  const [recordData, setRecordData] = useState({})
  const [prevCommentsOfApprover, setPrevCommentsOfApprover] = useState([])
  const [prevCommentsOfHRApprover, setPrevCommentsOfHRApprover] = useState([])

  /**
   *@function uuidv4
   *@description Generates a unique identifier used for mapping keys.
   *@param {Object} None no parameters needed.
   *@returns {String} a unique identifier.
   */
  function uuidv4() {
    try {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)

      return (
        array[0].toString(16).padStart(8, '0') +
        '-' +
        array[1].toString(16).padStart(4, '0') +
        '-4' +
        array[2].toString(12).padStart(3, '0') +
        '-y' +
        array[3].toString(12).padStart(3, '0') +
        '-' +
        array[4].toString(16).padStart(12, '0')
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *@function fetchUserDataSheets
   *@description Fetches the user's data sheets and filters the data sheets that are final and approved.
   *@param {Object} None no parameters needed.
   *@returns {Object} the data sheets that are final and approved.
   */
  const fetchUserDataSheets = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const { username } = user
      const userDetails = await API.graphql(
        graphqlOperation(UserbyUsername, { username })
      )

      const foundDetails = userDetails.data.UserbyUsername.items[0]

      setUserFound(foundDetails)

      let nextToken = null
      let userDataSheets = []

      do {
        const userDataSheetsData = await API.graphql(
          graphqlOperation(listPersonalDataSheets, { nextToken })
        )

        userDataSheets.push(
          ...userDataSheetsData.data.listPersonalDataSheets.items
        )
        nextToken = userDataSheetsData.data.listPersonalDataSheets.nextToken
      } while (nextToken)
      userDataSheets.map((item, index) => {
        try {
          const parsedData = JSON.parse(item.data)
          if (parsedData.data.skillsAndHobies) {
            parsedData.data.skillsAndHobbiesGrid =
              parsedData.data.skillsAndHobies.map((hobby) => ({
                skillsAndHobbies: hobby,
              }))
            delete parsedData.data.skillsAndHobies
          }
          if (parsedData.data.orgMembership) {
            parsedData.data.orgMembershipGrid =
              parsedData.data.orgMembership.map((org) => ({
                orgMembership: org,
              }))
            delete parsedData.data.orgMembership
          }
          if (parsedData.data.nonAcademicdistinctionsrecognition) {
            parsedData.data.nonAcademicdistinctionsrecognitionGrid =
              parsedData.data.nonAcademicdistinctionsrecognition.map(
                (nonAcad) => ({
                  nonAcademicdistinctionsrecognition: nonAcad,
                })
              )
            delete parsedData.data.nonAcademicdistinctionsrecognition
          }

          item.data = JSON.stringify(parsedData)
        } catch (error) {
          console.error(
            `Error parsing data for user data sheet ${index + 1}:`,
            error
          )
        }
      })

      setUserDataSheets(
        userDataSheets.filter((item) => {
          if (item.isFinal === true && item.isApproved === 'Approved') {
            return item
          }
        })
      )
    } catch (err) {
      console.log('error fetching user data sheets:', err)
      notification.error({
        message: 'Error',
        description:
          'Failed to fetch user data sheets, please contact System Admin for help.',
      })
    }
  }

  useEffect(() => {
    fetchUserDataSheets()
  }, [])

  const STATUS_CONFIG = {
    Approved: { color: 'green' },
    Returned: { color: 'red' },
    'Pending Approval': {
      color: 'yellow',
      label: 'Pending your approval',
    },
  }

  const departmentFilter = Array.from(
    new Set(
      userDataSheets.reduce((acc, item) => {
        acc.add(item.ownedBy.department)
        return acc
      }, new Set())
    )
  ).map((department) => ({
    text: department,
    value: department,
  }))

  const columns = [
    {
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '5%',
    },
    {
      title: 'Submitted by',
      dataIndex: 'submittedBy', // corrected typo
      key: 'submittedBy',
      render: (text, record) => {
        return (
          <span>
            <b>{`${record.ownedBy.name} ${
              record.ownedBy.mname ? record.ownedBy.mname : ''
            } ${record.ownedBy.lname}`}</b>
          </span>
        )
      },
      width: '13%',
      sorter: (a, b) => a.ownedBy.name.localeCompare(b.ownedBy.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search 'Submitted by'`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => confirm()}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      // Function to determine if a row matches the search text
      onFilter: (value, record) => {
        return (
          record.ownedBy.name.toLowerCase().includes(value.toLowerCase()) ||
          (record.ownedBy.mname &&
            record.ownedBy.mname.toLowerCase().includes(value.toLowerCase())) ||
          record.ownedBy.lname.toLowerCase().includes(value.toLowerCase())
        )
      },
      // Optional: Custom filter icon
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    },
    {
      title: 'Assigned Department',
      dataIndex: 'department',
      key: 'department',
      render: (text, record) => {
        return (
          <span>
            <b>{record.ownedBy.assignedDept}</b>
          </span>
        )
      },
      filters: departmentFilter,
      onFilter: (value, record) => record.ownedBy.assignedDept === value,
      sorter: (a, b) =>
        a.ownedBy.assignedDept.localeCompare(b.ownedBy.assignedDept),
      width: '13%',
    },
    {
      title: 'Date Personal Data Sheet Form Approved (by Department)',
      dataIndex: 'date',
      key: 'locale',
      render: (text, record) => (
        <Tag color='geekblue'>
          {moment(record.updatedAt).format('MMMM D, YYYY h:mm A')}
        </Tag>
      ),
      width: '15%',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
    {
      title: 'Status from Department Admin',
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      render: (text, record) => (
        <div key={record.id}>
          <Row justify='center'>
            {STATUS_CONFIG[record.isApproved] && (
              <Tag
                className='px-3'
                color={STATUS_CONFIG[record.isApproved].color}
              >
                {STATUS_CONFIG[record.isApproved].label || record.isApproved}
              </Tag>
            )}
          </Row>
          <Row justify='center'>
            <Button
              onClick={() => handleViewRemarks(record)}
              type='text'
              icon={<CommentOutlined />}
            >
              Remarks
            </Button>
          </Row>
        </div>
      ),
      sorter: (a, b) => {
        const order = { Approved: 1, 'Pending Approval': 2, Returned: 3 }
        return order[a.isApproved] - order[b.isApproved]
      },
    },
    {
      title: 'Status from HR Admin',
      dataIndex: 'statusHR',
      key: 'statusHR',
      render: (text, record) => (
        <div key={record.id}>
          <Row justify='center'>
            {record.isHRApproved === 'Approved' ? (
              <Tag className='pe-3 ps-3' color='green'>
                Approved{' '}
              </Tag>
            ) : null}
            {record.isHRApproved === 'Returned' ? (
              <Tag className='pe-3 ps-3' color='red'>
                Returned
              </Tag>
            ) : null}
            {record.isHRApproved === 'Pending Approval' ? (
              <Tag className='pe-3 ps-3' color='yellow'>
                Pending HR approval
              </Tag>
            ) : null}
          </Row>

          <Row justify='center'>
            <Button
              onClick={() => handleViewHRRemarks(record)}
              type='text'
              icon={<CommentOutlined />}
            >
              Remarks
            </Button>
          </Row>
        </div>
      ),
      width: '12%',
      sorter: (a, b) => {
        const order = { Approved: 1, 'Pending Approval': 2, Returned: 3 }
        return order[a.isHRApproved] - order[b.isHRApproved]
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Row justify='space-around'>
          <Col>
            <Button
              danger
              size='large'
              onClick={() => handleViewForm(record, record.isFinal)}
              icon={<FundViewOutlined />}
            >
              View
            </Button>
          </Col>
          {record.isFinal ? (
            <Col>
              <Button
                className='pdf-preview-button'
                size='large'
                onClick={() => handlePDFSave(record)}
                icon={<FilePdfOutlined />}
              >
                PDF Preview
              </Button>
            </Col>
          ) : null}
        </Row>
      ),
      width: '15%',
    },
  ]

  /**
   *@function handleViewRemarks
   *@description Opens a modal that displays the remarks of the department admin.
   *@param {Object} e the data of the data sheet.
   *@returns {Object} the remarks of the department admin.
   */
  const handleViewRemarks = (e) => {
    try {
      setIsModalOpenEditApprover(true)

      if (!e?.commentsOfApprover) {
        setPrevCommentsOfApprover([])
        return
      }

      let parsedComments
      try {
        parsedComments = JSON.parse(e.commentsOfApprover)
      } catch (parseError) {
        console.log(
          'Failed to parse department approver comments JSON:',
          parseError
        )
        parsedComments = []
      }

      setPrevCommentsOfApprover(parsedComments)
    } catch (err) {
      console.log('Error handling department approver remarks:', err)
      notification.error({
        message: 'Error',
        description:
          'Failed to load department approver remarks, please contact System Admin for help.',
      })
    }
  }

  /**
   *@function handleViewHRRemarks
   *@description Opens a modal that displays the remarks of the HR admin.
   *@param {Object} e the data of the data sheet.
   *@returns {Object} the remarks of the HR admin.
   */
  const handleViewHRRemarks = (e) => {
    console.log(e.commentsOfHRApprover)
    try {
      setIsModalOpenEditHRApprover(true)

      if (!e?.commentsOfHRApprover) {
        setPrevCommentsOfHRApprover([])
        return
      }

      let parsedComments
      try {
        parsedComments = JSON.parse(e.commentsOfHRApprover)
      } catch (parseError) {
        console.log(
          'Failed to parse department approver comments JSON:',
          parseError
        )
        parsedComments = []
      }

      setPrevCommentsOfHRApprover(parsedComments)
    } catch (err) {
      console.log('Error handling HR approver remarks:', err)
      notification.error({
        message: 'Error',
        description:
          'Failed to load HR approver remarks, please contact System Admin for help.',
      })
    }
  }

  const handlePDFSave = (record) => {
    try {
      setIsOpenPDF(true)
      setRecordData(record)
    } catch (err) {
      console.log(err)
    }
  }
  /**
   *@function handleViewForm
   *@description Opens a modal that displays the Personal Data Sheet.
   *@param {Object} e the data of the data sheet.
   *@returns {Object} the Personal Data Sheet.
   */
  const handleViewForm = (e, isFinal) => {
    try {
      setIsModalOpen(true)
      setFormIsFinal(isFinal)
      setFoundRecord(e)
      setHoldData(JSON.parse(e.data).data)
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Error',
        description:
          'Failed to display Personal Data Sheet, please contact System Admin for help.',
      })
    }
  }

  /**
   *@function handleImages
   *@description Displays the images of the Personal Data Sheet.
   *@param {Object} None no parameters needed.
   *@returns {Object} the images of the Personal Data Sheet.
   */
  const handleImages = () => {
    try {
      if (foundRecord?.eSignatureLoc) {
        const eSignDiv = document.getElementsByClassName(
          'electronic-signature'
        )[0]

        const imgElementESign = document.createElement('img')
        imgElementESign.src = foundRecord.eSignatureLoc

        eSignDiv.appendChild(imgElementESign)
      }

      if (foundRecord?.thumbmarkSignLoc) {
        const eThumbDiv = document.getElementsByClassName(
          'electronic-thumbmark'
        )[0]

        const imgElementThumb = document.createElement('img')
        imgElementThumb.src = foundRecord.thumbmarkSignLoc

        eThumbDiv.appendChild(imgElementThumb)
      }

      if (foundRecord?.passportPhotoLoc) {
        const passportDiv = document.getElementsByClassName('passport-photo')[0]

        const imgElementPassport = document.createElement('img')
        imgElementPassport.src = foundRecord.passportPhotoLoc

        passportDiv.appendChild(imgElementPassport)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleSubmitButton
   *@description Handles the showing of the Submit button and the disabling of the fields of the PDS Form if its already submitted as Final.
   *@param {Object} None no parameters needed.
   */
  const handleSubmitButton = () => {
    if (formIsFinal) {
      const submitDiv = document.getElementsByClassName(
        'formio-component-submit'
      )

      submitDiv[0].style.display = 'none'
      setTimeout(() => {
        document
          .querySelectorAll('.submitted-pds-page-modal button')
          .forEach((i) => {
            i.parentNode.remove()
          })
        document
          .querySelectorAll(
            '.submitted-pds-page-modal input, .submitted-pds-page-modal textarea'
          )
          .forEach((i) => {
            i.disabled = true
          })
      }, 1000)
      setTimeout(() => {
        document
          .querySelectorAll('.submitted-pds-page-modal select')
          .forEach((i) => {
            if (i && i.getAttribute('name') === 'data[bloodtype]') {
              let selectedValue = i.options[0].value
              let inputElement = document.createElement('input')
              inputElement.value = selectedValue.toUpperCase()
              inputElement.setAttribute('aria-required', 'false')
              inputElement.setAttribute('spellcheck', 'true')
              inputElement.setAttribute('lang', 'en')
              inputElement.setAttribute('class', 'form-control')
              inputElement.setAttribute('type', 'text')
              inputElement.setAttribute('ref', 'input')
              inputElement.setAttribute('aria-invalid', 'false')
              inputElement.setAttribute('disabled', '')
              i.parentNode.insertAdjacentElement('afterend', inputElement)
              i.parentNode.remove()
            }
          })
      }, 1000)
      setTimeout(() => {
        handleImages()
      }, 1000)
    }
  }

  return (
    <div className='site-layout-background submitted-pds-page'>
      <Card>
        <Breadcrumb className={'font-Mont pds-breadcrumb'}>
          <Breadcrumb.Item>
            <b>Home</b>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <b>View Submitted Personal Data Sheet (HR Approver)</b>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className={'mb-4'}>
          <Col span={24}>
            <div className='compTable' style={{ marginTop: '30px' }}>
              <Table
                className={'font-Mont'}
                rowKey='id'
                bordered
                dataSource={userDataSheets}
                columns={columns}
              />
            </div>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          width={'75vw'}
          title='Employee Personal Data Sheet'
          open={isModalOpen}
          onCancel={() => {
            setPrevCommentsOfApprover([])
            setPrevCommentsOfHRApprover([])
            setIsModalOpen(false)
          }}
          footer={[]}
          destroyOnClose={true}
        >
          {formIsFinal ? (
            <div className='submitted-pds-page-modal'>
              <Form
                onRender={handleSubmitButton}
                submission={{ data: holdData }}
                src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
              />
            </div>
          ) : (
            <Form
              submission={{ data: holdData }}
              src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
            />
          )}
        </Modal>

        <Modal
          maskClosable={false}
          title='Remarks of Department Admin'
          open={isModalOpenEditApprover}
          onCancel={() => setIsModalOpenEditApprover(false)}
          footer={[]}
          destroyOnClose={true}
          width={'50vw'}
        >
          <Card>
            {prevCommentsOfApprover?.length ? (
              prevCommentsOfApprover.map((i) => (
                <div key={uuidv4()} className='mb-4'>
                  <p>
                    <b>Date: </b>
                    {i.dateOfRemarks}
                  </p>
                  <div>
                    <b>Remarks: </b>
                    <RenderRemarksContent remarks={i.commentsOfApprover} />
                  </div>
                </div>
              ))
            ) : (
              <span>no remarks</span>
            )}
          </Card>
        </Modal>

        <Modal
          maskClosable={false}
          title='Remarks of HR Admin'
          open={isModalOpenEditHRApprover}
          onCancel={() => setIsModalOpenEditHRApprover(false)}
          footer={[]}
          destroyOnClose={true}
          width={'50vw'}
        >
          <Card>
            {prevCommentsOfHRApprover?.length ? (
              prevCommentsOfHRApprover.map((i) => (
                <div key={uuidv4()} className='mb-4'>
                  <p>
                    <b>Date: </b>
                    {i.dateOfRemarks}
                  </p>
                  <div>
                    <b>Remarks: </b>
                    <RenderRemarksContent remarks={i.commentsOfHRApprover} />
                  </div>
                </div>
              ))
            ) : (
              <span>no remarks</span>
            )}
          </Card>
        </Modal>
      </Card>
      <PDFModal
        record={recordData}
        userRole={userFound?.pdsRole}
        isOpenPDF={isOpenPDF}
        setIsOpenPDF={(e) => setIsOpenPDF(e)}
        userFound={userFound}
        fetchUserData={fetchUserDataSheets}
      />
    </div>
  )
}
