/**
 * Title: home.js
 * Description: This file contains the home page logic. It includes a function that checks if the user has an employee ID number. If the employee ID number is not present, a modal will be displayed to prompt the user to add the employee ID number.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Homepage/home.js
 *
 * Changes made:
 * - 11/08/2024 | Justine Villanueva | Added a function to check if the user has an employee ID number. If not, a modal is displayed to
 *   prompt the user to add their employee ID number.
 * - 11/08/2024 | Harry Lagunsad | Added a function to check if the user has a valid appointment status. If the status is missing or invalid,
 *   a warning message is displayed.
 */

/*
 *Changes made:
 * - 2024.12.09  | Harry Lagunsad | Show the PDS employee view table and answer PDS menu item for PDS role HR approver
 *   and department approver
 * - 2024.10.10  | Harry Lagunsad | Added handling for content to be displayed that is stated in the localstorage
 */
import React, { useState } from 'react'

import {
  Alert,
  Button,
  Card,
  Collapse,
  Image,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Popover,
  Row,
  notification,
} from 'antd'
import { API, graphqlOperation } from 'aws-amplify'

import { UserOutlined } from '@ant-design/icons'

import AuthContext from '../../../ContextProvider/ContextProvider'
import { updateUser } from '../../../api/mutations.js'
import MainEncoderPage from '../../PDS/MainEncoderPage.js'
import PDSEncode from '../../PDS/PDSEncode.js'
import PDSForm from '../../PDS/PersonalDataSheet.js'
import SubmittedEncodedPDSForms from '../../PDS/SubmittedEncodedPDSForms.js'
import SubmittedPDSForms from '../../PDS/SubmittedPDSForms.js'
import SubmittedPDSFormsDept from '../../PDS/SubmittedPDSFormsDept.js'
import SubmittedPDSFormsHR from '../../PDS/SubmittedPDSFormsHR.js'
import CSCOnlineTrainingJan21 from '../CSCOnlineTrainingJan21.js'
import CSCOnlineTrainingNov20 from '../CSCOnlineTrainingNov20'
import CSCOnlineTrainingSept8 from '../CSCOnlineTrainingSept8'
import CSCOnlineTrainingSept15 from '../CSCOnlineTrainingSept15'
import CSCOnlineTrainingSept8Batch2 from '../csc-business-writing-batch2/csc-business-writing-batch2.js'
import CscOnlineVideosAcknowledgement from '../cscOnlineVideosAcknowledgement'
import OrientVideosAcknowledgement from '../orientVideosAcknowledgement'
import StrategicPerformanceManagementSystem from '../strategicPerformanceManagementSystem'
import StrategicPerformanceManagementSystemAcknowledgement from '../strategicPerformanceManagementSystemAcknowledgement'
import VolunteersCorner from '../volunteersCorner'
import { FormByUserId } from './../../../api/queries'
import Employee from './../../Employee/employee'
import FormEmployee from './../../Employee/formEmployee'
import CreateForm from './../../Form/createForm'
import CreateForm2 from './../../Form/createForm2'
import SurveyFeedback from './../../Form/surveyFeedback'
import UploadedILDP from './../../Form/uploadedILDP'
import ViewForm from './../../Form/viewForm'
import LoginHome from './../../Login/LoginHome'
import ViewProfile from './../../Profile/profile'
import ManageEtna from './../../Users/manageEtna'
import ManageEtnaFeedBack from './../../Users/manageEtnaFeedback'
import ManageFeedback from './../../Users/manageFeedback'
import ManageILDP from './../../Users/manageILDP'
import ManageUsers from './../../Users/manageUser'
import Users from './../../Users/pendingUser'
import EmpAcknowledgement from './..//empAcknowledgement.js'
import AboutUs from './../About Us/aboutUs.js'
import CSCOnlineMaterials from './../CSCOnlineMaterials.js'
import CSCOnlineVideos from './../CSCOnlineVideos.js'
import CSCOnlineVideosAccessList from './../CSCOnlineVideosAccessList.js'
import CSCOnlineVideosWeek2 from './../CSCOnlineVideosWeek2.js'
import CSCOnlineVideosWeek3 from './../CSCOnlineVideosWeek3.js'
import ContactUs from './../Contact Us/contactUs.js'
import InstitutionalDev from './../Internship/institutionalDev.js'
import InternLanding from './../Internship/internLanding.js'
import InternNewsLetter from './../Internship/internNewsLetter.js'
import InternRequest from './../Internship/internRequest.js'
import MyTrainings from './../MyTrainings.js'
import DeptAdminSPMS from './../SPMS/deptAdminSPMS.js'
import FormSPMS from './../SPMS/formSPMS.js'
import SupervisorSPMS from './../SPMS/supervisorSPMS.js'
import Dashboard from './../dashboard'
import EmployeeHandbook from './../employeeHandbook.js'
import FAQs from './../faq.js'
import './../home.css'
import OnlineTrainingPrograms from './../onlineTrainingPrograms.js'
import OrientationVideos from './../orientationVideos.js'
import Recruitment from './../recruitment.js'
import SiteFeedback from './../siteFeedback.js'
import TrainingPrograms from './../trainingPrograms.js'
import TrainingProgramsTester from './../trainingProgramsTester.js'
import TrainingVideos from './../trainingVideos.js'
import UploadAdmin from './../uploadAdmin.js'
import UploadTrainings from './../uploadTrainings.js'
import UserCalendar from './../userCalendar.js'
import LDRefresher from './LDRefresher.js'
import LDRefresherViewLists from './LDRefresherViewLists.js'
import MindscapesModal from './MindscapesModal.js'
import HomeContent from './homeContent.js'
import HomeName from './homeName.js'

const { Header, Content, Footer } = Layout
const { SubMenu } = Menu
const { Panel } = Collapse
const VALID_APPOINTMENT_STATUSES = [
  'PERMANENT',
  'TEMPORARY',
  'SUBSTITUTE',
  'COTERMINOUS',
  'FIXED_TERM',
  'CONTRACTUAL',
  'CASUAL',
]
const avatar =
  'https://png.pngtree.com/png-vector/20200614/ourlarge/pngtree-businessman-user-avatar-character-vector-illustration-png-image_2242909.jpg'

export default class Home extends React.Component {
  static contextType = AuthContext

  constructor() {
    super()
    this.state = {
      employeeToBeEncoded: {},
      openProfile: false,
      content: 'home',
      isHomeModal: false,
      isEmployeeModal: false,
      employeeID: '', // Add state to handle employee ID input
      showApmtStatus: false,
      // isMaintenance: false,
      image: avatar,
      isMindscapesModalOpen: false, // Pdbc8
      birthday: '',
      civilStatus: '',
      userData: null,
    }
  }
  componentDidMount() {
    const contentFound = localStorage.getItem('content')
    if (contentFound) {
      this.setState({ content: contentFound }, () =>
        localStorage.removeItem('content')
      )
    }

    const { userData } = this.context
    this.setState({
      isHomeModal: !userData,
      image: userData?.profilePicture
        ? `https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/userProfilePictures/${userData.id}`
        : avatar,
    })
    this.checkUserData() // Check if employeeIDNumber is missing
    this.checkUserAppointmentStatus()
  }

  checkFinal = async (userData) => {
    API.graphql(graphqlOperation(FormByUserId, { userID: userData.id })).then(
      (res) => {
        let f = res.data.FormByUserId.items

        if (f.length) {
          let arr = f.filter((item) => item.isFinal !== null)

          if (arr.length) {
            this.setState({ isFinal: true })
          } else {
            this.setState({ isFinal: false })
          }
        } else {
          this.setState({ isFinal: false })
        }
      }
    )
  }

  /**
   * @function checkUserAppointmentStatus
   * Checks if the user has a valid appointment status and updates the component state accordingly.
   * Sets showApmtStatus to true if the status is missing or invalid, false otherwise.
   * @throws {Error} Logs any errors encountered during status check
   */
  checkUserAppointmentStatus = () => {
    try {
      const { userData } = this.context

      if (userData) {
        if (
          userData.appointmentStatus?.length > 0 &&
          VALID_APPOINTMENT_STATUSES.includes(
            userData.appointmentStatus.toUpperCase()
          )
        ) {
          this.setState({ showApmtStatus: false })
        } else {
          this.setState({ showApmtStatus: true })
        }
      } else {
        this.setState({ showApmtStatus: false })
      }
    } catch (err) {
      console.log(err)
    }
  }

  // ================== Handle Employee ID Modal ================== //
  checkUserData = () => {
    const { userData } = this.context
    if (
      userData &&
      (!userData.employeeIDNumber || userData.employeeIDNumber.trim() === '')
    ) {
      // Show the modal if employeeIDNumber is not present
      this.setState({
        isEmployeeModal: true,
        employeeID: userData.employeeIDNumber,
      })
    }
  }
  handleCancel = () => {
    this.setState({ isEmployeeModal: false })
  }
  handleIsHomeModal = () => {
    this.setState({
      isHomeModal: false,
    })
  }

  handleMindscapesClick = () => {
    this.setState({ isMindscapesModalOpen: true }) // Pbf2e
    const { userData } = this.context
    this.setState({ userData: userData })
  }

  render() {
    const { userData, updated, setUpdated } = this.context
    const userRole = userData ? userData.role : 'employee'
    const { isEmployeeModal, employeeID, validationError, showApmtStatus } =
      this.state
    const content = (
      <div className='profileContent'>
        <ViewProfile
          openProfile={(e) => this.setState({ openProfile: e })}
          userData={userData}
          refresh={(e) => setUpdated(e)}
          setProfileImage={(image) => this.setState({ image: image })}
        />
        <div style={{ color: 'red' }} onClick={() => this.props.logout()}>
          Logout
        </div>
      </div>
    )

    return (
      <div
        className={
          this.state.content === 'SubmittedPDSForms' ||
          this.state.content === 'PDSForm' ||
          this.state.content === 'PDSForm2'
            ? null
            : 'showcase'
        }
      >
        <div className='contentHome'>
          <Layout className={'site-layout'}>
            <div style={{ width: '100%' }}>
              <header className='pt-3 position-md-static position-sticky overflow-hidden'>
                <div className='row align-items-center overflow-hidden'>
                  <div className='lgu-logo col-12 col-md-2 text-center'>
                    <div className='d-flex flex-column align-items-center pe-md-5'>
                      <img
                        className='qclogo img-fluid'
                        src='./Quezon_City.svg'
                        alt='QC–LGU Seal'
                      />
                    </div>
                  </div>
                  <div className='col-md-8 d-flex justify-content-center align-items-center mb-2 mb-md-0 mt-md-0 mt-3'>
                    <div className='mt-md-0 mt-3 d-md-flex justify-content-center align-items-center mb-2 mb-md-0'>
                      <span
                        className='text-md-end mt-1 mt-md-0 ms-md-4 order-md-last'
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          color: '#B9B9B9',
                        }}
                      >
                        BETA VERSION
                      </span>
                      <div className='me-md-2 ms-0 ms-md-5 order-md-first'>
                        <HomeName />
                      </div>
                    </div>
                  </div>
                  <div
                    className='user-div col-11 col-md-2 text-center order-md-last order-first ms-4 ms-md-0 ps-md-5 overflow-hidden'
                    style={userData === null ? { height: '100px' } : undefined}
                  >
                    <div className='d-flex flex-md-column align-items-md-center justify-content-md-center justify-content-end mt-md-3 overflow-hidden'>
                      {userData && (
                        <>
                          <div className='profile-image-container mb-2 order-md-first order-last'>
                            <Popover
                              content={content}
                              placement='bottomRight'
                              trigger='click'
                              open={this.state.openProfile}
                              onClick={() =>
                                this.setState({
                                  openProfile: !this.state.openProfile,
                                })
                              }
                            >
                              <Image
                                preview={false}
                                className='homeProfile-img img-fluid'
                                alt='Profile'
                                src={this.state.image}
                                fallback='https://png.pngtree.com/png-vector/20200614/ourlarge/pngtree-businessman-user-avatar-character-vector-illustration-png-image_2242909.jpg'
                              />
                            </Popover>
                          </div>
                          <div className='d-none d-md-block'>
                            <span style={{ fontSize: '15px' }}>
                              Hi!
                              {userData && (
                                <span className='ms-2 fw-bold text-uppercase'>
                                  {userData.name}
                                </span>
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </header>

              <Header className={'headerHomeCopy mt-md-3'}>
                <Menu
                  mode='horizontal'
                  // onClick={this.handleClick}
                  // selectedKeys={[this.state.current]}
                  defaultOpenKeys={['sub1']}
                  className={'menuDesign font-Mont'}
                >
                  <Menu.Item
                    key='home1'
                    className={'font-Mont'}
                    onClick={() => this.setState({ content: 'home' })}
                  >
                    Home
                  </Menu.Item>

                  <Menu.Item
                    key='aboutUs1'
                    className={'font-Mont'}
                    onClick={() => this.setState({ content: 'aboutUs' })}
                  >
                    About Us
                  </Menu.Item>

                  <Menu.SubMenu
                    key='Recruitment'
                    className={'font-Mont'}
                    title='Recruitment'
                  >
                    <Menu.Item
                      key='kalibrr1'
                      className={'font-Mont'}
                      onClick={() => this.setState({ content: 'recruitment' })}
                    >
                      Kalibrr
                    </Menu.Item>
                    {userRole.toLowerCase() === 'admin' ? (
                      <Menu.Item
                        key='intersCorner1'
                        className={'font-Mont'}
                        onClick={() => this.setState({ content: 'internship' })}
                      >
                        Intern's Corner
                      </Menu.Item>
                    ) : null}
                  </Menu.SubMenu>

                  <Menu.SubMenu
                    key='onboarding1'
                    className={'font-Mont'}
                    title='Resources'
                  >
                    <Menu.Item
                      key='handbook1'
                      className={'font-Mont'}
                      onClick={() =>
                        this.setState({ content: 'employeeHandbook' })
                      }
                    >
                      Employee HandBook
                    </Menu.Item>
                    <Menu.Item
                      key='orientationVideos1'
                      className={'font-Mont'}
                      onClick={() =>
                        this.setState({ content: 'orientationVideos' })
                      }
                    >
                      Orientation Videos
                    </Menu.Item>
                    {userData && (
                      <Menu.Item
                        key='strategicPerformanceManagementSystem'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({
                            content: 'strategicPerformanceManagementSystem',
                          })
                        }
                      >
                        Learning Video on SPMS (Strategic Performance Management
                        System)
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='refresher'
                      className={'font-Mont'}
                      onClick={() =>
                        this.setState({
                          content: 'refresher',
                        })
                      }
                    >
                      L&D Refresher
                    </Menu.Item>
                    {userRole.toLowerCase() === 'admin' ? (
                      <>
                        <Menu.Item
                          key='handbookAccessList1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'empAcknowledgement' })
                          }
                        >
                          List of Employee Handbook Access
                        </Menu.Item>
                      </>
                    ) : (
                      ''
                    )}
                    {userRole.toLowerCase() === 'admin' ? (
                      <>
                        <Menu.Item
                          key='orientVideosAccessList1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({
                              content: 'orientVideosAcknowledgement',
                            })
                          }
                        >
                          List of Orientation Videos Access
                        </Menu.Item>
                      </>
                    ) : (
                      ''
                    )}
                    {userRole.toLowerCase() === 'admin' && (
                      <>
                        <Menu.Item
                          key='strategicPerformanceManagementSystemAknowledgement'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({
                              content:
                                'strategicPerformanceManagementSystemAknowledgement',
                            })
                          }
                        >
                          List of Strategic Performance Management System Access
                        </Menu.Item>
                      </>
                    )}
                    {userRole.toLowerCase() === 'admin' && (
                      <>
                        <Menu.Item
                          key='LDRefresherViewLists'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({
                              content: 'LDRefresherViewLists',
                            })
                          }
                        >
                          List of L&D Refresher Access
                        </Menu.Item>
                      </>
                    )}
                  </Menu.SubMenu>

                  <Menu.SubMenu
                    key='lds1'
                    className={'font-Mont'}
                    title='Learning and Development (L&D)'
                  >
                    {userData ? (
                      <>
                        <Menu.SubMenu
                          key='forms1'
                          className={'font-Mont'}
                          title='Forms'
                        >
                          <Menu.Item
                            key='supOrAdmin1'
                            className={'font-Mont'}
                            onClick={() =>
                              this.setState({ content: 'employeeForms' })
                            }
                          >
                            {userRole.toLowerCase() === 'admin'
                              ? 'Dashboard'
                              : 'Forms'}
                          </Menu.Item>

                          {
                            // userRole.toLowerCase() === "employee" || userRole.toLowerCase() === "tester"? (
                            //   <>
                            //     <Menu.Item
                            //       key="spms1"
                            //       className={"font-Mont"}
                            //       onClick={() =>
                            //         this.setState({ content: "SPMS" })
                            //       }
                            //     >
                            //       SPMS
                            //     </Menu.Item>
                            //   </>
                            // ) : null
                          }
                          {
                            // userRole.toLowerCase() === "deptadmin" || userRole.toLowerCase() === "admin" ? (
                            //   <>
                            //     <Menu.Item
                            //       key="spms2"
                            //       className={"font-Mont"}
                            //       onClick={() =>
                            //         this.setState({ content: "SPMSDeptAdmin" })
                            //       }
                            //     >
                            //       SPMS
                            //     </Menu.Item>
                            //   </>
                            // ) : null
                          }
                          {
                            // userRole.toLowerCase() === "supervisor" ? (
                            //   <>
                            //     <Menu.Item
                            //       key="spms3"
                            //       className={"font-Mont"}
                            //       onClick={() =>
                            //         this.setState({ content: "SPMSSupervisor" })
                            //       }
                            //     >
                            //       SPMS
                            //     </Menu.Item>
                            //   </>
                            // ) : null
                          }

                          {userRole.toLowerCase() === 'supervisor' ||
                          userRole.toLowerCase() === 'employee' ||
                          userRole.toLowerCase() === 'tester' ? (
                            <Menu.Item
                              key='viewForm1'
                              className={'font-Mont'}
                              onClick={() =>
                                this.setState({ content: 'viewForm' })
                              }
                              // icon={<FormOutlined />}
                            >
                              My Submitted Forms
                            </Menu.Item>
                          ) : null}
                        </Menu.SubMenu>
                        <Menu.Item
                          key='userCalendar1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'userCalendar' })
                          }
                        >
                          My Calendar
                        </Menu.Item>

                        <Menu.Item
                          key='myTrainings1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'myTrainings' })
                          }
                        >
                          My Trainings
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          key='login1'
                          className={'font-Mont'}
                          onClick={() => (window.location.pathname = '/login')}
                        >
                          Sign-in
                        </Menu.Item>
                      </>
                    )}
                    <Menu.Item
                      key='trainingPrograms1'
                      className={'font-Mont'}
                      onClick={() =>
                        this.setState({ content: 'trainingPrograms' })
                      }
                    >
                      Training Programs
                    </Menu.Item>
                    {/* {userRole.toLowerCase() === 'admin' ||
                    userRole.toLowerCase() === 'tester' ||
                    userRole.toLowerCase() === 'spmsadmin' ? (
                      <Menu.Item
                        key='trainingPrograms2'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'onlinetrainingPrograms' })
                        }
                      >
                        Training Programs (Online only)
                      </Menu.Item>
                    ) : null}
                    {userData ? (
                      <>
                        <Menu.Item
                          key='trainingVideos'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'trainingVideos' })
                          }
                        >
                          Training Videos
                        </Menu.Item>
                      </>
                    ) : null} */}

                    {userRole.toLowerCase() === 'admin' ? (
                      <>
                        <SubMenu
                          key='feedback1'
                          className={'font-Mont'}
                          title='Comments / Feedback'
                        >
                          <Menu.Item
                            key='manageEtnaFeedback1'
                            className={'font-Mont'}
                            onClick={() =>
                              this.setState({ content: 'manageEtnaFeedback' })
                            }
                          >
                            for e-TNA
                          </Menu.Item>
                          {/* <Menu.Item
                              key="key05"
                              onClick={() => this.setState({ content: "manageILDPFeedback" })}
                            >
                              for ILDP
                            </Menu.Item> */}
                          <Menu.Item
                            key='manageFeedback1'
                            className={'font-Mont'}
                            onClick={() =>
                              this.setState({ content: 'manageFeedback' })
                            }
                          >
                            for Evaluation Form
                          </Menu.Item>
                        </SubMenu>

                        <SubMenu
                          key='manageForms1'
                          className={'font-Mont'}
                          title='Submitted Forms'
                        >
                          {/* <Menu.Item
                              key="key0"
                              onClick={() => this.setState({ content: "pendingUser" })}
                            >
                              Pending User
                            </Menu.Item> */}

                          <Menu.Item
                            key='manageEtna1'
                            className={'font-Mont'}
                            onClick={() =>
                              this.setState({ content: 'manageEtna' })
                            }
                          >
                            e-TNA Forms
                          </Menu.Item>

                          <Menu.Item
                            key='manageILDP1'
                            className={'font-Mont'}
                            onClick={() =>
                              this.setState({ content: 'manageILDP' })
                            }
                          >
                            ILDP Forms
                          </Menu.Item>
                        </SubMenu>
                        <Menu.Item
                          key='CSCOnlineVideosAccessList'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({
                              content: 'CSCOnlineVideosAccessList',
                            })
                          }
                        >
                          List of CSC Training Materials Access
                        </Menu.Item>

                        <Menu.Item
                          key='cscVideoTraining'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({
                              content: 'cscVideoTraining',
                            })
                          }
                        >
                          List of CSC Training Videos' Access
                        </Menu.Item>
                      </>
                    ) : (
                      ''
                    )}
                  </Menu.SubMenu>

                  <Menu.SubMenu
                    key='pds1'
                    className={'font-Mont'}
                    title='Personal Data Sheet'
                  >
                    {userData ? (
                      <Menu.Item
                        key='pdsItem1'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'SubmittedPDSForms' })
                        }
                      >
                        View / Edit Submitted PDS Forms
                      </Menu.Item>
                    ) : null}

                    {userData ? (
                      <Menu.Item
                        key='pdsItem2'
                        className={'font-Mont'}
                        onClick={() => this.setState({ content: 'PDSForm' })}
                      >
                        Create PDS Form
                      </Menu.Item>
                    ) : null}

                    {userData?.pdsRole === 'deptEncoder' ||
                    userData?.pdsRole === 'deptApproverAndEncoder' ? (
                      <Menu.Item
                        key='pdsItem3'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'PDSFormEncoder' })
                        }
                      >
                        Encode PDS Form
                      </Menu.Item>
                    ) : null}

                    {userData?.pdsRole === 'deptEncoder' ||
                    userData?.pdsRole === 'deptApproverAndEncoder' ? (
                      <Menu.Item
                        key='pdsItem4'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'SubmittedEncodedPDSForms' })
                        }
                      >
                        View / Edit Encoded PDS Forms
                      </Menu.Item>
                    ) : null}

                    {userData?.pdsRole === 'deptApprover' ||
                    userData?.pdsRole === 'deptApproverAndEncoder' ? (
                      <Menu.Item
                        key='pds2'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'SubmittedPDSFormsDept' })
                        }
                      >
                        Department PDS Forms (Department Approver)
                      </Menu.Item>
                    ) : null}

                    {userData?.pdsRole === 'hrApprover' ? (
                      <Menu.Item
                        key='pds3'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'SubmittedPDSFormsHR' })
                        }
                      >
                        Employees PDS Forms (HR Approver)
                      </Menu.Item>
                    ) : null}
                  </Menu.SubMenu>

                  <Menu.Item
                    key='faq1'
                    className={'font-Mont'}
                    onClick={() => this.setState({ content: 'faq' })}
                  >
                    FAQs
                  </Menu.Item>

                  {userRole.toLowerCase() === 'admin' ||
                  userRole.toLowerCase() === 'deptadmin' ? (
                    <>
                      <SubMenu
                        key='userManage1'
                        title='Users'
                        className={'font-Mont'}
                      >
                        {/* <Menu.Item
                            key="key0"
                            onClick={() => this.setState({ content: "pendingUser" })}
                          >
                            Pending User
                          </Menu.Item> */}

                        <Menu.Item
                          key='manageUser1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'manageUser' })
                          }
                        >
                          Manage User
                        </Menu.Item>
                      </SubMenu>

                      <SubMenu
                        key='sub100'
                        title='Training Videos & Materials'
                        className={'font-Mont'}
                      >
                        <Menu.Item
                          key='uploadAdmin1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'uploadAdmin' })
                          }
                        >
                          Training Videos & Materials
                        </Menu.Item>
                        <Menu.Item
                          key='uploadPubmat1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'uploadPubmat' })
                          }
                        >
                          Training Announcements
                        </Menu.Item>
                      </SubMenu>
                    </>
                  ) : null}

                  {userRole.toLowerCase() === 'supervisor' ? (
                    <SubMenu
                      key='supervisor1'
                      className={'font-Mont'}
                      icon={<UserOutlined />}
                      title='Employee'
                    >
                      <Menu.Item
                        key='viewEmployee1'
                        className={'font-Mont'}
                        onClick={() =>
                          this.setState({ content: 'viewEmployee' })
                        }
                      >
                        View Employees
                      </Menu.Item>
                    </SubMenu>
                  ) : null}
                  {!userData && (
                    <Menu.Item
                      key='login2'
                      className={'font-Mont'}
                      onClick={() => (window.location.pathname = '/login')}
                    >
                      Sign-in
                    </Menu.Item>
                  )}

                  {userRole.toLowerCase() === 'admin' ||
                  userRole.toLowerCase() === 'tester' ? (
                    <>
                      <SubMenu
                        key='tester'
                        className={'font-Mont'}
                        title='Tester'
                      >
                        <Menu.Item
                          key='trainingProgramsTester1'
                          className={'font-Mont'}
                          onClick={() =>
                            this.setState({ content: 'trainingProgramsTester' })
                          }
                        >
                          Training Programs
                        </Menu.Item>
                      </SubMenu>
                    </>
                  ) : null}
                  {/*{userData && (
                    <Menu.Item
                      key='mindscapes'
                      className={'font-Mont'}
                      onClick={() => this.handleMindscapesClick()}
                    >
                      <img
                        src='./../../../mindscapes/logo.png'
                        alt='Mindscapes'
                        style={{ height: '30px' }} // Adjust size as needed
                      />
                    </Menu.Item>
                  )}*/}
                </Menu>
              </Header>
            </div>

            <Content
              style={{
                width: '100%',
                // padding: 24,
                minHeight: 280,
              }}
            >
              {this.state.content === 'pendingUser' ? (
                <Users />
              ) : this.state.content === 'manageUser' ? (
                <ManageUsers
                  userData={userData}
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              ) : this.state.content === 'home' ? (
                <HomeContent
                  userData={userData}
                  afterSubmit={(el) => this.setState({ content: el })}
                  goto={(el) => this.setState({ content: el })}
                />
              ) : this.state.content === 'recruitment' ? (
                <Recruitment userData={userData} />
              ) : this.state.content === 'trainingPrograms' ? (
                <TrainingPrograms
                  userData={userData}
                  gotoCSCVideos={() =>
                    this.setState({ content: 'CSCOnlineVideos' })
                  }
                  gotoSecWeek={() =>
                    this.setState({ content: 'secondWeekCSC' })
                  }
                  gotoThirdWeek={() =>
                    this.setState({ content: 'thirdWeekCSC' })
                  }
                  gotoSept8={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept8' })
                  }
                  gotoSept8Batch2={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept8Batch2' })
                  }
                  gotosept15={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept15' })
                  }
                  gotoNov20={() =>
                    this.setState({ content: 'CSCOnlineTrainingNov20' })
                  }
                  gotoJan21={() =>
                    this.setState({ content: 'CSCOnlineTrainingJan21' })
                  }
                  gotoCSCMaterials={(e) => {
                    this.setState({ cscTrainID: e })
                    this.setState({ content: 'CSCOnlineMaterials' })
                  }}
                  gotoForms={() => this.setState({ content: 'employeeForms' })}
                  triggerScroll={this.state.content}
                />
              ) : this.state.content === 'onlinetrainingPrograms' ? (
                <OnlineTrainingPrograms
                  userData={userData}
                  gotoForms={() => this.setState({ content: 'employeeForms' })}
                  triggerScroll={this.state.content}
                />
              ) : this.state.content === 'trainingProgramsTester' ? (
                <TrainingProgramsTester
                  userData={userData}
                  gotoCSCVideos={() =>
                    this.setState({ content: 'CSCOnlineVideos' })
                  }
                  gotoSecWeek={() =>
                    this.setState({ content: 'secondWeekCSC' })
                  }
                  gotoThirdWeek={() =>
                    this.setState({ content: 'thirdWeekCSC' })
                  }
                  gotoSept8={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept8' })
                  }
                  gotoSept8Batch2={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept8Batch2' })
                  }
                  gotosept15={() =>
                    this.setState({ content: 'CSCOnlineTrainingSept15' })
                  }
                  gotoNov20={() =>
                    this.setState({ content: 'CSCOnlineTrainingNov20' })
                  }
                  gotoJan21={() =>
                    this.setState({ content: 'CSCOnlineTrainingJan21' })
                  }
                  gotoCSCMaterials={(e) => {
                    this.setState({ cscTrainID: e })
                    this.setState({ content: 'CSCOnlineMaterials' })
                  }}
                  gotoForms={() => this.setState({ content: 'employeeForms' })}
                  triggerScroll={this.state.content}
                />
              ) : this.state.content === 'aboutUs' ? (
                <AboutUs />
              ) : this.state.content === 'employeeHandbook' ? (
                <EmployeeHandbook />
              ) : this.state.content === 'empAcknowledgement' ? (
                <EmpAcknowledgement />
              ) : this.state.content === 'orientationVideos' ? (
                <OrientationVideos userData={userData} />
              ) : this.state.content === 'orientVideosAcknowledgement' ? (
                <OrientVideosAcknowledgement />
              ) : this.state.content ===
                'strategicPerformanceManagementSystem' ? (
                <StrategicPerformanceManagementSystem userData={userData} />
              ) : this.state.content ===
                'strategicPerformanceManagementSystemAknowledgement' ? (
                <StrategicPerformanceManagementSystemAcknowledgement />
              ) : this.state.content === 'LDRefresherViewLists' ? (
                <LDRefresherViewLists />
              ) : this.state.content === 'cscVideoTraining' ? (
                <CscOnlineVideosAcknowledgement />
              ) : this.state.content === 'faq' ? (
                <FAQs role={userRole} />
              ) : this.state.content === 'contactUs' ? (
                <ContactUs />
              ) : this.state.content === 'siteFeedback' ? (
                <SiteFeedback />
              ) : this.state.content === 'uploadAdmin' ? (
                <UploadAdmin userData={userData} />
              ) : this.state.content === 'manageEtna' ? (
                <ManageEtna
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              ) : this.state.content === 'manageILDP' ? (
                <ManageILDP
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              ) : this.state.content === 'manageFeedback' ? (
                <ManageFeedback
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              ) : this.state.content === 'manageEtnaFeedback' ? (
                <ManageEtnaFeedBack
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              ) : this.state.content === 'viewEmployee' ? (
                <Employee userData={userData} />
              ) : this.state.content === 'createForm' ? (
                <CreateForm
                  afterSubmit={(el) => this.setState({ content: el })}
                  user={userData}
                  refresh={(e) => {
                    this.setState({ content: '' })
                    this.checkFinal(userData)
                    setUpdated(e)
                  }}
                  updated={updated}
                />
              ) : this.state.content === 'createForm2' ? (
                <CreateForm2
                  afterSubmit={(el) => this.setState({ content: el })}
                  user={userData}
                  refresh={(e) => {
                    setUpdated(e)
                    this.setState({ content: '' })
                  }}
                />
              ) : this.state.content === 'viewForm' ? (
                <ViewForm
                  afterSubmit={(el) => this.setState({ content: el })}
                  user={userData}
                  refresh={(e) => setUpdated(e)}
                  viewer='self'
                />
              ) : this.state.content === 'loginhome' ? (
                <LoginHome />
              ) : this.state.content === 'CSCOnlineVideos' ? (
                <CSCOnlineVideos userData={userData} />
              ) : this.state.content === 'secondWeekCSC' ? (
                <CSCOnlineVideosWeek2 userData={userData} />
              ) : this.state.content === 'thirdWeekCSC' ? (
                <CSCOnlineVideosWeek3 userData={userData} />
              ) : this.state.content === 'CSCOnlineTrainingSept8' ? (
                <CSCOnlineTrainingSept8 userData={userData} />
              ) : this.state.content === 'CSCOnlineTrainingSept8Batch2' ? (
                <CSCOnlineTrainingSept8Batch2 userData={userData} />
              ) : this.state.content === 'CSCOnlineTrainingSept15' ? (
                <CSCOnlineTrainingSept15 userData={userData} />
              ) : this.state.content === 'CSCOnlineTrainingNov20' ? (
                <CSCOnlineTrainingNov20 userData={userData} />
              ) : this.state.content === 'CSCOnlineTrainingJan21' ? (
                <CSCOnlineTrainingJan21 userData={userData} />
              ) : this.state.content === 'CSCOnlineVideosAccessList' ? (
                <CSCOnlineVideosAccessList userData={userData} />
              ) : this.state.content === 'SubmittedPDSForms' ? (
                <SubmittedPDSForms />
              ) : this.state.content === 'PDSForm' ? (
                <PDSForm afterSubmit={(e) => this.setState({ content: e })} />
              ) : this.state.content === 'PDSFormEncoder' ? (
                <MainEncoderPage
                  encoderData={userData}
                  nextPage={(e) => this.setState({ content: 'PDSForm2' })}
                  employeeToBeEncoded={(d) =>
                    this.setState({ employeeToBeEncoded: d })
                  }
                />
              ) : this.state.content === 'PDSForm2' ? (
                <PDSEncode
                  userData={userData}
                  employeeToBeEncoded={this.state.employeeToBeEncoded}
                  afterSubmit={() =>
                    this.setState({ content: 'SubmittedEncodedPDSForms' })
                  }
                />
              ) : this.state.content === 'SubmittedPDSFormsDept' ? (
                <SubmittedPDSFormsDept />
              ) : this.state.content === 'SubmittedEncodedPDSForms' ? (
                <SubmittedEncodedPDSForms />
              ) : this.state.content === 'SubmittedPDSFormsHR' ? (
                <SubmittedPDSFormsHR />
              ) : this.state.content === 'CSCOnlineMaterials' ? (
                <CSCOnlineMaterials
                  userData={userData}
                  cscTrainID={this.state.cscTrainID}
                  gotoCSCVideos={() =>
                    this.setState({ content: 'CSCOnlineVideos' })
                  }
                />
              ) : this.state.content === 'refresher' ? (
                <LDRefresher userData={userData} />
              ) : this.state.content === 'viewEmpForm' ? (
                <FormEmployee userData={userData} />
              ) : this.state.content === 'uploadedILDP' ? (
                <UploadedILDP userData={userData} />
              ) : this.state.content === 'uploadPubmat' ? (
                <UploadTrainings userData={userData} />
              ) : this.state.content === 'userCalendar' ? (
                <UserCalendar userData={userData} />
              ) : this.state.content === 'myTrainings' ? (
                <MyTrainings userData={userData} />
              ) : this.state.content === 'internship' ? (
                <InternLanding goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'volunteer' ? (
                <VolunteersCorner />
              ) : this.state.content === 'institutionalDev' ? (
                <InstitutionalDev
                  goto={(el) => this.setState({ content: el })}
                />
              ) : this.state.content === 'internNewsLetter' ? (
                <InternNewsLetter
                  goto={(el) => this.setState({ content: el })}
                />
              ) : this.state.content === 'internRequest' ? (
                <InternRequest goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'SPMS' ? (
                <FormSPMS goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'SPMSDeptAdmin' ? (
                <DeptAdminSPMS goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'SPMSSupervisor' ? (
                <SupervisorSPMS goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'trainingVideos' ? (
                <TrainingVideos goto={(el) => this.setState({ content: el })} />
              ) : this.state.content === 'surveyFeedback' ? (
                <SurveyFeedback
                  afterSubmit={(el) => this.setState({ content: el })}
                  user={userData}
                  refresh={(e) => {
                    setUpdated(e)
                    this.setState({ content: '' })
                  }}
                />
              ) : (
                <Dashboard
                  afterSubmit={(el) => this.setState({ content: el })}
                  data={userData}
                  final={this.state.isFinal}
                  content={(e) => this.setState({ content: e })}
                  refresh={() => this.checkFinal(userData)}
                  refresh1={(e) => {
                    setUpdated(e)
                    this.setState({ content: '' })
                  }}
                  assignedDepartment={
                    userRole.toLocaleLowerCase() === 'admin'
                      ? ''
                      : userData.assignedDept
                  }
                />
              )}

              <Modal
                className='landing-modal'
                maskClosable={false}
                title=''
                closable={false}
                open={this.state.isHomeModal}
                style={{ top: 20 }}
                centered
                footer={[
                  <Button
                    key='submit'
                    onClick={() => this.setState({ isHomeModal: false })}
                  >
                    Close
                  </Button>,
                ]}
              >
                <div>
                  <h4 className='landing-modal-title'>
                    Human Resource @ Quezon City Government
                  </h4>

                  <Row justify='center'>
                    <span className='my-3'>
                      Please sign in to access the platform resources.
                    </span>
                  </Row>

                  <Button
                    shape='round'
                    onClick={() => (window.location.pathname = '/login')}
                    size='large'
                    type='primary'
                    block
                  >
                    <b>Sign In</b>
                  </Button>
                  <Row justify='center'>
                    <span className='mt-3'>
                      Don't have an account?{' '}
                      <a href='/register' className='landing-modal-link'>
                        Click here to register
                      </a>
                    </span>
                  </Row>
                </div>
              </Modal>

              <div>
                {isEmployeeModal && (
                  <Modal
                    open={isEmployeeModal}
                    onCancel={this.handleCancel}
                    footer={[
                      <Button key='cancel' onClick={this.handleCancel}>
                        Cancel
                      </Button>,
                    ]}
                  >
                    <div className='mt-4'>
                      <Alert
                        message='Missing Employee ID Number'
                        description='Your Employee ID Number is missing. Please go to your profile and enter your Employee ID Number to update it.'
                        type='warning'
                        showIcon
                        style={{ fontSize: '13px' }}
                      />
                    </div>
                  </Modal>
                )}
              </div>
            </Content>
            {showApmtStatus && (
              <Modal
                open={showApmtStatus}
                onCancel={() => this.setState({ showApmtStatus: false })}
                role='alertdialog'
                aria-labelledby='appointment-status-title'
                aria-describedby='appointment-status-description'
                footer={[
                  <Button
                    key='cancel'
                    onClick={() => this.setState({ showApmtStatus: false })}
                    aria-label='Close appointment status warning'
                  >
                    Cancel
                  </Button>,
                ]}
              >
                <div className='mt-4'>
                  <Alert
                    message='Missing Employee Appointment Status'
                    id='appointment-status-modal-title'
                    description='Your Employee Appointment Status is missing. Please go to your profile and select your Employee Appointment Status to update it.'
                    aria-describedby='appointment-status-modal-description'
                    type='warning'
                    showIcon
                    style={{ fontSize: '13px' }}
                  />
                </div>
              </Modal>
            )}
            <Footer className='footer-distributed'>
              <div className='footer-left col-3'>
                <div className='d-md-flex flex-md-column justify-content-md-center align-items-md-center'>
                  <img
                    src='https://i.imgur.com/Ta9Dnxv.png'
                    width='150'
                    alt=''
                  />

                  <p className='footer-company-name mt-3'>
                    Quezon City Human Resource &copy; 2022
                  </p>
                </div>
              </div>

              <div className='footer-center col-6'>
                <div className='d-flex justify-content-center'>
                  <div>
                    <div className='d-flex align-items-center'>
                      <i className='fa fa-map-marker'></i>
                      <p>
                        <a
                          target='_blank'
                          href='https://www.google.com/maps/place/Quezon+City+Hall/@14.6463858,121.0498468,21z/data=!4m14!1m7!3m6!1s0x3397b70950b66739:0xa0f8a709cb1843a7!2sQuezon+City+Hall!8m2!3d14.6464639!4d121.0500976!16s%2Fg%2F11c1c4nxpc!3m5!1s0x3397b70950b66739:0xa0f8a709cb1843a7!8m2!3d14.6464639!4d121.0500976!16s%2Fg%2F11c1c4nxpc?entry=ttu'
                        >
                          <span>Elliptical Road, Brgy. Central,</span> Diliman,
                          Quezon City
                        </a>
                      </p>
                    </div>
                    <div className='d-flex align-items-center'>
                      <i className='fa fa-fax'></i>
                      <p>
                        <a href='tel:+63289884242'>89884242 loc. 8504</a>
                      </p>
                    </div>
                    <div className='d-flex align-items-center'>
                      <i className='fa fa-envelope'></i>
                      <p>
                        <a href='mailto:HRMD@quezoncity.gov.ph'>
                          HRMD@quezoncity.gov.ph
                        </a>
                        <br />
                        <a href='mailto:SHRU@quezoncity.gov.ph'>
                          SHRU@quezoncity.gov.ph
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='footer-right text-center col-3'>
                <p className='footer-company-about'>
                  <span>See us on</span>
                </p>
                <div className='footer-icons'>
                  <a href='#'>
                    <i className='fa fa-facebook'></i>
                  </a>
                  <a href='#'>
                    <i className='fa fa-twitter'></i>
                  </a>
                </div>
              </div>
            </Footer>
          </Layout>
        </div>
        <MindscapesModal
          userData={this.state.userData}
          onClose={(e) => this.setState({ isMindscapesModalOpen: e })}
          open={this.state.isMindscapesModalOpen}
        />
      </div>
    )
  }
}
