/**
 * Title: PDSVideo.js
 * Description: This is a file that contains the components for the PDS video guide.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSVideo.js
 **/
import { useState } from 'react'

import { Button, Modal, Row } from 'antd'

import { ArrowRightOutlined } from '@ant-design/icons'

import PDSVideoLoader from './PDSVideoLoader'

export default function PDSVideo({ setIsOpenPDSCacheMessage }) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Row className='me-4 my-4' justify='end'>
      <Button
        type='default'
        shape='round'
        size='large'
        onClick={() => setIsOpen(true)}
        style={{ backgroundColor: 'green' }}
      >
        <Row>
          <ArrowRightOutlined style={{ color: 'white' }} />
          <b className='ms-2'>
            <em style={{ color: 'white' }}>
              Click here to watch the video guide.
            </em>
          </b>
        </Row>
      </Button>
      <Modal
        title='PDS Video Guide'
        open={window.location.href.includes('localhost') ? false : isOpen}
        width={'50vw'}
        footer={null}
        onCancel={() => {
          setIsOpen(false)
          if (setIsOpenPDSCacheMessage) {
            setIsOpenPDSCacheMessage(true)
          }
        }}
        destroyOnClose={true}
      >
        <PDSVideoLoader />
      </Modal>
    </Row>
  )
}
